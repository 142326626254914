import styled from "styled-components";

export const Image = styled.div`
    height: 360px;
    width: 360px;

    img {
        height: 360px;
        width: 360px;
    }
`