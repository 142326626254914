import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    .marker-cluster-custom {
        background: #9370db;
        border: 3px solid #ededed;
        border-radius: 50%;
        color: #ededed;
        height: 40px;
        line-height: 37px;
        text-align: center;
        width: 40px;
    }
    .leaflet-map{
        width: 100%;
        height: 100%;
        position: fixed !important;
    }
`