import React from 'react';
import globalStyle from '../../styles/globalStyle';

const LotesIcon = ({ largura, altura, color = globalStyle.colors.GRAY100 }) => {
  return (
    <svg
      width={largura}
      height={altura}
      viewBox="0 0 24 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline', marginRight: 10, marginLeft: 10 }}
    >
      <g clipPath="url(#clip0_2743_7749)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 3.87109C6.30964 3.87109 5.75 4.43074 5.75 5.12109V19.1211C5.75 19.8115 6.30964 20.3711 7 20.3711H17C17.6904 20.3711 18.25 19.8114 18.25 19.1211V5.12109C18.25 4.43074 17.6904 3.87109 17 3.87109H7ZM4.25 5.12109C4.25 3.60231 5.48122 2.37109 7 2.37109H17C18.5188 2.37109 19.75 3.60231 19.75 5.12109V19.1211C19.75 20.6399 18.5188 21.8711 17 21.8711H7C5.48122 21.8711 4.25 20.6399 4.25 19.1211V5.12109ZM8.25 7.12109C8.25 6.70688 8.58579 6.37109 9 6.37109H15C15.4142 6.37109 15.75 6.70688 15.75 7.12109C15.75 7.53531 15.4142 7.87109 15 7.87109H9C8.58579 7.87109 8.25 7.53531 8.25 7.12109ZM8.25 11.1211C8.25 10.7069 8.58579 10.3711 9 10.3711H15C15.4142 10.3711 15.75 10.7069 15.75 11.1211C15.75 11.5353 15.4142 11.8711 15 11.8711H9C8.58579 11.8711 8.25 11.5353 8.25 11.1211ZM8.25 15.1211C8.25 14.7069 8.58579 14.3711 9 14.3711H13C13.4142 14.3711 13.75 14.7069 13.75 15.1211C13.75 15.5353 13.4142 15.8711 13 15.8711H9C8.58579 15.8711 8.25 15.5353 8.25 15.1211Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2743_7749">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0 0.121094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LotesIcon;
