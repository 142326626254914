import React, { useState } from 'react';
import { Grid, Row, Col } from 'rsuite';
import {
  Flex,
  Heading,
  FormControl,
  Button,
  CircularProgress,
  InputGroup,
  InputRightElement,
  Icon,
  Image
} from '@chakra-ui/core';

import { userLogin } from '../../hooks/auth';
import ErrorMessage from '../../components/ErrorMessage';
import logo from '../../assets/logo.png';
import playStore from '../../assets/play-store.png';
import appStore from '../../assets/app-store.png';
import styled from 'styled-components';
import FormLabel from '../../components/UI/FormLabel';
import Input from '../../components/UI/Input';
import { Box, ForgetText, BaixeAppText } from './styled';
import validator from 'validator';
import globalStyle from '../../styles/globalStyle';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      await userLogin({ email, password });
      setIsLoading(false);
      setShowPassword(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
      setShowPassword(false);
    }
  };

  const handleForgot = () => {
    window.location.pathname = '/esqueci-senha';
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  // const validateEmail = (e) => {
  //   var email = e.target.value

  //   if (validator.isEmail(email)) {
  //     setEmailError('Valid Email :)')
  //   } else {
  //     setEmailError('Enter valid Email!')
  //   }
  // }

  return (
    <Flex width="full" align="center" justifyContent="center" h={'100dvh'}>
      <Box
        p={8}
        maxWidth="380px"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
        style={{ marginTop: 50 }}
      >
        <>
          <Box>
            <Heading mb={8}>
              <Image src={logo} alt="Precious Group" objectFit='contain' />
            </Heading>
          </Box>
          <Box mt={4} mb={0} textAlign="left">
            <form onSubmit={handleSubmit}>
              {error && <ErrorMessage message={error} />}
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Digite seu e-mail"
                  size="lg"
                  focusBorderColor="gray"
                  onChange={(event) => setEmail(event.currentTarget.value)}
                />
              </FormControl>
              <FormControl isRequired mt={6}>
                <FormLabel>Senha</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="*******"
                    size="lg"
                    autoComplete="off"
                    focusBorderColor="gray"
                    onChange={(event) => setPassword(event.currentTarget.value)}
                  />
                  <InputRightElement width="3rem">
                    <Button
                      h="1.5rem"
                      size="sm"
                      onClick={handlePasswordVisibility}
                    >
                      {showPassword ? (
                        <Icon name="view-off" />
                      ) : (
                        <Icon name="view" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button variant="outline" style={{ border: `solid 1px ${globalStyle.colors.GRAY100}`, color: globalStyle.colors.GRAY900 }} type="submit" width="full" mt={4}>
                {isLoading ? (
                  <CircularProgress isIndeterminate size="24px" color={globalStyle.colors.GRAY100} />
                ) : (
                  'Entrar '
                )}
              </Button>
              <ForgetText onClick={handleForgot}>
                Esqueci minha senha
              </ForgetText>
            </form>
          </Box>

          <Box>
            <Grid fluid>
              <Row>
                <Col xs={24}>
                  <BaixeAppText>Baixe nosso aplicativo</BaixeAppText>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.siber"
                    target="_blank"
                  >
                    <Image
                      src={playStore}
                      width="149px"
                      height="43.8px"
                      alt="Disponível no Google Play"
                    />
                  </a>
                </Col>
                <Col xs={12}>
                  <a
                    href="https://apps.apple.com/us/app/siber-agente/id1629535813"
                    target="_blank"
                  >
                    <Image
                      src={appStore}
                      width="131.1px"
                      height="43.8px"
                      alt="Baixar na App Store"
                    />
                  </a>
                </Col>
              </Row>
            </Grid>
          </Box>
        </>
      </Box>
    </Flex>
  );
}
