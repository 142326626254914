import { Modal } from "rsuite";
import styled from "styled-components";

export const Container = styled(Modal)`
    width: 276px;
    /* height: 154px; */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -125px; /* Metade da altura */
    margin-left: -125px; /* Metade da largura */
    .rs-modal-content {
        position: relative;
        background: ${props => props.theme.colors.white};
        width: 300px;
        height: 100%;

        .rs-modal-header-close {
            color: ${props => props.theme.colors.GRAY900};
        }

        .rs-modal-body {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
`

export const Title = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.colors.GRAY900};
`

export const BodyText = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.colors.GRAY600};
`

export const BotaoAcaoContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 38px;
    margin-top: 15px;
    margin-bottom: 20px;
`