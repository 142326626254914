import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 1rem;
  padding-left: 20px;
  background-color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
  
  button {
    height: 3rem;
    padding: 0 2rem;
    color: #fff;
    border-color: var(--info-color);
    border-radius: 0.25rem;
    border: none;
    margin-bottom: 1.25rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    &:focus {
      outline: none;
    }
  }

  .quantidade_container {
    display: flex;

    p {
      color: ${props => props.theme.colors.GRAY100};
    }

    input {
      width: 50px;
      margin-left: 16px;
      color: ${props => props.theme.colors.GRAY100};
      background: transparent;
      border-bottom-width: 1px;
      ${props => props.theme.colors.GRAY800};
    }
  }

  .total_items {
    margin-right: 20px;

    div {
      margin-left: 8px;
      color: ${props => props.theme.colors.GRAY100};
    }
  }
`;


export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`;

export const ContainerSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  @media screen and (max-width: 560px) {
    align-items: flex-start;
    flex-direction: column;
    p {
      margin-top: 10px;
    }
    div {
      flex-direction: column;
    }
  }

  label,
  p {
    color: #fff;
  }

  span {
    color: #fff;
    font-weight: bold;
  }

  select {
    background-color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
    color: ${(props) => props.theme.colors.GRAY100};
    border: 2px solid ${(props) => props.theme.colors.GRAY100};
    padding: 0 10px;
    margin: 5px 10px 0px 10px;
  }

  input {
    width: 50px;
    padding: 0 10px;
    height: 2rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    display: inline-block;
    cursor: pointer;
    margin: 5px 10px 0px 10px;
    color: ${(props) => props.theme.colors.GRAY100};

    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }

    & + input {
      margin-top: 0.25rem;
    }
  }
`;

export const ContainerQuantidade = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;

  label {
    color: #fff;
  }
`;
export const InputSearch = styled.input`
  width: 20rem !important;
  padding: 0 1.5rem;
  height: 3rem;
  border-radius: 0.25rem 0 0 0;
  border: 1px solid #d7d7d7;
  background: #e7e9ee;
  margin-left: 1rem;
  font-weight: 400;
  font-size: 1rem;
  &::placeholder {
    color: var(--text-body);
  }
  @media screen and (max-width: 560px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-left: 0;
    width: 15rem !important;
  }
`;
