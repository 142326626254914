import { useState } from 'react';
import { CatalogoTable } from '../../components/CatalogoTable/index';
import { NewCatalogoModal } from '../../components/NewCatalogoModal/index';
import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { CatalogoProvider, useCatalogo } from '../../hooks/catalogo';
import { EditarCatalogoModal } from '../../components/EditarCatalogoModal/index';
import { SearchCatalogoModal } from '../../components/SearchCatalogoModal/index';
import { VisualizarCatalogoModal } from '../../components/VisualizarCatalogoModal/index';
import { Container, Header, Sidebar as Sidenav, Content, Loader } from 'rsuite';
import { usePerfil } from '../../hooks/perfil';
import { Navigate } from 'react-router-dom';
import globalStyle from '../../styles/globalStyle';

export function Catalogo() {
  let app = 'catalogo';
  const [isEditCatalogoModalOpen, setIsEditCatalogoModalOpen] = useState(false);
  const [isNewCatalogoModalOpen, setIsNewCatalogoModalOpen] = useState(false);
  const [isViewCatalogoModalOpen, setIsViewCatalogoModalOpen] = useState(false);
  const [isSearchCatalogoModalOpen, setIsSearchCatalogoModalOpen] =
    useState(false);
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();
  const {
    isLoading,
    isLoadingSearch
  } = useCatalogo();

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_' + app + '_visualizar') == false &&
    permissaoPerfil.includes('per_' + app + '_adicionar') == false &&
    permissaoPerfil.includes('per_' + app + '_editar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }

  function handleOpenNewCatalogoModal() {
    setIsNewCatalogoModalOpen(true);
  }

  function handleCloseNewCatalogoModal() {
    setIsNewCatalogoModalOpen(false);
  }

  function handleOpenEditCatalogoModal() {
    setIsEditCatalogoModalOpen(true);
  }

  function handleCloseEditCatalogoModal() {
    setIsEditCatalogoModalOpen(false);
  }

  function handleOpenViewCatalogoModal() {
    setIsViewCatalogoModalOpen(true);
  }

  function handleCloseViewCatalogoModal() {
    setIsViewCatalogoModalOpen(false);
  }

  function handleOpenSearchCatalogoModal() {
    setIsSearchCatalogoModalOpen(true);
  }

  function handleCloseSearchCatalogoModal() {
    setIsSearchCatalogoModalOpen(false);
  }

  return (
    <>
      <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
        <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
          <Sidebar activePage={'3'} />
        </Sidenav>
        <Container style={{ width: '80%' }}>
          <Header style={{ minHeight: '3.6rem' }}>
            <Topbar
              pageTitle={'Catálogo'}
              onOpenSearchCatalogoModal={handleOpenSearchCatalogoModal}
            />
          </Header>
          <Content>
            <CatalogoTable
              onOpenNewCatalogoModal={handleOpenNewCatalogoModal}
              onCloseNewCatalogoModal={handleCloseNewCatalogoModal}
              onOpenEditCatalogoModal={handleOpenEditCatalogoModal}
              onOpenViewCatalogoModal={handleOpenViewCatalogoModal}
            />

            <NewCatalogoModal
              isOpen={isNewCatalogoModalOpen}
              onRequestClose={handleCloseNewCatalogoModal}
            />

            {isEditCatalogoModalOpen ? (
              <EditarCatalogoModal
                isOpenEdit={isEditCatalogoModalOpen}
                onRequestCloseEdit={handleCloseEditCatalogoModal}
              />
            ) : null}

            {isViewCatalogoModalOpen ? (
              <VisualizarCatalogoModal
                isOpenView={isViewCatalogoModalOpen}
                onRequestCloseView={handleCloseViewCatalogoModal}
                onOpenEditCatalogoModal={handleOpenEditCatalogoModal}
              />
            ) : null}

            {isSearchCatalogoModalOpen ? (
              <SearchCatalogoModal
                isOpenSearch={isSearchCatalogoModalOpen}
                onRequestCloseSearch={handleCloseSearchCatalogoModal}
              />
            ) : null}
          </Content>
        </Container>
      </Container>
      {loadingPer || isLoading || isLoadingSearch ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : null}
    </>
  );
}
