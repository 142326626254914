import styled from 'styled-components';
import NumberFormatUI from 'react-number-format';

const NumberFormat = styled(NumberFormatUI)(({ theme }) => ({
  backgroundColor: theme.colors.TRANSPARENT,
  color: theme.colors.GRAY100
  // border: `2px solid ${theme.colors.DARK400}`
}));

export default NumberFormat;
