import { Table, Container, Pagination, Alert } from 'rsuite';
import * as S from './styles';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useExtrato } from '../../hooks/extrato';
import Skeleton from 'react-loading-skeleton';
import { VerCompraModal } from '../../components/VerCompraModal/index';
import { ConfirmaExcluirCompraModal } from '../ConfirmaExcluirCompraModal/index';
import { useCompra } from '../../hooks/compra';
import TableComponent from '../UI/TableComponent';
import { usePerfil } from '../../hooks/perfil';
import Button from '../UI/Button';
import ConfirmaModal from '../ConfirmaModal';
import globalStyle from '../../styles/globalStyle';
import ObservacaoModal from '../ObservacaoModal';

export function VerLotesTable() {
  const app = 'visualizar_lotes';
  const { id } = useParams();
  const { codigoLote } = useParams();
  const { permissaoPerfil, tipoPerfil } = usePerfil();
  const [isConfirmaModalOpen, setIsConfirmaModalOpen] = useState(false);
  const [isEditando, setIsEditando] = useState(false);
  const [titleConfirmaModal, setTitleConfirmaModal] = useState('');
  const [produtos, setProdutos] = useState('');
  const [uuid, setUuid] = useState('');
  const [listaFinalizar, setListaFinalizar] = useState([]);
  const [isConfirmDeleteExtratoModalOpen, setIsConfirmDeleteExtratoModalOpen] = useState(false);
  const [isConfirmFinalizarExtratoModalOpen, setIsConfirmFinalizarExtratoModalOpen] = useState(false);
  const [isConfirmEditExtratoModalOpen, setIsConfirmEditExtratoModalOpen] = useState(false);
  const [isConfirmCancelEditExtratoModalOpen, setIsConfirmCancelEditExtratoModalOpen] = useState(false);
  const [observacaoModal, setObservacaoModal] = useState(false);
  const [observacao, setObservacao] = useState('');

  const {
    getCompraPorUuid,
    isLoading,
    CompraPorUUID,
    compraDownload,
    isLoadingDownload,
    verCompraPorUuid,
    VerCompraPorUUID,
    paginationUUID,
    CompraModalPage,
    VisualizarCompraPorUUID,
    CompraPageUUID,
    orderCompra,
    deletarCompra,
    atualizaCompra
  } = useCompra();
  const { editExtrato } = useExtrato();

  const handleOrderingColumn = (event, column) => {
    const columns = document.getElementsByTagName('th');
    for (let column of columns) {
      column.classList.add('columnDefault');
    }

    let el = event.target;
    el.classList.remove('columnDefault');
    el.classList.add('columnActive');

    if (el.lastChild.classList.contains('rs-icon-arrow-up')) {
      el.lastChild.classList.remove('rs-icon-arrow-up');
      el.lastChild.classList.add('rs-icon-arrow-down');
      orderCompra(column, 'DESC', paginationUUID[0].current_page);
    } else {
      el.lastChild.classList.remove('rs-icon-arrow-down');
      el.lastChild.classList.add('rs-icon-arrow-up');
      orderCompra(column, 'ASC', paginationUUID[0].current_page);
    }
  };

  function handleOpenConfirmCancelarEditModal() {
    setIsConfirmCancelEditExtratoModalOpen(true);
  }

  function handleCloseConfirmCancelarEditModal() {
    setIsConfirmCancelEditExtratoModalOpen(false);
  }

  function handleCloseConfirmFinalizarExtratoModal() {
    setIsConfirmFinalizarExtratoModalOpen(false);
  }

  function handleOpenEditExtratoModal() {
    setIsConfirmEditExtratoModalOpen(true);
  }

  function handleCloseConfirmEditExtratoModal() {
    setIsConfirmEditExtratoModalOpen(false);
  }

  const handleCancelEdit = async () => {
    await handleGetData();
    setIsEditando(false);
    setIsConfirmCancelEditExtratoModalOpen(false);
  }

  const handleCodigo = async (event) => {
    let zero = false;
    event.preventDefault();

    produtos.forEach((p) => {
      if (p.qty == 0) {
        Alert.error('A quantidade não pode ser 0 !');
        zero = true;
        return;
      }
    })

    if (zero == false) {
      let arrayExpandida = await expandArray(produtos);

      let data = {
        catalogos: arrayExpandida,
        usuario: CompraPorUUID[0]?.usuario,
      };

      await atualizaCompra(codigoLote, data, id, handleCloseConfirmEditExtratoModal, CompraPorUUID[1]);
      setIsEditando(false);
    }

  };

  const handleFinalizaCompra = async (event) => {
    event.preventDefault();

    function formatarValor(valor) {
      let valorFormatado;

      valorFormatado = `${valor.replace('.', ',')}`;

      return valorFormatado;
    }

    const vendaTipo = '4';

    const formData = {
      uuid: CompraPorUUID[0]?.uuid,
      usuario: CompraPorUUID[0]?.usuario,
      quantidade: produtos.length,
      qtd_bruta: CompraPorUUID[0]?.Catalogo.length,
      descricao: vendaTipo,
      status: CompraPorUUID[0]?.status,
      valor: formatarValor(CompraPorUUID[0]?.valor?.real),
      codigo: CompraPorUUID[0]?.codigo,
      observacao
    };

    await editExtrato(formData, handleCloseConfirmFinalizarExtratoModal);
  };

  const handleUrlLotes = () => {
    let url = window.location.href;

    let newUrl = url.replace(codigoLote, '');
    newUrl = newUrl.slice(0, -1);
    return newUrl;
  }

  const handleGetData = async () => {
    await getCompraPorUuid(codigoLote, paginationUUID.current_page);

    await handleItems(CompraPorUUID[0]?.Catalogo);
  }

  useEffect(() => {
    if (codigoLote) handleGetData();
  }, []);

  const handleSelect = async (eventKey) => {
    await CompraModalPage(codigoLote, eventKey);
  };

  const handleEditQty = (qtd, uuid, index) => {
    let prod = produtos.find((e) => e.uuid == uuid);
    prod.qty = qtd;
    produtos[index] = prod;

    setProdutos(produtos);
    console.log("PROD", produtos);
  };

  const handleItems = async (items) => {
    const products = items.reduce((acc, item) => {
      let found = acc.find(i => i.uuid == item.uuid);

      if (found) {
        found.qty++;
      } else {
        item.qty = 1;

        acc.push(item);
      }

      return acc;
    }, []);

    CompraPorUUID[0].Catalogo = products;
    setProdutos(products);
  }

  function expandArray(reducedArray) {
    let expandedArray = [];
    reducedArray.forEach(item => {
      for (let i = 0; i < item.qty; i++) {
        expandedArray.push(item);
      }
    });
    return expandedArray;
  }


  const handleOpenObservacaoModal = () => setObservacaoModal(true);

  const handleCloseObservacaoModal = () => setObservacaoModal(false);

  return (
    <div>
      <S.Container>
        {isLoading ? (
          <Skeleton count={8} />
        ) : (
          <>
            <TableComponent
              page={app}
              pagination={paginationUUID}
              handleOrderingColumn={handleOrderingColumn}
              handleClickEdit={setIsEditando}
              handleEditQty={handleEditQty}
              isEditando={isEditando}
              isLoading={isLoading}
              lista={produtos ? produtos : []}
              loteData={CompraPorUUID[0] ? CompraPorUUID[0] : []}
              columns={[
                { header: '', body: 'imagem' },
                { header: 'Marca', body: 'marca' },
                { header: 'Modelo', body: 'nome' },
                { header: 'Código 1', body: 'codigo' },
                { header: 'Código 2', body: 'codigo2' },
                { header: 'Código 3', body: 'codigo3' },
                { header: 'Qtd', body: 'quantidade' },
                { header: 'Preço', body: 'preco' },
                { header: 'Preço/Kg', body: 'preco/kg' },
                { header: 'Peso (Kg)', body: 'peso/kg' },
              ]}
              // sempre enviar valores no mesmo index da prop "columns", componente pega pelo index
              orderingByColumnsIndex={['', '', '', '']}
            />
            {isConfirmFinalizarExtratoModalOpen ? (
              <ConfirmaModal
                show={isConfirmFinalizarExtratoModalOpen}
                setShow={setIsConfirmFinalizarExtratoModalOpen}
                bodyText={'O lote não poderá ser alterado depois de finalizado. Tem certeza?'}
                title={'Finalizar Lote'}
                btnAcaoTexto={'Finalizar'}
                submitFn={handleFinalizaCompra}
              />
            ) : null}
            {isConfirmEditExtratoModalOpen ? (
              <ConfirmaModal
                show={isConfirmEditExtratoModalOpen}
                setShow={setIsConfirmEditExtratoModalOpen}
                bodyText={'Confirma a atualização?'}
                title={'Atualizar Lote'}
                btnAcaoTexto={'Finalizar'}
                submitFn={handleCodigo}
              />
            ) : null}
            {isConfirmCancelEditExtratoModalOpen ? (
              <ConfirmaModal
                show={isConfirmCancelEditExtratoModalOpen}
                setShow={setIsConfirmCancelEditExtratoModalOpen}
                bodyText={'Esta ação não pode ser desfeita depois de confirmada. Tem certeza?'}
                title={'Cancelar Atualização'}
                btnAcaoTexto={'Cancelar Atualização'}
                submitFn={handleCancelEdit}
              />
            ) : null}
          </>
        )}

        <S.ContainerPagination>
          {paginationUUID.length ? (
            <Pagination
              prev
              last
              next
              first
              size="lg"
              pages={paginationUUID[0].last_page}
              activePage={paginationUUID[0].current_page}
              onSelect={handleSelect}
            />
          ) : <Pagination
            prev
            last
            next
            first
            size="lg"
            pages={undefined}
            activePage={undefined}
            onSelect={handleSelect}
          />}
        </S.ContainerPagination>
        <S.Footer>
          <div className='footerButtons'>
            <Button
              width="160px"
              height="40px"
              marginLeft="20px"
              backGroundColor={globalStyle.colors.GRAY50}
              color={globalStyle.colors.white}
              text={'Observação'}
              onClick={handleOpenObservacaoModal}
            >
            </Button>
            {!isEditando && CompraPorUUID[0]?.status_purchase !== 'finished' ? (
              <Button
                type="submit"
                width="124px"
                height="40px"
                marginLeft="20px"
                onClick={() => { setIsEditando(true) }}
                backGroundColor={'#EAB308'}
                color='white'
                text={'Editar'}
              >
              </Button>
            ) : null}

            {isEditando && CompraPorUUID[0]?.status_purchase !== 'finished' ? (
              <Button
                type="submit"
                width="124px"
                color='white'
                height="40px"
                marginLeft="20px"
                onClick={() => { handleOpenConfirmCancelarEditModal() }}
                backGroundColor={globalStyle.colors.RED500}
                text={'Cancelar'}
              >
              </Button>
            ) : null}
            {isEditando && CompraPorUUID[0]?.status_purchase !== 'finished' ? (
              <Button
                onClick={() => { handleOpenEditExtratoModal() }}
                width="124px"
                height="40px"
                color='white'
                marginLeft="20px"
                backGroundColor="#EAB308"
                text={'Atualizar'}
              >
              </Button>
            ) : null}

            {CompraPorUUID[0]?.status_purchase !== 'finished' ? (
              <Button
                onClick={(e) => { setIsConfirmFinalizarExtratoModalOpen(true) }}
                width="160px"
                height="40px"
                color='white'
                marginLeft="20px"
                backGroundColor={globalStyle.colors.GRAY100}
                text={'Finalizar Lote'}
                submitFn={handleFinalizaCompra}
              >
              </Button>
            ) : null}
          </div>
        </S.Footer>

      </S.Container>
      <ObservacaoModal
        show={observacaoModal}
        setShow={setObservacaoModal}
        btnAcaoTexto="Salvar"
        submitFn={setObservacao}
        observacao={CompraPorUUID[0]?.observacao}
        closeFn={handleCloseObservacaoModal}
        isFinished={CompraPorUUID[0]?.status_purchase === 'finished'}
      />
    </div>
  );
}