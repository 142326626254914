import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
  table {
    width: 100%;
    border-radius: 5px;
    @media screen and (max-width: 1000px) {
      border: 0;
    }
    thead {
      height: 32px;
      @media screen and (max-width: 1000px) {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      i {
        margin-left: 12px;
        font-size: 8px;
        pointer-events: none;
      }
    }
    .columnActive {
      color: var(--black);
    }

    .columnDefault {
      color: var(--background);
    }

    th {
      color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
      font-weight: 400;
      padding: 6px 1rem;
      height: 32px;
      text-align: left;
      line-height: 1.5em;
      background-color: ${(props) => props.theme.colors.GRAY100};
      &.status {
        text-align: center;
      }
      @media screen and (max-width: 1000px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }

    tr {
      cursor: pointer;
      &:hover {
        filter: brightness(0.9);
      }
      @media screen and (max-width: 1000px) {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
      }
    }

    td {
      padding: 1rem 1rem;
      border: 0;
      background-color: ${(props) => props.theme.colors.TRANSPARENT};
      font-weight: 400;
      color: var(--text-body);
      vertical-align: middle;

      @media screen and (max-width: 1000px) {
        /* &:nth-child(2) {
                    display: none;
                } */
        &:before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
        }
        &:last-child {
          border-bottom: 0;
        }
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
      }

      &:first-child(1) {
        color: var(--text-title);
      }
    }

    .ativo {
      vertical-align: middle;
      div {
        margin: 0 auto;
        content: ' ';
        height: 25px;
        width: 50%;
        background: var(--green);
        border-radius: 2px;
        text-align: center;
        @media screen and (max-width: 600px) {
          width: 16%;
          padding: 2px 10px;
        }
        @media screen and (max-width: 1000px) {
          margin: 0px 10px 0px auto;
        }
      }
    }

    .inativo {
      vertical-align: middle;
      div {
        margin: 0 auto;
        content: ' ';
        height: 25px;
        width: 50%;
        background: var(--red);
        border-radius: 2px;
        text-align: center;
        @media screen and (max-width: 600px) {
          width: 16%;
          padding: 2px 10px;
        }
        @media screen and (max-width: 1000px) {
          margin: 0px 10px 0px auto;
        }
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`;
