import React from 'react';
import globalStyle from '../../styles/globalStyle';

const ConfigIcon = ({ largura, altura, color = globalStyle.colors.GRAY100 }) => {
  return (
    <svg
      width={largura}
      height={altura}
      viewBox="0 0 24 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline', marginRight: 10, marginLeft: 10 }}
    >
      <g clipPath="url(#clip0_2743_7755)">
        <path
          d="M10.325 4.43809C10.751 2.68209 13.249 2.68209 13.675 4.43809C13.7389 4.70189 13.8642 4.94687 14.0407 5.15309C14.2172 5.35931 14.4399 5.52095 14.6907 5.62484C14.9414 5.72873 15.2132 5.77194 15.4838 5.75096C15.7544 5.72998 16.0162 5.6454 16.248 5.50409C17.791 4.56409 19.558 6.33009 18.618 7.87409C18.4769 8.10575 18.3924 8.36743 18.3715 8.63787C18.3506 8.9083 18.3938 9.17986 18.4975 9.43047C18.6013 9.68108 18.7627 9.90367 18.9687 10.0801C19.1747 10.2566 19.4194 10.382 19.683 10.4461C21.439 10.8721 21.439 13.3701 19.683 13.7961C19.4192 13.86 19.1742 13.9853 18.968 14.1618C18.7618 14.3383 18.6001 14.561 18.4963 14.8117C18.3924 15.0625 18.3491 15.3343 18.3701 15.6049C18.3911 15.8755 18.4757 16.1373 18.617 16.3691C19.557 17.9121 17.791 19.6791 16.247 18.7391C16.0153 18.598 15.7537 18.5135 15.4832 18.4926C15.2128 18.4717 14.9412 18.5149 14.6906 18.6186C14.44 18.7224 14.2174 18.8838 14.0409 19.0898C13.8645 19.2958 13.7391 19.5405 13.675 19.8041C13.249 21.5601 10.751 21.5601 10.325 19.8041C10.2611 19.5403 10.1358 19.2953 9.95929 19.0891C9.7828 18.8829 9.56011 18.7212 9.30935 18.6173C9.05859 18.5135 8.78683 18.4702 8.51621 18.4912C8.24559 18.5122 7.98375 18.5968 7.752 18.7381C6.209 19.6781 4.442 17.9121 5.382 16.3681C5.5231 16.1364 5.60755 15.8748 5.62848 15.6043C5.64942 15.3339 5.60624 15.0623 5.50247 14.8117C5.3987 14.5611 5.23726 14.3385 5.03127 14.162C4.82529 13.9856 4.58056 13.8602 4.317 13.7961C2.561 13.3701 2.561 10.8721 4.317 10.4461C4.5808 10.3822 4.82578 10.2569 5.032 10.0804C5.23822 9.90389 5.39985 9.6812 5.50375 9.43044C5.60764 9.17968 5.65085 8.90793 5.62987 8.63731C5.60889 8.36669 5.5243 8.10485 5.383 7.87309C4.443 6.33009 6.209 4.56309 7.753 5.50309C8.753 6.11109 10.049 5.57309 10.325 4.43809Z"
          stroke="#F5F5F5"
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 15.1211C13.6569 15.1211 15 13.7779 15 12.1211C15 10.4642 13.6569 9.12109 12 9.12109C10.3431 9.12109 9 10.4642 9 12.1211C9 13.7779 10.3431 15.1211 12 15.1211Z"
          stroke="#F5F5F5"
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2743_7755">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0 0.121094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConfigIcon;
