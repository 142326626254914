import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { Alert } from 'rsuite';

const UsuarioContext = createContext({});

export const UsuarioProvider = (props) => {
  const [usuarioLista, setUsuarioLista] = useState([]);
  const [usuarioListaPorTipo, setUsuarioListaPorTipo] = useState([]);
  const [usuarioPorUUID, setUsuarioPorUUID] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [pagination, setPagination] = useState([] || '');
  const [activePage, setPageActive] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [order, setOrder] = useState('');
  const [direction, setDirection] = useState('');
  const [cleanFilters, setCleanFilters] = useState(false);
  const [piecesHistory, setPiecesHistory] = useState([]);
  const [openPiecesHistory, setOpenPiecesHistory] = useState(false);
  const [piecesHistoryPagination, setPiecesHistoryPagination] = useState([]);
  const [userSearch, setUserSearch] = useState('');

  const handleUsuario = useCallback(async () => {
    const buscaAtiva = localStorage.getItem('salvarBuscaUsuario');

    if (buscaAtiva && buscaAtiva != '') {
      let search = JSON.parse(buscaAtiva);

      const cpfHasOnlyNumbers = /^\d+$/.test(search.cpf);
      setUserSearch(search.nome || search.cpf);
      const response = await api.get(
        `/usuario?nome=${search.nome}&cpf=${cpfHasOnlyNumbers ? search.cpf.replace(
          /\D/g,
          ''
        ) : ''}&email=${search.email}&tipo=${search.tipo}&status=${search.status}&order=${order ? order : 'name'
        }&direction=${direction ? direction : 'asc'}&quantidade=15`
      );
      setIsLoading(true);

      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);
      setUsuarioLista(response.data.items);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const response = await api.get(
        `/usuario?order=${order}&direction=${direction}&quantidade=15`
      );

      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);
      setUsuarioLista(response.data.items);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleUsuario();

    return () => {
      setUserSearch('');
      setPagination([]);
      setUsuarioLista([]);
    };
  }, [handleUsuario]);

  // Paginação
  async function userPage(page = null, type, mainp_uuid, mainp_id, piece) {
    try {
      const buscaAtiva = localStorage.getItem('salvarBuscaUsuario');
      if (buscaAtiva && buscaAtiva != '') {
        setIsLoading(true);

        let search = JSON.parse(buscaAtiva);

        const cpfHasOnlyNumbers = /^\d+$/.test(search.cpf);
        const response = await api.get(
          '/usuario?nome=' +
            search.nome +
            '&cpf=' +
            cpfHasOnlyNumbers ? search.cpf.replace(/\D/g, '') : '' +
            '&tipo=' +
            search.tipo +
            '&email=' +
            search.email +
            '&status=' +
            search.status +
            '&page=' +
            page +
            '&order=' +
            order +
            '&direction=' +
            direction +
          '&quantidade=15'
        );

        setUsuarioLista(response.data.items);
        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];

        setIsLoading(false);
        setPagination(data);
      } else if (type) {
        if (type == 4) {
          getUsuariosPorTipo(4, true, page);
        } else if (type == 5) {
          getUsuariosPorTipo(5, true, page);
        } else {
          setIsLoading(true);

          const response = await api.get(
            `/usuario?page=${page}&tipo=${type}&order=${order}&direction=${direction}&quantidade=15`
          );

          setUsuarioLista(response.data.items);
          const data = [
            {
              current_page: response.data.current_page,
              next_page: response.data.next_page,
              prev_page: response.data.prev_page,
              last_page: response.data.last_page,
              total: response.data.total
            }
          ];
          setIsLoading(false);
          setPagination(data);
        }
      } else if (mainp_uuid) {
        getUsuariosByMainPartner(mainp_id, mainp_uuid, page);
      } else if (piece) {
        const response = await api.get(
          `/usuario?page=${page}&peca=${piece}&quantidade=15`
        );

        setUsuarioLista(response.data.items);
        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setIsLoading(false);
        setPagination(data);
      } else {
        setIsLoading(true);

        const response = await api.get(
          `/usuario?page=${page}&order=${order}&direction=${direction}&quantidade=15`
        );

        setUsuarioLista(response.data.items);
        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setIsLoading(false);
        setPagination(data);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  // USUÁRIOS POR UUID

  async function getUsuarioPorUuid(uuid, page) {
    try {
      setIsLoadingEdit(true);
      const responseedit = await api.get('/usuario/' + uuid);

      setUsuarioPorUUID([responseedit.data]);
      setPageActive(page);
      setIsLoadingEdit(false);

      return responseedit.data;
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingEdit(false);
    }
  }

  // USUÁRIOS POR TIPO

  async function getUsuariosPorTipo(type, isFilter = true, page) {
    try {
      if (!isFilter) {
        // BUSCA PARCEIRO PRINCIPAL PARA PREENCHER FILTRO DE PRINCIPAIS - TIPO 5
        setIsLoading(true);
        const res = await api.get(
          `/usuario?tipo=${5}${page ? `&page=${page}` : ''}`
        );
        const data = [
          {
            current_page: res.data.current_page,
            next_page: res.data.next_page,
            prev_page: res.data.prev_page,
            last_page: res.data.last_page,
            total: res.data.total
          }
        ];
        // setPagination(data);
        setUsuarioListaPorTipo(res.data.items);
        setIsLoading(false);
        setIsLoadingSearch(false);
      }

      if (isFilter && type) {
        await limpaUser(false);
        setUserSearch('');
        setIsLoading(true);
        const response = await api.get(
          `/usuario?tipo=${type}${page ? `&page=${page}` : ''}&quantidade=15`
        );
        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
        setUsuarioLista(response.data.items);
        setIsLoading(false);
        setIsLoadingSearch(false);
      } else if (isFilter && !type) {
        handleUsuario();
        setIsLoadingSearch(false);
      }
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false);
    }
  }

  async function getUsuariosByMainPartner(mainp_id, mainp_uuid, page) {
    await limpaUser(false);
    setUserSearch('');
    try {
      if (mainp_id) {
        setIsLoading(true);
        let userList = [];

        const partnersByMainPartner = await api.get(
          `/usuario/parrceiro-principal/${mainp_id}?order=${order}&direction=${direction}${page ? `&page=${page}` : ''
          }`
        );
        const mainPartner = await api.get(`/usuario/${mainp_uuid}`);

        userList.push(mainPartner.data);
        userList.push(...partnersByMainPartner.data.items);

        const data = [
          {
            current_page: partnersByMainPartner.data.current_page,
            next_page: partnersByMainPartner.data.next_page,
            prev_page: partnersByMainPartner.data.prev_page,
            last_page: partnersByMainPartner.data.last_page,
            total: partnersByMainPartner.data.total
          }
        ];
        setPagination(data);
        setUsuarioLista(userList);
        setIsLoadingSearch(false);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        const response = await api.get(`/usuario?quantidade=15`);

        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
        setUsuarioLista(response.data.items);
        setIsLoading(false);
        setIsLoadingSearch(false);
      }
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false);
      setIsLoading(false);
    }
  }

  async function getPiecesHistory(user) {
    api
      .get(`/pieces-history?user=${user}&page=1&quantidade=20`)
      .then((response) => {
        setPiecesHistory(response.data.items.rows);
        setPiecesHistoryPagination(response.data);
        setOpenPiecesHistory(true);
      })
      .catch((err) => {
        Alert.error(err.response.data.message);
        setPiecesHistory([]);
        setOpenPiecesHistory(false);
      });
  }

  async function piecesPage(page, uuid) {
    try {
      setIsLoading(false);

      const response = await api.get(
        `/pieces-history?page=${page}&quantidade=10&order=id&direction=asc&user=${uuid}`
      );

      setPiecesHistory(response.data.items.rows);
    } catch (err) {
      setIsLoading(false);
    }
  }

  async function getUserByPiece(peca, page) {
    const response = await api.get(
      `/usuario?page=${page}&peca=${peca}&quantidade=15`
    );

    const data = [
      {
        current_page: response.data.current_page,
        next_page: response.data.next_page,
        prev_page: response.data.prev_page,
        last_page: response.data.last_page,
        total: response.data.total
      }
    ];
    setPagination(data);
    setUsuarioLista(response.data.items);
  }

  // Criar OS USUÁRIOS
  async function createUsuario(UsuarioInputEdit, Modal) {
    try {
      setIsLoading(true);

      const body = {
        name: UsuarioInputEdit.nome ? UsuarioInputEdit.nome : null,
        cpf: UsuarioInputEdit.cpf ? UsuarioInputEdit.cpf : null,
        email: UsuarioInputEdit.email ? UsuarioInputEdit.email : null,
        phone: UsuarioInputEdit.telefone ? UsuarioInputEdit.telefone : null,
        cellphone: UsuarioInputEdit.celular ? UsuarioInputEdit.celular : null,
        birthday: UsuarioInputEdit.data_nascimento
          ? UsuarioInputEdit.data_nascimento
          : null,
        piece: UsuarioInputEdit.peca ? UsuarioInputEdit.peca : null,
        dust: UsuarioInputEdit.po ? UsuarioInputEdit.po : null,
        inox: UsuarioInputEdit.inox ? UsuarioInputEdit.inox : null,
        filter: UsuarioInputEdit.filtro ? UsuarioInputEdit.filtro : null,
        bank: UsuarioInputEdit.banco ? UsuarioInputEdit.banco : null,
        holder: UsuarioInputEdit.titular ? UsuarioInputEdit.titular : null,
        holder_cpf: UsuarioInputEdit.titularCpf
          ? UsuarioInputEdit.titularCpf
          : null,
        agency: UsuarioInputEdit.agencia ? UsuarioInputEdit.agencia : null,
        account: UsuarioInputEdit.conta ? UsuarioInputEdit.conta : null,
        address: UsuarioInputEdit.logradouro
          ? UsuarioInputEdit.logradouro
          : null,
        number: UsuarioInputEdit.numero ? UsuarioInputEdit.numero : null,
        district: UsuarioInputEdit.bairro ? UsuarioInputEdit.bairro : null,
        city: UsuarioInputEdit.cidade ? UsuarioInputEdit.cidade : null,
        state: UsuarioInputEdit.estado ? UsuarioInputEdit.estado : null,
        type: UsuarioInputEdit.tipo ? UsuarioInputEdit.tipo : null,
        password: UsuarioInputEdit.senha ? UsuarioInputEdit.senha : null,
        codigo: UsuarioInputEdit.codigo ? UsuarioInputEdit.codigo : null,
        value_limit: UsuarioInputEdit.limite ? UsuarioInputEdit.limite : null,
        permission: UsuarioInputEdit.permissao
          ? UsuarioInputEdit.permissao
          : null,
        status: UsuarioInputEdit.status ? `${UsuarioInputEdit.status}` : null,
        user_principal:
          UsuarioInputEdit.tipo == '4' && UsuarioInputEdit.principal
            ? UsuarioInputEdit.principal
            : null,
        parametro: UsuarioInputEdit.parametro
          ? UsuarioInputEdit.parametro
          : null,
        permissions: UsuarioInputEdit.permissions
          ? UsuarioInputEdit.permissions
          : []
      };
      const data = new FormData();
      data.append('body', JSON.stringify(body));

      const response = await api.post('/usuario', data);

      let keysToRemove = ['buscaAtivaUsuario', 'salvarBuscaUsuario'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));

      setIsLoading(false);
      handleUsuario();

      Alert.success(response.data.message);

      setTimeout(() => {
        if (Modal) Modal();
      }, 1000);
      return {
        erro: false
      };
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoading(false);
      return {
        erro: true
      };
    }
  }

  // EDITANDO OS USUÁRIOS
  async function editUsuario(UsuarioInputEdit, uuid, page) {
    try {
      setIsLoadingEdit(true);

      const body = {
        id: UsuarioInputEdit.id ? UsuarioInputEdit.id : null,
        idDispositivo: UsuarioInputEdit.idDispositivo
          ? UsuarioInputEdit.idDispositivo
          : null,
        name: UsuarioInputEdit.nome ? UsuarioInputEdit.nome : null,
        cpf: UsuarioInputEdit.cpf ? UsuarioInputEdit.cpf : null,
        email: UsuarioInputEdit.email ? UsuarioInputEdit.email : null,
        phone: UsuarioInputEdit.telefone ? UsuarioInputEdit.telefone : null,
        cellphone: UsuarioInputEdit.celular ? UsuarioInputEdit.celular : null,
        birthday: UsuarioInputEdit.data_nascimento
          ? UsuarioInputEdit.data_nascimento
          : null,
        // avatar: UsuarioInputEdit.avatar ? UsuarioInputEdit.avatar : null,
        piece: UsuarioInputEdit.peca ? UsuarioInputEdit.peca : null,
        dust: UsuarioInputEdit.po ? UsuarioInputEdit.po : null,
        inox: UsuarioInputEdit.inox ? UsuarioInputEdit.inox : null,
        filter: UsuarioInputEdit.filtro ? UsuarioInputEdit.filtro : null,
        bank: UsuarioInputEdit.banco ? UsuarioInputEdit.banco : null,
        holder: UsuarioInputEdit.titular ? UsuarioInputEdit.titular : null,
        holder_cpf: UsuarioInputEdit.titularCpf
          ? `${UsuarioInputEdit.titularCpf}`
          : null,
        agency: UsuarioInputEdit.agencia ? UsuarioInputEdit.agencia : null,
        account: UsuarioInputEdit.conta ? UsuarioInputEdit.conta : null,
        address: UsuarioInputEdit.logradouro
          ? UsuarioInputEdit.logradouro
          : null,
        number: UsuarioInputEdit.numero ? UsuarioInputEdit.numero : null,
        district: UsuarioInputEdit.bairro ? UsuarioInputEdit.bairro : null,
        city: UsuarioInputEdit.cidade ? UsuarioInputEdit.cidade : null,
        state: UsuarioInputEdit.estado ? UsuarioInputEdit.estado : null,
        type: UsuarioInputEdit.tipo,
        code: UsuarioInputEdit.codigo ? UsuarioInputEdit.codigo : null,
        value_limit: UsuarioInputEdit.limite ? UsuarioInputEdit.limite : null,
        permission: JSON.stringify(UsuarioInputEdit.permissao),
        password: UsuarioInputEdit.senha ? UsuarioInputEdit.senha : null,
        status: UsuarioInputEdit.status ? `${UsuarioInputEdit.status}` : null,
        dependente: UsuarioInputEdit.aplicarParaDependentes
          ? UsuarioInputEdit.aplicarParaDependentes
          : null,
        user_principal: UsuarioInputEdit.principal
          ? UsuarioInputEdit.principal
          : null,
        parametro: UsuarioInputEdit.parametro
          ? UsuarioInputEdit.parametro
          : null,
        permissions: UsuarioInputEdit.permissions
      };
      const data = new FormData();
      data.append('body', JSON.stringify(body));
      data.append('avatar', UsuarioInputEdit.avatar);

      const response = await api.post('/usuario/atualizar/' + uuid, data);

      const buscaAtiva = localStorage.getItem('salvarBuscaUsuario');
      if (buscaAtiva && buscaAtiva != '') {
        let search = JSON.parse(buscaAtiva);

        const cpfHasOnlyNumbers = /^\d+$/.test(search.cpf);
        const responsepage = await api.get(
          `/usuario?nome=${search.nome}&cpf=${cpfHasOnlyNumbers ? search.cpf.replace(/\D/g, '') : ''}&tipo=${search.tipo}&email=${search.email}&status=${search.status}&page=${page}&quantidade=15`
        );
        setUsuarioLista(responsepage.data.items);
        const datapage = [
          {
            count: responsepage.data.count,
            current_page: responsepage.data.current_page,
            next_page: responsepage.data.next_page,
            prev_page: responsepage.data.prev_page,
            last_page: responsepage.data.last_page,
            total: responsepage.data.total
          }
        ];
        setPagination(datapage);

        setIsLoadingEdit(false);

        Alert.success(response.data.message);
        // Modal();
      } else {
        let keysToRemove = ['buscaAtivaUsuario', 'salvarBuscaUsuario'];

        keysToRemove.forEach((k) => localStorage.removeItem(k));

        const responsepage = await api.get(
          '/usuario?page=' + page + '&quantidade=15'
        );
        setUsuarioLista(responsepage.data.items);
        const datapage = [
          {
            count: responsepage.data.count,
            current_page: responsepage.data.current_page,
            next_page: responsepage.data.next_page,
            prev_page: responsepage.data.prev_page,
            last_page: responsepage.data.last_page,
            total: responsepage.data.total
          }
        ];
        setPagination(datapage);
        setCleanFilters(true);
        setIsLoadingEdit(false);

        Alert.success(response.data.message);
        getUsuariosPorTipo(5, false);
        // Modal();
      }
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingEdit(false);
    }
  }

  // Pesquisar Catálogo
  async function orderUsuario(
    column,
    direction,
    page,
    filter,
    type,
    mainp,
    mainp_uuid
  ) {
    setOrder(column);
    setDirection(direction);
    const buscaAtiva = localStorage.getItem('salvarBuscaUsuario');

    if (buscaAtiva && buscaAtiva != '' && !filter) {
      let search = JSON.parse(buscaAtiva);
      const cpfHasOnlyNumbers = /^\d+$/.test(search.cpf);
      const response = await api.get(
        `/usuario?nome=${search.nome}&cpf=${cpfHasOnlyNumbers ? search.cpf.replace(
          /\D/g,
          ''
        ) : ''}&email=${search.email}&tipo=${search.tipo}&status=${search.status
        }&order=${column}&direction=${direction}&quantidade=15`
      );
      setIsLoading(true);

      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);
      setUsuarioLista(response.data.items);
      setIsLoading(false);
    } else {
      if (!filter) {
        setIsLoading(true);
        const response = await api.get(
          `/usuario?order=${column}&direction=${direction}&page=${page}&quantidade=15`
        );

        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
        setUsuarioLista(response.data.items);
        setIsLoading(false);
      } else {
        if (filter == 'type') {
          setIsLoading(true);
          const response = await api.get(
            `/usuario?tipo=${type}&order=${column}&direction=${direction}&page=${page}&quantidade=15`
          );

          const data = [
            {
              current_page: response.data.current_page,
              next_page: response.data.next_page,
              prev_page: response.data.prev_page,
              last_page: response.data.last_page,
              total: response.data.total
            }
          ];
          setPagination(data);
          setUsuarioLista(response.data.items);
          setIsLoading(false);
        } else {
          setIsLoading(true);
          let userList = [];

          const partnersByMainPartner = await api.get(
            `/usuario/parrceiro-principal/${mainp[0]}?order=${column}&direction=${direction}&page=${page}`
          );
          const mainPartner = await api.get(`/usuario/${mainp_uuid}`);

          userList.push(mainPartner.data);
          userList.push(...partnersByMainPartner.data.items);

          const data = [
            {
              current_page: partnersByMainPartner.data.current_page,
              next_page: partnersByMainPartner.data.next_page,
              prev_page: partnersByMainPartner.data.prev_page,
              last_page: partnersByMainPartner.data.last_page,
              total: partnersByMainPartner.data.total
            }
          ];
          setPagination(data);
          setUsuarioLista(userList);
          setIsLoading(false);
        }
      }
    }
  }
  async function searchUsuario(search, Modal) {
    try {
      setIsLoadingSearch(true);

      if (
        search &&
        search.nome == '' &&
        search.cpf == '' &&
        search.tipo == '' &&
        search.email == '' &&
        search.status == ''
      ) {
        let keysToRemove = ['buscaAtivaUsuario', 'salvarBuscaUsuario'];

        keysToRemove.forEach((k) => localStorage.removeItem(k));
        handleUsuario();
      } else {
        const cpfHasOnlyNumbers = /^\d+$/.test(search.cpf);
        const response = await api.get(`/usuario?nome=${search.nome}&cpf=${cpfHasOnlyNumbers ? search.cpf.replace(/\D/g, '') : ''}&email=${search.email}&tipo=${search.tipo}&status=${search.status}`);

        if (response.data.error == true) {
          Alert.error(response.data.message);
        } else {
          localStorage.setItem('buscaAtivaUsuario', 'ativa');
          localStorage.setItem('salvarBuscaUsuario', JSON.stringify(search));

          setUsuarioLista(response.data.items);
          const data = [
            {
              count: response.data.count,
              current_page: response.data.current_page,
              next_page: response.data.next_page,
              prev_page: response.data.prev_page,
              last_page: response.data.last_page,
              total: response.data.total
            }
          ];
          setPagination(data);
        }
      }

      setIsLoadingSearch(false);
      if (Modal != undefined) Modal();
    } catch (err) {
      Alert.error(err.response?.data?.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }
  async function limpaUser(reload = true) {
    try {
      setIsLoadingSearch(true);

      if (reload) handleUsuario();
      let keysToRemove = ['buscaAtivaUsuario', 'salvarBuscaUsuario'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));
      if (reload) window.location.reload();

      if (reload) setIsLoadingSearch(false);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  async function buscaRelatorioUsuarios() {
    setIsLoading(true);

    try {
      const response = await api.get(`/usuario/relatorio`);

      window.location.assign(response.data.url);

      setIsLoading(false);
    } catch (err) {
      console.log(err);

      setIsLoading(false);
    }
  }

  async function buscaRelatorioConsultas(filtros) {
    setIsLoading(true);
    if (filtros.origem === 'Origem') filtros.origem = '';

    try {
      const response = await api.post(
        `/location-history/relatorio/all`,
        filtros
      );

      window.location.assign(response.data.url);

      setIsLoading(false);
      return response;
    } catch (err) {
      setIsLoading(false);
      return {
        error: true,
        message: err?.response?.data?.message
      };
    }
  }

  return (
    <UsuarioContext.Provider
      value={{
        isLoading,
        usuarioLista,
        getUsuarioPorUuid,
        handleUsuario,
        usuarioPorUUID,
        editUsuario,
        createUsuario,
        userPage,
        pagination,
        activePage,
        isLoadingEdit,
        searchUsuario,
        userSearch,
        setUserSearch,
        limpaUser,
        isLoadingSearch,
        orderUsuario,
        getUsuariosPorTipo,
        usuarioListaPorTipo,
        getUsuariosByMainPartner,
        cleanFilters,
        getPiecesHistory,
        piecesHistory,
        setOpenPiecesHistory,
        openPiecesHistory,
        piecesHistoryPagination,
        piecesPage,
        getUserByPiece,
        buscaRelatorioUsuarios,
        buscaRelatorioConsultas
      }}
    >
      {props.children}
    </UsuarioContext.Provider>
  );
};

export function useUsuario() {
  const context = useContext(UsuarioContext);

  return context;
}
