import { useState } from 'react';
import { Modal, Button, FormGroup } from 'rsuite';
import * as S from './styles';
import { useUsuario } from '../../hooks/user';

export function SearchUsuarioModal({ isOpenSearch, onRequestCloseSearch }) {
  const { searchUsuario, isLoadingSearch } = useUsuario();

  const [tipo, setTipo] = useState('');
  const [status, setStatus] = useState('');
  const [nome, setNome] = useState('');
  const [cpf, setCPF] = useState('');

  async function handlSearchUsuario(e) {
    e.preventDefault();

    let data = {
      tipo,
      nome,
      cpf,
      status
    };

    await searchUsuario(data, onRequestCloseSearch);
  }

  const tipo_lista = {
    1: 'Administrador',
    2: 'Recebimento',
    3: 'Analisador',
    4: 'Parceiro'
  };

  function optionsTipoLista() {
    var es = Object.entries(tipo_lista);
    return es.map((item) => (
      <option key={`tipo_lista${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const status_lista = {
    1: 'Ativo',
    2: 'Inativo'
  };

  function optionsStatus() {
    var es = Object.entries(status_lista);
    return es.map((item) => (
      <option key={`status${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  return (
    <Modal
      backdrop={'static'}
      show={isOpenSearch}
      onHide={onRequestCloseSearch}
    >
      <Modal.Header>
        <Modal.Title>Pesquisar</Modal.Title>
      </Modal.Header>
      <S.Container onSubmit={handlSearchUsuario}>
        <Modal.Body>
          <S.ContainerCardForm>
            <div className="card-form">
              <FormGroup>
                <label>Tipo:</label>
                <select
                  placeholder="Tipo"
                  onChange={(e) => {
                    setTipo(e.target.value);
                  }}
                  value={tipo}
                >
                  <option key="" value="">
                    Selecione o tipo
                  </option>
                  {optionsTipoLista()}
                </select>
              </FormGroup>
              <FormGroup>
                <label>Nome</label>
                <input
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(event) => setNome(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>CPF</label>
                <input
                  type="text"
                  placeholder="CPF"
                  value={cpf}
                  onChange={(event) => setCPF(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>Status:</label>
                <select
                  placeholder="Tipo"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  value={status}
                >
                  <option key="" value="">
                    Selecione o tipo
                  </option>
                  {optionsStatus()}
                </select>
              </FormGroup>
            </div>
          </S.ContainerCardForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            appearance="primary"
            style={{ marginTop: '20px' }}
          >
            {isLoadingSearch ? 'Carregando...' : 'Pesquisar'}
          </Button>
        </Modal.Footer>
      </S.Container>
    </Modal>
  );
}
