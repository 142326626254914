function includes() {

    var args = Array.prototype.slice.call(arguments);
    var target = args[0];
    var strs = args.slice(1); // remove first element
  
    for(var i = 0; i < strs.length; i++) {
        if(target.includes(strs[i])) {
            return true;
        }
    }
  
    return false;
}

export default includes;