export const formataCampoPorcentagemNegativos = (elemento) => {
  if (elemento) {
    let valor = elemento;
    valor = valor.replace(/[^\d,-]/g, '');
    let isNegative = false;
    if (valor.startsWith('-')) {
      isNegative = true;
      valor = valor.substring(1);
    }
    valor = valor.replace(/(\d{2})$/, '.$1');
    valor = valor.replace(/(\d+)(\d{3},\d{2})$/g, '$1.$2');

    const parts = valor.split(',');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const formattedValue =
      parts.length > 1 ? `${integerPart},${parts[1]}` : integerPart;
    const finalValue = isNegative ? `-${formattedValue}` : formattedValue;
    const replaced = finalValue.replace('%', '');
    return replaced;
  }
  return '';
};

const formataCampoPorcentagem = (elemento) => {
  if (elemento) {
    let valor = elemento;

    if (valor.length > 3) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d{2})$/, '.$1');
      valor = valor.replace(/(\d+)(\d{3},\d{2})$/g, '$1.$2');
      var qtdLoop = (valor.length - 3) / 3;
      var count = 0;

      while (qtdLoop > count) {
        count++;
        valor = valor.replace(/(\d+)(\d{3}.*)/, '$1.$2');
      }

      valor = valor.replace(/^(0)(\d)/g, '$2');
    }

    return valor.replace('%', '');
  }
  return '';
};

export default formataCampoPorcentagem;
