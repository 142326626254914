import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 5px;
`;

export const Container = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  @media (max-width: 768px) {
    padding: 1rem 0px;
  }

  .rs-modal-body {
    overflow-x: hidden !important;
  }

  .rs-modal-footer {
    width: 100%;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .rs-form-group {
    width: 100%;
  }

  .rs-form-group.valor {
    margin-top: 1.25rem;
  }

  input,
  select {
    width: 250px;
    padding: 0 1.5rem;
    height: 3rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;

    color: ${(props) => props.theme.colors.DARK400};
    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }
  }

  h2 {
    width: 100%;
    color: var(--text-title);
    font-size: 1.5rem;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-top: 20px;
  }
`;

export const ContainerCardForm = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 10px;
  }

  .card-form {
    width: calc(100% - 20px);
    display: grid;
    flex: 1;
    margin: 0px 10px;
    color: ${(props) => props.theme.colors.GRAY100};

    input {
      color: ${(props) => props.theme.colors.DARK400};
    }
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
      h3 {
        margin-top: 40px;
      }
    }

    &.title {
      gap: 0px;
    }
    .valor {
      font-size: 1.2em;
      line-height: 1.5em;
      color: var(--red);
    }

    .rs-uploader-picture .rs-uploader-trigger-btn {
      width: 150px;
      height: 150px;
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      border-radius: 50%;
      border: solid 2px #ccc;
    }
    .rs-uploader-trigger-btn {
      img {
        object-fit: cover;
      }
    }
    .rs-icon-size-5x,
    .rs-icon-size-5x.rs-icon {
      font-size: 150px;
      height: 0px;
      margin-left: -2px;
      margin-top: -17px;
    }
    .rs-icon-size-5x,
    .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
      margin-top: 0px !important;
    }
  }

  .card-form-upload {
    margin-right: 20px;
    .botao_download,
    a {
      padding: 0.8rem 1rem;
      font-size: 1rem;
      color: #fff;
      background-color: #ff9800;
      border-color: #ff9800;
      border-radius: 0.25rem;
      border: none;
      margin-left: 1rem;
      margin-bottom: 1.25rem;
      cursor: pointer;
      transition: filter 0.2s;

      &:hover,
      &:active {
        filter: brightness(0.9);
        text-decoration: none;
      }
      &:focus {
        outline: none;
      }
    }

    .botao_ver_observacao,
    a {
      padding: 0.8rem 1rem;
      font-size: 1rem;
      color: #fff;
      background-color: #ff9800;
      border-color: #ff9800;
      border-radius: 0.25rem;
      border: none;
      margin-left: 1rem;
      margin-bottom: 1.25rem;
      cursor: pointer;
      transition: filter 0.2s;

      &:hover,
      &:active {
        filter: brightness(0.9);
        text-decoration: none;
      }
      &:focus {
        outline: none;
      }
    }
  }
  table {
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;

    thead {
      display: none;
      th {
        background: ${(props) => props.theme.colors.GRAY100};
        color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
      }
      @media screen and (min-width: 1000px) {
        display: contents;
      }
    }

    tr {
      max-width: calc(100% - 20px);
      padding: 5px;
      margin-bottom: 10px;
      display: block;
      @media screen and (min-width: 1000px) {
        display: table-row;
        border-bottom-width: 1px;
        margin-bottom: 0;

        &:nth-child(even) {
          background: ${(props) => props.theme.colors.GRAY600};
        }
      }
    }

    th,
    td {
      padding: 10px;
      text-align: left;
    }

    th {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
      text-align: center;
      color: ${(props) => props.theme.colors.GRAY100};
    }

    td {
      display: block;
      margin: 0 auto;
      vertical-align: middle;
      text-align: center;
      font-size: 13px;
      border-bottom: 1px dotted #ddd;
      color: ${(props) => props.theme.colors.GRAY100};

      &:last-child {
        border-bottom: none;
      }
      &.feito {
        background-color: var(--green);
      }
      &.analise {
        background-color: var(--warning-color);
      }
      &.estorno {
        background-color: #ccc;
      }
      .del {
        color: var(--red);
        font-size: 1em;
        line-height: 1.5em;
        vertical-align: middle;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      img {
        margin: 0 auto;
        height: 100px;
      }

      input {
        width: 150px;
        padding: 0 1rem;
        height: 2rem;
        &::placeholder {
          color: #000;
        }
      }

      @media screen and (min-width: 1000px) {
        display: table-cell;
        text-align: center;
        font-size: 14px;
        border-bottom: none;
      }
    }

    td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: bold;
      @media screen and (min-width: 1000px) {
        content: '';
        display: none;
      }
    }
  }

  select {
    background-color: ${(props) => props.theme.colors.TRANSPARENT};
    color: ${(props) => props.theme.colors.GRAY100};
    option {
      background-color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
      color: ${(props) => props.theme.colors.GRAY100};
    }
  }
`;

export const ButtonImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 2px #ccc;

  img {
    object-fit: cover;
  }
`;

export const StatusFilialContainer = styled.div`
  margin: 1rem 0px;
`;

export const ContainerPermissao = styled.div`
  padding-left: 5px;
  padding-right: 20px;
`;

export const TituloPermissao = styled.p`
  font-size: 20px;
  line-height: 1.5em;
  color: #333;
`;
export const ContainerCheck = styled.div`
  margin: 0 0 20px 0;
`;

export const TabelaExtrato = styled.table`
  width: calc(100% - 10px);
  border-collapse: collapse;
  border-spacing: 0;
  margin: 10;
  padding: 10;
  border: 1px #ccc solid;

  tr {
    padding: 15px;
    margin-bottom: 10px;
    display: block;
    display: table-row;
    border-bottom-width: 1px;
    margin-bottom: 0;
  }

  td,
  th {
    border: 1px #ccc solid;
    padding: 5px;
  }

  thead {
    background: #fafafa;
  }

  thead tr:first-child th:first-child {
    background-color: #333;
    color: #fff;
  }

  tbody tr td {
    text-align: center;
    input,
    select {
      width: 100%;
      padding: 0 1.5rem;
      height: 2rem;
      border-radius: 0.25rem;
      border: 1px solid #eee;

      font-weight: 400;
      font-size: 1rem;
      &::placeholder {
        color: var(--text-body);
      }

      & + input {
        margin-top: 0.25rem;
      }
    }
  }
`;
export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`;
