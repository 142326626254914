import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 5px;
`;

export const Container = styled.form`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100vw;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;

  .extrato-modal-body {
    display: flex;
    padding: 2rem 4rem;
    flex-direction: column;
    height: 100%;
    width: 597px;
    background-color: white;
    overflow-x: hidden !important;

    h2 {
      font-size: 2.5rem;
      border: none;
      padding: 0;
      margin-bottom: 30px;
      font-weight: 600;
    }

    p {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .rs-modal-footer {
    width: 100%;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
    &.credito {
      align-items: center;
      justify-content: space-between;
      &::before,
      &::after {
        display: none;
      }
    }
  }

  .rs-form-group {
    width: 100%;

    select,
    input {
      height: 40px;
      background-color: white;
    }

    label {
      font-weight: 400;
      white-space: nowrap;
      font-size: 0.9rem;
    }
  }

  .rs-form-group.descricao {
    width: 50%;

    div {
      font-weight: 600;
      font-size: 1.5rem;
    }

    .rs-checkbox-checker {
      padding-top: 0;
    }
  }

  .rs-form-group.valor {
    select,
    input {
      background-color: white;
    }

    max-width: 40%;
  }

  .rs-form-group.status {
    max-width: 25%;
  }

  .rs-form-group.qnt-bruta {
    display: flex;
    flex-direction: column;
  }

  input,
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }

  h2 {
    width: 100%;
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 5px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-top: 20px;
  }
`;

export const Informacoes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .rs-form-group {
    select {
      background-color: white;
    }

    select,
    input {
      margin-top: 5px;
      padding: 0.5rem;
    }

    margin: 5px;
    flex: 1 1 calc(33% - 30px);
  }
`;
export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;

  table tbody tr td input {
    background-color: transparent;
    text-align: center;
  }

  table tbody tr td select {
    background-color: transparent;
    width: 130px;
  }
`;

export const ContainerCardForm = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 10px;
  }

  .card-form {
    width: calc(100% - 20px);
    display: grid;
    flex: 1;
    gap: 1.5rem;
    margin: 0px 10px;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
      h3 {
        margin-top: 40px;
      }
    }

    .rs-uploader-picture .rs-uploader-trigger-btn {
      width: 150px;
      height: 150px;
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      border-radius: 50%;
      border: solid 2px #ccc;
    }
    .rs-uploader-trigger-btn {
      img {
        object-fit: cover;
      }
    }
    .rs-icon-size-5x,
    .rs-icon-size-5x.rs-icon {
      font-size: 150px;
      height: 0px;
      margin-left: -2px;
      margin-top: -17px;
    }
    .rs-icon-size-5x,
    .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
      margin-top: 0px !important;
    }
  }
`;

export const ButtonImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 2px #ccc;

  img {
    object-fit: cover;
  }
`;

export const ButtonSalvar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.GRAY100};
  color: #fff;
  padding: 5px 40px;
  margin: 0px 5px;
  border-radius: 3px;
  text-transform: uppercase;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const SaldoPositivo = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green);
  color: #fff;
  padding: 5px 18px;
  margin: 0px 5px;
  border-radius: 3px;
  text-transform: uppercase;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonCancelar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.RED500};
  color: #fff;
  padding: 5px 20px;
  margin: 0px 5px;
  border-radius: 3px;
  text-transform: uppercase;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const StatusFilialContainer = styled.div`
  margin: 1rem 0px;
`;

export const ContainerPermissao = styled.div`
  padding-left: 5px;
  padding-right: 20px;
`;

export const TituloPermissao = styled.p`
  font-size: 20px;
  line-height: 1.5em;
  color: #333;
`;
export const ContainerCheck = styled.div`
  margin: 0 0 20px 0;
`;
