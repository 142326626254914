import React from 'react';
import globalStyle from '../../styles/globalStyle';

const CatalogIcon = ({ color = globalStyle.colors.GRAY100 }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline', marginRight: 10, marginLeft: 10 }}
    >
      <g clipPath="url(#clip0_2743_7791)">
        <path
          d="M14 8.12109V6.12109C14 5.59066 13.7893 5.08195 13.4142 4.70688C13.0391 4.33181 12.5304 4.12109 12 4.12109H5C4.46957 4.12109 3.96086 4.33181 3.58579 4.70688C3.21071 5.08195 3 5.59066 3 6.12109V18.1211C3 18.6515 3.21071 19.1602 3.58579 19.5353C3.96086 19.9104 4.46957 20.1211 5 20.1211H12C12.5304 20.1211 13.0391 19.9104 13.4142 19.5353C13.7893 19.1602 14 18.6515 14 18.1211V16.1211M7 12.1211H21M21 12.1211L18 9.12109M21 12.1211L18 15.1211"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2743_7791">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.121094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CatalogIcon;
