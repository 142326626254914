import styled from 'styled-components';
import Button from '../../components/UI/Button';

export const ContentContainer = styled.div`
    padding: 39px 0 0 65px;
`

export const Title = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: ${props => props.theme.colors.GRAY900};
  margin-bottom: 8px;
`

export const Label = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.colors.GRAY400};
`

export const Flex = styled.div`
  display: flex;
`

export const ResetButton = styled(Button)`
`