import styled from 'styled-components';
import { Box as BoxUI } from '@chakra-ui/core';

export const Box = styled(BoxUI)(({ theme }) => ({
  backgroundColor: theme.colors.TRANSPARENT
}));

export const ForgetText = styled.p`
  font-size: 12px;
  margin-top: 30px;
  text-align: center;
  color: ${props => props.theme.colors.GRAY900};
  cursor: pointer;
`;

export const BaixeAppText = styled.p`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
  color: ${props => props.theme.colors.GRAY100};
`;
