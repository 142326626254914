import styled from 'styled-components';

export const SidebarContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
`;

export const SidebarHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 16px;
`;

export const SidebarBody = styled.div`
  display: grid;
`;
