import { useState } from 'react';
import { Modal, Table, Whisper, Tooltip } from 'rsuite';
import * as S from './styles';
import { useExtrato } from '../../hooks/extrato';
import Skeleton from 'react-loading-skeleton';
import { ConfirmaExcluirExtratoModal } from '../ConfirmaExcluirExtratoModal/index';
import { ConfirmaEditExtratoModal } from '../ConfirmaEditExtratoModal/index';
import Nav from '@rsuite/responsive-nav';
import { usePerfil } from '../../hooks/perfil';
import { FaRegEdit } from 'react-icons/fa';
import { AiOutlineClose, AiOutlineEye } from 'react-icons/ai';
import { VscChromeClose } from 'react-icons/vsc';
import { VerExtratoDescricaoModal } from '../VerExtratoDescricaoModal';
import globalStyle from '../../styles/globalStyle';
import Button from '../UI/Button';
import formatReal from '../../utils/formatReal';

export function VerExtratoModal({ isOpenVer, onRequestCloseVer }) {
  const {
    VerExtratoPorUUID,
    setSemExtrato,
    semExtrato,
    isLoadingVer,
    paginationExtrato,
    deletarExtrato,
    extratoModalPage,
    nomeEdit,
    vereditExtrato,
    verEditExtratoPorUuid,
    extratoDownload,
    isLoadingDownload,
    getExtratoPorUuid
  } = useExtrato();

  const [isConfirmDeleteExtratoModalOpen, setIsConfirmDeleteExtratoModalOpen] =
    useState(false);
  const [isConfirmEditExtratoModalOpen, setIsConfirmEditExtratoModalOpen] =
    useState(false);
  const [isVerExtratoDescricaoModal, setIsVerExtratoDescricaoModal] =
    useState(false);
  const [uuid, setUuid] = useState('');
  const { Pagination } = Table;
  const [activeKey, setActiveKey] = useState('A');
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null
  });
  const [tipoEdit, setTipoEdit] = useState(0);
  const [tipo, setTipo] = useState('');
  const [vendaTipoSelecionada, setVendaTipoSelecionada] = useState('');
  const [descricao, setDescricao] = useState('');
  const [qtdBruta, setQtdBruta] = useState('');
  const [codigo, setCodigo] = useState('');
  const [status, setStatus] = useState('');
  const [qtdLiquida, setQtdLiquida] = useState('');
  const [valor, setValor] = useState('');
  const [dataCriacao, setDataCriacao] = useState('');
  const [usuarioId, setUsuarioId] = useState('');
  const [usuarioUUID, setUsuarioUUID] = useState('');
  const [naoEditavel, setNaoEditavel] = useState({});
  const { permissaoPerfil, tipoPerfil } = usePerfil();

  const [editExtratoDescricao, setEditExtratoDescricao] = useState(false);

  const [saldo, setSaldo] = useState('');
  const [observacao, setObservacao] = useState('');

  const onEditTable = ({
    id,
    data,
    descricao,
    qtdBruta,
    qtdLiquida,
    codigo,
    status,
    valor,
    uuid,
    ...rest
  }) => {
    setInEditMode({
      status: true,
      rowKey: uuid
    });
    setUsuarioId(id);
    // setDataCriacao(data);
    setDescricao(descricao);
    setQtdBruta(qtdBruta);
    setQtdLiquida(qtdLiquida);
    setCodigo(codigo);
    setStatus(status);
    setValor(valor.real);
    setNaoEditavel(rest);
    setUsuarioUUID(uuid);
    if (rest.tipo == 2) {
      setSaldo(0);
    } else {
      setSaldo(1);
    }
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null
    });
    setDescricao('');
    setQtdBruta('');
    setQtdLiquida('');
    setCodigo('');
    setStatus('');
    setValor('');
    setDataCriacao('');
  };

  const handleSelect = async (eventKey) => {
    await extratoModalPage(VerExtratoPorUUID[1], eventKey);
  };

  function handleOpenConfirmDelExtratoModal(uuid, userUUID) {
    setUsuarioUUID(userUUID);
    setUuid(uuid);
    setIsConfirmDeleteExtratoModalOpen(true);
  }

  function handleCloseConfirmDelExtratoModal() {
    setIsConfirmDeleteExtratoModalOpen(false);
  }

  function handleCloseConfirmEditExtratoModal() {
    setIsConfirmEditExtratoModalOpen(false);
  }

  function handleLimpa() {
    setUuid('');
    setSemExtrato(false);
  }

  function handleVerExtratoDescricaoModal(observacao, saldo) {
    setObservacao(observacao);
    setSaldo(saldo);
    setIsVerExtratoDescricaoModal(true);
  }

  function handleEditExtratoDescricaoModal(observacaoEdit, item, tipo) {
    setObservacao(observacaoEdit);
    setIsVerExtratoDescricaoModal(true);
    setEditExtratoDescricao(true);
  }

  function handleCloseVerExtratoDescricaoModal() {
    setIsVerExtratoDescricaoModal(false);
    setEditExtratoDescricao(false);
  }

  function handleSetExtratoDescricaoESaldo(observacao, saldo) {
    setObservacao(observacao);
    setSaldo(saldo);
    setIsVerExtratoDescricaoModal(false);
  }

  const handleDownload = async () => {
    let result = await extratoDownload(VerExtratoPorUUID[1]);

    if (result.erro == false) {
      window.location.assign(result.link);
    }
  };

  const handleUpdate = async () => {
    const dados = {
      ...naoEditavel,
      usuario: usuarioId,
      valor: valor,
      descricao: descricao,
      quantidade: qtdBruta,
      status: status,
      saldo: saldo,
      observacao: observacao,
      codigo: codigo,
      created_at: dataCriacao,
      qtd2: qtdLiquida
    };

    await vereditExtrato(dados, onCancel, usuarioUUID, dados.usuario);
  };

  return (
    <>
      <Modal
        full
        backdrop={'static'}
        show={isOpenVer}
        onExit={handleLimpa}
        onHide={() => {
          setSemExtrato(false);
          onRequestCloseVer();
        }}
      >
        {isLoadingVer ? (
          <Skeleton count={8} />
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>
                <div style={{ color: globalStyle.colors.GRAY100 }}>Extrato</div>
              </Modal.Title>
              <Modal.Title
                style={{
                  marginTop: '10px',
                  fontWeight: 'bold',
                  color: globalStyle.colors.GRAY100
                }}
              >
                {nomeEdit}
              </Modal.Title>
            </Modal.Header>
            <S.Container>
              <Modal.Body>
                <S.ContainerCardForm>
                  <S.Row>
                    <div
                      className="card-form title"
                      style={{ marginBottom: '1rem' }}
                    >
                      <p style={{ marginBottom: '5px' }}>
                        Saldo: {VerExtratoPorUUID[0]?.total?.real || ''}
                      </p>
                      <p>Limite: {VerExtratoPorUUID[0]?.limite?.real || ''}</p>
                    </div>
                    <Button
                      style={{ marginBottom: '1rem' }}
                      onClick={() => handleDownload()}
                      text={isLoadingDownload ? 'Carregando...' : 'Download'}
                      color={globalStyle.colors.GRAY100}
                      backGroundColor={globalStyle.colors.YELLOW600}
                    />
                  </S.Row>
                  <Nav
                    activeKey={activeKey}
                    onSelect={setActiveKey}
                    appearance="tabs"
                  >
                    <Nav.Item eventKey="A">
                      <div style={{ color: globalStyle.colors.GRAY100 }}>
                        Extrato
                      </div>
                    </Nav.Item>
                  </Nav>
                  <S.TableContainer>
                    <table>
                      <thead>
                        <tr>
                          <th>Data de criação</th>
                          <th>Descrição</th>
                          <th>Quantidade Bruta</th>
                          <th>Código</th>
                          <th>Status</th>
                          <th>Quantidade Liquida</th>
                          <th>Valor</th>
                          <th style={{ width: 150 }} />
                        </tr>
                      </thead>
                      <tbody>
                        {!semExtrato &&
                          VerExtratoPorUUID[0].items?.map((item) =>
                            inEditMode.status &&
                            inEditMode.rowKey == item.uuid ? (
                              <tr key={item.uuid}>
                                <td data-label="Data de criação">
                                  <input
                                    style={{ height: 25, width: 180 }}
                                    type="datetime-local"
                                    placeholder="Data de criação"
                                    value={dataCriacao}
                                    onChange={(event) =>
                                      setDataCriacao(event.target.value)
                                    }
                                  />
                                </td>
                                <td
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center'
                                  }}
                                >
                                  <select
                                    style={{ height: 25, width: 130 }}
                                    defaultValue={descricao}
                                    onChange={(e) =>
                                      setDescricao(e.target.value)
                                    }
                                  >
                                    <option value={1}>Transferência</option>
                                    <option value={2}>Pesáveis</option>
                                    <option value={3}>Catalisadores</option>
                                    <option value={4}>Venda</option>
                                    <option value={5}>Crédito</option>
                                    <option value={6}>Outros</option>
                                    <option value={7}>Retorno Pesáveis</option>
                                    <option value={8}>
                                      Retorno Catalisadores
                                    </option>
                                  </select>
                                  {descricao == 6 && (
                                    <FaRegEdit
                                      size={25}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleEditExtratoDescricaoModal(
                                          item.description1,
                                          item,
                                          item.tipo
                                        )
                                      }
                                    />
                                  )}
                                </td>

                                <td data-label="Quantidade Bruta">
                                  {item?.qtd1 || 'Sem quantidade'}
                                </td>

                                <td width={135}>
                                  <input
                                    style={{
                                      padding: 0
                                    }}
                                    value={codigo}
                                    onChange={(event) =>
                                      setCodigo(event.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <select
                                    style={{ height: 25, width: 130 }}
                                    defaultValue={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                  >
                                    <option value={1}>Feito</option>
                                    <option value={2}>Analise</option>
                                    <option value={3}>Estorno</option>
                                  </select>
                                </td>
                                <td width={150}>
                                  <input
                                    style={{ padding: 0 }}
                                    value={qtdLiquida}
                                    onChange={(event) =>
                                      setQtdLiquida(
                                        formatReal(event.target.value)
                                      )
                                    }
                                  />
                                </td>
                                <td style={{ width: 105 }}>
                                  <input
                                    style={{
                                      padding: 0
                                    }}
                                    type="text"
                                    value={valor}
                                    onChange={(event) =>
                                      setValor(event.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  {inEditMode.status && (
                                    <div style={{ display: 'flex' }}>
                                      <Button
                                        placement="bottom"
                                        trigger="hover"
                                        tooltip="Salvar"
                                        onClick={() => handleUpdate()}
                                        text="Salvar"
                                        style={{
                                          marginRight: 5
                                        }}
                                        height="1.8rem"
                                      />
                                      <Button
                                        placement="bottom"
                                        trigger="hover"
                                        tooltip="Cancelar"
                                        onClick={() => onCancel()}
                                        text="Cancelar"
                                        backGroundColor={
                                          globalStyle.colors.RED500
                                        }
                                        height="1.8rem"
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ) : (
                              <tr key={item.uuid}>
                                <td data-label="Data de criação">
                                  {item?.data_criacao.br || 'Data de criação'}
                                </td>
                                <td
                                  data-label="Descrição"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div>
                                    {item.descricao == 1
                                      ? 'Transferência' // if
                                      : item.descricao == 2
                                      ? 'Pesáveis' // else if
                                      : item.descricao == 3
                                      ? 'Catalisadores'
                                      : item.descricao == 4
                                      ? 'Venda'
                                      : item.descricao == 5
                                      ? 'Crédito'
                                      : item.descricao == 6
                                      ? item.description1
                                      : item.descricao == 7
                                      ? 'Retorno Pesáveis'
                                      : item.descricao == 8
                                      ? 'Retorno Catalisadores'
                                      : 'Sem descrição'}
                                  </div>
                                  {item.descricao == 6 && (
                                    <AiOutlineEye
                                      size={25}
                                      style={{
                                        cursor: 'pointer',
                                        marginLeft: 5
                                      }}
                                      onClick={() =>
                                        handleVerExtratoDescricaoModal(
                                          item.description1,
                                          item.saldo == 1
                                            ? '+ Saldo'
                                            : '- Saldo'
                                        )
                                      }
                                    />
                                  )}
                                </td>
                                <td data-label="Quantidade Bruta">
                                  {item?.qtd1 || 'Sem quantidade'}
                                </td>
                                <td data-label="Código">
                                  {item?.codigo ? item?.codigo : 'Sem código'}
                                </td>
                                <td
                                  data-label="Status"
                                  className={
                                    item.status == 1
                                      ? 'feito' // if
                                      : item.status == 2
                                      ? 'analise' // else if
                                      : item.status == 3
                                      ? 'estorno'
                                      : 'Sem status'
                                  }
                                >
                                  {item.status == 1
                                    ? 'Feito' // if
                                    : item.status == 2
                                    ? 'Análise' // else if
                                    : item.status == 3
                                    ? 'Estorno'
                                    : 'Sem status'}
                                </td>
                                <td data-label="Quantidade Liquida">
                                  {item?.qtd2}
                                </td>
                                <td data-label="Valor">{item?.valor?.real}</td>
                                {inEditMode.status &&
                                inEditMode.rowKey == item.id ? (
                                  <td>
                                    <button className={'btn-success'}>
                                      Salvar
                                    </button>

                                    <button
                                      className={'btn-secondary'}
                                      style={{ marginLeft: 8 }}
                                      onClick={() => onCancel()}
                                    >
                                      Cancelar
                                    </button>
                                  </td>
                                ) : (
                                  <td
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-evenly'
                                    }}
                                  >
                                    {((permissaoPerfil != null &&
                                      permissaoPerfil.includes(
                                        'per_extrato_editar'
                                      )) ||
                                      tipoPerfil == 1) && (
                                      <>
                                        <Whisper
                                          placement="bottom"
                                          trigger="hover"
                                          speaker={<Tooltip>Cancelar</Tooltip>}
                                          onClick={() => {
                                            onEditTable({
                                              id: item.usuario,
                                              data: item.data_criacao.br,
                                              descricao: item.description1,
                                              qtdBruta: item.quantidade,
                                              qtdLiquida: item.qtd2,
                                              codigo: item.codigo,
                                              status: item.status,
                                              valor: item.valor?.real,
                                              uuid: item.usuario,
                                              ...item
                                            });
                                          }}
                                        >
                                          <div
                                            style={{
                                              background:
                                                globalStyle.colors.YELLOW500,
                                              padding: 10,
                                              borderRadius: 4
                                            }}
                                          >
                                            <FaRegEdit
                                              color={globalStyle.colors.GRAY100}
                                              size={20}
                                              style={{ cursor: 'pointer' }}
                                            />
                                          </div>
                                        </Whisper>
                                        <Whisper
                                          placement="bottom"
                                          trigger="hover"
                                          speaker={<Tooltip>Excluir</Tooltip>}
                                          onClick={() => {
                                            handleOpenConfirmDelExtratoModal(
                                              item.uuid,
                                              item.usuario
                                            );
                                          }}
                                        >
                                          <div
                                            style={{
                                              background:
                                                globalStyle.colors.RED500,
                                              padding: 10,
                                              borderRadius: 4
                                            }}
                                          >
                                            <VscChromeClose
                                              color={globalStyle.colors.GRAY100}
                                              style={{ cursor: 'pointer' }}
                                              size={20}
                                            />
                                          </div>
                                        </Whisper>
                                      </>
                                    )}
                                  </td>
                                )}
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </S.TableContainer>
                  <S.ContainerPagination>
                    {paginationExtrato.length ? (
                      <Pagination
                        prev
                        last
                        next
                        first
                        size="lg"
                        pages={paginationExtrato[0].last_page}
                        activePage={paginationExtrato[0].current_page}
                        onSelect={handleSelect}
                      />
                    ) : null}
                  </S.ContainerPagination>
                </S.ContainerCardForm>
              </Modal.Body>
            </S.Container>
          </>
        )}
      </Modal>
      {isConfirmDeleteExtratoModalOpen ? (
        <ConfirmaExcluirExtratoModal
          isOpenExcluirConfirm={isConfirmDeleteExtratoModalOpen}
          onRequestCloseExcluirConfirm={handleCloseConfirmDelExtratoModal}
          uuid={uuid}
          uuidUser={usuarioUUID}
        />
      ) : null}
      {isConfirmEditExtratoModalOpen ? (
        <ConfirmaEditExtratoModal
          isOpenEditConfirm={isConfirmEditExtratoModalOpen}
          onRequestCloseEditConfirm={handleCloseConfirmEditExtratoModal}
          uuid={VerExtratoPorUUID[1]}
        />
      ) : null}
      {isVerExtratoDescricaoModal ? (
        <VerExtratoDescricaoModal
          isOpenVer={isVerExtratoDescricaoModal}
          onRequestClose={handleCloseVerExtratoDescricaoModal}
          observacao={observacao}
          saldo={saldo}
          handleEditDados={handleSetExtratoDescricaoESaldo}
          isEdit={editExtratoDescricao}
        />
      ) : null}
    </>
  );
}
