import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
  thead {
    background: ${(props) => props.theme.colors.GRAY100};
    margin-bottom: 32px;
  }

  tbody {
    tr:first-child {
      td {
        margin-top: 32px;
      }
    }
  }

  .columnActive {
    color: ${(props) => props.theme.colors.GRAY400};
  }

  .columnDefault {
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: 1;
  padding: 5px;
  background: ${(props) => props.theme.colors.GRAY50};
  border-radius: 50px;
  cursor: pointer;
`;

export const TableHeader = styled.th`
  color: white;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  text-align: center;
  width: ${(props) => (props.width ? props.width : 100)}px;

  i {
    margin-left: 12px;
    font-size: 8px;
    pointer-events: none;
  }
`;

// Product

export const ProductRow = styled.tr`
  width: 100%;
  padding: 32px 16px;
  height: ${(props) => (props.height ? props.height : 64)}px;
  background: ${(props) => props.backgroundColor};
  margin-left: 16px;
`;

export const PriceCalcRow = styled.tr`
  padding: 32px 16px;
  height: ${(props) => (props.height ? props.height : 64)}px;
  width: 100%;
  position: relative;
`;

export const ProductContent = styled.td`
  width: ${(props) => (props.width ? props.width : 100)}px;
  color: ${(props) => props.theme.colors.GRAY900};
  height: 64px;
  text-align: center;
  vertical-align: middle;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border: none;
  line-height: 21px;
  word-wrap: break-word;
  position: relative;
  div {
    background: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
    border-radius: 0 4px 4px 0;
    overflow: hidden;
  }
  img {
    background: ${(props) => props.theme.colors.GRAY50};
    height: 64px;
    width: 64px;
    border-radius: 4px;
    border: none;
  }

  // classname of popover component
  .css-1v9y1m7 {
    background-color: white;
    border-radius: 8px;
  }
`;

export const ActionContainer = styled.section`
  display: flex;
  justify-content: center;
  height: 32px;

  div {
    cursor: pointer;
    background: transparent;
  }
`;

export const Popover = styled.div`
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  position: absolute;
  color: black;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 8px;
  left: -32px;
  margin-top: 8px;
  width: 200px;
  z-index: 100;
  transition: all ease-in 0.4s;
`;
