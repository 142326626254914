import React from 'react';
import globalStyle from '../../styles/globalStyle';

const CatalogIcon = ({ largura, altura, color = globalStyle.colors.GRAY100 }) => {
  return (
    <svg
      width={largura}
      height={altura}
      viewBox="0 0 24 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline', marginRight: 10, marginLeft: 10 }}
    >
      <g clipPath="url(#clip0_2743_7743)">
        <path
          d="M12 19.1211C10.6318 18.3311 9.07983 17.9153 7.5 17.9153C5.92017 17.9153 4.36817 18.3311 3 19.1211V6.12105C4.36817 5.33114 5.92017 4.91528 7.5 4.91528C9.07983 4.91528 10.6318 5.33114 12 6.12105M12 19.1211C13.3682 18.3311 14.9202 17.9153 16.5 17.9153C18.0798 17.9153 19.6318 18.3311 21 19.1211V6.12105C19.6318 5.33114 18.0798 4.91528 16.5 4.91528C14.9202 4.91528 13.3682 5.33114 12 6.12105M12 19.1211V6.12105"
          stroke="#F5F5F5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2743_7743">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0 0.121094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CatalogIcon;
