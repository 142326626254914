import React from 'react';

const PlaceholderIcon = ({ largura, altura }) => {
  return (
    <svg 
        width="18" 
        height="18" 
        viewBox="0 0 18 18" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V10.1893L4.47987 7.45947C5.04567 6.91503 5.74149 6.58013 6.5 6.58013C7.25851 6.58013 7.95424 6.91513 8.52003 7.45957L8.53043 7.46957L11 9.93934L11.4799 9.45947C12.0457 8.91503 12.7415 8.58013 13.5 8.58013C14.2585 8.58013 14.9542 8.91513 15.52 9.45957L15.5304 9.46957L16.25 10.1893V4C16.25 2.75736 15.2426 1.75 14 1.75H4ZM17.75 11.9994V4C17.75 1.92893 16.0711 0.25 14 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V14C0.25 16.0711 1.92893 17.75 4 17.75H14C16.0711 17.75 17.75 16.0711 17.75 14V11.9994C17.75 11.9991 17.75 11.9998 17.75 11.9994ZM16.25 12.3107L14.4751 10.5358C14.1305 10.206 13.7933 10.0801 13.5 10.0801C13.2067 10.0801 12.8695 10.206 12.5249 10.5358L12.0607 11L13.5303 12.4697C13.8232 12.7626 13.8232 13.2374 13.5303 13.5303C13.2374 13.8232 12.7626 13.8232 12.4697 13.5303L7.47512 8.53578C7.13049 8.206 6.79334 8.08013 6.5 8.08013C6.20667 8.08013 5.86952 8.206 5.52489 8.53577L1.75 12.3107V14C1.75 15.2426 2.75736 16.25 4 16.25H14C15.2426 16.25 16.25 15.2426 16.25 14V12.3107ZM11.25 5C11.25 4.58579 11.5858 4.25 12 4.25H12.01C12.4242 4.25 12.76 4.58579 12.76 5C12.76 5.41421 12.4242 5.75 12.01 5.75H12C11.5858 5.75 11.25 5.41421 11.25 5Z" 
            fill="#F5F5F5"
        />
    </svg>
  );
};



export default PlaceholderIcon;
