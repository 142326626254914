import { Skeleton } from '@chakra-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Modal, Button, Placeholder, Table } from 'rsuite';
import { useParceiro } from '../../hooks/parceiro';
import globalStyle from '../../styles/globalStyle';
import * as S from './styles';

export default function HistoricoPorGeolocalizacao({
  isOpen,
  onRequestClose,
  data,
  onRequestOpen
}) {
  const {
    historicoDeLocalizacaoPorUUID,
    VisualizarHistoricoDeLocalizacaoPorUUID,
    isLoading,
    pagination,
    localizacoesPage,
    historicoPagination
  } = useParceiro();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      getData(data);
    }
  }, [data]);

  const getData = async (data) => {
    const response = await VisualizarHistoricoDeLocalizacaoPorUUID(data.uuid);
    if (response == false) {
      onRequestClose();
    } else {
      onRequestOpen();
    }
  };

  const handleSelect = async (eventKey) => {
    await localizacoesPage(eventKey, data.uuid);
  };

  const { Pagination } = Table;

  return (
    <Modal show={isOpen} size="lg" backdrop="static" onHide={onRequestClose}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ color: globalStyle.colors.GRAY100 }}>
            Histórico de Geolocalização
          </div>
        </Modal.Title>
      </Modal.Header>
      <S.Container>
        <table>
          <thead>
            <tr>
              <th style={{ width: 150 }}>Data/Hora</th>
              {/* <th style={{ width: 50 }}>ID</th> */}
              {/* <th style={{ width: 50 }}>Origem</th> */}
              <th style={{ width: 250 }}>Localização</th>
              <th style={{ width: 150 }}>ID do Dispositivo</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Skeleton />
            ) : (
              historicoDeLocalizacaoPorUUID.map((dados) => (
                <tr key={dados.id}>
                  <td style={{ width: 150 }}>
                    {new Date(
                      `${dados.created_at.split('T')[0]} ${
                        dados.created_at.split('T')[1]
                      }`
                    ).toLocaleDateString('pt-BR')}
                    {' - '}
                    {new Date(
                      `${dados.created_at.split('T')[0]} ${
                        dados.created_at.split('T')[1]
                      }`
                    ).toLocaleTimeString('pt-BR')}
                  </td>
                  {/* <td style={{ width: 50 }}>{dados.id}</td> */}
                  {/* <td style={{ width: 50 }}>{dados.origem}</td> */}
                  <td style={{ width: 250 }}>
                    {dados.latitude && dados.longitude
                      ? `${dados.latitude}, ${dados.longitude} `
                      : 'S/D'}
                  </td>
                  <td styled={{ width: 150 }}>{dados.imei}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <S.ContainerPagination>
          {historicoPagination.length ? (
            <Pagination
              prev
              last
              next
              first
              size="lg"
              pages={historicoPagination[0]?.last_page}
              activePage={historicoPagination[0]?.current_page}
              onSelect={handleSelect}
            />
          ) : null}
        </S.ContainerPagination>
      </S.Container>
    </Modal>
  );
}
