import React, { useEffect, useRef, useState } from 'react';
import { Modal, Icon, Alert } from 'rsuite';
import { IoMdClose } from 'react-icons/io';
import { EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import InnerImageZoom from 'react-inner-image-zoom';

import PriceCalcTable from '../PriceCalcTable';
import Input from '../../UI/Input';
import ConfirmaModal from '../../ConfirmaModal';

import TableIcon from '../../../assets/Icons/TableIcon';
import EyeIcon from '../../../assets/Icons/EyeIcon';
import TrashIcon from '../../../assets/Icons/TrashIcon';
import CloseIcon2 from '../../../assets/Icons/CloseIcon2';
import PlaceholderImg from '../../../assets/default-placeholder.png';

import { useCatalogo } from '../../../hooks/catalogo';

import {
  TableHeader,
  Table as TableComponent,
  ProductRow,
  ProductContent,
  ActionContainer,
  PriceCalcRow,
  IconContainer,
  Popover
} from './styled';
import globalStyle from '../../../styles/globalStyle';
import { usePerfil } from '../../../hooks/perfil';
import {
  Button,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/core';

const FaSvgIcon = ({ faIcon, ...rest }) => {
  const { width, height, svgPathData } = faIcon;
  return (
    <svg
      {...rest}
      viewBox={`0 0 ${width} ${height}`}
      width="2em"
      height="2em"
      fill="currentColor"
    >
      <path d={svgPathData}></path>
    </svg>
  );
};

const Product = ({
  data,
  index,
  handleProduto,
  handleDeletar,
  submitInativa,
  email
}) => {
  const [showProductPriceCalc, setShowProductPriceCalc] = useState(false);
  const [desconto, setDesconto] = useState(
    data.desconto ? data.desconto : '00'
  );
  const [editandoDesconto, setEditandoDesconto] = useState(false);
  const [foto, setFoto] = useState(PlaceholderImg);
  const [abrirFotoModal, setAbrirFotoModal] = useState(false);

  const [modalInativo, setModalInativo] = useState(false);
  const [modalExcluir, setModalExcluir] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const inputRef = useRef(null);
  const navigate = useNavigate();

  const handleViewDetails = async () => {
    navigate(`/catalogo/${data.uuid}`, { state: { data } });
  };

  const handleVerificaImagem = () => {
    const img = new Image();

    img.src = data.photos[0]?.photo;

    img.onload = () => {
      setFoto(data.photos[0]?.photo);
    };
  };

  const handleDesconto = (value) => {
    if (value == '00-') return setDesconto('-');

    if (isNaN(value) || Number(value) > 100) return;

    if (value.length >= 2) {
      let valueformat = value;

      if (value[0] == '0') valueformat = value.replace('0', '');

      return setDesconto(valueformat);
    }

    setDesconto('0' + value);
  };

  const handleInativaProduto = async () => {
    if (data.status == 2) {
      return Alert.info('Produto inativo!');
    }
    const produto = {
      A: JSON.stringify(data.A),
      B: JSON.stringify(data.B),
      C: JSON.stringify(data.C),
      D: JSON.stringify(data.D),
      marca: data.marca,
      modelo: data.modelo,
      codigo1: data.codigo1,
      codigo2: data.codigo2,
      codigo3: data.codigo3,
      definition: data.definition,
      targeting: data.targeting,
      status: 2,
      observacao: data.observacao,
      photos: data.photos,
      uuid: data.uuid,
      foto: data.foto,
      tipo: data.tipo,
      photo_excluir: [],
      desconto: desconto && desconto !== '00' ? desconto : null
    };

    await handleProduto(produto, cancelaCb, false);
    setModalInativo(true);
  };

  const handleExcluirProduto = () => handleDeletar(data.uuid);

  const cancelaCb = () => setDesconto(data.desconto ? data.desconto : '00');

  const handleSalvarAlteração = async () => {
    if (
      desconto == data.desconto ||
      (data.desconto === null && desconto === '00')
    ) {
      setEditandoDesconto(false);
      return;
    }

    try {
      const produto = {
        A: JSON.stringify(data.A),
        B: JSON.stringify(data.B),
        C: JSON.stringify(data.C),
        D: JSON.stringify(data.D),
        marca: data.marca,
        modelo: data.modelo,
        codigo1: data.codigo1,
        codigo2: data.codigo2,
        codigo3: data.codigo3,
        definition: data.definition,
        targeting: data.targeting,
        status: data.status,
        observacao: data.observacao,
        photos: data.photos,
        uuid: data.uuid,
        foto: data.foto,
        tipo: data.tipo,
        photo_excluir: [],
        desconto: desconto && desconto !== '00' ? desconto : null
      };

      const cb = () => { };
      await handleProduto(produto, cb, true);

      setEditandoDesconto(false);
    } catch (err) {
      setDesconto(data.desconto);
      setEditandoDesconto(false);
    }
  };

  const handleModalFoto = () => setAbrirFotoModal((ant) => !ant);
  const handleFechaModalFoto = () => setAbrirFotoModal(false);

  useEffect(() => {
    handleVerificaImagem();
  }, [data]);

  useEffect(() => {
    if (editandoDesconto) {
      inputRef.current.focus();
    }
  }, [editandoDesconto]);

  return (
    <>
      <ProductRow
        backgroundColor={
          index % 2 === 0 ? globalStyle.colors.DARK100 : 'transparent'
        }
      >
        <ProductContent width={66} align="right">
          <div
            style={{ width: 64, cursor: 'pointer' }}
            onClick={handleModalFoto}
          >
            <img
              src={foto}
              width={64}
              height={64}
              style={{ borderRadius: 4 }}
            />
          </div>
        </ProductContent>
        <ProductContent width={50}>{data.marca}</ProductContent>
        <ProductContent width={150}>{data.modelo}</ProductContent>
        <ProductContent>{data.codigo1}</ProductContent>
        <ProductContent>{data.codigo2}</ProductContent>
        <ProductContent>{data.codigo3}</ProductContent>
        <ProductContent>R$ {data.preco?.br}</ProductContent>
        <ProductContent width={50}>{data.definition}</ProductContent>
        <ProductContent width={50}>
          {editandoDesconto ? (
            <Input
              ref={inputRef}
              size="sm"
              style={{
                textAlign: 'center'
              }}
              value={desconto}
              onChange={(e) => handleDesconto(e.target.value)}
              onBlur={handleSalvarAlteração}
            />
          ) : (
            <>
              <span
                onMouseEnter={() => setShowPopover(true)}
                onMouseLeave={() => setShowPopover(false)}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'static',
                  zIndex: 101
                }}
                onClick={() => {
                  console.log('set');
                  setEditandoDesconto(true);
                  setShowPopover(false);
                }}
              >
                {desconto}%
                <EditIcon
                  style={{
                    width: 12.5,
                    height: 12.5,
                    position: 'absolute',
                    top: 10,
                    right: 10
                  }}
                  boxSize={2}
                />
              </span>
            </>
          )}
          <Popover isOpen={showPopover}>
            Valores negativos são válidos.{' '}
          </Popover>
        </ProductContent>
        <ProductContent>
          <ActionContainer>
            <div style={{ marginTop: 2 }} onClick={handleViewDetails}>
              <EyeIcon largura={32} altura={32} />
            </div>
            <div
              style={{ marginLeft: 4 }}
              onClick={() => setShowProductPriceCalc(!showProductPriceCalc)}
            >
              <TableIcon largura={32} altura={32} />
            </div>
            <div
              style={{ marginLeft: 4 }}
              onClick={() => handleInativaProduto()}
            >
              <CloseIcon2 largura={32} altura={32} />
            </div>
            {email && email == 'ivan@siber.com' ? (
              <div
                style={{ marginLeft: 4 }}
                onClick={() => setModalExcluir(true)}
              >
                <TrashIcon largura={32} altura={32} />
              </div>
            ) : null}
          </ActionContainer>
        </ProductContent>
      </ProductRow>
      {showProductPriceCalc && (
        <PriceCalcRow height={148}>
          <PriceCalcTable data={data} />
        </PriceCalcRow>
      )}
      <Modal show={abrirFotoModal} onHide={handleFechaModalFoto}>
        <div style={{ position: 'relative' }}>
          <IconContainer onClick={handleFechaModalFoto}>
            <IoMdClose size={32} color={globalStyle.colors.GRAY900} />
          </IconContainer>
          <InnerImageZoom
            alt="Imagem"
            className="w-100"
            src={foto}
            startsActive={true}
            hideCloseButton={true}
            hideHint={true}
            moveType={'drag'}
          />
        </div>
      </Modal>
      <ConfirmaModal
        title="Inativar produto"
        show={modalInativo}
        setShow={(value) => {
          setModalInativo(value);
        }}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto="Salvar"
        submitFn={() => submitInativa(setModalInativo)}
      />
      <ConfirmaModal
        title="Excluir produto"
        show={modalExcluir}
        setShow={(value) => {
          setModalExcluir(value);
        }}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto="Salvar"
        submitFn={handleExcluirProduto}
      />
    </>
  );
};

export default function Table() {
  const {
    orderCatalogo,
    pagination,
    catalogoLista: data,
    EditCatalogo,
    handleDeletar
  } = useCatalogo();
  const { dados } = usePerfil();
  const [showConfirmaZerarModal, setShowConfirmaZerarModal] = useState(false);
  const [produto, setProduto] = useState({ produto: {}, cb: () => { } });

  const navigate = useNavigate();

  const handleOrderingColumn = (event, column) => {
    let el = event.target;

    if (el.lastChild.classList.contains('rs-icon-arrow-up')) {
      el.lastChild.classList.remove('rs-icon-arrow-up');
      el.lastChild.classList.add('rs-icon-arrow-down');
      orderCatalogo(column, 'desc', pagination[0].current_page);
    } else {
      el.lastChild.classList.remove('rs-icon-arrow-down');
      el.lastChild.classList.add('rs-icon-arrow-up');
      orderCatalogo(column, 'asc', pagination[0].current_page);
    }
  };

  const handleProduto = (produto, cb, abrirModal) => {
    setProduto({ produto, cb });
    if (abrirModal) setShowConfirmaZerarModal(true);
  };

  const handleInativaProduto = async (fechaModal) => {
    try {
      const data = produto.produto;

      const formData = new FormData();

      const photos = data.photos;
      delete data.photos;

      formData.append('body', JSON.stringify(data));

      photos.forEach((foto) => {
        formData.append('photos', foto);
      });

      const message = 'Produto inativado com sucesso!';
      await EditCatalogo(formData, data.uuid, 1, navigate, message);
      fechaModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => produto.cb();

  const handleDesconto = async () => {
    try {
      const data = produto.produto;

      const formData = new FormData();

      const photos = data.photos;
      delete data.photos;

      formData.append('body', JSON.stringify(data));

      photos.forEach((foto) => {
        formData.append('photos', foto);
      });

      const message = 'Desconto atualizado com sucesso!';
      await EditCatalogo(formData, data.uuid, 1, navigate, message);
      setShowConfirmaZerarModal(false);
    } catch (err) {
      setShowConfirmaZerarModal(false);
      throw new Error(err?.message);
    }
  };

  return (
    <TableComponent>
      <thead>
        <tr>
          <TableHeader width={50} />
          <TableHeader
            width={50}
            onClick={(e) => handleOrderingColumn(e, 'brand')}
          >
            Marca
            {<Icon icon="arrow-up" />}
          </TableHeader>
          <TableHeader
            width={150}
            onClick={(e) => handleOrderingColumn(e, 'model')}
          >
            Modelo
            {<Icon icon="arrow-up" />}
          </TableHeader>
          <TableHeader onClick={(e) => handleOrderingColumn(e, 'code1')}>
            Código1
            {<Icon icon="arrow-up" />}
          </TableHeader>
          <TableHeader onClick={(e) => handleOrderingColumn(e, 'code2')}>
            Código2
            {<Icon icon="arrow-up" />}
          </TableHeader>
          <TableHeader onClick={(e) => handleOrderingColumn(e, 'code3')}>
            Código3
            {<Icon icon="arrow-up" />}
          </TableHeader>
          <TableHeader onClick={(e) => handleOrderingColumn(e, 'valor')}>
            Preço
            {<Icon icon="arrow-up" />}
          </TableHeader>
          <TableHeader
            width={50}
            onClick={(e) => handleOrderingColumn(e, 'definition')}
          >
            Defin.
            {<Icon icon="arrow-up" />}
          </TableHeader>
          <TableHeader
            width={75}
            onClick={(e) => handleOrderingColumn(e, 'discount')}
          >
            Desconto
            {<Icon icon="arrow-up" />}
          </TableHeader>
          <TableHeader
            style={{
              fontWeight: 400,
              fontStyle: 'normal',
              fontSize: 12
            }}
          >
            Análise:
          </TableHeader>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((product, i) => (
            <Product
              key={product.uuid}
              data={product}
              index={i}
              handleProduto={handleProduto}
              handleDeletar={handleDeletar}
              submitInativa={handleInativaProduto}
              email={dados?.email || ''}
            />
          ))}
      </tbody>
      <ConfirmaModal
        title="Desconto da Peça"
        show={showConfirmaZerarModal}
        setShow={(value) => {
          setShowConfirmaZerarModal(value);
          handleCancel();
        }}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto="Salvar"
        submitFn={handleDesconto}
      />
    </TableComponent>
  );
}
