import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Image as Img } from './styled'
import PlaceholderImg from '../../../assets/default-placeholder.png'

const Imagem = ({ foto }) => {
    const [fotoCarregada, setFotoCarregada] = useState({photo: PlaceholderImg});

    const handleVerificaImagem = (fotoVerificar) => {
        const img = new Image()
        
        img.src = fotoVerificar.photo;

        img.onload = () => {
            setFotoCarregada(fotoVerificar)
        }
    }
    
    useEffect(() => {
        handleVerificaImagem(foto)
    }, [foto])

    return (
        <Img key={fotoCarregada.uuid}style={{ height: 360 }}>
            <img src={fotoCarregada.photo} width={360} height={360} />
        </Img>
    )
}

export default function ProductCarousel({ fotoArr }) {

    return (
        <div style={{ height: 360 }}>
            {fotoArr.length > 0 && (
                <Carousel 
                    width={360} 
                    renderIndicator={false}
                    showStatus={false}
                    showThumbs={false}

                    dynamicHeight={false}
                    emulateTouch={true}
                    swipeable={false}
                > 
                    {
                        fotoArr.map((photo, index) => <Imagem foto={photo} key={index} />)
                    }
                </Carousel>
            )}
            {
                fotoArr.length === 0 && (
                <Carousel 
                    width={360} 
                    renderIndicator={false}
                    showStatus={false}
                    showThumbs={false}

                    dynamicHeight={false}
                    emulateTouch={true}
                    swipeable={false}
                > 
                    {
                        fotoArr.length === 0 && (
                            <Imagem foto={{photo: PlaceholderImg}} />
                        )
                    }
                </Carousel>
                )
            }
        </div>
    )
}