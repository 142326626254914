import axios from 'axios';
import { getToken } from './auth';
import config from './config';

// Instância do Axios, define a URL base
const LOCAL_URL = 'http://localhost:3002';
const PROD_URL = 'https://api.siber.app.br';
const DEV_URL = 'https://api-siber.techlise.com.br';

const api = axios.create({
  baseURL: config.URL_API,
  headers: {
    'Content-Type': '*',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

// Middleware: Requisição - Token
// Realiza ação em todas as requisições, no caso, envia o Token
api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data?.error == 401) {
      localStorage.clear();
      window.location.pathname = '/login';
    }
    if (error?.response?.status == 406) {
      localStorage.clear();
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
