import React from 'react';
import { Navigate } from 'react-router-dom';
import { Container, Sidebar as Sidenav, Header, Content } from 'rsuite';
import { IAForm } from '../../components/IA';
import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar';
import { usePerfil } from '../../hooks/perfil';
import globalStyle from '../../styles/globalStyle';

export function IA() {
  const { loadingPer, tipoPerfil, permissaoPerfil } = usePerfil()

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_ia_adicionar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }
  return (
    <div>
      <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
        <Sidenav style={{ minHeight: '100vh', maxWidth: '14rem' }}>
          <Sidebar activePage={''} />
        </Sidenav>
        <Container>
          <Header style={{ minHeight: '3.6rem' }}>
            <Topbar pageTitle={'IA'} />
          </Header>
          <Content>
            <IAForm />
          </Content>
        </Container>
      </Container>
    </div>
  );
}
