import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.41);
`;

export const Content = styled.div`
  /* position: absolute;
  bottom: 125px;
  right: 115px; */
`;
