import React from 'react';

import { isAuthenticated } from '../services/auth';
import {
  // Switch,
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  Outlet
} from 'react-router-dom';

import Login from '../pages/Login/index';
import Home from '../pages/Home/index';
import { Perfil } from '../pages/Perfil/index';
import { IA } from '../pages/IA/index';
import { Usuario } from '../pages/Usuario/index';
import { Parceiro } from '../pages/Parceiro/index';
import { ParceiroLista } from '../pages/ParceiroLista/index';
import { ParceiroListaMap } from '../pages/ParceiroListaMap/index';
import { NotFound } from '../pages/NotFound/index';
import { Catalogo } from '../pages/Catalogo/index';
import { SemPermissao } from '../pages/SemPermissao/index';
import { PerfilProvider } from '../hooks/perfil';
import { Configuracao } from '../pages/Configuracao/index';
import { Extrato } from '../pages/Extrato/index';
import { Compra } from '../pages/Compra/index';
import { VerCompra } from '../pages/VerCompra/index';
import { ConfigProvider } from '../hooks/configuracao';
import EsqueciSenha from '../pages/EsqueciSenha';
import ResetSenha from '../pages/ResetSenha';
import { NovoUsuario } from '../pages/NovoUsuario';
import { UsuarioProvider } from '../hooks/user';
import { DetalheUsuario } from '../components/DetalheUsuario';
import HistoricoLocalizacaoParceiro from '../pages/HistoricoLocalizacaoParceiro';
import { ParceiroProvider } from '../hooks/parceiro';
import DetalheProduto from '../pages/DetalheProduto';
import EditarProduto from '../pages/EditarProduto';
import { CatalogoProvider } from '../hooks/catalogo';
import NovoProduto from '../pages/NovoProduto';
import { ExtratoDetalhe } from '../pages/ExtratoDetalhe';
import { ExtratoProvider } from '../hooks/extrato';
import { VerLotes } from '../pages/VerLotes';
import { CompraProvider } from '../hooks/compra';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate replace to={{ pathname: '/login' }} />
  );
};

const MainRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/esqueci-senha" element={<EsqueciSenha />} />
      <Route exact path="/restaurar-senha" element={<ResetSenha />} />
      <Route path="" element={<PrivateRoute />}>
        <Route exact path="/" element={
          <PerfilProvider>
            <Home />
          </PerfilProvider>
        } />
        <Route exact path="/perfil" element={<Perfil />} />
        <Route
          exact
          path="/IA"
          element={
            <PerfilProvider>
              <IA />
            </PerfilProvider>
          }
        />
        <Route
          path="/usuario"
          element={
            <PerfilProvider>
              <Usuario />
            </PerfilProvider>
          }
        />
        <Route
          path="/usuario/detalhes/:id"
          element={
            <PerfilProvider>
              <UsuarioProvider>
                <DetalheUsuario />
              </UsuarioProvider>
            </PerfilProvider>
          }
        />
        <Route exact path="/perfil" element={<Perfil />} />
        <Route
          exact
          path="/usuario"
          element={
            <PerfilProvider>
              <Usuario />
            </PerfilProvider>
          }
        />
        <Route
          exact
          path="/usuario/novo-usuario"
          element={
            <PerfilProvider>
              <UsuarioProvider>
                <NovoUsuario />
              </UsuarioProvider>
            </PerfilProvider>
          }
        />
        <Route
          exact
          path="/parceiros"
          element={
            <PerfilProvider>
              <Parceiro />
            </PerfilProvider>
          }
        />
        <Route
          exact
          path="/parceiros-lista"
          element={
            <ParceiroProvider>
              <PerfilProvider>
                <ParceiroLista />
              </PerfilProvider>
            </ParceiroProvider>
          }
        />
        <Route
          exact
          path="/parceiro/historico-localizacao/:id"
          element={
            <PerfilProvider>
              <ParceiroProvider>
                <HistoricoLocalizacaoParceiro />
              </ParceiroProvider>
            </PerfilProvider>
          }
        />
        <Route
          exact
          path="/parceiro/:id"
          element={
            <PerfilProvider>
              <ParceiroListaMap />
            </PerfilProvider>
          }
        />
        <Route
          exact
          path="/catalogo"
          element={
            <CatalogoProvider>
              <PerfilProvider>
                <Catalogo />
              </PerfilProvider>
            </CatalogoProvider>
          }
        />
        <Route
          exact
          path="/catalogo/novo"
          element={
            <CatalogoProvider>
              <PerfilProvider>
                <NovoProduto />
              </PerfilProvider>
            </CatalogoProvider>
          }
        />
        <Route
          exact
          path="/catalogo/:id"
          element={
            <CatalogoProvider>
              <PerfilProvider>
                <DetalheProduto />
              </PerfilProvider>
            </CatalogoProvider>
          }
        />
        <Route
          exact
          path="/catalogo/editar/:id"
          element={
            <CatalogoProvider>
              <PerfilProvider>
                <EditarProduto />
              </PerfilProvider>
            </CatalogoProvider>
          }
        />
        <Route
          exact
          path="/configuracao"
          element={
            <PerfilProvider>
              <ConfigProvider>
                <Configuracao />
              </ConfigProvider>
            </PerfilProvider>
          }
        />
        <Route
          exact
          path="/extrato"
          element={
            <ExtratoProvider>
              <PerfilProvider>
                <Extrato />
              </PerfilProvider>
            </ExtratoProvider>
          }
        />
        <Route
          exact
          path="/extrato/detalhe"
          element={
            <ExtratoProvider>
              <PerfilProvider>
                <ExtratoDetalhe />
              </PerfilProvider>
            </ExtratoProvider>
          }
        />
        <Route
          exact
          path="/compra"
          element={
            <CompraProvider>
              <PerfilProvider>
                <Compra />
              </PerfilProvider>
            </CompraProvider>
          }
        />
        <Route
          path="/compra/:id"
          element={
            <CompraProvider>
              <PerfilProvider>
                <VerCompra />
              </PerfilProvider>
            </CompraProvider>
          }
        />
        <Route
          path="/compra/:id/:codigoLote"
          element={
            <PerfilProvider>
              <VerLotes />
            </PerfilProvider>
          }
        />
        <Route exact path="/sem-permissao" element={<SemPermissao />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default MainRoutes;
