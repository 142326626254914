import React, { useEffect, useState } from "react";
import { CarregarContainer, Container, ImageContainer, ImagesContainer } from "./styled";
import PlaceholderIcon from "../../assets/Icons/PlaceholderIcon";
import Input from "../UI/Input";
import { IoMdClose } from 'react-icons/io';
import globalStyle from "../../styles/globalStyle";
import { Text } from "@chakra-ui/core";

export default function UploadImages({ data, setData }) {
    const [fotos, setFotos] = useState(data.photos ? data.photos : [])
    const [fotosUpload, setFotosUpload] = useState([])
    const [fotosExcluir, setFotosExcluir] = useState([])

    const handleCarregarImagem = (event) => {
        const { files } = event.target;
        if (fotos.length < 3) {
            setFotos(
                [
                    ...fotos,
                    {
                        photo: URL.createObjectURL(files[0]),
                        name: files[0].name
                    }
                ]
            );
            setFotosUpload(
                [
                    ...fotosUpload,
                    files[0]
                ]
            );
        }
    }

    const handleRemoverFoto = (foto) => {
        const fotosArr = fotos.filter(item => item.photo != foto.photo)
        const fotosUploadArr = fotosUpload.filter(item => item.name != foto.name)

        setFotos(fotosArr);
        setFotosUpload(fotosUploadArr);

        if (foto.uuid) {
            const fotosExcluirArr = fotos.filter(item => item.uuid == foto.uuid)

            setFotosExcluir([
                ...fotosExcluir,
                fotosExcluirArr[0].uuid
            ])
        }
    }

    useEffect(() => {
        setData({
            fotosExcluir,
            fotosUpload
        })
    }, [
        fotosUpload,
        fotosExcluir
    ])

    return (
        <Container>
            <div>
                <label htmlFor="foto">
                    <CarregarContainer>
                        <div>
                            <PlaceholderIcon largura={18} altura={18} />
                        </div>
                        <Text color={globalStyle.colors.GRAY900}>
                            Carregar imagem
                        </Text>
                        <Input type="file" onChange={handleCarregarImagem} id="foto" />
                    </CarregarContainer>
                </label>
            </div>
            <ImagesContainer>
                {
                    fotos.map((foto, index) => (
                        <ImageContainer key={index}>
                            <IoMdClose
                                onClick={() => handleRemoverFoto(foto)}
                                color={globalStyle.colors.GRAY700}
                                style={{
                                    position: 'absolute',
                                    right: 5,
                                    top: 5,
                                    cursor: 'pointer'
                                }}
                                size={32}
                            />
                            <img src={foto.photo} />
                        </ImageContainer>
                    ))
                }
            </ImagesContainer>
        </Container>
    )
}