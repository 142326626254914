import React, { useState } from 'react';
import { Container, Entrada, HeaderWhite, PlusIconContainer } from './styled';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Avatar, Icon, Tooltip, Whisper } from 'rsuite';
import globalStyle from '../../../styles/globalStyle';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import api from '../../../services/api';
import EyeIcon from '../../../assets/Icons/EyeIcon';
import TableIcon from '../../../assets/Icons/TableIcon';
import PaperPlaneIcon from '../../../assets/Icons/PaperPlaneIcon';
import DocumentIcon from '../../../assets/Icons/DocumentIcon';
import CloseIcon from '../../../assets/Icons/CloseIcon';
import PlusIcon from '../../../assets/PlusIcon.png';
import { usePerfil } from '../../../hooks/perfil';
import { useNavigate } from 'react-router-dom';
import { VscChromeClose } from 'react-icons/vsc';

export default function TableComponent(props) {
  const [lista, setLista] = useState([]);
  const { permissaoPerfil, tipoPerfil } = usePerfil();

  const navigate = useNavigate()

  useEffect(() => {
    setLista(
      props.lista.map((item) => {
        const newObj = props.columns
          .map((key) => ({ [key.body]: item[key.body] }))
          .reduce((anterior, atual) => {
            return {
              ...anterior,
              ...atual
            };
          }, {});
        return newObj;
      })
    );
  }, [props.listaPorTipo, props.lista, props.columns]);

  return (
    <Container>
      {(props.page == 'catalogo' || props.page == 'usuario') &&
        ((permissaoPerfil != null &&
          permissaoPerfil.includes('per_' + props.page + '_adicionar')) ||
          tipoPerfil == 1) && (
          <PlusIconContainer onClick={() => props.handleClickNew()}>
            <img src={PlusIcon} alt="" />
          </PlusIconContainer>
        )}
      <table>
        <thead>
          <tr>
            {props.columns.map((col, index) => (
              <th
                key={col.body}
                onClick={(e) => {
                  let column = props.orderingByColumnsIndex[index];
                  if (column) props.handleOrderingColumn(e, column);
                }}
                className={`${col.header === 'ID'
                  ? 'columnID columnDefault'
                  : col.header === '' &&
                    props.page === 'HistoricoLocalizacaoParceiro'
                    ? 'columnDefault spaceColumn'
                    : col.header === '' && props.page != 'visualizar_lotes'
                      ? 'action'
                      : col.header === 'Status'
                        ? 'status columnDefault'
                        : col.header === 'Nome' && props.page != 'visualizar_compra'
                          ? 'columnDefault nameColumn'
                          : col.header === '' && props.page == 'visualizar_lotes'
                            ? 'columnDefault imgColumn'
                            : col.header === 'Valor Total' && props.page == 'visualizar_compra'
                              ? 'columnDefault valorColumn'
                              : col.header === 'Nome' && props.page == 'visualizar_compra'
                                ? 'columnDefault nomeVisuCompraColumn'
                                : col.header === 'Código' && props.page == 'visualizar_compra'
                                  ? 'columnDefault codigoColumn'
                                  : col.header === 'Data / Hora' && props.page == 'visualizar_compra'
                                    ? 'columnDefault dataHoraColumn'
                                    : col.header === 'Localização'
                                      ? 'columnDefault location'
                                      : 'columnDefault'


                  }
                  `}
              >
                {col.header}

                {props.orderingByColumnsIndex[index] ? <Icon icon="arrow-up" /> : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.lista.map((list, i) => (
            <tr key={list.uuid ? list.uuid : i}>
              {props.columns.map((col, index) => (
                <td
                  key={index}
                  style={{
                    color: globalStyle.colors.GRAY900,
                    textTransform: props.uppercase
                      ? props.uppercase
                      : 'uppercase'
                  }}
                  onClick={() => {
                    if (
                      props.page != 'usuario' &&
                      props.page != 'catalogo' &&
                      props.page != 'compra' &&
                      col.body != 'action'
                    ) {
                      if (props.page == 'extrato') {
                        return
                      }
                      props.handleClickOpenEdit(
                        list.uuid,
                        props.pagination[0].current_page,
                        list.nome
                      );
                    }
                  }}
                >
                  {props.isLoading ? (
                    <Skeleton />
                  ) : col.body == 'image' ? (
                    <Avatar size="md" src={list['foto']} />
                  ) : col.body == 'preco' && props.page != 'visualizar_lotes' ? (
                    `R$ ${list.preco?.br}`
                  ) : col.body == 'cpf' && props.page != 'visualizar_compra' ? (
                    `${list.cpf?.br}`
                  ) : col.body == 'saldo' ? (
                    `${list?.saldo?.br || list.valor.real}`
                  ) : col.body == 'catalogo' ? (
                    `${list?.Catalogo?.length}`
                  ) : col.body == 'data_criacao' ? (
                    `${list?.data_criacao?.br}`
                  ) : col.body == 'limite' ? (
                    `${list.limite.real}`
                  ) : col.body === 'data/hora' ? (
                    <div>
                      {new Date(list['created_at']).toLocaleDateString()} -{' '}
                      {new Date(list['created_at']).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </div>
                  ) : col.body == "search_text" ? (
                    <div>
                      {list.search_text ? list.search_text : 'S/D'}
                    </div>
                  ) : col.body === 'latitude' ? (
                    <div
                      style={{
                        width: 200,
                        borderWidth: 2,
                        borderColor: 'red',
                        fontSize: 14
                      }}
                    >
                      {list.latitude} , {list.longitude}
                    </div>
                  ) : col.body === 'imei' ? (
                    <div>{list.imei ? list.imei : 'S/D'}</div>
                  ) : col.body == 'imagem' && props.page == 'visualizar_lotes' ? (
                    <Avatar size="md" src={list?.photos ? list?.photos[0]?.photo : list?.image ? list?.image : null} />
                  ) : col.body == 'marca' && props.page == 'visualizar_lotes' ? (
                    <div>{list.brand ? list.brand : list.marca}</div>
                  ) : col.body == 'nome' && props.page == 'visualizar_lotes' ? (
                    <div>{list.model ? list.model : list.modelo}</div>
                  ) : col.body == 'codigo' && props.page == 'visualizar_lotes' ? (
                    <div>{list.code1 ? list.code1 : list.codigo}</div>
                  ) : col.body == 'codigo2' && props.page == 'visualizar_lotes' ? (
                    <div>{list.code2 ? list.code2 : "*"}</div>
                  ) : col.body == 'codigo3' && props.page == 'visualizar_lotes' ? (
                    <div>{list.code3 ? list.code3 : "*"}</div>
                  ) : col.body == 'quantidade' && props.page == 'visualizar_lotes' ? (
                    <>
                      {!props.isEditando && (
                        <div>{list.qty}</div>
                      )}
                      {props.isEditando && (
                        <input
                          className='inputQty'
                          // value={list.qty} 
                          onChange={(e) => props.handleEditQty(e.target.value, list.uuid, i)}
                        />
                      )}
                    </>

                  ) : col.body == 'preco' && props.page == 'visualizar_lotes' ? (
                    <div>R$ {list.preco}</div>
                  ) : col.body == 'preco/kg' && props.page == 'visualizar_lotes' ? (
                    <div>{list.precoKG == null ? '*' : list.precoKG}</div>
                  ) : col.body == 'peso/kg' && props.page == 'visualizar_lotes' ? (
                    <div>{list.peso != null ? list.peso : '*'}</div>
                  )
                    : col.body == 'dataHora' && props.page == 'visualizar_compra' ? (
                      <div>{list.data_criacao.br}</div>
                    ) : col.body == 'nome' && props.page == 'visualizar_compra' ? (
                      <div>{props.parceiroData.nome}</div>
                    ) : col.body == 'cpf' && props.page == 'visualizar_compra' ? (
                      <div>{props.parceiroData.cpf}</div>
                    ) : col.body == 'valorTotal' && props.page == 'compra' ? (
                      <div>R$ {list?.saldo?.br}</div>
                    ) : col.body == 'valorTotal' && props.page == 'visualizar_compra' ? (
                      <div>R$ {list.valor.real}</div>
                    ) : col.body == 'peca' && props.page == 'usuario' ? (
                      <div>{list.tipo == 1 ? 'S/d' : list.peca}</div>
                    ) : col.body == 'action' && props.page == 'parceiros_lista' ? (
                      <div
                        onClick={() => props.handleDowloadHIstoryLocation(list.uuid)}
                        style={
                          {
                            width: 28,
                            backgroundColor: globalStyle.colors.GRAY50,
                            height: 28,
                            padding: 0,
                            marginRight: 10,
                            borderRadius: 4,
                          }
                        }>
                        <DocumentIcon largura={25} style={{ padding: '1px 1px 0' }} />
                      </div>
                    ) : col.body == 'action' && props.page == 'compra' ? (
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <Button
                          onClick={() => {
                            props.handleClickOpenEdit(
                              list.uuid,
                              props.page == 'extrato'
                                ? list.nome
                                  ? list.nome
                                  : ''
                                : props.pagination[0].current_page,
                              list.nome
                            );
                          }}
                          placement="bottom"
                          trigger="hover"
                          tooltip={'Detalhes'}
                          text={<EyeIcon largura={32}></EyeIcon>}
                          backGroundColor="transparent"
                          width="28px"
                          height="28px"
                          marginRight="10px"
                          padding="0 0"
                        ></Button>
                        <div
                          onClick={() => props.handleDowloadAllPurchasesUser(list.uuid)}
                          style={
                            {
                              width: 28,
                              backgroundColor: globalStyle.colors.GRAY50,
                              height: 28,
                              padding: 0,
                              marginRight: 10,
                              borderRadius: 4,
                            }
                          }>
                          <DocumentIcon largura={25} style={{ padding: '1px 1px 0' }} />
                        </div>
                      </div>
                    ) : col.body == 'action' && props.page == 'extrato' ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <div
                          style={{ marginRight: 8, cursor: 'pointer' }}
                          onClick={() => props.handleClickOpenEdit(
                            list.uuid,
                            list.nome
                          )}
                        >
                          <EyeIcon largura={38} altura={32} />
                        </div>
                        <Whisper
                          placement="bottom"
                          trigger="hover"
                          speaker={<Tooltip>Adicionar saldo</Tooltip>}
                        >
                          <Entrada
                            onClick={() =>
                              props.handleAction(list.uuid, 1, list.nome)
                            }
                          >
                            Saldo
                          </Entrada>
                        </Whisper>
                      </div>
                    ) : col.body == 'action' && props.page == 'ver_compra' ? (
                      <div
                        className="del"
                        onClick={() => props.handleAction(list.uuid)}
                      >
                        Deletar
                      </div>
                    ) : col.body == 'action' && props.page == 'visualizar_compra' ? (
                      <>
                        <Button
                          onClick={() => { props.handleClickOpenEdit(list.uuid, list.uuid, props.pagination.current_page) }}
                          placement="bottom"
                          trigger="hover"
                          tooltip={'Detalhes'}
                          text={<EyeIcon largura={25} />}
                          backGroundColor="transparent"
                          width="28px"
                          height="28px"
                          marginRight="10px"
                          padding="0 0"
                        ></Button>

                        <Button
                          onClick={(e) => { list.status_purchase != 'finished' && props.handleClickFinalizarLote(list.uuid, list) }}
                          placement="bottom"
                          trigger="hover"
                          tooltip={'Finalizar lote'}
                          text={<PaperPlaneIcon largura={25}></PaperPlaneIcon>}
                          backGroundColor={list.status_purchase == 'finished' ? globalStyle.colors.GRAY600 : globalStyle.colors.GRAY100}
                          width="28px"
                          height="28px"
                          marginRight="10px"
                          padding="0 0"
                          disable={list.status_purchase == 'finished'}
                        ></Button>

                        <Button
                          onClick={() => { props.handleClickDownload(list.uuid) }}
                          placement="bottom"
                          trigger="hover"
                          tooltip={'Baixar planilha'}
                          text={<DocumentIcon largura={25} />}
                          backGroundColor={globalStyle.colors.GRAY50}
                          width="28px"
                          height="28px"
                          marginRight="10px"
                          padding="0 0"
                        ></Button>

                        <Button
                          onClick={() => { props.handleDeleteExtrato(list.uuid) }}
                          placement="bottom"
                          trigger="hover"
                          tooltip={'Excluir usuário'}
                          text={<VscChromeClose
                            color={globalStyle.colors.white}
                            style={{ cursor: 'pointer', marginLeft: 1 }}
                            size={25}
                          />}
                          backGroundColor={globalStyle.colors.RED500}
                          width="28px"
                          height="28px"
                          padding="0 0"
                        ></Button>
                      </>
                    ) : col.body == 'action' && props.page == 'usuario' ? (
                      <>
                        <Button
                          onClick={() => {
                            props.toggleViewUser(list.uuid);
                            props.handleClickOpenEdit(
                              list.uuid,
                              props.page == 'extrato'
                                ? list.nome
                                  ? list.nome
                                  : ''
                                : props.pagination[0].current_page
                            );
                          }}
                          placement="bottom"
                          trigger="hover"
                          tooltip={'Ver usuário'}
                          text={
                            <FontAwesomeIcon
                              size="sm"
                              icon={faEye}
                              style={{
                                marginBottom: '-5px'
                              }}
                            />
                          }
                          fontSize={'35px'}
                          width="32px"
                          height="32px"
                          marginRight="20px"
                          backGroundColor={globalStyle.colors.TRANSPARENT}
                        ></Button>
                        <Button
                          onClick={() => {
                            props.setIsOpenInativarConfirm(true);
                            props.setUuid(list.uuid);
                          }}
                          placement="bottom"
                          trigger="hover"
                          tooltip={'Excluir usuário'}
                          text={<VscChromeClose
                            color={globalStyle.colors.white}
                            style={{ cursor: 'pointer', margin: 'auto' }}
                            size={25}
                          />}
                          backGroundColor={globalStyle.colors.RED500}
                          width="32px"
                          height="32px"
                          padding="0 0"
                        ></Button>
                      </>
                    ) : col.body == 'action' && props.page == 'catalogo' ? (
                      <>
                        <div>
                          <Whisper
                            placement="bottom"
                            trigger="hover"
                            speaker={<Tooltip>Detalhes</Tooltip>}
                          >
                            <div
                              onClick={() => {
                                props.handleClickOpenEdit(
                                  list.uuid,
                                  props.page == 'extrato'
                                    ? list.nome
                                      ? list.nome
                                      : ''
                                    : props.pagination[0].current_page
                                );
                              }}
                              style={{ width: 40, float: 'left' }}
                            >
                              <EyeIcon largura={40} altura={40}></EyeIcon>
                            </div>
                          </Whisper>

                          {/* <Whisper
                          placement="bottom"
                          trigger="hover"
                          speaker={<Tooltip>Table</Tooltip>}
                        >
                          <div
                            onClick={() => {}}
                            style={{ width: 40, float: 'left' }}
                          >
                            <TableIcon largura={40} altura={40}></TableIcon>
                          </div>
                        </Whisper> */}
                        </div>
                      </>
                    ) : col.body == 'action' && props.page == 'extrato' ? (
                      <Whisper
                        placement="bottom"
                        trigger="hover"
                        speaker={<Tooltip>Adicionar saldo</Tooltip>}
                      >
                        <Entrada
                          onClick={() =>
                            props.handleAction(list.uuid, 1, list.nome)
                          }
                        >
                          Saldo
                        </Entrada>
                      </Whisper>
                    ) : col.body == 'action' && props.page == 'ver_compra' ? (
                      <div
                        className="del"
                        onClick={() => props.handleAction(list.uuid)}
                      >
                        Deletar
                      </div>
                    ) : col.body == 'status' ? (
                      <div className="acoes">
                        <Whisper
                          placement="bottom"
                          trigger="hover"
                          speaker={<Tooltip>Status do usuário</Tooltip>}
                        >
                          <div
                            style={{
                              color: `${list[col.body] == 1
                                ? globalStyle.colors.GREEN500
                                : list[col.body] == 2
                                  ? globalStyle.colors.RED500
                                  : globalStyle.colors.YELLOW500
                                }`
                            }}
                          >
                            {list[col.body] == 1
                              ? 'Ativo'
                              : list[col.body] == 2
                                ? 'Inativo'
                                : list[col.body] == 3
                                  ? 'Analise'
                                  : ''}
                          </div>
                        </Whisper>
                      </div>
                    ) : (
                      <div style={{ color: globalStyle.colors.GRAY900 }}>{list[col.body]}</div>
                    )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}
