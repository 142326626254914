const formatPercent = (elemento) => {
    if (elemento) {
      let valor = elemento;

      valor = valor / 100;

      return valor.toFixed(4);
    }
    return '';
};

export default formatPercent;