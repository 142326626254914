import React from "react";
import { Container, FlexContainer, Label, Mes, Title, Valor } from './styled'
import moment from "moment";
import formatReal from "../../../utils/formatReal";

export default function Pecas({ data }) {
    const mesesAno = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ]

    const mesAtual = mesesAno[moment(new Date()).month()];

    const { piecesReceived, inputValues, piecesAnalyzed } = data;

    const pesaveisPecasRecebidas =
        !data.piecesReceived ? {}
            : piecesReceived[0]?.description === 'pesaveis' ? piecesReceived[0] : piecesReceived[1];
    const catalisadoresPecasRecebidas =
        !data.piecesReceived ? {}
            : piecesReceived[0]?.description !== 'pesaveis' ? piecesReceived[0] : piecesReceived[1];

    const pesaveisValoresEntrada =
        !data.inputValues ? {}
            : inputValues[0]?.description === 'pesaveis' ? inputValues[0] : inputValues[1];
    const catalisadoresValoresEntrada =
        !data.inputValues ? {}
            : inputValues[0]?.description !== 'pesaveis' ? inputValues[0] : inputValues[1];

    return (
        <Container>
            <FlexContainer style={{ display: 'flex' }}>
                <div style={{ marginRight: 48, minWidth: 300 }}>
                    <Title>Peças Recebidas</Title>
                    <Mes>Mês Referência: {mesAtual + moment(new Date()).format('/YYYY')}</Mes>
                    <FlexContainer style={{
                        alignItems: 'flex-end',
                        marginBottom: 13
                    }}>
                        <Label>Pesáveis</Label>
                        <Valor>{pesaveisPecasRecebidas?.value}Kg</Valor>
                    </FlexContainer>
                    <FlexContainer style={{
                        alignItems: 'flex-end'
                    }}>
                        <Label>Catalisadores</Label>
                        <Valor>{catalisadoresPecasRecebidas?.value}</Valor>
                    </FlexContainer>
                </div>
                <div
                    style={{
                        minWidth: 300
                    }}
                >
                    <Title>Valores de Entrada</Title>
                    <Mes>Mês Referência: {mesAtual + moment(new Date()).format('/YYYY')}</Mes>
                    <FlexContainer style={{
                        alignItems: 'flex-end',
                        marginBottom: 13,
                        width: '100%'
                    }}>
                        <Label>Pesáveis</Label>
                        <Valor>R${formatReal(pesaveisValoresEntrada?.value)}</Valor>
                    </FlexContainer>
                    <FlexContainer style={{
                        alignItems: 'flex-end'
                    }}>
                        <Label>Catalisadores</Label>
                        <Valor>R${formatReal(catalisadoresValoresEntrada?.value)}</Valor>
                    </FlexContainer>
                </div>
            </FlexContainer>
            <div
                style={{
                    alignItems: 'flex-end',
                    width: 300,
                    marginTop: 20
                }}
            >
                <Title>Peças Analisadas</Title>
                <Mes>Mês Referência: {mesAtual + moment(new Date()).format('/YYYY')}</Mes>
                <FlexContainer style={{
                    alignItems: 'flex-end'
                }}>
                    <Label>Total</Label>
                    <Valor>{piecesAnalyzed?.total ? piecesAnalyzed.total : '0'}</Valor>
                </FlexContainer>
            </div>
        </Container>
    )
}