import { FormGroup, Uploader, Icon, Loader, Alert } from 'rsuite';
import * as S from './styles';
import api from '../../services/api';
import { useEffect, useState } from 'react';
import { usePerfil } from '../../hooks/perfil';
import Input from '../UI/Input';
import InputMask from 'react-input-mask';
import Button from '../UI/Button';
import { FormControl, CircularProgress, InputRightElement, Icon as Icone, Button as Botao } from '@chakra-ui/core';
import globalStyle from '../../styles/globalStyle';
import ConfirmaModal from '../ConfirmaModal';

export function PerfilForm() {
  const { editPerfil, isLoading, updateSenha } = usePerfil();

  const [uuid, setUUID] = useState('');
  const [nome, setNome] = useState('');
  const [cpf, setCPF] = useState('');
  const [email, setEmail] = useState('');
  const [oldEmail, setOldEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [data_nascimento, setDataNascimento] = useState('');
  const [avatar, setAvatar] = useState('');

  const [peca, setPeca] = useState('');
  const [po, setPo] = useState('');
  const [inox, setInox] = useState('');
  const [filtro, setFiltro] = useState('');
  const [banco, setBanco] = useState('');
  const [titular, setTitular] = useState('');
  const [titularCpf, setTitularCPF] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');

  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');

  const [tipo, setTipo] = useState('');
  const [tipoExibe, setTipoExibe] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const [senha_atual, setSenhaAtual] = useState('');
  const [nova_senha, setNovaSenha] = useState('');
  const [confirmar_senha, setConfirmarSenha] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const estados = {
    Estado: 'Estado',
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins'
  };

  function options() {
    var es = Object.entries(estados);
    return es.map((item) => (
      <option key={`estado${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const handlePasswordVisibility2 = () => setShowPassword2(!showPassword2);
  const [showConfirmaModal, setShowConfirmaModal] = useState(false);

  const handlUpdatePerfil = async (event) => {
    event.preventDefault();
    setLoading(true);
    const exists = await verifyIfEmailExists();

    let data = {
      nome,
      cpf,
      email,
      telefone,
      celular,
      data_nascimento,
      avatar,
      peca,
      po,
      inox,
      filtro,
      banco,
      titular,
      titularCpf,
      agencia,
      conta,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      tipo,
      status: status == true ? 1 : 2,
      password: nova_senha
    };

    if (exists.exists) {
      data.email = oldEmail;
    }

    await editPerfil(data, uuid);
    setShowConfirmaModal(false);
    setLoading(false);
  };

  const handleUpdatePerfilSenha = async (event) => {
    event.preventDefault();

    // let data = {
    //   old_password: senha_atual,
    //   password: nova_senha, 
    //   confirm_password: confirmar_senha
    // };
    let data = {
      password: nova_senha,
      confirm_password: confirmar_senha
    };

    await updateSenha(data);
  };

  const getData = () => {
    setLoading(true);
    api
      .get('/me')
      .then(({ data }) => {
        setLoading(false);
        setUUID(data.uuid || '');
        setNome(data.nome || '');
        setTelefone(data.telefone.br || '');
        setAvatar(data.avatar || '');
        setCPF(data.cpf.br || '');
        setDataNascimento(data.data_nascimento.br || '');
        setCelular(data.celular.br || '');
        setEmail(data.email || '');
        setOldEmail(data.email || '');
        setLogradouro(data.endereco || '');
        setBairro(data.bairro || '');
        setCidade(data.cidade || '');
        setEstado(data.estado || '');
        setNumero(data.numero || '');
        setPeca(data.peca || '');
        setPo(data.po || '');
        setInox(data.inox || '');
        setFiltro(data.filtro || '');
        setBanco(data.banco || '');
        setTitular(data.titular || '');
        setTitularCPF(data.titular_cpf || '');
        setAgencia(data.agencia || '');
        setConta(data.conta || '');
        setStatus(data.status || '');
        setTipo(data.tipo);

        if (data.tipo == 1) {
          setTipoExibe('Administrador');
        } else if (data.tipo == 2) {
          setTipoExibe('Recebimento');
        } else if (data.tipo == 3) {
          setTipoExibe('Analisador');
        }


      })
      .catch((error) => {
        setLoading(false);

        Alert.error(error.response.data.message);
      })
      .finally((response) => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect(() => {
    getData();
  }, []);

  const resetInfo = () => {
    getData();
  }

  const verifyIfEmailExists = async () => {
    const exists = await api.get(`/usuario/email/${email}`);

    if (!exists.data.status) {
      return { exists: true, message: exists.data.message };
    }

    return { exists: false };
  };

  const handleBlurEmail = async () => {
    const exists = await verifyIfEmailExists();

    if (exists.exists) {
      Alert.error(exists.message);
    }
  };
  return (
    <div>
      <S.Container>
        <div>
          {loading ? <Loader backdrop content="Carregando..." vertical /> : ''}
        </div>
        <S.TituloTopo fontWeight="700">Tipo de Usuário</S.TituloTopo>
        <S.Text fontSize="18px">{tipoExibe}</S.Text>
        <S.ContainerCardForm>

          {/* <div className="card-form"> */}
          <S.TituloTopo fontWeight="700">Dados Básicos</S.TituloTopo>
          <FormGroup>
            {/* <label>Nome</label> */}
            <S.DivInput>
              <S.InputUser
                placeholder="Nome"
                value={nome}
                type="text"
                onChange={(event) => setNome(event.target.value)}
              />
            </S.DivInput>
          </FormGroup>
          <FormGroup>
            {/* <label>E-mail: </label> */}
            <S.DivInput>
              <S.InputUser
                placeholder="E-mail"
                value={email}
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                onBlur={handleBlurEmail}
              />
            </S.DivInput>
          </FormGroup>

          <FormGroup>
            <S.DivInput display="flex">
              <FormControl isRequired>
                <S.InputMask
                  placeholder="CPF"
                  name="cpf"
                  mask="999.999.999-99"
                  width="160px"
                  value={cpf}
                  required
                  onChange={(event) => setCPF(event.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <S.InputMask
                  placeholder="Telefone"
                  mask="(99) 9999-9999"
                  name="celular"
                  width="160px"
                  value={telefone}
                  onChange={(event) => setTelefone(event.target.value)}
                />
              </FormControl>
              <S.InputMask
                placeholder="Celular"
                mask="(99) 99999-9999"
                name="telefone"
                width="160px"
                required
                value={celular}
                onChange={(event) => setCelular(event.target.value)}
              />
              <FormControl isRequired>
                <S.InputMask
                  placeholder="Data de nascimento"
                  name="nascimento"
                  mask="99/99/9999"
                  width="160px"
                  required
                  value={data_nascimento}
                  onChange={(event) => setDataNascimento(event.target.value)}
                />
              </FormControl>
            </S.DivInput>
          </FormGroup>
          {/* </div> */}
          <S.TituloTopo fontWeight="700">Senha</S.TituloTopo>
          <S.DivInput>
            <FormControl>
              <S.InputGroupS width="328px">
                <S.DivInput>
                  <S.InputUser
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Senha"
                    size="lg"
                    width="328px"
                    autoComplete="off"
                    focusBorderColor="gray"
                    onChange={(e) => setNovaSenha(e.target.value)}
                  />
                  <InputRightElement width="3rem">
                    <Botao
                      h="1.5rem"
                      size="lg"
                      onClick={handlePasswordVisibility}
                      style={{
                        backgroundColor: 'transparent',
                        color: globalStyle.colors.GRAY400,
                        top: '20px'
                      }}
                    >
                      {showPassword ? (
                        <Icone name="view" />
                      ) : (
                        <Icone name="view-off" />
                      )}
                    </Botao>
                  </InputRightElement>
                </S.DivInput>
              </S.InputGroupS>
            </FormControl>
          </S.DivInput>

          <S.DivInput>
            <FormControl>
              <S.InputGroupS width="328px">
                <S.DivInput>
                  <S.InputUser
                    type={showPassword2 ? 'text' : 'password'}
                    placeholder="Confirme a senha"
                    width="328px"
                    size="lg"
                    autoComplete="off"
                    focusBorderColor="gray"
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                  />
                  <InputRightElement width="3rem">
                    <Botao
                      h="1.5rem"
                      size="lg"
                      onClick={handlePasswordVisibility2}
                      style={{
                        backgroundColor: 'transparent',
                        color: globalStyle.colors.GRAY400,
                        top: '20px'
                      }}
                    >
                      {showPassword2 ? (
                        <Icone name="view" />
                      ) : (
                        <Icone name="view-off" />
                      )}
                    </Botao>
                  </InputRightElement>
                </S.DivInput>
              </S.InputGroupS>
            </FormControl>
          </S.DivInput>
          <S.DivInput display="flex">
            <Button
              text="CANCELAR"
              color='white'
              backGroundColor={globalStyle.colors.RED500}
              fontSize="16px"
              padding="0"
              marginTop="15px"
              marginRight="20px"
              width="132px"
              height="40px"
              tooltip="Cancelar"
              onClick={() => resetInfo()}
            />

            <Button
              text="ATUALIZAR"
              color='white'
              backGroundColor={globalStyle.colors.GRAY100}
              fontSize="16px"
              padding="0"
              marginTop="15px"
              width="132px"
              height="40px"
              tooltip="Atualizar"
              onClick={() => setShowConfirmaModal(true)}
            >
              {isLoading ? (
                <CircularProgress isIndeterminate size="24px" color="red" />
              ) : (
                'ATUALIZAR'
              )}
            </Button>
          </S.DivInput>
        </S.ContainerCardForm>
      </S.Container>
      <ConfirmaModal
        title={"Editar Perfil"}
        show={showConfirmaModal}
        setShow={setShowConfirmaModal}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto={"Editar"}
        submitFn={handlUpdatePerfil}
      />
    </div>
  );
}
