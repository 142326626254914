import styled from "styled-components";

export const Container = styled.div`
    padding: 39px 0 0 65px;
    table {
        border-collapse: separate;
        border-spacing: 0 16px;
        margin-top: -8px;
        width: 100%;
        thead {
            background: ${props => props.theme.colors.GRAY100};
            height: 32px;
            tr {
                th {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 32px;
                    color: ${props => props.theme.colors.white};
                    text-align: left;
                }
            }
        }

        tbody {
            tr {
                height: 32px;
                .primeira_td {
                    padding: 0 16px;
                }
                td {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: ${props => props.theme.colors.GRAY100};

                    &.feito {
                        color: ${(props) => props.theme.colors.GREEN500};
                    }
                    &.analise {
                        color: ${(props) => props.theme.colors.YELLOW500};
                    }
                    &.estorno {
                        color: ${(props) => props.theme.colors.GREEN200};
                    }
                    
                    input {
                        background: transparent;
                        border-bottom: solid 1px ${props => props.theme.colors.GRAY50}
                    }

                    select {
                        background: transparent;
                        border-bottom: solid 1px ${props => props.theme.colors.GRAY50}
                    }
                }
            }
        }
    }
`

export const TH = styled.th`
    width: ${props => props.width || '150px'};
`

export const Select = styled.select`
    height: 25px;
`

export const LinhaDeMargem = styled.tr`
    td {
        height: 37.5px;
    }
`


export const ValorTotal = styled.div`
    color: ${props => props.tipo == 2 || props.tipo == 3 ? props.theme.colors.RED600 : props.theme.colors.GREEN600};
`

export const ContainerPaginacao = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`