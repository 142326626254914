import React from "react";
import { Container, Table, TableHeader } from "./styled";
import globalStyle from "../../../styles/globalStyle";

const TableSeparator = () => (
    <tr style={{ height: 12 }}>
        <td style={{ height: 12 }} />
        <td style={{ height: 12 }} />
        <td style={{ height: 12 }} />
        <td style={{ height: 12 }} />
        <td style={{ height: 12 }} />
    </tr>
)
export default function PriceCalcTable({ data, style }) {
    const headerFields = [
        () =>
            <TableHeader style={{
                fontWeight: 500,
                fontStyle: 'normal',
                fontSize: 14,
                color: 'white'
            }}>
                Corpo
            </TableHeader>,
        () =>
            <TableHeader style={{
                fontWeight: 500,
                fontStyle: 'normal',
                fontSize: 14,
                color: 'white'
            }}>
                Peso
            </TableHeader>,
        () =>
            <TableHeader style={{
                fontWeight: 500,
                fontStyle: 'normal',
                fontSize: 14,
                color: 'white'
            }}>
                Pt
            </TableHeader>,
        () =>
            <TableHeader style={{
                fontWeight: 500,
                fontStyle: 'normal',
                fontSize: 14,
                color: 'white'
            }}>
                Pd
            </TableHeader>,
        () =>
            <TableHeader style={{
                fontWeight: 500,
                fontStyle: 'normal',
                fontSize: 14,
                color: 'white'
            }}>
                Rh
            </TableHeader>
    ]

    return (
        <Container style={style}>
            <Table>
                <thead>
                    <tr>
                        {
                            headerFields.map(
                                (Content, i) =>
                                    <Content key={i} />
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    <TableSeparator />
                    <tr
                        style={{
                            background: globalStyle.colors.DARK100
                        }}
                    >
                        <td>A</td>
                        <td>{data.A?.PESO}</td>
                        <td>{data.A?.PT}</td>
                        <td>{data.A?.PD}</td>
                        <td>{data.A?.RH}</td>
                    </tr>
                    <tr
                        style={{
                            background: globalStyle.colors.TRANSPARENT
                        }}
                    >
                        <td>B</td>
                        <td>{data.B?.PESO}</td>
                        <td>{data.B?.PT}</td>
                        <td>{data.B?.PD}</td>
                        <td>{data.B?.RH}</td>
                    </tr>
                    <tr
                        style={{
                            background: globalStyle.colors.DARK100
                        }}
                    >
                        <td>C</td>
                        <td>{data.C?.PESO}</td>
                        <td>{data.C?.PT}</td>
                        <td>{data.C?.PD}</td>
                        <td>{data.C?.RH}</td>
                    </tr>
                    <tr
                        style={{
                            background: globalStyle.colors.TRANSPARENT
                        }}
                    >
                        <td>D</td>
                        <td>{data.D?.PESO}</td>
                        <td>{data.D?.PT}</td>
                        <td>{data.D?.PD}</td>
                        <td>{data.D?.RH}</td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    )
}