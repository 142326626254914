import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 39px 0 0 65px;
`

export const Col = styled.div`
    width: 360px;
`

export const ProductPrice = styled.div`
    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${props => props.theme.colors.DARK100};
`

export const Price = styled.h2`
    text-align: center;
    color: ${props => props.theme.colors.GRAY900};
    font-size: 36px;
    font-weight: 700;
    line-height: 45px;
    font-style: normal;
`

export const Info = styled.p`
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: ${props => props.theme.colors.GRAY900};
    text-align: center;
    font-style: normal;
`

export const ActionsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
`

export const InfoContainer = styled.div`
    margin-left: 42px;
`

export const Row = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 13px;
`

export const RowColumn = styled.div`
    margin-right: 50px;
`
export const Label = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.colors.DARK100};
    text-transform: uppercase;
`

export const Text = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: ${props => props.theme.colors.GRAY900};
    margin-left: 4px;
    text-transform: uppercase;

`

export const Observation = styled.p`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.colors.GRAY50};
    text-align: justify;
    /* height: 100px;
    overflow: hidden; */
`