import styled from "styled-components";

export const Container = styled.div`
    margin-top: 32px;
    margin-right: 72px;
`

export const DivRadio = styled.div`
  margin-top: 8px;
  input:checked {
    background-color: #e5e5e5;
    color: #fff;
  }
  align-items: center;
  
  display: flex;
`;

export const Radio = styled.input`
  margin-right: 8px;
  -webkit-appearance: none;
  appearance: none;

  background: ${(props) => props.theme.colors.GRAY100};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ::after {
    content: '';
    background: transparent;
    border: solid 4px ${(props) => props.theme.colors.GRAY100};
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
`;

export const Text = styled.h2`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  color: ${(props) => props.theme.colors.GRAY900};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-family: 'Lato';
  margin-right: 32px;
  line-height: 27px;
`;