import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { ParceiroTable } from '../../components/ParceiroTable/index';
import { ParceiroProvider, useParceiro } from '../../hooks/parceiro';
import { Container, Header, Sidebar as Sidenav, Content, Loader } from 'rsuite';
import { usePerfil } from '../../hooks/perfil';
import { Navigate } from 'react-router-dom';
import globalStyle from '../../styles/globalStyle';

export function ParceiroLista() {
  let app = 'parceiro';
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();
  const {
    isLoading,
  } = useParceiro();

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_' + app + '_visualizar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }

  return (
    <>
      {loadingPer || isLoading ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : null}
      <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
        <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
          <Sidebar activePage={'4'} />
        </Sidenav>
        <Container>
          <Header style={{ minHeight: '3.6rem', zIndex: 1000 }}>
            <Topbar pageTitle={'Parceiros'} />
          </Header>
          <Content>
            <ParceiroTable />
          </Content>
        </Container>
      </Container>
    </>
  );
}
