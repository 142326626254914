import { Container as RSContainer } from 'rsuite';
import styled from 'styled-components';

export const Container = styled(RSContainer)`
  background-color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
`

export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 20px;
  position: relative;
  bottom: 0; 
  width: 100%;
  height: 2.5rem;  
  justify-content: end;
  right: 80px;
  margin-bottom: 20px;
  .footerButtons{
    display: flex;
    justify-content: center;
    
  }

`;