import api from "../services/api";
import { login } from '../services/auth';

export const userLogin = async ({email, password}) => {
    return new Promise((resolve,reject) =>  {
        api.post('/login', {email, password}).then(response => {

            if(response.data.user.original.tipo == 4){
                let erro = 'Tipo de usuário não permitido para acesso ao sistema';
                reject(erro);
                return false;
            }else{
                login(response.data.access_token);
                if(response?.data?.user?.original?.tipo != 1) {
                    setTimeout(() => {
                         window.location.pathname = '/catalogo';
                     }, 1000);
                    resolve();
                } else {
                    setTimeout(() => {
                        window.location.pathname = '/';
                    }, 1000);
                    resolve();
                }
                
            }
       }).catch(err => {
           if(err.response){
                let erro = err.response.data.message;
                reject(erro);
           }
       });
    });
};