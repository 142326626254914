import React, { useState } from 'react';
import { BotaoAcaoContainer, Title, FilterContainer } from './styled';
import InputMask from 'react-input-mask';
import { Message, Modal } from 'rsuite';
import globalStyle from '../../styles/globalStyle';
import Button from '../UI/Button';

export default function SpreadsheetFilterModal({ show, setShow, submitFn }) {
  const [error, setError] = useState(errorInitialState);
  const [filtros, setFiltros] = useState(inicialFiltros);
  const [showFieldError, setShowFieldError] = useState('');

  const fechaModal = () => setShow && setShow(false);

  const handleGenerate = async () => {
    setShowFieldError('');

    if (!filtros.periodo.inicio) {
      return setShowFieldError('inicio');
    }
    if (!filtros.periodo.fim) {
      return setShowFieldError('fim');
    }

    const response = await submitFn(filtros);
    if (response?.error) {
      console.log(response);
      setError(response);
      return setTimeout(() => setError(errorInitialState), 1000);
    }
    setShow(false);
  };

  return (
    <Modal backdrop={'static'} show={show} onHide={fechaModal} size="lg">
      <Modal.Header>
        <Modal.Title>
          <Title>Selecione o período das consultas</Title>
        </Modal.Title>
        <div
          style={{
            width: '100%',
            height: 1,
            background: '#E5E7EB',
            position: 'absolute',
            left: 0,
            top: 50
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <div>
          <FilterContainer>
            <div>
              <label>Data Inicial</label>
              <InputMask
                type="datetime-local"
                placeholder="Data inicial"
                onChange={(event) => {
                  setShowFieldError('');
                  setFiltros({
                    ...filtros,
                    periodo: {
                      ...filtros.periodo,
                      inicio: event.target.value
                    }
                  });
                }}
                value={filtros.periodo.inicio}
              />
              {showFieldError === 'inicio' ? (
                <span>Selecione a data inicial</span>
              ) : null}
            </div>
            <div>
              <label style={{ color: 'black' }}>Data Final</label>
              <InputMask
                type="datetime-local"
                placeholder="Data final"
                onChange={(event) => {
                  setShowFieldError('');
                  setFiltros({
                    ...filtros,
                    periodo: { ...filtros.periodo, fim: event.target.value }
                  });
                }}
                value={filtros.periodo.fim}
              />
              {showFieldError === 'fim' ? (
                <span>Selecione a data final</span>
              ) : null}
            </div>
            <div>
              <label>Origem</label>
              <select
                onChange={(e) =>
                  setFiltros({ ...filtros, origem: e.target.value })
                }
                value={filtros.origem}
              >
                <option value="Origem" selected disabled>
                  Origem
                </option>
                <option value="consulta">CONSULTA</option>
                <option value="login">LOGIN</option>
              </select>
            </div>
            <div>
              <Button
                text="Cancelar"
                width="100%"
                padding="0"
                style={{ border: `${globalStyle.colors.GRAY500} solid 1px ` }}
                backGroundColor="transparent"
                color={globalStyle.colors.GRAY500}
                onClick={fechaModal}
              />
            </div>
            <div>
              <Button
                text={'Gerar'}
                width="100%"
                padding="0"
                backGroundColor={globalStyle.colors.GREEN500}
                color={globalStyle.colors.GRAY50}
                onClick={handleGenerate}
              />
            </div>
          </FilterContainer>
          {error.error && (
            <Message
              type="error"
              description={error.message}
              style={{ width: 300, textAlign: 'center', margin: '32px auto 0' }}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

const errorInitialState = {
  error: false,
  message: ''
};

const inicialFiltros = {
  periodo: {
    inicio: '',
    fim: ''
  },
  origem: 'Origem'
};
