import React, { useEffect, useState } from "react";
import { Container, Header, Row, RowLabel } from "./styled";
import Input from "../../UI/Input";

export default function Table({ data, setTeor }) {
    const [pesoA, setPesoA] = useState(data?.A?.PESO ? data.A.PESO : '')
    const [pesoB, setPesoB] = useState(data?.B?.PESO ? data.B.PESO : '')
    const [pesoC, setPesoC] = useState(data?.C?.PESO ? data.C.PESO : '')
    const [pesoD, setPesoD] = useState(data?.D?.PESO ? data.D.PESO : '')

    const [platinumA, setPlatinumA] = useState(data?.A?.PT ? data.A.PT : '')
    const [platinumB, setPlatinumB] = useState(data?.B?.PT ? data.B.PT : '')
    const [platinumC, setPlatinumC] = useState(data?.C?.PT ? data.C.PT : '')
    const [platinumD, setPlatinumD] = useState(data?.D?.PT ? data.D.PT : '')

    const [palladiumA, setPalladiumA] = useState(data?.A?.PD ? data.A.PD : '')
    const [palladiumB, setPalladiumB] = useState(data?.B?.PD ? data.B.PD : '')
    const [palladiumC, setPalladiumC] = useState(data?.C?.PD ? data.C.PD : '')
    const [palladiumD, setPalladiumD] = useState(data?.D?.PD ? data.D.PD : '')

    const [rhodiumA, setRhodiumA] = useState(data?.A?.RH ? data.A.RH : '')
    const [rhodiumB, setRhodiumB] = useState(data?.B?.RH ? data.B.RH : '')
    const [rhodiumC, setRhodiumC] = useState(data?.C?.RH ? data.C.RH : '')
    const [rhodiumD, setRhodiumD] = useState(data?.D?.RH ? data.D.RH : '')


    useEffect(() => {
        const teor = {
            A: JSON.stringify({
                PESO: pesoA,
                PT: platinumA,
                PD: palladiumA,
                RH: rhodiumA
            }),
            B: JSON.stringify({
                PESO: pesoB,
                PT: platinumB,
                PD: palladiumB,
                RH: rhodiumB
            }),
            C: JSON.stringify({
                PESO: pesoC,
                PT: platinumC,
                PD: palladiumC,
                RH: rhodiumC
            }),
            D: JSON.stringify({
                PESO: pesoD,
                PT: platinumD,
                PD: palladiumD,
                RH: rhodiumD
            })
        }

        setTeor(teor)
    }, [
        pesoA,
        pesoB,
        pesoC,
        pesoD,
        platinumA,
        platinumB,
        platinumC,
        platinumD,
        palladiumA,
        palladiumB,
        palladiumC,
        palladiumD,
        rhodiumA,
        rhodiumB,
        rhodiumC,
        rhodiumD
    ])

    return (
        <Container>
            <Header>
                <div>Teor</div>
                <div>Peso</div>
                <div>Pt</div>
                <div>Pd</div>
                <div>Rh</div>
            </Header>

            <Row>
                <RowLabel>A</RowLabel>
                <div>
                    <Input
                        value={pesoA}
                        onChange={(e) => setPesoA(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                    <span>Peso em gramas</span>
                </div>
                <div>
                    <Input
                        value={platinumA}
                        onChange={(e) => setPlatinumA(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
                <div>
                    <Input
                        value={palladiumA}
                        onChange={(e) => setPalladiumA(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
                <div>
                    <Input
                        value={rhodiumA}
                        onChange={(e) => setRhodiumA(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
            </Row>

            <Row>
                <RowLabel>B</RowLabel>
                <div>
                    <Input
                        value={pesoB}
                        onChange={(e) => setPesoB(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                    <span>Peso em gramas</span>
                </div>
                <div>
                    <Input
                        value={platinumB}
                        onChange={e => setPlatinumB(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
                <div>
                    <Input
                        value={palladiumB}
                        onChange={(e) => setPalladiumB(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
                <div>
                    <Input
                        value={rhodiumB}
                        onChange={(e) => {
                            setRhodiumB(e.target.value)
                        }}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
            </Row>

            <Row>
                <RowLabel>C</RowLabel>
                <div>
                    <Input
                        value={pesoC}
                        onChange={(e) => setPesoC(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                    <span>Peso em gramas</span>
                </div>
                <div>
                    <Input
                        value={platinumC}
                        onChange={(e) => setPlatinumC(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
                <div>
                    <Input
                        value={palladiumC}
                        onChange={(e) => setPalladiumC(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
                <div>
                    <Input
                        value={rhodiumC}
                        onChange={e => setRhodiumC(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
            </Row>

            <Row>
                <RowLabel>D</RowLabel>
                <div>
                    <Input
                        value={pesoD}
                        onChange={(e) => setPesoD(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                    <span>Peso em gramas</span>
                </div>
                <div>
                    <Input
                        value={platinumD}
                        onChange={e => setPlatinumD(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
                <div>
                    <Input
                        value={palladiumD}
                        onChange={e => setPalladiumD(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
                <div>
                    <Input
                        value={rhodiumD}
                        onChange={e => setRhodiumD(e.target.value)}
                        width={98}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </div>
            </Row>
        </Container>
    )
}