import React from 'react';
import globalStyle from '../../styles/globalStyle';

const CatalogIcon = ({ largura, altura, color = globalStyle.colors.GRAY100 }) => {
  return (
    <svg
      width={largura}
      height={altura}
      viewBox="0 0 24 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline', marginRight: 10, marginLeft: 10 }}
    >
      <g clipPath="url(#clip0_2743_7769)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 3.87109C7.20507 3.87109 5.75 5.32617 5.75 7.12109C5.75 8.91602 7.20507 10.3711 9 10.3711C10.7949 10.3711 12.25 8.91602 12.25 7.12109C12.25 5.32617 10.7949 3.87109 9 3.87109ZM4.25 7.12109C4.25 4.49774 6.37665 2.37109 9 2.37109C11.6234 2.37109 13.75 4.49774 13.75 7.12109C13.75 9.74445 11.6234 11.8711 9 11.8711C6.37665 11.8711 4.25 9.74445 4.25 7.12109Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 15.8711C6.13805 15.8711 5.3114 16.2135 4.7019 16.823C4.09241 17.4325 3.75 18.2591 3.75 19.1211V21.1211C3.75 21.5353 3.41421 21.8711 3 21.8711C2.58579 21.8711 2.25 21.5353 2.25 21.1211V19.1211C2.25 17.8613 2.75044 16.6531 3.64124 15.7623C4.53204 14.8715 5.74022 14.3711 7 14.3711H11C12.2598 14.3711 13.468 14.8715 14.3588 15.7623C15.2496 16.6531 15.75 17.8613 15.75 19.1211V21.1211C15.75 21.5353 15.4142 21.8711 15 21.8711C14.5858 21.8711 14.25 21.5353 14.25 21.1211V19.1211C14.25 18.2591 13.9076 17.4325 13.2981 16.823C12.6886 16.2135 11.862 15.8711 11 15.8711H7Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2734 3.06513C15.3762 2.66386 15.7848 2.42185 16.186 2.52459C17.2078 2.7862 18.1134 3.38043 18.7601 4.21359C19.4068 5.04675 19.7578 6.07146 19.7578 7.12616C19.7578 8.18086 19.4068 9.20556 18.7601 10.0387C18.1134 10.8719 17.2078 11.4661 16.186 11.7277C15.7848 11.8305 15.3762 11.5885 15.2734 11.1872C15.1707 10.7859 15.4127 10.3773 15.814 10.2746C16.5131 10.0956 17.1327 9.68903 17.5752 9.11897C18.0177 8.54891 18.2578 7.8478 18.2578 7.12616C18.2578 6.40452 18.0177 5.70341 17.5752 5.13335C17.1327 4.56329 16.5131 4.15671 15.814 3.97772C15.4127 3.87498 15.1707 3.4664 15.2734 3.06513Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2738 15.0837C17.3774 14.6826 17.7864 14.4414 18.1875 14.545C19.2026 14.8071 20.1025 15.3976 20.7469 16.2246C21.3913 17.0516 21.744 18.0685 21.75 19.1169L21.75 19.1212H21.75V21.1212C21.75 21.5354 21.4142 21.8712 21 21.8712C20.5858 21.8712 20.25 21.5354 20.25 21.1212V19.1234C20.2454 18.4068 20.0041 17.7118 19.5637 17.1466C19.1228 16.5807 18.5071 16.1767 17.8125 15.9974C17.4114 15.8938 17.1703 15.4848 17.2738 15.0837Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2743_7769">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0 0.121094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CatalogIcon;
