import React from 'react';
import { Container, Label, Valor, Content, Header, HeaderLabel, Value, Title } from './styled';
import globalStyle from '../../../styles/globalStyle';
import formatReal from '../../../utils/formatReal';
import moment from 'moment';

export default function PrepostosAtivos({ data }) {
    return (
        <Container>
            <Title>Prepostos Ativos</Title>
            {
                data?.fornecedoresAtivos?.preposto ? (
                    <>
                        <Content>
                            <Label>Total</Label>
                            <Valor>{data?.fornecedoresAtivos?.preposto ? data?.fornecedoresAtivos?.preposto : '0'}</Valor>
                        </Content>
                    </>
                ) : null
            }
        </Container>
    )
}