import { useState } from 'react';
import { UsuarioTable } from '../../components/UsuarioTable/index';
import { NewUserModal } from '../../components/NewUserModal/index';
import { EditUserModal } from '../../components/EditUserModal/index';
import { VisualizarUserModal } from '../../components/VisualizarUserModal/index';
import { SearchUsuarioModal } from '../../components/SearchUsuarioModal/index';
import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { UsuarioProvider, useUsuario } from '../../hooks/user';
import { Header, Sidebar as Sidenav, Loader, Container, Icon } from 'rsuite';
import { usePerfil } from '../../hooks/perfil';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Content } from './styles';
import { useEffect } from 'react';
import globalStyle from '../../styles/globalStyle';

export function Usuario() {
  useEffect(() => {
    setViewUser(false);
  }, []);
  let app = 'usuario';
  const [selectedUser, setSelectedUser] = useState('');
  const [viewUser, setViewUser] = useState(false);
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isViewUserModalOpen, setIsViewUserModalOpen] = useState(false);
  const [isSearchUsuarioModalOpen, setIsSearchUsuarioModalOpen] =
    useState(false);
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  const {
    isLoadingSearch
  } = useUsuario()

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_' + app + '_visualizar') == false &&
    permissaoPerfil.includes('per_' + app + '_adicionar') == false &&
    permissaoPerfil.includes('per_' + app + '_editar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }

  function handleOpenNewUserModal() {
    setIsNewUserModalOpen(true);
  }

  function handleCloseNewUserModal() {
    setIsNewUserModalOpen(false);
  }

  function handleOpenEditUserModal() {
    setIsEditUserModalOpen(true);
  }

  function handleCloseEditUserModal() {
    setIsEditUserModalOpen(false);
  }

  function handleOpenViewUserModal() {
    setIsViewUserModalOpen(true);
  }

  function handleCloseViewUserModal() {
    setIsViewUserModalOpen(false);
  }
  function handleOpenSearchUsuarioModal() {
    setIsSearchUsuarioModalOpen(true);
  }

  function handleCloseSearchUsuarioModal() {
    setIsSearchUsuarioModalOpen(false);
  }

  // function toggleViewUser(mode) {
  //   setViewUser(mode);
  // }

  function toggleViewUser(usr_id) {
    navigate(`/usuario/detalhes/${usr_id}`);
  }

  return (
    <UsuarioProvider>
      {loadingPer || isLoadingSearch ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : null}
      <Container style={{ background: globalStyle.colors.BACKGROUND_PRIMARY }}>
        <Sidenav style={{ minHeight: '100vh', maxWidth: '14rem' }}>
          <Sidebar activePage={'2'} />
        </Sidenav>
        <Container>
          <Header style={{ minHeight: '3.6rem' }}>
            <Topbar
              pageTitle={'Usuários'}
              onOpenSearchUsuarioModal={handleOpenSearchUsuarioModal}
              viewUser={viewUser}
              username={userName}
            />
          </Header>
          <Content>
            <UsuarioTable
              onOpenNewUserModal={handleOpenNewUserModal}
              onOpenEditUserModal={handleOpenEditUserModal}
              onOpenViewUserModal={handleOpenViewUserModal}
              toggleViewUser={toggleViewUser}
              viewUser={viewUser}
              setUserName={setUserName}
            />
            {/* <NewUserModal
                isOpen={isNewUserModalOpen}
                onRequestClose={handleCloseNewUserModal}
              /> */}

            {isEditUserModalOpen ? (
              <EditUserModal
                isOpenEdit={isEditUserModalOpen}
                onRequestCloseEdit={handleCloseEditUserModal}
              />
            ) : null}

            {isViewUserModalOpen ? (
              <VisualizarUserModal
                isOpenView={isViewUserModalOpen}
                onRequestCloseView={handleCloseViewUserModal}
                onOpenEditUserModal={handleOpenEditUserModal}
              />
            ) : null}
            {isSearchUsuarioModalOpen ? (
              <SearchUsuarioModal
                isOpenSearch={isSearchUsuarioModalOpen}
                onRequestCloseSearch={handleCloseSearchUsuarioModal}
              />
            ) : null}
          </Content>
        </Container>
      </Container>
    </UsuarioProvider>
  );
}
