import styled from "styled-components";

export const Container = styled.div`

    > div {
        display: grid;
        grid-template-columns: 42px repeat(4, 98px);
        gap: 16px
    }

    height: 250px;
    margin-bottom: 46px;
`

export const Header = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.theme.colors.GRAY100};
`

export const Row = styled.div`
    margin-bottom: 8px;

    > div > span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: ${props => props.theme.colors.GRAY400}
    }
`

export const RowLabel = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    color: ${props => props.theme.colors.GRAY100};
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
`