import React from 'react';
import { Loader } from 'rsuite';
import { Content, Container } from './styled';

export default function Loading(props) {
  return (
    <Container>
      <Content>
        <Loader size={props.size} />
      </Content>
    </Container>
  );
}
