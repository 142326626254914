import { useState } from 'react';
import { Modal, Button, FormGroup } from 'rsuite';
import * as S from './styles';
import { useExtrato } from '../../hooks/extrato';
import Skeleton from 'react-loading-skeleton';
import formatReal from '../../utils/formatReal';
import globalStyle from '../../styles/globalStyle';

export function ConfirmaEditExtratoModal({
  isOpenEditConfirm,
  onRequestCloseEditConfirm,
  uuid
}) {
  const { isLoadingVerEdit, VerEditExtratoPorUUID, vereditExtrato } =
    useExtrato();

  const [valor, setValor] = useState(
    VerEditExtratoPorUUID[0]?.valor?.real || ''
  );
  const [descricao, setDescricao] = useState(
    VerEditExtratoPorUUID[0]?.descricao.toString() || ''
  );
  const [codigo, setCodigo] = useState(VerEditExtratoPorUUID[0]?.codigo || '');
  const [venda_tipo, setVendaTipo] = useState(
    VerEditExtratoPorUUID[0]?.venda_tipo || ''
  );
  const [ns1, setNS1] = useState(VerEditExtratoPorUUID[0]?.ns1 || '');
  const [ns2, setNS2] = useState(VerEditExtratoPorUUID[0]?.ns2 || '');
  const [ns3, setNS3] = useState(VerEditExtratoPorUUID[0]?.ns3 || '');
  const [quantidade, setQuantidade] = useState(
    VerEditExtratoPorUUID[0]?.quantidade || ''
  );
  const [tnox, setTnox] = useState(VerEditExtratoPorUUID[0]?.tinox || '');
  const [tfiltro, setTfiltro] = useState(
    VerEditExtratoPorUUID[0]?.tfiltro || ''
  );
  const [tflex, setTflex] = useState(VerEditExtratoPorUUID[0]?.tflex || '');
  const [status, setStatus] = useState(
    VerEditExtratoPorUUID[0]?.status.toString() || ''
  );
  const [v1, setV1] = useState(VerEditExtratoPorUUID[0]?.v1 || '');
  const [v2, setV2] = useState(VerEditExtratoPorUUID[0]?.v2 || '');
  const [v3, setV3] = useState(VerEditExtratoPorUUID[0]?.v3 || '');
  const [descricao1, setDescricao1] = useState(
    VerEditExtratoPorUUID[0]?.description1 || ''
  );
  const [descricao2, setDescricao2] = useState(
    VerEditExtratoPorUUID[0]?.description2 || ''
  );
  const [descricao3, setDescricao3] = useState(
    VerEditExtratoPorUUID[0]?.description3 || ''
  );
  const [qtd1, setQTD1] = useState(VerEditExtratoPorUUID[0]?.qtd1 || '');
  const [qtd2, setQTD2] = useState(VerEditExtratoPorUUID[0]?.qtd2 || '');
  const [qtd3, setQTD3] = useState(VerEditExtratoPorUUID[0]?.qtd3 || '');

  const handlUpdateExtrato = async (event) => {
    event.preventDefault();

    let data = {
      valor,
      tipo: VerEditExtratoPorUUID[0].tipo,
      ns1,
      ns2,
      ns3,
      descricao,
      codigo,
      quantidade,
      tnox,
      tfiltro,
      tflex,
      status,
      venda_tipo,
      v1,
      v2,
      v3,
      descricao1,
      descricao2,
      descricao3,
      qtd1,
      qtd2,
      qtd3
    };
    await vereditExtrato(
      data,
      onRequestCloseEditConfirm,
      VerEditExtratoPorUUID[0].uuid,
      uuid
    );
  };

  const descricoesPositivo = {
    1: 'Transferência',
    4: 'Venda'
  };

  function optionsDescricaoPositivo() {
    var es = Object.entries(descricoesPositivo);
    return es.map((item) => (
      <option key={`descricao${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const descricoesNegativo = {
    2: 'Pesáveis',
    3: 'Catalisadores',
    5: 'Crédito'
  };

  function optionsDescricaoNegativo() {
    var es = Object.entries(descricoesNegativo);
    return es.map((item) => (
      <option key={`descricaoNegativo${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const vendaTipo = {
    1: 'SELECIONADO',
    2: 'MANTA',
    3: 'FILTRO',
    4: 'CAÇAMBA',
    5: 'CAMINHÃO',
    6: 'TIPO AMAROK',
    7: 'TIPO DUCATO',
    8: 'ANEL DE INOX',
    9: 'SONDA LAMBDA',
    10: 'B2'
  };

  function optionsVendaTipo() {
    var es = Object.entries(vendaTipo);
    return es.map((item) => (
      <option key={`venda_tipo${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const ostatus = {
    1: 'Feito',
    2: 'Análise',
    3: 'Estorno'
  };

  function optionsStatus() {
    var es = Object.entries(ostatus);
    return es.map((item) => (
      <option key={`status${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }
  // Limpa modal ao fechar
  function handleLimpa() {
    setValor('');
    setDescricao('');
    setCodigo('');
    setVendaTipo('');
    setNS1('');
    setNS2('');
    setNS3('');
    setQuantidade('');
    setTnox('');
    setTfiltro('');
    setTflex('');
    setStatus('');
    setV1('');
    setV2('');
    setV3('');
    setDescricao1('');
    setDescricao2('');
    setDescricao3('');
    setQTD1('');
    setQTD2('');
    setQTD3('');
  }

  return (
    <Modal
      backdrop={'static'}
      show={isOpenEditConfirm}
      onExit={handleLimpa}
      onHide={onRequestCloseEditConfirm}
    >
      {isLoadingVerEdit ? (
        <Skeleton count={4} />
      ) : (
        <>
          <Modal.Header>
            <Modal.Title style={{ color: globalStyle.colors.GRAY100 }}>
              Editar Extrato
            </Modal.Title>
          </Modal.Header>
          <S.Container onSubmit={handlUpdateExtrato}>
            <Modal.Body>
              <S.ContainerCardForm>
                <div className="card-form" style={{ marginBottom: '2rem' }}>
                  {VerEditExtratoPorUUID[0]?.tipo == 1 ? (
                    <FormGroup>
                      <select
                        placeholder="Transação"
                        onChange={(e) => {
                          setDescricao(e.target.value);
                        }}
                        value={descricao}
                      >
                        <option key="" value="">
                          Tipo de transação
                        </option>
                        {optionsDescricaoPositivo()}
                      </select>
                    </FormGroup>
                  ) : VerEditExtratoPorUUID[0]?.tipo == 2 ? (
                    <FormGroup>
                      <label> Tipo de transação</label>
                      <select
                        placeholder="Transação"
                        onChange={(e) => {
                          setDescricao(e.target.value);
                        }}
                        value={descricao}
                      >
                        <option key="" value="">
                          Tipo de transação
                        </option>
                        {optionsDescricaoNegativo()}
                      </select>
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    <label>Status</label>
                    <select
                      placeholder="Status"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      value={status}
                    >
                      <option key="" value="">
                        Selecione o status
                      </option>
                      {optionsStatus()}
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <label>Código</label>
                    <input
                      type="text"
                      placeholder="Código"
                      value={codigo}
                      onChange={(event) => setCodigo(event.target.value)}
                    />
                  </FormGroup>
                  {descricao != '' ? (
                    <>
                      {descricao == '4' ? (
                        <FormGroup className="valor">
                          <label>Tipo da venda</label>
                          <select
                            placeholder="Tipo da venda"
                            onChange={(e) => {
                              setVendaTipo(e.target.value);
                            }}
                            value={venda_tipo}
                          >
                            <option key="" value="">
                              Selecione
                            </option>
                            {optionsVendaTipo()}
                          </select>
                        </FormGroup>
                      ) : null}
                      {descricao == '1' ||
                      descricao == '3' ||
                      descricao == '4' ||
                      (descricao == '2' && status != '2') ? (
                        <FormGroup className="valor">
                          <label>Valor</label>
                          <input
                            type="text"
                            placeholder="Valor"
                            value={valor}
                            onChange={(event) =>
                              setValor(formatReal(event.target.value))
                            }
                          />
                        </FormGroup>
                      ) : null}
                      {descricao == '5' || descricao == '6' ? (
                        <>
                          <FormGroup className="valor">
                            <label>Descrição 1</label>
                            <input
                              type="text"
                              placeholder="Descrição 1"
                              value={descricao1}
                              onChange={(event) =>
                                setDescricao1(event.target.value)
                              }
                            />
                          </FormGroup>
                          <FormGroup className="valor">
                            <label>Descrição 2</label>
                            <input
                              type="text"
                              placeholder="Descrição 2"
                              value={descricao2}
                              onChange={(event) =>
                                setDescricao2(event.target.value)
                              }
                            />
                          </FormGroup>
                          <FormGroup className="valor">
                            <label>Descrição 3</label>
                            <input
                              type="text"
                              placeholder="Descrição 3"
                              value={descricao3}
                              onChange={(event) =>
                                setDescricao3(event.target.value)
                              }
                            />
                          </FormGroup>
                          {descricao == '5' ? (
                            <>
                              <FormGroup className="valor">
                                <label>V1</label>
                                <input
                                  type="text"
                                  placeholder="V1"
                                  value={v1}
                                  onChange={(event) =>
                                    setV1(formatReal(event.target.value))
                                  }
                                />
                              </FormGroup>
                              <FormGroup className="valor">
                                <label>V2</label>
                                <input
                                  type="text"
                                  placeholder="V2"
                                  value={v2}
                                  onChange={(event) =>
                                    setV2(formatReal(event.target.value))
                                  }
                                />
                              </FormGroup>
                              <FormGroup className="valor">
                                <label>V3</label>
                                <input
                                  type="text"
                                  placeholder="V3"
                                  value={v3}
                                  onChange={(event) =>
                                    setV3(formatReal(event.target.value))
                                  }
                                />
                              </FormGroup>
                            </>
                          ) : null}
                          <FormGroup className="valor">
                            <label>Quantidade 1</label>
                            <input
                              type="text"
                              placeholder="Quantidade 1"
                              value={qtd1}
                              onChange={(event) =>
                                setQTD1(formatReal(event.target.value))
                              }
                            />
                          </FormGroup>
                          <FormGroup className="valor">
                            <label>Quantidade 2</label>
                            <input
                              type="text"
                              placeholder="Quantidade 2"
                              value={qtd2}
                              onChange={(event) =>
                                setQTD2(formatReal(event.target.value))
                              }
                            />
                          </FormGroup>
                          <FormGroup className="valor">
                            <label>Quantidade 3</label>
                            <input
                              type="text"
                              placeholder="Quantidade 3"
                              value={qtd3}
                              onChange={(event) =>
                                setQTD3(formatReal(event.target.value))
                              }
                            />
                          </FormGroup>
                        </>
                      ) : null}
                      {descricao == '4' || descricao == '2' ? (
                        <FormGroup>
                          <label>NS1</label>
                          <input
                            type="text"
                            placeholder="Titulo"
                            value={ns1}
                            onChange={(event) =>
                              setNS1(formatReal(event.target.value))
                            }
                          />
                        </FormGroup>
                      ) : null}
                      {descricao == '2' ? (
                        <>
                          <FormGroup>
                            <label>NS2</label>
                            <input
                              type="text"
                              placeholder="Titulo"
                              value={ns2}
                              onChange={(event) =>
                                setNS2(formatReal(event.target.value))
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>NS3</label>
                            <input
                              type="text"
                              placeholder="Titulo"
                              value={ns3}
                              onChange={(event) =>
                                setNS3(formatReal(event.target.value))
                              }
                            />
                          </FormGroup>
                        </>
                      ) : null}
                      {descricao == '4' ||
                      descricao == '2' ||
                      descricao == '3' ? (
                        <FormGroup>
                          <label>Quantidade Bruta</label>
                          <input
                            type="text"
                            placeholder="Titulo"
                            value={quantidade}
                            onChange={(event) =>
                              setQuantidade(formatReal(event.target.value))
                            }
                          />
                        </FormGroup>
                      ) : null}
                      {descricao == '2' && status != '2' ? (
                        <>
                          <FormGroup>
                            <label>T. Inox / KG</label>
                            <input
                              type="text"
                              placeholder="T. Inox"
                              value={tnox}
                              onChange={(event) =>
                                setTnox(formatReal(event.target.value))
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>T. Filtro / KG</label>
                            <input
                              type="text"
                              placeholder="T. Filtro"
                              value={tfiltro}
                              onChange={(event) =>
                                setTfiltro(formatReal(event.target.value))
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>T. Flex / KG</label>
                            <input
                              type="text"
                              placeholder="T. Flex"
                              value={tflex}
                              onChange={(event) =>
                                setTflex(formatReal(event.target.value))
                              }
                            />
                          </FormGroup>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </S.ContainerCardForm>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" color="green" style={{ marginTop: '20px' }}>
                Atualizar
              </Button>
            </Modal.Footer>
          </S.Container>
        </>
      )}
    </Modal>
  );
}
