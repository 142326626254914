import { Button, Icon, Table, Tooltip, Whisper } from 'rsuite';
import * as S from './styles';
import { Loader } from 'rsuite';
import Skeleton from 'react-loading-skeleton';
import { useParceiro } from '../../hooks/parceiro';
import { usePerfil } from '../../hooks/perfil';
import includes from '../../utils/includes';
import { Navigate, useNavigate } from 'react-router-dom';
import HistoricoPorGeolocalizacao from '../VisualizarHistoricoGeolocalizacao';
import { useState } from 'react';
import TableComponent from '../UI/TableComponent';

export function ParceiroTable() {
  const app = 'parceiros_lista';
  const [isOpen, setIsOpen] = useState(false);
  const [usuarioDados, setUsuarioDados] = useState({});
  const {
    getParceiroPorUuid,
    parceiroLista,
    isLoading,
    pagination,
    orderParceiro,
    VisualizarHistoricoDeLocalizacaoPorUUID,
    parceiroPage,
    downloadRelatorioHistoricoLocalizacao
  } = useParceiro();
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();
  const navigate = useNavigate();

  function handleCloseHistoricoLocalizacaoModal() {
    setIsOpen(false);
    setUsuarioDados({});
  }

  function handleOpenHistoricoLocalizacaoModal() {
    setIsOpen(true);
  }

  function handleDowloadHIstoryLocation(uuid) {
    downloadRelatorioHistoricoLocalizacao(uuid)
  }

  const handleClickOpenEdit = async (uuid) => {
    await getParceiroPorUuid(uuid);
  };

  const handleOrderingColumn = (event, column) => {
    const columns = document.getElementsByTagName('th');

    for (let column of columns) {
      column.classList.add('columnDefault');
    }

    let el = event.target;
    el.classList.remove('columnDefault');
    el.classList.add('columnActive');

    if (el.lastChild.classList.contains('rs-icon-arrow-up')) {
      el.lastChild.classList.remove('rs-icon-arrow-up');
      el.lastChild.classList.add('rs-icon-arrow-down');
      orderParceiro(column, 'DESC', pagination[0].current_page);
    } else {
      el.lastChild.classList.remove('rs-icon-arrow-down');
      el.lastChild.classList.add('rs-icon-arrow-up');
      orderParceiro(column, 'ASC', pagination[0].current_page);
    }
  };

  const handleSelect = async (eventKey) => {
    await parceiroPage(eventKey);
  };

  const { Pagination } = Table;

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    !includes(permissaoPerfil, 'per_parceiro_visualizar')
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }

  async function toggleViewUser(usr_id, _, usr_name) {
    const hasLocationHistory = await VisualizarHistoricoDeLocalizacaoPorUUID(
      usr_id
    );

    if (hasLocationHistory) {
      navigate(`/parceiro/historico-localizacao/${usr_id}`, {
        state: { usr_id, usr_name }
      });
    }
  }


  console.log('parceiro pagination', pagination)
  return (
    <div>
      {loadingPer ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : (
        <S.Container>
          <S.HeaderTable>
            <div>
              <p>
                Total: {pagination[0]?.total}
              </p>
            </div>
          </S.HeaderTable>
          <TableComponent
            page={app}
            pagination={pagination}
            handleOrderingColumn={handleOrderingColumn}
            handleClickOpenEdit={toggleViewUser}
            handleDowloadHIstoryLocation={handleDowloadHIstoryLocation}
            uppercase="none"
            handleAction={setUsuarioDados}
            lista={parceiroLista}
            isLoading={isLoading}
            // toggleViewUser={toggleViewUser}
            columns={[
              { header: 'Nome', body: 'nome' },
              { header: 'Status', body: 'status' },
              { header: '', body: 'action' }
            ]}
            // sempre enviar valores no mesmo index da prop "columns", componente pega pelo index
            orderingByColumnsIndex={['name', 'status', '']}
          />
          <HistoricoPorGeolocalizacao
            isOpen={isOpen}
            onRequestClose={handleCloseHistoricoLocalizacaoModal}
            data={usuarioDados}
            onRequestOpen={handleOpenHistoricoLocalizacaoModal}
          />
          <S.ContainerPagination>
            {pagination.length ? (
              <Pagination
                prev
                last
                next
                first
                size="lg"
                pages={pagination[0]?.last_page}
                activePage={pagination[0]?.current_page}
                onSelect={handleSelect}
              />
            ) : null}
          </S.ContainerPagination>
        </S.Container>
      )}
    </div>
  );
}
