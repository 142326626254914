import { useState } from 'react';
import {
  Modal,
  Button,
  FormGroup,
  Icon,
  InputGroup,
  Whisper,
  Tooltip
} from 'rsuite';
import * as S from './styles';
import { useCatalogo } from '../../hooks/catalogo';
import globalStyle from '../../styles/globalStyle';
import Input from '../UI/Input';

export function NewCatalogoModal({ isOpen, onRequestClose }) {
  const { createCatalogo, isLoadingCreate } = useCatalogo();

  const [tipo, setTipo] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [codigo1, setCodigo1] = useState('');
  const [codigo2, setCodigo2] = useState('');
  const [codigo3, setCodigo3] = useState('');
  const [definition, setDefinition] = useState('');
  const [targeting, setTargeting] = useState('');
  const [foto, setFoto] = useState('');

  const [foto_upload, setFotoUpload] = useState('');
  const [status, setStatus] = useState(1);

  async function handlCreateCatalogo(e) {
    e.preventDefault();

    let data = {
      tipo,
      marca,
      modelo,
      codigo1,
      codigo2,
      codigo3,
      definition,
      targeting,
      foto: foto_upload,
      status: status
    };

    let result = await createCatalogo(data, onRequestClose);

    if (result.erro == false) {
      setTipo('');
      setMarca('');
      setModelo('');
      setCodigo1('');
      setCodigo2('');
      setCodigo3('');
      setDefinition('');
      setTargeting('');
      setFoto('');
      setFotoUpload('');
      setStatus('');
      result = {};
    }
  }
  // Limpa modal ao fechar
  function handleLimpa() {
    setTipo('');
    setMarca('');
    setModelo('');
    setCodigo1('');
    setCodigo2('');
    setCodigo3('');
    setDefinition('');
    setTargeting('');
    setFoto('');
    setFotoUpload('');
    setStatus('');
  }
  // Limpa modal ao selecionar tipo
  function handleLimpaTipo(valorTipo) {
    setTipo(valorTipo);
    setMarca('');
    setModelo('');
    setCodigo1('');
    setCodigo2('');
    setCodigo3('');
    setDefinition('');
    setTargeting('');
    setFoto('');
    setFotoUpload('');
    setStatus('');
  }

  const onImageChange = (event) => {
    const { files } = event.target;
    setFoto(URL.createObjectURL(files[0]));
    setFotoUpload(files[0]);
  };

  const handlClearFoto = async () => {
    setFoto('');
    setFotoUpload('');
  };

  const status_lista = {
    1: 'Ativo',
    2: 'Inativo',
    3: 'Pendente para análise'
  };

  function optionsStatus() {
    var es = Object.entries(status_lista);
    return es.map((item) => (
      <option key={`status${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const tipo_lista = {
    1: 'Catalisador',
    2: 'Pó comum',
    3: 'Inox',
    4: 'Filtro'
  };

  function optionsTipoLista() {
    var es = Object.entries(tipo_lista);
    return es.map((item) => (
      <option key={`tipo_lista${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const typeDefinition = {
    flex: 'Catalisador Flex',
    inox: 'Inox',
    dpf: 'DPF'
  };

  const typeTargeting = {
    'corte especial': 'Corte Especial',
    g51: 'G51'
  };

  function optionsDefinition() {
    var es = Object.entries(typeDefinition);
    return es.map((item) => (
      <option key={`${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  function optionsTargeting() {
    var es = Object.entries(typeTargeting);
    return es.map((item) => (
      <option key={`${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  return (
    <Modal
      backdrop={'static'}
      show={isOpen}
      onHide={onRequestClose}
      onExit={handleLimpa}
    >
      <Modal.Header>
        <Modal.Title>
          <div style={{ color: globalStyle.colors.GRAY100 }}>
            Cadastrar Produto
          </div>
        </Modal.Title>
      </Modal.Header>
      <S.Container onSubmit={handlCreateCatalogo}>
        <Modal.Body>
          <S.ContainerCardForm>
            <div className="card-form" style={{ marginBottom: '1rem' }}>
              <FormGroup>
                <S.FotoContainer>
                  {foto ? (
                    <>
                      <InputGroup className="close_search" label="Excluir foto">
                        <InputGroup.Button onClick={() => handlClearFoto()}>
                          <Icon icon="close" />
                        </InputGroup.Button>
                      </InputGroup>
                      <img
                        src={foto}
                        alt="imagem"
                        width="300"
                        height="300"
                        onChange={onImageChange}
                      />
                    </>
                  ) : (
                    <>
                      <Icon icon="file-image-o" size="4x" />
                      <Input
                        type="file"
                        required
                        id="upload-button"
                        onChange={onImageChange}
                      />
                      <label>Clique aqui para inserir uma imagem</label>
                    </>
                  )}
                </S.FotoContainer>
              </FormGroup>
            </div>
            <div className="card-form">
              <FormGroup>
                <label>Tipo:</label>
                <select
                  placeholder="Tipo"
                  required
                  onChange={(e) => {
                    handleLimpaTipo(e.target.value);
                  }}
                  value={tipo}
                >
                  <option key="" value="">
                    Selecione o tipo
                  </option>
                  {optionsTipoLista()}
                </select>
              </FormGroup>
              <FormGroup>
                <label>Marca</label>
                <Input
                  type="text"
                  placeholder="Marca"
                  required
                  value={marca}
                  required
                  onChange={(event) => setMarca(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>Modelo</label>
                <Input
                  type="text"
                  placeholder="Modelo"
                  required
                  value={modelo}
                  required
                  onChange={(event) => setModelo(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>Código 1</label>
                <Input
                  type="text"
                  placeholder="Código 1"
                  value={codigo1}
                  onChange={(event) => setCodigo1(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>Código 2</label>
                <Input
                  type="text"
                  placeholder="Código 2"
                  value={codigo2}
                  onChange={(event) => setCodigo2(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>Código 3</label>
                <Input
                  type="text"
                  placeholder="Código 3"
                  value={codigo3}
                  onChange={(event) => setCodigo3(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>Definição</label>
                <select
                  placeholder="Definição"
                  onChange={(e) => {
                    setDefinition(e.target.value);
                  }}
                  value={definition}
                >
                  <option key="" value="">
                    Selecione a definição
                  </option>
                  {optionsDefinition()}
                </select>
              </FormGroup>
              <FormGroup>
                <label>Classificação</label>
                <select
                  placeholder="Classificação"
                  onChange={(e) => {
                    setTargeting(e.target.value);
                  }}
                  value={targeting}
                >
                  <option key="" value="">
                    Selecione a classificação
                  </option>
                  {optionsTargeting()}
                </select>
              </FormGroup>
            </div>

            <div className="card-form">
              <FormGroup>
                <h2>Permissões</h2>
                <label>Status:</label>
                <select
                  placeholder="Tipo"
                  required
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  value={status}
                >
                  <option key="" value="">
                    Selecione o tipo
                  </option>
                  {optionsStatus()}
                </select>
              </FormGroup>
            </div>
          </S.ContainerCardForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            appearance="primary"
            style={{ marginTop: '20px' }}
          >
            {isLoadingCreate ? 'Carregando...' : 'Cadastrar'}
          </Button>
        </Modal.Footer>
      </S.Container>
    </Modal>
  );
}
