import styled from 'styled-components'

export const Container = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .rs-modal-body{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }

    h2 {
        width: 100%;
        color: var(--red);
        font-size: 1.7rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #d7d7d7;
    }

    input[type=text],
    input[type=email] ,
    input[type=number],
    select  {
        width: 100%;
        padding: 0 1.5rem;
        height: 4rem;
        border-radius: 0.25rem;
        border: 1px solid #d7d7d7;
        background: #e7e9ee;

        font-weight: 400;
        font-size: 1rem;

        &::placeholder{
            color: var(--text-body);
        }
        //todo input que tiver um input acima vai receber margin top
        & + input {
            margin-top: 0.25rem;
        }

    }

    button[type=submit], button{
        width: auto;
        padding: 0 1.5rem;
        height: 2rem;
        color: #FFFFFF;
        border-radius: 0.25rem;
        border: 0;
        font-size: 1rem;
        margin-top: 1.8rem;
        font-weight: 600;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.9);
        }
    }
    button{
        color: #666;
    }
`


export const ContainerCardForm = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    @media (max-width: 768px) {
        flex-direction: column;
    }

    .card-form {
        width: 100%;
        display: grid;
        flex: 1;
        gap: 2rem;
        margin: 0px 1rem;
        padding: 0px 20px;
        @media (max-width: 768px) {
            width: 100%;
            margin: 0px;
            h3{
                margin-top: 40px;
            }
        }
        .rs-form-group{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            p {
                font-size: 1.2em;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }

`

export const StatusFilialContainer = styled.div`
    margin: 1rem 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap : 0.5rem;

`
