import React from 'react';
import { IconButton, Tooltip, Whisper, Nav, Icon } from 'rsuite';
import { Image } from '@chakra-ui/core';
import logo from '../../assets/logo_nova.png';
import { Link } from 'react-router-dom';
import { usePerfil } from '../../hooks/perfil';
import globalStyle from '../../styles/globalStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faHome,
  faHouse,
  faFileLines,
  faBookOpen,
  faGear,
  faMoneyBill,
  faUserGroup,
  faArrowRightFromBracket,
  faMicrochip
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { logout } from '../../services/auth';
import { SidebarBody, SidebarContainer, SidebarHeader } from './styled';

import CatalogIcon from '../../assets/Icons/CatalogIcon';
import DashboardIcon from '../../assets/Icons/DashboardIcon';
import LotesIcon from '../../assets/Icons/LotesIcon';
import ConfigIcon from '../../assets/Icons/ConfigIcon';
import ExtratoIcon from '../../assets/Icons/ExtratoIcon';
import ParceirosIcon from '../../assets/Icons/ParceirosIcon';
import UserIcon from '../../assets/Icons/UserIcon';
import IAIcon from '../../assets/Icons/IAIcon';
import SairIcon from '../../assets/Icons/SairIcon';

function includes() {
  var args = Array.prototype.slice.call(arguments);
  var target = args[0];
  var strs = args.slice(1); // remove first element

  for (var i = 0; i < strs.length; i++) {
    if (target?.includes(strs[i])) {
      return true;
    }
  }

  return false;
}

const Sidebar = ({ activePage }) => {
  const { loadingPer, tipoPerfil, permissaoPerfil } = usePerfil();

  return (
    <SidebarContainer>
      <SidebarBody>
        <SidebarHeader>
          <Image src={logo} height="50.12px" alt="Precious Group" width="150px" />
        </SidebarHeader>
        <Nav className={'nav_sidebar'}>
          {
            tipoPerfil == 1 ? (
              <Nav.Item
                to="/"
                key={1}
                className={
                  activePage == '1'
                    ? 'menu_ativo menu_item_container'
                    : 'menu_item_container'
                }
                style={{
                  display: 'flex',
                  color: globalStyle.colors.GRAY100
                }}
                componentClass={Link}
                eventKey="1"

              >
                <DashboardIcon largura={24} altura={25}></DashboardIcon>
                <Text>
                  Dashboard
                </Text>
              </Nav.Item>
            ) : null
          }


          {(permissaoPerfil != null &&
            includes(
              permissaoPerfil,
              'per_catalogo_visualizar',
              'per_catalogo_adicionar',
              'per_catalogo_editar'
            )) ||
            tipoPerfil == 1 ? (
            <Nav.Item
              to="/catalogo"
              key={3}
              className={
                activePage == '3'
                  ? 'menu_ativo menu_item_container'
                  : 'menu_item_container'
              }
              componentClass={Link}
              eventKey="3"
              style={{ flexDirection: 'row' }}
            >
              {/* <FontAwesomeIcon
                icon={faBookOpen}
                color={globalStyle.colors.GRAY50}
                size="1x"
                width={40}
                height={24}
              ></FontAwesomeIcon> */}
              <CatalogIcon largura={24} altura={25}></CatalogIcon>
              <Text>

                Catálogo
              </Text>

            </Nav.Item>
          ) : null}

          {(permissaoPerfil != null &&
            includes(permissaoPerfil, 'per_compra_visualizar')) ||
            tipoPerfil == 1 ? (
            <Nav.Item
              to="/compra"
              key={7}
              className={
                activePage == '7'
                  ? 'menu_ativo menu_item_container'
                  : 'menu_item_container'
              }
              componentClass={Link}
              eventKey="7"
            >
              <LotesIcon largura={24} altura={25}></LotesIcon>
              <Text>

                Lotes
              </Text>
            </Nav.Item>
          ) : null}

          {(permissaoPerfil != null &&
            permissaoPerfil.includes('per_configuracao_visualizar')) ||
            tipoPerfil == 1 ? (
            <Nav.Item
              to="/configuracao"
              key={5}
              className={
                activePage == '5'
                  ? 'menu_ativo menu_item_container'
                  : 'menu_item_container'
              }
              componentClass={Link}
              eventKey="5"
            >
              <ConfigIcon largura={24} altura={25}></ConfigIcon>
              <Text>
                Configuração
              </Text>

            </Nav.Item>
          ) : null}

          {(permissaoPerfil != null &&
            includes(permissaoPerfil, 'per_extrato_visualizar')) ||
            tipoPerfil == 1 ? (
            <Nav.Item
              to="/extrato"
              key={6}
              className={
                activePage == '6'
                  ? 'menu_ativo menu_item_container'
                  : 'menu_item_container'
              }
              componentClass={Link}
              eventKey="6"
            >
              <ExtratoIcon largura={24} altura={25}></ExtratoIcon>
              <Text>

                Extrato
              </Text>

            </Nav.Item>
          ) : null}

          {(permissaoPerfil != null &&
            permissaoPerfil.includes('per_parceiro_visualizar')) ||
            tipoPerfil == 1 ? (
            <Nav>
              <Nav.Item
                to="/parceiros-lista"
                className={
                  activePage == '4'
                    ? 'menu_ativo menu_item_container'
                    : 'menu_item_container'
                }
                key={4}
                componentClass={Link}
                eventKey="4"
              >
                <ParceirosIcon largura={24} altura={25}></ParceirosIcon>
                <Text>

                  Parceiros
                </Text>

              </Nav.Item>
            </Nav>
          ) : null}

          {(permissaoPerfil != null &&
            includes(
              permissaoPerfil,
              'per_usuario_visualizar',
              'per_usuario_adicionar',
              'per_usuario_editar'
            )) ||
            tipoPerfil == 1 ? (
            <Nav.Item
              to="/usuario"
              key={2}
              className={
                activePage == '2'
                  ? 'menu_ativo menu_item_container'
                  : 'menu_item_container'
              }
              componentClass={Link}
              eventKey="2"
            >
              <UserIcon largura={24} altura={25}></UserIcon>
              <Text>

                Usuários
              </Text>
            </Nav.Item>
          ) : null}

          {
            <Nav.Item
              to="/perfil"
              key={2}
              className={
                activePage == '2'
                  ? 'menu_ativo menu_item_container'
                  : 'menu_item_container'
              }
              componentClass={Link}
              eventKey="2"
            >
              <UserIcon largura={24} altura={25}></UserIcon>
              <Text>

                Minha Conta
              </Text>
            </Nav.Item>
          }

          {(permissaoPerfil != null &&
            includes(
              permissaoPerfil,
              'per_ia_adicionar',
            )) ||
            tipoPerfil == 1 ? (
            <Nav.Item
              to="/IA"
              key={2}
              className={
                activePage == '2'
                  ? 'menu_ativo menu_item_container'
                  : 'menu_item_container'
              }
              componentClass={Link}
              eventKey="2"
            >
              <IAIcon largura={24} altura={25}></IAIcon>
              <Text>

                IA
              </Text>
            </Nav.Item>
          ) : null}

          <Nav.Item
            // to="/usuario"
            key={2}
            className={
              activePage == '2'
                ? 'menu_ativo menu_item_container'
                : 'menu_item_container'
            }
            // componentClass={Link}
            onClick={() => logout()}
            eventKey="2"
            style={{
              marginLeft: 4
            }}
          >
            <SairIcon></SairIcon>
            <Text>

              Sair
            </Text>

          </Nav.Item>
        </Nav>
      </SidebarBody>
    </SidebarContainer>
  );
};

const Text = ({ color = globalStyle.colors.GRAY900, children }) => <span style={{ color }}>{children}</span>
export default Sidebar;
