import React from "react";
import { Container, DivRadio, Radio, Text } from './styled'

// Componente Global da pagina Config
import { Flex, Label, Title } from "../styles";

import { useConfig } from "../../../hooks/configuracao";
import globalStyle from "../../../styles/globalStyle";
import NumberFormat from "react-number-format";
import formataCampoPorcentagem from "../../../utils/formataCampoPorcentagem";
import formatReal from "../../../utils/formatReal";
import Input from "../../../components/UI/Input";

export default function Cotacoes() {
    const { setAtualizaCotacao, atualizaCotacao, cotacoes, setCotacoes } = useConfig();

    const handleDataInicial = (data) => {
        const hoje = new Date();

        if (new Date(data) <= hoje) {
            setCotacoes({ ...cotacoes, validadeInicio: data });
        }
    }

    return (
        <Container>
            <Title>Cotações</Title>
            <DivRadio>
                <Radio
                    name="classificacao"
                    type="radio"
                    checked={atualizaCotacao === 'diario'}
                    onChange={() =>
                        setAtualizaCotacao('diario')
                    }
                />
                {' '}
                <Text fontSize="18px">Diário</Text>
                <Radio
                    name="classificacao"
                    type="radio"
                    checked={atualizaCotacao === 'semanal'}
                    onChange={() =>
                        setAtualizaCotacao('semanal')
                    }
                />{' '}
                <Text fontSize="18px">Semanal</Text>
                <Radio
                    name="classificacao"
                    type="radio"
                    checked={atualizaCotacao === 'hora'}
                    onChange={() =>
                        setAtualizaCotacao('hora')
                    }
                />{' '}
                <Text fontSize="18px">3 vezes ao dia</Text>
                <Radio
                    name="classificacao"
                    type="radio"
                    checked={atualizaCotacao === 'inativo'}
                    onChange={() =>
                        setAtualizaCotacao('inativo')
                    }
                />{' '}
                <Text fontSize="18px">Inativo</Text>
            </DivRadio>
            <Flex
                style={{
                    marginTop: 32
                }}
            >
                <div>
                    <Label
                        style={{ marginBottom: 8 }}
                    >
                        Platinum
                    </Label>
                    <NumberFormat
                        type="text"
                        prefix="$ "
                        disabled={atualizaCotacao !== 'inativo'}
                        decimalSeparator=","
                        thousandSeparator="."
                        value={cotacoes.ptCotacao || '   '}
                        onChange={(e) => atualizaCotacao === 'inativo' && setCotacoes({ ...cotacoes, ptCotacao: formatReal(e.target.value) })}
                        style={{
                            width: 99,
                            height: 34,
                            borderRadius: 4,
                            padding: '0 16px',
                            marginRight: 50,
                            background: 'transparent',
                            border: `solid 1px ${globalStyle.colors.DARK400}`,
                            color: globalStyle.colors.GRAY400
                        }}
                    />
                </div>
                <div>
                    <Label
                        style={{ marginBottom: 8 }}
                    >
                        Palladium
                    </Label>
                    <NumberFormat
                        type="text"
                        prefix="$ "
                        decimalSeparator=","
                        thousandSeparator="."
                        disabled={atualizaCotacao !== 'inativo'}
                        value={cotacoes.pdCotacao || '   '}
                        onChange={(e) => atualizaCotacao === 'inativo' && setCotacoes({ ...cotacoes, pdCotacao: formatReal(e.target.value) })}
                        style={{
                            width: 99,
                            height: 34,
                            borderRadius: 4,
                            padding: '0 16px',
                            marginRight: 50,
                            background: 'transparent',
                            border: `solid 1px ${globalStyle.colors.DARK400}`,
                            color: globalStyle.colors.GRAY400
                        }}
                    />
                </div>
                <div>
                    <Label
                        style={{ marginBottom: 8 }}
                    >
                        Rhodium
                    </Label>
                    <NumberFormat
                        type="text"
                        prefix="$ "
                        decimalSeparator=","
                        disabled={atualizaCotacao !== 'inativo'}
                        thousandSeparator="."
                        value={cotacoes.rhCotacao || '   '}
                        onChange={(e) => atualizaCotacao === 'inativo' && setCotacoes({ ...cotacoes, rhCotacao: formatReal(e.target.value) })}
                        style={{
                            width: 99,
                            height: 34,
                            borderRadius: 4,
                            padding: '0 16px',
                            marginRight: 50,
                            background: 'transparent',
                            border: `solid 1px ${globalStyle.colors.DARK400}`,
                            color: globalStyle.colors.GRAY400
                        }}
                    />
                </div>
                <div>
                    <Label
                        style={{ marginBottom: 8 }}
                    >
                        Dolar
                    </Label>
                    <NumberFormat
                        type="text"
                        prefix="$ "
                        decimalSeparator=","
                        disabled={atualizaCotacao !== 'inativo'}
                        thousandSeparator="."
                        value={cotacoes.dolarCotacao || '   '}
                        onChange={(e) => {
                            atualizaCotacao === 'inativo' && setCotacoes({ ...cotacoes, dolarCotacao: formatReal(e.target.value) })
                        }}
                        style={{
                            width: 99,
                            height: 34,
                            borderRadius: 4,
                            padding: '0 16px',
                            marginRight: 50,
                            background: 'transparent',
                            border: `solid 1px ${globalStyle.colors.DARK400}`,
                            color: globalStyle.colors.GRAY400
                        }}
                    />
                </div>
            </Flex>
            <Flex
                style={{
                    marginTop: 8,
                    flexDirection: "column",
                    marginBottom: 32,
                }}
            >
                <Title style={{ marginTop: 12 }}>Validade das cotações</Title>
                <Flex
                    style={{
                        marginTop: 8,
                    }}
                >
                    <div>
                        <Label>Data de inicio</Label>
                        <Input
                            placeholder="Data inicial"
                            onChange={
                                (e) => atualizaCotacao === 'inativo' && handleDataInicial(e.target.value)
                            }
                            value={cotacoes.validadeInicio || new Date()}
                            type="date"
                            style={{
                                marginRight: "50px",
                                width: 175
                            }}
                        />
                    </div>
                    <div>
                        <Label>Data fim</Label>
                        <Input
                            value={cotacoes.validadeFim || '   '}
                            onChange={
                                (e) => atualizaCotacao === 'inativo' && setCotacoes({ ...cotacoes, validadeFim: e.target.value })
                            }
                            style={{
                                width: 175
                            }}
                            type="date"
                            placeholder="Data final"
                        />
                    </div>
                </Flex>
            </Flex>
        </Container>
    )
}