import styled from 'styled-components';

export const TopbarStyle = styled.div`
  background-color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
  height: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 62px;
  padding-left: 50px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  /* width: 100%; */
  color: ${(props) => props.theme.colors.GRAY900};
  font-family: ${(props) => props.theme.fontFamily}, sans-serif;

  h2 {
    font-size: 30px;
    font-family: 'Lato';
  }

  h2 svg {
    margin-right: 15px;
  }

  section {
    display: flex;
    align-items: flex-end;
    width: 85%;
    min-width: 550px;

    .titleLeft{
      width: 100%;
      display: flex;
      align-items: flex-end;

      @media only screen and (max-width: 1340px) {
      h2{
        font-size: 25px;
      }
      }
    }
  }

  .codigoLote{
    
  }

  .userName {
    margin-left: 20px;
  }
  
  .novoProduto {
    margin-left: 20px;
  }

  .producName {
    margin-left: 20px;
    text-transform: uppercase;
  }
`;

export const VoltarPagina = styled.a`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.GRAY100};
  &:hover {
    color: #fff;
    text-decoration: none;
  }
  .voltar_page {
    margin-right: 20px;
    font-size: 22px;
    cursor: pointer;
  }
`;

export const ButtonFornecedor = styled.a`
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.GRAY900} !important;
  }
`;

export const End = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  h3{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #F5F5F5;
    float: left;
    margin-right: 15px;
    padding-top: 6px;
  }
  h2{
    float: left;
  }

  div > h3, .valor_total {
    color: ${(props) => props.theme.colors.GRAY900};
  }

  .valor_total {
    font-size: 30px;
    font-family: 'Lato';
  }

  @media only screen and (max-width: 1340px) {
  .valor_total {
    font-size: 25px;
    padding-top: 6px;
  }

  h3{
    font-size: 19px;
    padding-top: 12px;
  }
}

  .close_search {
    margin-right: 5px;
    a {
      background-color: #181818;
      color: rgb(255, 255, 255);
    }
    &:hover,
    &:focus {
      border-color: #181818 !important;
    }
  }
`;

export const InputSearch = styled.input`
  padding: 0 1.5rem;
  height: 3rem;
  border-radius: 0.25rem 0 0 0;
  border: 1px solid #d7d7d7;
  background: #e7e9ee;
  margin-left: 1rem;
  font-weight: 400;
  font-size: 1rem;
  &::placeholder {
    color: var(--text-body);
  }
  @media screen and (max-width: 560px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-left: 0;
  }
`;
