import React from 'react';
import globalStyle from '../../styles/globalStyle';

const TableIcon = ({ largura, altura }) => {
  return (
    <div style={{
      width: largura + 2,
      height: altura + 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: globalStyle.colors.GRAY100,
      borderRadius: 4
    }}>
      <svg
        width={largura}
        height={altura}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1868_10267)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00004 6.3335C7.07957 6.3335 6.33337 7.07969 6.33337 8.00016V12.3335H12.3334V6.3335H8.00004ZM8.00004 4.3335C5.975 4.3335 4.33337 5.97512 4.33337 8.00016V24.0002C4.33337 26.0252 5.975 27.6668 8.00004 27.6668H24C26.0251 27.6668 27.6667 26.0252 27.6667 24.0002V8.00016C27.6667 5.97512 26.0251 4.3335 24 4.3335H8.00004ZM14.3334 6.3335V12.3335H25.6667V8.00016C25.6667 7.07969 24.9205 6.3335 24 6.3335H14.3334ZM25.6667 14.3335H14.3334V25.6668H24C24.9205 25.6668 25.6667 24.9206 25.6667 24.0002V14.3335ZM12.3334 25.6668V14.3335H6.33337V24.0002C6.33337 24.9206 7.07957 25.6668 8.00004 25.6668H12.3334Z"
            fill="#F5F5F5"
          />
        </g>
        <defs>
          <clipPath id="clip0_1868_10267">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default TableIcon;
