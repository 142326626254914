import styled from "styled-components";

export const Container = styled.div`
margin-left: 75px;
`


export const Header = styled.div`
    display: flex;
    background: ${props => props.theme.colors.GRAY800};
    height: 28px;
    align-items: center;
    padding: 0 8px;

`

export const Title = styled.h2`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${props => props.theme.colors.GRAY900};
`

export const HeaderLabel = styled.div`
    width: ${props => props.width ? props.width : '300px'};
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.colors.GRAY300};
`

export const Content = styled.div`
    display: flex;
    padding: 0 0px;
    margin-top: 5px;

    p {
        color: ${props => props.theme.colors.GRAY300};
    }
`

export const Value = styled.div`
    width: ${props => props.width ? props.width : '300px'};
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: ${props => props.theme.colors.GRAY500};
`

export const Label = styled.div`
    width: 50%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: ${props => props.theme.colors.GRAY500};
    line-height: 20px;
    /* margin-bottom: 13px; */
`


export const Valor = styled.div`
    width: 50%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: ${props => props.theme.colors.GRAY900};
`