import styled from "styled-components";

export const Container = styled.div`
    margin-top: 32px;
    padding: 0 8px;
`
export const FlexContainer = styled.div`
    display: flex;
`
export const Title = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${props => props.theme.colors.GRAY900};
`

export const Mes = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
    color: ${props => props.theme.colors.GRAY500};
`

export const Label = styled.div`
    width: 50%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: ${props => props.theme.colors.GRAY500};
    line-height: 20px;
    /* margin-bottom: 13px; */
`


export const Valor = styled.div`
    width: 50%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: ${props => props.theme.colors.GRAY900};
`