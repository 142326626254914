import React, { useState } from 'react';
import {
    Flex,
    Heading,
    FormControl,
    CircularProgress,
    InputGroup,
    InputRightElement,
    Icon,
    Image
} from '@chakra-ui/core';

import ErrorMessage from '../../components/ErrorMessage';
import logo from '../../assets/logo.png';
import styled from 'styled-components';
import FormLabel from '../../components/UI/FormLabel';
import Input from '../../components/UI/Input';
import { Botao, Box, InputEmail, Logo } from './styles';
import globalStyle from '../../styles/globalStyle';
import api from '../../services/api';

export default function EsqueciSenha() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [step2, setStep2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            await api.post('/usuario/resetarSenha', { email });

            setIsLoading(false);
            setStep2(true);
        } catch (err) {
            setError(err);
            setIsLoading(false);
        }
    };

    const voltarLogin = () => {
        window.location.pathname = '/login';
    }

    return <>
        <Flex width="full" align="center" justifyContent="center" height="100%">
            <Box
                p={8}
                maxWidth="380px"
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg"
                style={{ marginTop: '12%' }}
            >

                {!step2 && (
                    <>
                        <Box textAlign="center">
                            <Heading>
                                <Logo src={logo} height="120px" alt="Precious Group" />
                            </Heading>
                        </Box>
                        <Box my={4} >
                            <form onSubmit={handleSubmit}>
                                {error && <ErrorMessage message={error} />}
                                <FormControl isRequired>
                                    <InputEmail
                                        type="email"
                                        placeholder="Email"
                                        focusBorderColor="gray"
                                        onChange={(event) => setEmail(event.currentTarget.value)}
                                    />
                                </FormControl>
                                <Botao
                                    backgroundColor={email ? globalStyle.colors.GRAY100 : null}
                                    color={'white'}
                                    variant="outline"
                                    type="submit"
                                    width="full"
                                    mt={4}>
                                    {isLoading ? (
                                        <CircularProgress isIndeterminate size="24px" color="red" />
                                    ) : (
                                        'ENVIAR EMAIL DE RECUPERAÇÃO'
                                    )}
                                </Botao>
                            </form>
                        </Box>
                    </>
                )}
                {step2 && (
                    <>
                        <Box textAlign="center">
                            <Heading>
                                <Logo src={logo} height="50px" alt="Precious Group" />
                            </Heading>
                        </Box>
                        <Box my={4} textAlign="left">
                            <Botao
                                onClick={voltarLogin}
                                backgroundColor={globalStyle.colors.GREEN500}
                                color={'white'}
                                variant="outline"
                                width="full"
                                mt={4}>
                                {isLoading ? (
                                    <CircularProgress isIndeterminate size="24px" color="red" />
                                ) : (
                                    'RETORNAR PARA O LOGIN'
                                )}
                            </Botao>
                        </Box>
                    </>
                )}
            </Box>
        </Flex>
    </>
};