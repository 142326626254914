import React from 'react';
import {
  ThemeProvider as ThemeProviderUI,
  ColorModeProvider,
  CSSReset
} from '@chakra-ui/core';
import { ThemeProvider } from 'styled-components';
import Routes from './routes/routes';
import 'rsuite/dist/styles/rsuite-default.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import './styles/style.css';
import './styles/custom-theme.less';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import globalStyle from './styles/globalStyle';

export default function App() {
  return (
    <ThemeProviderUI theme={globalStyle}>
      <ColorModeProvider>
        <CSSReset />
        <ThemeProvider theme={globalStyle}>
          <Routes />
        </ThemeProvider>
      </ColorModeProvider>
    </ThemeProviderUI>
  );
}
