import React from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { Btn, ButtonContainer } from './styled';
import CloseIcon from '../../../assets/Icons/CloseIcon';
import { IoMdClose } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi'
import globalStyle from '../../../styles/globalStyle';


export default function Button(props) {
  return (
    <ButtonContainer
      onClick={props.onClick}
      backGroundColor={props.backGroundColor}
      height={props.height}
      width={props.width}
      style={props.style}
      marginRight={props.marginRight}
      marginTop={props.marginTop}
      marginLeft={props.marginLeft}
    >
      {props.btnType === "delete" && <IoMdClose size={props.iconSize} color={'white'} />}
      {props.btnType === "edit" && <FiEdit size={props.iconSize} color={'white'} />}
      <Btn
        type={props.type ? props.type : 'button'}
        color={props.color}
        fontWeight={props.fontWeight}
        fontSize={props.fontSize}
        padding={props.padding}
        uppercase={props.uppercase}
      >
        {props.text}
      </Btn>
    </ButtonContainer>
  );
}