import React from 'react';
import globalStyle from '../../styles/globalStyle';

const EyeIcon = ({ largura, altura, color = globalStyle.colors.GRAY100 }) => {
  return (
    <svg
      width={largura}
      height={altura}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.82435 15.9998C7.21473 21.6901 11.2817 24.3332 16 24.3332C20.7182 24.3332 24.7852 21.6901 28.1756 15.9998C24.7852 10.3096 20.7182 7.6665 16 7.6665C11.2817 7.6665 7.21473 10.3096 3.82435 15.9998ZM1.79839 15.5037C5.46283 9.09126 10.1866 5.6665 16 5.6665C21.8133 5.6665 26.5371 9.09126 30.2015 15.5037C30.3772 15.8111 30.3772 16.1886 30.2015 16.496C26.5371 22.9084 21.8133 26.3332 16 26.3332C10.1866 26.3332 5.46283 22.9084 1.79839 16.496C1.6227 16.1886 1.6227 15.8111 1.79839 15.5037ZM16 14.3332C15.0795 14.3332 14.3333 15.0794 14.3333 15.9998C14.3333 16.9203 15.0795 17.6665 16 17.6665C16.9204 17.6665 17.6666 16.9203 17.6666 15.9998C17.6666 15.0794 16.9204 14.3332 16 14.3332ZM12.3333 15.9998C12.3333 13.9748 13.9749 12.3332 16 12.3332C18.025 12.3332 19.6666 13.9748 19.6666 15.9998C19.6666 18.0249 18.025 19.6665 16 19.6665C13.9749 19.6665 12.3333 18.0249 12.3333 15.9998Z"
        fill={color}
      />
    </svg>
  );
};

export default EyeIcon;
