import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 1rem;
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
`;

export const HeaderTable = styled.div`
  text-align: right;
  margin-bottom: 1rem;
  
  div > p {
    color: ${props => props.theme.colors.GRAY100};
  }
`

export const Saida = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--red);
  color: #fff;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 3px;
  z-index: 400;

  &:hover {
    filter: brightness(0.9);
  }
`;
export const Entrada = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green);
  color: #fff;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 3px;
  z-index: 400;

  &:hover {
    filter: brightness(0.9);
  }
`;
export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`;
export const InputSearch = styled.input`
  padding: 0 1.5rem;
  height: 3rem;
  border-radius: 0.25rem 0 0 0;
  border: 1px solid #d7d7d7;
  background: #e7e9ee;

  font-weight: 400;
  font-size: 1rem;
  &::placeholder {
    color: var(--text-body);
  }
`;
