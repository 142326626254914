import { useEffect, useState } from 'react';
import {
  Modal,
  // Button,
  FormGroup,
  InputGroup,
  Icon,
  Loader,
  Toggle,
  Checkbox,
  CheckboxGroup,
  Alert,
  SelectPicker
} from 'rsuite';
// import InputMask from 'react-input-mask';
import InputMask from '../UI/InputMask';
import * as S from './styles';
import { useUsuario } from '../../hooks/user';
import { usePerfil } from '../../hooks/perfil';
import listaPermissao from '../../utils/permissao';
import formatReal from '../../utils/formatReal';
// import NumberFormat from 'react-number-format';
import NumberFormat from '../UI/NumberFormat';
import formataCampoPorcentagem from '../../utils/formataCampoPorcentagem';
import globalStyle from '../../styles/globalStyle';
import Input from '../UI/Input';
import Button from '../UI/Button';

export function EditUserModal({ isOpenEdit, onRequestCloseEdit }) {
  const {
    usuarioPorUUID,
    editUsuario,
    isLoadingEdit,
    activePage,
    usuarioListaPorTipo,
    getUsuariosPorTipo
  } = useUsuario();

  useEffect(() => {
    getUsuariosPorTipo(5, false);
  }, []);

  const { tipoPerfil } = usePerfil();

  const [nome, setNome] = useState(usuarioPorUUID[0]?.nome || '');
  const [cpf, setCPF] = useState(usuarioPorUUID[0]?.cpf?.br || '');
  const [email, setEmail] = useState(usuarioPorUUID[0]?.email || '');
  const [telefone, setTelefone] = useState(
    usuarioPorUUID[0]?.telefone?.br || ''
  );
  const [celular, setCelular] = useState(usuarioPorUUID[0]?.celular?.br || '');
  const [data_nascimento, setDataNascimento] = useState(
    usuarioPorUUID[0]?.data_nascimento.br || ''
  );
  const [avatar, setAvatar] = useState(usuarioPorUUID[0]?.avatar || '');
  const [avatar_upload, setAvatarUpload] = useState(usuarioPorUUID[0].avatar);

  const [peca, setPeca] = useState(usuarioPorUUID[0]?.peca || '');
  const [po, setPo] = useState(usuarioPorUUID[0]?.po || '');
  const [parametro, setParametro] = useState(
    usuarioPorUUID[0]?.parametro || ''
  );
  const [inox, setInox] = useState(usuarioPorUUID[0]?.inox || '');
  const [filtro, setFiltro] = useState(usuarioPorUUID[0]?.filtro || '');
  const [banco, setBanco] = useState(usuarioPorUUID[0]?.banco || '');
  const [titular, setTitular] = useState(usuarioPorUUID[0]?.titular || '');
  const [titularCpf, setTitularCPF] = useState(
    usuarioPorUUID[0]?.titular_cpf || ''
  );
  const [agencia, setAgencia] = useState(usuarioPorUUID[0]?.agencia || '');
  const [conta, setConta] = useState(usuarioPorUUID[0]?.conta || '');

  const [logradouro, setLogradouro] = useState(
    usuarioPorUUID[0]?.endereco || ''
  );
  const [numero, setNumero] = useState(usuarioPorUUID[0]?.numero || '');
  const [bairro, setBairro] = useState(usuarioPorUUID[0]?.bairro || '');
  const [cidade, setCidade] = useState(usuarioPorUUID[0]?.cidade || '');
  const [estado, setEstado] = useState(usuarioPorUUID[0]?.estado || '');
  const [codigo, setCodigo] = useState(usuarioPorUUID[0]?.codigo || '');
  const [limite, setLimite] = useState(usuarioPorUUID[0]?.limite?.real || '');
  let statusToggle = usuarioPorUUID[0].status == 1 ? true : false;
  const [status, setStatus] = useState(statusToggle);
  const [permissao, setPermissao] = useState(
    usuarioPorUUID[0]?.permissao != "''" && usuarioPorUUID[0]?.permissao != ''
      ? JSON.parse(usuarioPorUUID[0]?.permissao) || []
      : []
  );
  const [senha, setSenha] = useState('');
  const [aplicarParaDependentes, setAplicarParaDependentes] = useState(false);
  const [usuarioPrincipal, setUsuarioPrincipal] = useState(
    usuarioPorUUID[0]?.user_principal || null
  );
  const [usuarioTipo, setUsuarioTipo] = useState(
    usuarioPorUUID[0]?.tipo || null
  );

  const dadosUsuarioFormatado = usuarioListaPorTipo.map((usr) => {
    return { label: usr.id + ' - ' + usr.nome, value: usr.id };
  });

  const estados = {
    Estado: 'Estado',
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins'
  };

  function options() {
    var es = Object.entries(estados);
    return es.map((item) => (
      <option key={`estado${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  // const tipos = {
  //     1: "Administrador",
  //     2: "Recebimento",
  //     3: "Analisador",
  //     4: "Parceiro",
  // };

  // function optionsTipo() {
  //     var es = Object.entries(tipos);
  //     return es.map((item) => (
  //         <option key={`estado${item[0]}`} value={item[0]}>
  //             {item[1]}
  //         </option>
  //     ));
  // }

  const handlUpdateUser = async (event) => {
    event.preventDefault();

    if (
      nome == '' &&
      (usuarioPorUUID[0].tipo == 1 ||
        usuarioPorUUID[0].tipo == 2 ||
        usuarioPorUUID[0].tipo == 3 ||
        usuarioPorUUID[0].tipo == 4)
    ) {
      Alert.error('O campo nome é obrigatório.', 3000);
      return false;
    } else if (
      cpf == '' &&
      (usuarioPorUUID[0].tipo == 1 ||
        usuarioPorUUID[0].tipo == 2 ||
        usuarioPorUUID[0].tipo == 3 ||
        usuarioPorUUID[0].tipo == 4)
    ) {
      Alert.error('O campo cpf é obrigatório.', 3000);
      return false;
    } else if (
      email == '' &&
      (usuarioPorUUID[0].tipo == 1 ||
        usuarioPorUUID[0].tipo == 2 ||
        usuarioPorUUID[0].tipo == 3 ||
        usuarioPorUUID[0].tipo == 4)
    ) {
      Alert.error('O campo email é obrigatório.', 3000);
      return false;
    } else if (
      telefone == '' &&
      (usuarioPorUUID[0].tipo == 1 ||
        usuarioPorUUID[0].tipo == 2 ||
        usuarioPorUUID[0].tipo == 3 ||
        usuarioPorUUID[0].tipo == 4)
    ) {
      Alert.error('O campo telefone é obrigatório.', 3000);
      return false;
    } else if (
      celular == '' &&
      (usuarioPorUUID[0].tipo == 1 ||
        usuarioPorUUID[0].tipo == 2 ||
        usuarioPorUUID[0].tipo == 3 ||
        usuarioPorUUID[0].tipo == 4)
    ) {
      Alert.error('O campo celular é obrigatório.', 3000);
      return false;
    } else if (
      data_nascimento == '' &&
      (usuarioPorUUID[0].tipo == 1 ||
        usuarioPorUUID[0].tipo == 2 ||
        usuarioPorUUID[0].tipo == 3 ||
        usuarioPorUUID[0].tipo == 4)
    ) {
      Alert.error('O campo data de nascimento é obrigatório.', 3000);
      return false;
    } else if (avatar == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('A imagem é obrigatória.', 3000);
      return false;
    } else if (codigo == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo código é obrigatório.', 3000);
      return false;
    } else if (limite == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo limite é obrigatório.', 3000);
      return false;
    } else if (logradouro == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo logradouro é obrigatório.', 3000);
      return false;
    } else if (numero == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo número é obrigatório.', 3000);
      return false;
    } else if (cidade == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo cidade é obrigatório.', 3000);
      return false;
    } else if (estado == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo estado é obrigatório.', 3000);
      return false;
    } else if (bairro == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo bairro é obrigatório.', 3000);
      return false;
    } else if (peca == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo Peça (%) é obrigatório.', 3000);
      return false;
    } else if (po == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo PO (%) é obrigatório.', 3000);
      return false;
    } else if (filtro == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo Filtro (%) é obrigatório.', 3000);
      return false;
    } else if (inox == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo Inox (%) é obrigatório.', 3000);
      return false;
    } else if (agencia == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo Agência é obrigatório.', 3000);
      return false;
    } else if (banco == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo Banco é obrigatório.', 3000);
      return false;
    } else if (conta == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo Conta é obrigatório.', 3000);
      return false;
    } else if (titular == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo Titular é obrigatório.', 3000);
      return false;
    } else if (titularCpf == '' && usuarioPorUUID[0].tipo == 4) {
      Alert.error('O campo CPF do titular é obrigatório.', 3000);
      return false;
    } else if (
      permissao.length == 0 &&
      (usuarioPorUUID[0].tipo == 2 || usuarioPorUUID[0].tipo == 3)
    ) {
      Alert.error('O campo Permissão é obrigatório.', 3000);
      return false;
    }

    let data = {
      nome,
      cpf,
      email,
      telefone,
      celular,
      data_nascimento,
      avatar: avatar_upload,
      peca,
      po,
      inox,
      filtro,
      banco,
      titular,
      titularCpf,
      agencia,
      conta,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      codigo,
      permissao,
      limite,
      senha,
      tipo: usuarioTipo ? usuarioTipo : usuarioPorUUID[0]?.tipo,
      aplicarParaDependentes: aplicarParaDependentes,
      principal: usuarioPrincipal,
      status: status == true ? 1 : 2,
      parametro: parametro
    };

    await editUsuario(
      data,
      onRequestCloseEdit,
      usuarioPorUUID[0].uuid,
      activePage
    );
  };

  const onImageChange = (event) => {
    const { files } = event.target;
    setAvatar(URL.createObjectURL(files[0]));
    setAvatarUpload(files[0]);
  };

  const handlClearFoto = async () => {
    setAvatar('');
    setAvatarUpload('');
  };
  // update settings
  function handlePermissao(newValue) {
    setPermissao(newValue);
  }

  return (
    <Modal backdrop={'static'} show={isOpenEdit} onHide={onRequestCloseEdit}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ color: globalStyle.colors.GRAY100 }}>
            Editar Usuário
          </div>
        </Modal.Title>
      </Modal.Header>
      <S.Container onSubmit={handlUpdateUser}>
        <Modal.Body>
          <div>
            {isLoadingEdit ? (
              <Loader backdrop content="Carregando..." vertical />
            ) : (
              ''
            )}
          </div>
          <S.ContainerCardForm>
            {usuarioPorUUID[0].tipo != '' ? (
              <>
                {usuarioPorUUID[0].tipo == 4 || usuarioPorUUID[0]?.tipo == 5 ? (
                  <div className="card-form" style={{ marginBottom: '2rem' }}>
                    <FormGroup>
                      <S.FotoContainer>
                        {avatar ? (
                          <>
                            <InputGroup
                              className="close_search"
                              label="Excluir foto"
                            >
                              <InputGroup.Button
                                onClick={() => handlClearFoto()}
                              >
                                <Icon icon="close" />
                              </InputGroup.Button>
                            </InputGroup>
                            <img
                              src={avatar}
                              alt="imagem"
                              width="300"
                              height="300"
                              onChange={onImageChange}
                            />
                          </>
                        ) : (
                          <>
                            <Icon icon="file-image-o" size="4x" />
                            <Input
                              type="file"
                              id="upload-button"
                              onChange={onImageChange}
                            />
                            <label>Clique aqui para inserir uma imagem</label>
                          </>
                        )}
                      </S.FotoContainer>
                    </FormGroup>
                  </div>
                ) : null}
                {usuarioPorUUID[0]?.tipo == 4 ||
                usuarioPorUUID[0]?.tipo == 5 ? (
                  <>
                    <div className="card-form">
                      <h2>Tipo do usuário</h2>
                      <label>Alterar tipo do usuário</label>
                      <SelectPicker
                        data={[
                          { label: 'Parceiro', value: 4 },
                          { label: 'Parceiro Principal', value: 5 }
                        ]}
                        value={usuarioTipo}
                        style={{ position: 'relative' }}
                        size="lg"
                        menuStyle={{ zIndex: 10000 }}
                        placeholder="Parceiro Principal"
                        block
                        preventOverflow={true}
                        onChange={(val) => setUsuarioTipo(val)}
                      />
                    </div>
                    {usuarioPorUUID[0]?.tipo == 4 && (
                      <div className="card-form">
                        <h2>Parceiro Principal</h2>
                        <FormGroup>
                          <label>Digite o nome ou id do parceiro</label>
                          <SelectPicker
                            data={dadosUsuarioFormatado}
                            value={usuarioPrincipal}
                            style={{ position: 'relative' }}
                            size="lg"
                            menuStyle={{ zIndex: 10000 }}
                            placeholder="Parceiro Principal"
                            block
                            preventOverflow={true}
                            onChange={(val) => setUsuarioPrincipal(val)}
                          />
                        </FormGroup>
                      </div>
                    )}
                    <div className="card-form">
                      <h2>Código</h2>
                      <FormGroup>
                        <label>Digite o código</label>
                        <Input
                          type="text"
                          placeholder="Código"
                          value={codigo}
                          onChange={(event) => setCodigo(event.target.value)}
                        />
                      </FormGroup>
                    </div>
                    <div className="card-form">
                      <h2>Limite</h2>
                      <FormGroup>
                        <label>Digite o limite</label>
                        <Input
                          type="text"
                          placeholder="Limite"
                          value={limite}
                          onChange={(event) =>
                            setLimite(formatReal(event.target.value))
                          }
                        />
                      </FormGroup>
                    </div>
                  </>
                ) : null}
                <div className="card-form">
                  <h2>Dados básicos</h2>
                  <FormGroup>
                    <label>Nome</label>
                    <Input
                      type="text"
                      placeholder="Nome"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>CPF: </label>
                    <InputMask
                      type="text"
                      mask="999.999.999-99"
                      placeholder="CPF"
                      onChange={(event) => setCPF(event.target.value)}
                      value={cpf}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>E-mail: </label>
                    <Input
                      type="text"
                      placeholder="E-mail"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Telefone: </label>
                    <InputMask
                      type="text"
                      mask="(99) 9999-9999"
                      placeholder="Telefone"
                      onChange={(event) => setTelefone(event.target.value)}
                      value={telefone}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Celular: </label>
                    <InputMask
                      type="text"
                      mask="(99) 99999-9999"
                      placeholder="Celular"
                      value={celular}
                      onChange={(event) => setCelular(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Data de nascimento: </label>
                    <InputMask
                      type="text"
                      mask="99/99/9999"
                      placeholder="Data de nascimento"
                      value={data_nascimento}
                      onChange={(event) =>
                        setDataNascimento(event.target.value)
                      }
                    />
                  </FormGroup>
                </div>
                {usuarioPorUUID[0]?.tipo == 4 ||
                usuarioPorUUID[0]?.tipo == 5 ? (
                  <>
                    <div className="card-form">
                      <h2>Endereço</h2>
                      <S.Row>
                        <S.Column>
                          <label>Logradouro</label>
                          <Input
                            type="text"
                            value={logradouro}
                            placeholder="Logradouro"
                            onChange={(e) => {
                              setLogradouro(e.target.value);
                            }}
                          />
                        </S.Column>
                      </S.Row>
                      <S.Row>
                        <S.Column>
                          <label>Número</label>
                          <Input
                            type="text"
                            placeholder="Nº"
                            className="numero"
                            onChange={(e) => {
                              setNumero(e.target.value);
                            }}
                            value={numero}
                          />
                        </S.Column>

                        <S.Column>
                          <label>Cidade</label>
                          <Input
                            type="text"
                            placeholder="Cidade"
                            className="cidade"
                            onChange={(e) => setCidade(e.target.value)}
                            value={cidade}
                          />
                        </S.Column>

                        <S.Column>
                          <label>Estado</label>

                          <select
                            className="select-state"
                            placeholder="Estado"
                            onChange={(e) => {
                              setEstado(e.target.value);
                            }}
                            value={estado}
                          >
                            {options()}
                          </select>
                        </S.Column>
                      </S.Row>
                      <S.Column>
                        <label>Bairro</label>
                        <Input
                          value={bairro}
                          type="text"
                          placeholder="Bairro"
                          className="bairro"
                          onChange={(e) => {
                            setBairro(e.target.value);
                          }}
                        />
                      </S.Column>
                      <h2>Peça</h2>
                      <S.Row>
                        <S.Column>
                          <label>Peça (%)</label>
                          <NumberFormat
                            type="text"
                            value={peca}
                            suffix="%"
                            onChange={(e) => {
                              setPeca(formataCampoPorcentagem(e.target.value));
                            }}
                          />
                        </S.Column>

                        <S.Column>
                          <label>PO (%)</label>
                          <NumberFormat
                            type="text"
                            value={po}
                            suffix="%"
                            onChange={(e) => {
                              setPo(formataCampoPorcentagem(e.target.value));
                            }}
                          />
                        </S.Column>
                      </S.Row>
                      <S.Column>
                        <label>Inox (%)</label>
                        <NumberFormat
                          type="text"
                          value={inox}
                          suffix="%"
                          onChange={(e) => {
                            setInox(formataCampoPorcentagem(e.target.value));
                          }}
                        />
                      </S.Column>
                      <S.Column>
                        <label>Filtro (%)</label>
                        <NumberFormat
                          type="text"
                          value={filtro}
                          suffix="%"
                          onChange={(e) => {
                            setFiltro(formataCampoPorcentagem(e.target.value));
                          }}
                        />
                      </S.Column>
                      {usuarioPorUUID[0]?.tipo == 4 && usuarioPrincipal ? (
                        <S.Column>
                          <label>Parâmetro (%)</label>
                          <NumberFormat
                            type="text"
                            value={parametro}
                            placeholder="Parâmetro"
                            suffix="%"
                            onChange={(e) => {
                              setParametro(
                                formataCampoPorcentagem(e.target.value)
                              );
                            }}
                          />
                        </S.Column>
                      ) : null}
                      {usuarioPorUUID[0]?.tipo == 5 ? (
                        <Button
                          backGroundColor={
                            aplicarParaDependentes
                              ? globalStyle.colors.GREEN500
                              : globalStyle.colors.GRAY100
                          }
                          color={
                            aplicarParaDependentes
                              ? globalStyle.colors.GRAY100
                              : globalStyle.colors.GRAY600
                          }
                          style={{
                            marginTop: '20px',
                            width: 200
                          }}
                          onClick={() =>
                            setAplicarParaDependentes(!aplicarParaDependentes)
                          }
                          text="Aplicar Parceiros"
                        />
                      ) : null}

                      <h2>Dados Bancários</h2>
                      <S.Row>
                        <S.Column>
                          <label>Agência</label>
                          <InputMask
                            type="text"
                            placeholder="Agência"
                            value={agencia}
                            onChange={(event) => setAgencia(event.target.value)}
                          />
                        </S.Column>
                        <S.Column>
                          <label>Banco</label>
                          <InputMask
                            type="text"
                            placeholder="Banco"
                            value={banco}
                            onChange={(event) => setBanco(event.target.value)}
                          />
                        </S.Column>
                        <S.Column>
                          <label>Conta</label>
                          <InputMask
                            type="text"
                            placeholder="Conta"
                            value={conta}
                            onChange={(event) => setConta(event.target.value)}
                          />
                        </S.Column>
                      </S.Row>
                      <S.Row>
                        <S.Column>
                          <label>Titular</label>
                          <InputMask
                            type="text"
                            placeholder="Titular"
                            value={titular}
                            onChange={(event) => setTitular(event.target.value)}
                          />
                        </S.Column>

                        <S.Column>
                          <label>CPF do Titular</label>
                          <InputMask
                            type="text"
                            placeholder="CPF do Titular"
                            value={titularCpf}
                            onChange={(event) =>
                              setTitularCPF(event.target.value)
                            }
                          />
                        </S.Column>
                      </S.Row>
                    </div>
                  </>
                ) : null}
                <div className="card-form">
                  <h2>Permissões</h2>
                  {tipoPerfil == 1 &&
                  (usuarioPorUUID[0]?.tipo == 2 ||
                    usuarioPorUUID[0]?.tipo == 3) ? (
                    <FormGroup>
                      <S.ContainerPermissao>
                        <CheckboxGroup
                          inline
                          name="permissao"
                          value={permissao}
                          onChange={handlePermissao}
                        >
                          {listaPermissao.map((n, i) => (
                            <S.ContainerCheck key={i}>
                              <S.TituloPermissao>{n.titulo}</S.TituloPermissao>
                              {Object.entries(n.lista).map((item, i) => (
                                <Checkbox key={i} value={item[0]}>
                                  {item[1]}
                                </Checkbox>
                              ))}
                            </S.ContainerCheck>
                          ))}
                        </CheckboxGroup>
                      </S.ContainerPermissao>
                    </FormGroup>
                  ) : null}

                  <FormGroup>
                    <label>Status:</label>
                    <S.StatusFilialContainer>
                      <Toggle
                        onChange={(status) => {
                          if (status) setStatus(true);
                          else setStatus(false);
                        }}
                        size="lg"
                        defaultChecked={status}
                        checkedChildren={'Ativo'}
                        unCheckedChildren="Inativo"
                      />
                    </S.StatusFilialContainer>
                    <S.Column>
                      <label>Senha</label>
                      <InputMask
                        type="password"
                        placeholder="Senha"
                        value={senha}
                        onChange={(event) => setSenha(event.target.value)}
                      />
                    </S.Column>
                  </FormGroup>
                </div>
              </>
            ) : null}
          </S.ContainerCardForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            color="green"
            style={{ marginTop: '20px' }}
            text={isLoadingEdit ? 'Carregando...' : 'Atualizar'}
          />
        </Modal.Footer>
      </S.Container>
    </Modal>
  );
}
