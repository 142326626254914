import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormGroup, Loader, Pagination } from 'rsuite';
import { useParceiro } from '../../hooks/parceiro';
import TableComponent from '../UI/TableComponent';
import { ContainerPagination, Container, FilterContainer, ButtonSalvar, ButtonLimpar, Header } from './styled';
import Input from '../UI/Input';
import InputMask from 'react-input-mask';
import globalStyle from '../../styles/globalStyle';
import Button from '../UI/Button';

export default function HistoricoLocalizacaoTable(props) {
  const {
    getParceiroPorUuid,
    parceiroLista,
    isLoading,
    pagination,
    historicoPagination,
    VisualizarHistoricoDeLocalizacaoPorUUID,
    VisualizarHistoricoDeLocalizacaoPorUUIDFiltrado,
    orderParceiro,
    localizacoesPage,
    parceiroPage,
    historicoDeLocalizacaoPorUUID
  } = useParceiro();

  const inicialFiltros = {
    periodo: {
      inicio: '',
      fim: ''
    },
    origem: 'Origem'
  }
  const [filtros, setFiltros] = useState(inicialFiltros);

  useEffect(() => {
    VisualizarHistoricoDeLocalizacaoPorUUID(props.uuid);
  }, []);

  const handleSelect = async (eventKey) => await localizacoesPage(eventKey, props.uuid, filtros);


  const handleLimpaFiltros = async () => {
    setFiltros(inicialFiltros);
    VisualizarHistoricoDeLocalizacaoPorUUID(props.uuid);
  }

  const handleFiltrar = async () => {
    if (filtros.origem == "Origem") setFiltros({ ...filtros, origem: "Origem" })
    await VisualizarHistoricoDeLocalizacaoPorUUIDFiltrado(props.uuid, filtros)
  };
  console.log("--- " + "historicoPagination[0]".toUpperCase(), " ----")
  console.log(historicoPagination[0])
  return (
    <div>
      {isLoading ? <Loader backdrop content="Carregando..." vertical /> : ''}
      <Container>
        <Header>

          <FilterContainer>
            <div>
              <label>Data Inicial</label>
              <InputMask
                type="datetime-local"
                placeholder="Data inicial"
                onChange={(event) => setFiltros({ ...filtros, periodo: { ...filtros.periodo, inicio: event.target.value } })}
                value={filtros.periodo.inicio}
              />
            </div>
            <div>
              <label>Data Final</label>
              <InputMask
                type="datetime-local"
                placeholder="Data final"
                onChange={(event) => setFiltros({ ...filtros, periodo: { ...filtros.periodo, fim: event.target.value } })}
                value={filtros.periodo.fim}
              />
            </div>
            <div
            >
              <label>Origem</label>
              <select
                onChange={(e) => setFiltros({ ...filtros, origem: e.target.value })}
                value={filtros.origem}
              >
                <option value="Origem" selected disabled>Origem</option>
                <option value="consulta">CONSULTA</option>
                <option value="login">LOGIN</option>
              </select>
            </div>
            <div
              style={{ width: "auto" }}
            >
              <label style={{ color: "transparent" }}>Filtrar</label>
              <ButtonSalvar
                onClick={handleFiltrar}
              >
                Filtrar
              </ButtonSalvar>
            </div>
            <div
              style={{ width: "auto" }}
            >
              <label style={{ color: "transparent" }}>Limpar</label>
              <ButtonLimpar
                onClick={handleLimpaFiltros}
              >
                Limpar
              </ButtonLimpar>
            </div>
          </FilterContainer>
          {historicoPagination[0]?.total ?
            (
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <p>
                  Total: {historicoPagination[0].total}
                </p>
              </div>
            ) : null
          }
        </Header>
        <TableComponent
          lista={historicoDeLocalizacaoPorUUID}
          page={props.app}
          isLoading={isLoading}
          handleClickOpenEdit={() => { }}
          pagination={historicoPagination}
          columns={[
            { header: 'Data/Hora', body: 'data/hora' },
            { header: 'Localização', body: 'latitude' },
            { header: 'ID do Dispositivo', body: 'imei' },
            { header: 'Origem', body: 'origem' },
            { header: 'Pesquisa', body: 'search_text' },
            { header: '', body: '' }
          ]}
          orderingByColumnsIndex={['', '', '', '', '']}
        />
        <ContainerPagination>
          {historicoPagination.length ? (
            <Pagination
              prev
              last
              next
              first
              maxButtons={5}
              size="lg"
              pages={historicoPagination[0]?.last_page}
              activePage={historicoPagination[0]?.current_page}
              onSelect={handleSelect}
            />
          ) : null}
        </ContainerPagination>
      </Container>
    </div>
  );
}
