import { Table, Avatar, Whisper, Tooltip, InputGroup, Icon } from 'rsuite';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import { useCatalogo } from '../../hooks/catalogo';
import { usePerfil } from '../../hooks/perfil';
import { useState } from 'react';
import TableComponent from './Table';
// import TableComponent from '../UI/TableComponent';
import Button from '../UI/Button';
import Input from '../UI/Input';
import PlusButton from '../PlusButton';
import { useNavigate } from 'react-router-dom';
import DocumentIcon from '../../assets/Icons/DocumentIcon';
import globalStyle from '../../styles/globalStyle';

export function CatalogoTable({
  onOpenNewCatalogoModal,
  onCloseNewCatalogoModal,
  onOpenEditCatalogoModal,
  onOpenViewCatalogoModal
}) {
  const app = 'catalogo';
  const {
    catalogoLista,
    isLoading,
    catalogoPage,
    catalogoQuantidade,
    pagination,
    getCatalogoPorUuid,
    orderCatalogo,
    searchCatalogo,
    catalogoPorUUID,
    buscaRelatorioTodosCatalogos
  } = useCatalogo();

  const navigate = useNavigate();

  const { permissaoPerfil, tipoPerfil } = usePerfil();
  const [search, setSearch] = useState('');
  const [definition, setDefinition] = useState('');
  const [targeting, setTargeting] = useState('');

  const [quantidade, setQuantidade] = useState(pagination[0]?.count || 15);
  const { Pagination } = Table;

  const handleSelect = async (eventKey) => {
    await catalogoPage(eventKey, quantidade);
  };
  const handleQuantidade = async (page, valor) => {
    await catalogoQuantidade(page, valor);
    setQuantidade(valor);
  };

  const handleClickNew = () => {
    if (
      (permissaoPerfil != null &&
        permissaoPerfil.includes('per_' + app + '_adicionar')) ||
      tipoPerfil == 1
    ) {
      onOpenNewCatalogoModal();
    }
  };

  const handleCloseNew = () => {
    onCloseNewCatalogoModal();
  };

  const handleClickOpenEdit = async (uuid, page) => {
    if (
      permissaoPerfil != null &&
      permissaoPerfil.includes('per_' + app + '_editar') &&
      !permissaoPerfil.indexOf('per_' + app + '_visualizar') == -1
    ) {
      await getCatalogoPorUuid(uuid, page);
      onOpenEditCatalogoModal();
      return;
    }

    if (
      tipoPerfil == 1 ||
      (permissaoPerfil != null &&
        permissaoPerfil.includes('per_' + app + '_visualizar'))
    ) {
      await getCatalogoPorUuid(uuid, page);
      onOpenViewCatalogoModal();
    }
  };

  const buscaAtivaCatalogo = localStorage.getItem('buscaAtiva');

  const handleOrderingColumn = (event, column) => {
    const columns = document.getElementsByTagName('th');
    for (let column of columns) {
      column.classList.add('columnDefault');
    }

    let el = event.target;
    el.classList.remove('columnDefault');
    el.classList.add('columnActive');

    if (el.lastChild.classList.contains('rs-icon-arrow-up')) {
      el.lastChild.classList.remove('rs-icon-arrow-up');
      el.lastChild.classList.add('rs-icon-arrow-down');
      orderCatalogo(column, 'desc', pagination[0].current_page);
    } else {
      el.lastChild.classList.remove('rs-icon-arrow-down');
      el.lastChild.classList.add('rs-icon-arrow-up');
      orderCatalogo(column, 'asc', pagination[0].current_page);
    }
  };

  const handlSearchCatalog = async (definition, targeting) => {
    let data = {
      marca: search,
      modelo: search,
      codigo: search,
      definition: definition,
      targeting: targeting,
      tipo: '',
      status: ''
    };

    await searchCatalogo(data);
  };

  // limpa modal e seta tipo
  function handleLimpaSetDefinition(valorTipo) {
    setDefinition(valorTipo);
  }
  function handleLimpaSetTargeting(valorTipo) {
    setTargeting(valorTipo);
  }

  const handleDowloadAllCatalogs = async () =>
    await buscaRelatorioTodosCatalogos();

  const typeDefinition = {
    todos: 'Todos',
    flex: 'Catalisador Flex',
    inox: 'Inox',
    dpf: 'DPF'
  };

  const typeTargeting = {
    todos: 'Todos',
    analise: 'Análise',
    'corte especial': 'Corte Especial',
    b2: 'B2',
    g51: 'G51',
    a1: 'A1'
  };

  function optionsDefinition() {
    var es = Object.entries(typeDefinition);
    return es.map((item) => (
      <option key={`Definição ${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  function optionsTargeting() {
    var es = Object.entries(typeTargeting);
    return es.map((item) => (
      <option key={`Classificação ${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  function handleCriarProduto() {
    navigate('/catalogo/novo');
  }

  return (
    <div>
      <S.Container>
        <S.HeaderTable>
          <div className="quantidade_container">
            <p>Quantidade: </p>
            <input
              type="text"
              style={{ border: `solid 1px ${globalStyle.colors.GRAY900}` }}
              value={quantidade}
              onChange={(e) => setQuantidade(e.target.value)}
              onBlur={(e) => handleQuantidade(1, e.target.value)}
            ></input>
          </div>
          <div className="quantidade_container total_items">
            <p>Total: </p>
            <div>{pagination[0]?.total}</div>
            <div
              onClick={() => handleDowloadAllCatalogs()}
              style={{
                width: 28,
                backgroundColor: globalStyle.colors.GRAY50,
                height: 28,
                padding: 0,
                marginRight: 10,
                borderRadius: 4,
                cursor: 'pointer'
              }}
            >
              <DocumentIcon
                largura={25}
                style={{ padding: '1px 1px 0', marginLeft: 0 }}
              />
            </div>
          </div>
        </S.HeaderTable>
        <TableComponent data={catalogoLista} />
        <PlusButton onClick={handleCriarProduto} />
        <S.ContainerPagination>
          {pagination.length ? (
            <Pagination
              prev
              last
              next
              first
              size="lg"
              pages={pagination[0].last_page}
              activePage={pagination[0].current_page}
              onSelect={handleSelect}
            />
          ) : null}
        </S.ContainerPagination>
      </S.Container>
    </div>
  );
}
