import React from "react";
import { Container } from "./styled";
import PlusIcon from '../../assets/Icons/PlusIcon.svg'

export default function PlusButton({ onClick }) {
    return (
        <Container onClick={onClick}>
            <img src={PlusIcon} alt="" />
        </Container>
    )
}