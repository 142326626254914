import { useState } from 'react';
import { UsuarioTable } from '../../components/UsuarioTable/index';
import { NewUserModal } from '../../components/NewUserModal/index';
import { EditUserModal } from '../../components/EditUserModal/index';
import { VisualizarUserModal } from '../../components/VisualizarUserModal/index';
import { SearchUsuarioModal } from '../../components/SearchUsuarioModal/index';
import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useUsuario, UsuarioProvider } from '../../hooks/user';
import {
  Header,
  Sidebar as Sidenav,
  Loader,
  Container,
  Icon,
  FormGroup,
  Alert
} from 'rsuite';
import { usePerfil } from '../../hooks/perfil';
import { Navigate, useNavigate } from 'react-router-dom';
import { Content } from './styles';
import { useEffect } from 'react';
import * as S from './styles';

import InputMask from '../../components/UI/InputMask';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';

import {
  Flex,
  Heading,
  FormControl,
  CircularProgress,
  InputGroup,
  InputRightElement,
  Button as Botao,
  Icon as Icone,
  Image
} from '@chakra-ui/core';
import Button from '../../components/UI/Button';
import globalStyle from '../../styles/globalStyle';
import SelectPicker from '../../components/UI/SelectPicker';
import formatReal from '../../utils/formatReal';
import formataCampoPorcentagem from '../../utils/formataCampoPorcentagem';
import ConfirmaModal from '../../components/ConfirmaModal';

export function NovoUsuario({ }) {
  useEffect(() => {
    setViewUser(false);
  }, []);
  let app = 'novo-usuario';
  const [selectedUser, setSelectedUser] = useState('');
  const [viewUser, setViewUser] = useState(false);
  const [perUsuarioVisualizar, setPermUsuarioVisualizar] = useState(false);
  const [perUsuarioAdicionar, setPermUsuarioAdicionar] = useState(false);
  const [perUsuarioEditar, setPermUsuarioEditar] = useState(false);
  const [perCatalogoVisualizar, setPermCatalogoVisualizar] = useState(false);
  const [perCatalogoAdicionar, setPermCatalogoAdicionar] = useState(false);
  const [perCatalogoEditar, setPermCatalogoEditar] = useState(false);
  const [perExtratoVisualizar, setPermExtratoVisualizar] = useState(false);
  const [perExtratoAdicionar, setPermExtratoAdicionar] = useState(false);
  const [perExtratoAtualizar, setPermExtratoAtualizar] = useState(false);
  const [perLotesVisualizar, setPermLotesVisualizar] = useState(false);
  const [perLotesAdicionar, setPermLotesAdicionar] = useState(false);
  const [perLotesEditar, setPermLotesEditar] = useState(false);
  const [perParceiroVisualizar, setPermParceiroVisualizar] = useState(false);
  const [perParceiroAdicionar, setPermParceiroAdicionar] = useState(false);
  const [perParceiroAtualizar, setPermParceiroAtualizar] = useState(false);
  const [perConfiguracaoVisualizar, setPermConfiguracaoVisualizar] =
    useState(false);
  const [perConfiguracaoAtualizar, setPermConfiguracaoAtualizar] =
    useState(false);
  const [perCompraVisualizar, setPermCompraVisualizar] = useState(false);
  const [perCompraAtualizar, setPermCompraAtualizar] = useState(false);
  const [perCompraAdicionar, setPermCompraAdicionar] = useState(false);

  const [perIaAdicionar, setPermIaAdicionar] =
    useState(false);

  const permissions = {
    per_usuario_visualizar: {
      active: perUsuarioVisualizar,
      id: 13
    },
    per_usuario_adicionar: {
      active: perUsuarioAdicionar,
      id: 26
    },
    per_usuario_editar: {
      active: perUsuarioEditar,
      id: 14
    },
    per_catalogo_visualizar: {
      active: perCatalogoVisualizar,
      id: 10
    },
    per_catalogo_adicionar: {
      active: perCatalogoAdicionar,
      id: 27
    },
    per_catalogo_editar: {
      active: perCatalogoEditar,
      id: 12
    },
    per_extrato_visualizar: {
      active: perExtratoVisualizar,
      id: 28
    },
    per_extrato_editar: {
      active: perExtratoAtualizar,
      id: 15
    },
    per_extrato_adicionar: {
      active: perExtratoAdicionar,
      id: 32
    },
    per_lotes_visualizar: {
      active: perLotesVisualizar,
      id: 29
    },
    per_lotes_editar: {
      active: perLotesEditar,
      id: 30
    },
    per_lotes_adicionar: {
      active: perLotesAdicionar,
      id: 33
    },
    per_parceiro_visualizar: {
      active: perParceiroVisualizar,
      id: 31
    },
    per_parceiro_adicionar: {
      active: perParceiroAdicionar,
      id: 35
    },
    per_parceiro_atualizar: {
      active: perParceiroAtualizar,
      id: 34
    },
    per_configuracao_visualizar: {
      active: perConfiguracaoVisualizar,
      id: 16
    },
    per_configuracao_editar: {
      active: perConfiguracaoAtualizar,
      id: 36
    },
    per_compra_editar: {
      active: perCompraAtualizar,
      id: 37
    },
    per_compra_adicionar: {
      active: perCompraAdicionar,
      id: 38
    },
    per_compra_visualizar: {
      active: perCompraVisualizar,
      id: 39
    },
    per_ia_visualizar: {
      active: perIaAdicionar,
      id: 40
    }
  };

  const [tipoAdmin, setTipoAdmin] = useState(true);
  const [tipoRecebimento, setTipoRecebimento] = useState(false);
  const [tipoAnalisador, setTipoAnalisador] = useState(false);
  const [tipoParceiro, setTipoParceiro] = useState(false);
  const [tipoParceiroPrincipal, setTipoParceiroPrincipal] = useState(false);

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCPF] = useState('');
  const [celular, setCelular] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [senha, setSenha] = useState('');
  const [senha2, setSenha2] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  // Parceiro State / Parceiro Principal
  const [parceiroPrincipalId, setParceiroPrincipalId] = useState('');
  const [userCodigo, setUserCodigo] = useState('');
  const [userLimite, setUserLimite] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [enderecoNumero, setEnderecoNumero] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [bairro, setBairro] = useState('');
  const [peca, setPeca] = useState('');
  const [po, setPo] = useState('');
  const [inox, setInox] = useState('');
  const [filtro, setFiltro] = useState('');
  const [parametro, setParametro] = useState('');
  const [agencia, setAgencia] = useState('');
  const [banco, setBanco] = useState('');
  const [conta, setConta] = useState('');
  const [titular, setTitular] = useState('');
  const [cpfDoTitular, setCpfDoTitular] = useState('');
  const [status, setStatus] = useState(1);

  const [statusOpts, setStatusOpts] = useState([
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false }
  ]);

  // const [permissaoPerfil, setPermissao] = useState('');
  // const [tipoPerfil, setTipo] = useState('');
  const [permissoesUser, setPermissoesUser] = useState([]);
  const [viewPerms, setViewPerms] = useState(false);
  const [error, setError] = useState({
    name: {
      error: false
    },
    email: {
      error: false
    },
    cpf: {
      error: false
    },
    celular: {
      error: false
    },
    nascimento: {
      error: false
    }
  });

  const [showConfirmaModal, setShowConfirmaModal] = useState(false)

  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isViewUserModalOpen, setIsViewUserModalOpen] = useState(false);
  const [isSearchUsuarioModalOpen, setIsSearchUsuarioModalOpen] =
    useState(false);
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();

  const [userName, setUserName] = useState('');
  const [userType, setUserType] = useState('admin');

  const { createUsuario, isLoading, usuarioListaPorTipo, getUsuariosPorTipo } =
    useUsuario();

  useEffect(() => {
    getUsuariosPorTipo(5, false);
  }, []);

  const dadosUsuarioFormatado = usuarioListaPorTipo.map((usr) => {
    return { label: usr.id + ' - ' + usr.nome, value: usr.id };
  });

  let navigate = useNavigate();

  const handleEditRadio = (userType, setUserTypeInputCheck) => {
    setUserType(userType);
    setUserTypeInputCheck();
  };

  const status_lista = {
    1: 'Ativo',
    2: 'Inativo'
  };

  function optionsStatus() {
    var es = Object.entries(status_lista);
    return es.map((item) => (
      <option key={`status${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_usuario_visualizar') == false &&
    permissaoPerfil.includes('per_usuario_adicionar') == false &&
    permissaoPerfil.includes('per_usuario_editar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }

  const confereCampos = (type) => {
    if (type == 'name') {
      if (!nome) {
        setError((anterior) => {
          return {
            ...anterior,
            name: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            name: {
              error: false
            }
          };
        });
      }
    }
    if (type == 'email') {
      if (!email) {
        setError((anterior) => {
          return {
            ...anterior,
            email: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            email: {
              error: false
            }
          };
        });
      }
    }
    if (type == 'cpf') {
      if (!cpf) {
        setError((anterior) => {
          return {
            ...anterior,
            cpf: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            cpf: {
              error: false
            }
          };
        });
      }
    }
    if (type == 'celular') {
      if (!celular) {
        setError((anterior) => {
          return {
            ...anterior,
            celular: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            celular: {
              error: false
            }
          };
        });
      }
    }
    if (type == 'nascimento') {
      if (!nascimento) {
        setError((anterior) => {
          return {
            ...anterior,
            nascimento: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            nascimento: {
              error: false
            }
          };
        });
      }
    }
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const handlePasswordVisibility2 = () => setShowPassword2(!showPassword2);

  const handleSubmit = async () => {

    if (senha != senha2) {
      Alert.error('As senhas não conferem');
      return;
    }

    const tipo =
      userType === 'admin'
        ? 1
        : userType === 'recebimento'
          ? 2
          : userType === 'analisador'
            ? 3
            : userType === 'parceiro'
              ? 4
              : userType === 'parceiro principal'
                ? 5
                : null;

    const data = {
      nome,
      cpf,
      email,
      telefone,
      celular,
      data_nascimento: nascimento,
      tipo,
      senha,
      status: status ? 1 : 2
    };

    if (tipo === 5 || tipo === 4) {
      data.peca = peca;
      data.po = po;
      data.inox = inox;
      data.filtro = filtro;
      data.banco = banco;
      data.titular = titular;
      data.titularCpf = cpfDoTitular;
      data.agencia = agencia;
      data.conta = conta;
      data.logradouro = logradouro;
      data.numero = enderecoNumero;
      data.bairro = bairro;
      data.cidade = cidade;
      data.estado = estado;
      data.codigo = userCodigo;
      data.limite = userLimite;
      data.principal = parceiroPrincipalId;
      data.parametro = parametro;
    }

    if (tipo === 2 || tipo === 3) {
      let permissionsKeys = Object.keys(permissions);
      let permissionsToSave = [];
      for (let permissionKey of permissionsKeys) {
        if (permissions[permissionKey].active)
          permissionsToSave.push(permissions[permissionKey].id);
      }
      if (permissionsToSave.length != 0) {
        data.permissions = permissionsToSave;
      }
      data.permissao = '';
    }

    await createUsuario(data, () => navigate(-1));
    setShowConfirmaModal(false);
  };

  return (
    <>
      {loadingPer || isLoading ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : null}
      <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
        <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
          <Sidebar activePage={'2'} />
        </Sidenav>
        <Container onSubmit={handleSubmit}>
          <Header style={{ minHeight: '2.43rem' }}>
            <Topbar
              pageTitle={'Novo Usuário'}
              // onOpenSearchUsuarioModal={handleOpenSearchUsuarioModal}
              viewUser={viewUser}
              username={userName}
            />
          </Header>
          <Content>
            <>
              <S.Text fontWeight="700">Tipo de Usuário</S.Text>
              <S.DivRadio>
                <S.Radio
                  name="userType"
                  type="radio"
                  checked={tipoAdmin}
                  onChange={() =>
                    handleEditRadio('admin', () => {
                      setTipoAdmin(true);
                      setTipoAnalisador(false);
                      setTipoParceiro(false);
                      setTipoParceiroPrincipal(false);
                      setTipoRecebimento(false);
                    })
                  }
                />{' '}
                <S.Text fontSize="18px">Administrador</S.Text>
                <S.Radio
                  name="userType"
                  type="radio"
                  checked={tipoRecebimento}
                  onChange={() =>
                    handleEditRadio('recebimento', () => {
                      setTipoAdmin(false);
                      setTipoAnalisador(false);
                      setTipoParceiro(false);
                      setTipoParceiroPrincipal(false);
                      setTipoRecebimento(true);
                    })
                  }
                />{' '}
                <S.Text fontSize="18px">Recebimento</S.Text>
                <S.Radio
                  name="userType"
                  type="radio"
                  checked={tipoAnalisador}
                  onChange={() =>
                    handleEditRadio('analisador', () => {
                      setTipoAdmin(false);
                      setTipoAnalisador(true);
                      setTipoParceiro(false);
                      setTipoParceiroPrincipal(false);
                      setTipoRecebimento(false);
                    })
                  }
                />{' '}
                <S.Text fontSize="18px">Analisador</S.Text>
                <S.Radio
                  name="userType"
                  type="radio"
                  checked={tipoParceiro}
                  onChange={() =>
                    handleEditRadio('parceiro', () => {
                      setTipoAdmin(false);
                      setTipoAnalisador(false);
                      setTipoParceiro(true);
                      setTipoParceiroPrincipal(false);
                      setTipoRecebimento(false);
                    })
                  }
                />{' '}
                <S.Text fontSize="18px">Parceiro</S.Text>
                <S.Radio
                  name="userType"
                  type="radio"
                  checked={tipoParceiroPrincipal}
                  onChange={() =>
                    handleEditRadio('parceiro principal', () => {
                      setTipoAdmin(false);
                      setTipoAnalisador(false);
                      setTipoParceiro(false);
                      setTipoParceiroPrincipal(true);
                      setTipoRecebimento(false);
                    })
                  }
                />{' '}
                <S.Text fontSize="18px">Parceiro Principal</S.Text>
              </S.DivRadio>

              <S.Text fontWeight="700">Dados Básicos</S.Text>

              <form>
                <FormControl isRequired>
                  <S.DivInput>
                    <S.InputUser
                      placeholder="Nome"
                      required
                      name="nome"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                      onBlur={() => confereCampos('name')}
                      width="328px"
                    />
                    {error.name.error && (
                      <h1 style={{ color: '#BE123C' }}>Campo obrigatório</h1>
                    )}
                  </S.DivInput>
                </FormControl>
                <FormControl isRequired>
                  <S.DivInput>
                    <S.InputUser
                      placeholder="Email"
                      required
                      name="email"
                      value={email}
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      width="328px"
                      onBlur={() => confereCampos('email')}
                    />

                    {error.email.error && (
                      <h1 style={{ color: '#BE123C' }}>Campo obrigatório</h1>
                    )}
                  </S.DivInput>
                </FormControl>
                <S.DivInput display="flex">
                  <FormControl isRequired>
                    <S.InputMask
                      type="text"
                      onBlur={() => confereCampos('cpf')}
                      width="156px"
                      mask="999.999.999-99"
                      placeholder="CPF"
                      required
                      onChange={(event) => setCPF(event.target.value)}
                      value={cpf}
                    />
                    {error.cpf.error && (
                      <h1 style={{ color: '#BE123C' }}>Campo obrigatório</h1>
                    )}
                  </FormControl>
                  <FormControl isRequired>
                    <S.InputMask
                      type="text"
                      width="156px"
                      onBlur={() => confereCampos('celular')}
                      mask="(99) 99999-9999"
                      placeholder="Celular"
                      required
                      value={celular}
                      onChange={(event) => setCelular(event.target.value)}
                    />
                    {error.celular.error && (
                      <h1 style={{ color: '#BE123C' }}>Campo obrigatório</h1>
                    )}
                  </FormControl>
                  <S.InputMask
                    type="text"
                    width="156px"
                    mask="(99) 9999-9999"
                    placeholder="Telefone"
                    onBlur={() => confereCampos('telefone')}
                    onChange={(event) => setTelefone(event.target.value)}
                    value={telefone}
                  />
                  <FormControl isRequired>
                    <S.InputMask
                      placeholder="Data de nascimento"
                      required
                      name="nascimento"
                      type="text"
                      mask="99/99/9999"
                      width="156px"
                      value={nascimento}
                      onChange={(e) => setNascimento(e.target.value)}
                      onBlur={() => confereCampos('nascimento')}
                    />
                    {error.nascimento.error && (
                      <h1 style={{ color: '#BE123C' }}>Campo obrigatório</h1>
                    )}
                  </FormControl>
                </S.DivInput>

                {(userType === 'recebimento' ||
                  userType === 'analisador') && (
                    <>
                      <S.Text fontWeight="700">Permissões</S.Text>

                      <S.DivLabel>
                        <S.Label>
                          <S.SubText>Usuários</S.SubText>
                          <S.Check
                            value={perUsuarioVisualizar}
                            type="checkbox"
                            checked={perUsuarioVisualizar}
                            onChange={() =>
                              setPermUsuarioVisualizar(!perUsuarioVisualizar)
                            }
                          />
                          <S.Span />
                          Visualizar
                        </S.Label>

                        <S.Label>
                          <S.Check
                            value={perUsuarioAdicionar}
                            type="checkbox"
                            checked={perUsuarioAdicionar}
                            onChange={() =>
                              setPermUsuarioAdicionar(!perUsuarioAdicionar)
                            }
                          />
                          <S.Span />
                          Adicionar
                        </S.Label>

                        <S.Label>
                          <S.Check
                            value={perUsuarioEditar}
                            type="checkbox"
                            checked={perUsuarioEditar}
                            onChange={() =>
                              setPermUsuarioEditar(!perUsuarioEditar)
                            }
                          />
                          <S.Span />
                          Atualizar
                        </S.Label>

                        <S.Label style={{ marginLeft: 16 }}>
                          <S.SubText>Catálogo</S.SubText>
                          <S.Check
                            value={perCatalogoVisualizar}
                            type="checkbox"
                            checked={perCatalogoVisualizar}
                            onChange={() =>
                              setPermCatalogoVisualizar(!perCatalogoVisualizar)
                            }
                          />
                          <S.Span />
                          Visualizar
                        </S.Label>

                        <S.Label>
                          <S.Check
                            value={perCatalogoAdicionar}
                            type="checkbox"
                            checked={perCatalogoAdicionar}
                            onChange={() =>
                              setPermCatalogoAdicionar(!perCatalogoAdicionar)
                            }
                          />
                          <S.Span />
                          Adicionar
                        </S.Label>

                        <S.Label>
                          <S.Check
                            value={perCatalogoEditar}
                            type="checkbox"
                            checked={perCatalogoEditar}
                            onChange={() =>
                              setPermCatalogoEditar(!perCatalogoEditar)
                            }
                          />
                          <S.Span />
                          Atualizar
                        </S.Label>
                      </S.DivLabel>

                      <S.DivLabel>
                        <S.Label>
                          <S.SubText>Extrato</S.SubText>
                          <S.Check
                            value={perExtratoVisualizar}
                            type="checkbox"
                            checked={perExtratoVisualizar}
                            onChange={() =>
                              setPermExtratoVisualizar(!perExtratoVisualizar)
                            }
                          />
                          <S.Span />
                          Visualizar
                        </S.Label>

                        <S.Label>
                          <S.Check
                            value={perExtratoAdicionar}
                            type="checkbox"
                            checked={perExtratoAdicionar}
                            onChange={() =>
                              setPermExtratoAdicionar(!perExtratoAdicionar)
                            }
                          />
                          <S.Span />
                          Adicionar
                        </S.Label>
                        <S.Label>
                          <S.Check
                            value={perExtratoAtualizar}
                            type="checkbox"
                            checked={perExtratoAtualizar}
                            onChange={() =>
                              setPermExtratoAtualizar(!perExtratoAtualizar)
                            }
                          />
                          <S.Span />
                          Atualizar
                        </S.Label>
                        <S.Label style={{ marginLeft: 16 }}>
                          <S.SubText>Lotes</S.SubText>
                          <S.Check
                            value={perLotesVisualizar}
                            type="checkbox"
                            checked={perLotesVisualizar}
                            onChange={() =>
                              setPermLotesVisualizar(!perLotesVisualizar)
                            }
                          />
                          <S.Span />
                          Visualizar
                        </S.Label>

                        <S.Label>
                          <S.Check
                            value={perLotesAdicionar}
                            type="checkbox"
                            checked={perLotesAdicionar}
                            onChange={() =>
                              setPermLotesAdicionar(!perLotesAdicionar)
                            }
                          />
                          <S.Span />
                          Adicionar
                        </S.Label>

                        <S.Label>
                          <S.Check
                            value={perLotesEditar}
                            type="checkbox"
                            checked={perLotesEditar}
                            onChange={() => setPermLotesEditar(!perLotesEditar)}
                          />
                          <S.Span />
                          Atualizar
                        </S.Label>
                      </S.DivLabel>
                      <S.DivLabel>
                        <S.Label>
                          <S.SubText>Parceiros</S.SubText>
                          <S.Check
                            value={perParceiroVisualizar}
                            type="checkbox"
                            checked={perParceiroVisualizar}
                            onChange={() =>
                              setPermParceiroVisualizar(!perParceiroVisualizar)
                            }
                          />
                          <S.Span />
                          Visualizar
                        </S.Label>

                        <S.Label>
                          <S.Check
                            value={perParceiroAdicionar}
                            type="checkbox"
                            checked={perParceiroAdicionar}
                            onChange={() =>
                              setPermParceiroAdicionar(!perParceiroAdicionar)
                            }
                          />
                          <S.Span />
                          Adicionar
                        </S.Label>

                        <S.Label>
                          <S.Check
                            value={perParceiroAtualizar}
                            type="checkbox"
                            checked={perParceiroAtualizar}
                            onChange={() =>
                              setPermParceiroAtualizar(!perParceiroAtualizar)
                            }
                          />
                          Atualizar
                        </S.Label>
                        <S.Label style={{ marginLeft: 16 }}>
                          <S.SubText>Configuração</S.SubText>
                          <S.Check
                            value={perConfiguracaoVisualizar}
                            type="checkbox"
                            checked={perConfiguracaoVisualizar}
                            onChange={() =>
                              setPermConfiguracaoVisualizar(
                                !perConfiguracaoVisualizar
                              )
                            }
                          />
                          <S.Span />
                          Visualizar
                        </S.Label>
                        <S.Label>
                          <S.Check
                            value={perConfiguracaoAtualizar}
                            type="checkbox"
                            checked={perConfiguracaoAtualizar}
                            onChange={() =>
                              setPermConfiguracaoAtualizar(
                                !perConfiguracaoAtualizar
                              )
                            }
                          />
                          <S.Span />
                          Atualizar
                        </S.Label>
                      </S.DivLabel>
                      <S.DivLabel>
                        <S.Label>
                          <S.SubText>Compra</S.SubText>
                          <S.Check
                            value={perCompraVisualizar}
                            type="checkbox"
                            checked={perCompraVisualizar}
                            onChange={() =>
                              setPermCompraVisualizar(!perCompraVisualizar)
                            }
                          />
                          <S.Span />
                          Visualizar
                        </S.Label>
                        {/* <S.Label>
                          <S.Check
                            value={perCompraAdicionar}
                            type="checkbox"
                            checked={perCompraAdicionar}
                            onChange={() =>
                              setPermCompraAdicionar(!perCompraAdicionar)
                            }
                          />
                          <S.Span />
                          Adicionar
                        </S.Label> */}
                        <S.Label>
                          <S.Check
                            value={perCompraAtualizar}
                            type="checkbox"
                            checked={perCompraAtualizar}
                            onChange={() =>
                              setPermCompraAtualizar(!perCompraAtualizar)
                            }
                          />
                          <S.Span />
                          Atualizar
                        </S.Label>

                      </S.DivLabel>
                      <S.DivLabel>
                        <S.Label >
                          <S.SubText>IA</S.SubText>
                          <S.Check
                            value={perIaAdicionar}
                            type="checkbox"
                            checked={perIaAdicionar}
                            onChange={() =>
                              setPermIaAdicionar(
                                !perIaAdicionar
                              )
                            }
                          />
                          <S.Span />
                          Adicionar
                        </S.Label>
                      </S.DivLabel>
                    </>
                  )}
                {userType === 'parceiro' && (
                  <>
                    <S.Text>Parceiro Principal</S.Text>
                    <S.DivInput>
                      <SelectPicker
                        data={dadosUsuarioFormatado}
                        style={{
                          position: 'relative',
                          width: 328,
                          height: 34,
                          marginTop: 16,
                          color: globalStyle.colors.GRAY100
                        }}
                        backgroundc="transparent"
                        size="md"
                        menuStyle={{
                          zIndex: 10000,
                          height: 250,
                          color: globalStyle.colors.GRAY100
                        }}
                        placeholder="Parceiro Principal"
                        block
                        preventOverflow={true}
                        onChange={(val) => setParceiroPrincipalId(val)}
                      ></SelectPicker>
                    </S.DivInput>
                  </>
                )}
                {(userType === 'parceiro' ||
                  userType === 'parceiro principal') && (
                    <>
                      <S.DivInput display="flex">
                        <FormControl>
                          <S.Text>Código</S.Text>
                          <S.InputUser
                            type="text"
                            placeholder="Código"
                            size="lg"
                            autoComplete="off"
                            width="328px"
                            value={userCodigo}
                            focusBorderColor="gray"
                            onChange={(event) =>
                              setUserCodigo(event.currentTarget.value)
                            }
                          />
                        </FormControl>
                        <FormControl>
                          <S.Text>Limite</S.Text>
                          <S.InputUser
                            type="text"
                            placeholder="Limite"
                            required
                            size="lg"
                            autoComplete="off"
                            width="328px"
                            value={userLimite}
                            focusBorderColor="gray"
                            onChange={(event) =>
                              setUserLimite(
                                formatReal(event.currentTarget.value)
                              )
                            }
                          />
                        </FormControl>
                      </S.DivInput>
                      <S.DivInput>
                        <S.Text fontWeight="700">Endereço</S.Text>
                        <S.DivInput>
                          <S.InputUser
                            placeholder="Logradouro"
                            name="logradouro"
                            width="328px"
                            value={logradouro}
                            onChange={(e) => setLogradouro(e.target.value)}
                          // onBlur={() => confereCampos('cpf')}
                          />
                        </S.DivInput>
                        <S.DivInput display="flex">
                          <FormControl isRequired>
                            <S.NumberFormat
                              placeholder="Numero"
                              name="numero"
                              width="156px"
                              value={enderecoNumero}
                              onChange={(e) =>
                                setEnderecoNumero(e.target.value)
                              }
                            // onBlur={() => confereCampos('cpf')}
                            />
                            <S.InputUser
                              placeholder="Cidade"
                              name="cidade"
                              width="156px"
                              value={cidade}
                              onChange={(e) => setCidade(e.target.value)}
                            // onBlur={() => confereCampos('cpf')}
                            />
                            <S.InputUser
                              placeholder="Estado"
                              name="estado"
                              width="156px"
                              value={estado}
                              onChange={(e) => setEstado(e.target.value)}
                            // onBlur={() => confereCampos('cpf')}
                            />
                          </FormControl>
                        </S.DivInput>
                        <S.DivInput>
                          <S.InputUser
                            placeholder="Bairro"
                            name="bairro"
                            width="328px"
                            value={bairro}
                            onChange={(e) => setBairro(e.target.value)}
                          // onBlur={() => confereCampos('cpf')}
                          />
                        </S.DivInput>
                      </S.DivInput>
                      <S.DivInput>
                        <S.Text fontWeight="700">
                          Peça
                          {(userType === 'parceiro' ||
                            userType === 'parceiro principal') &&
                            ' (%)'}
                        </S.Text>
                        <S.DivInput display="flex">
                          <S.NumberFormat
                            suffix="%"
                            placeholder="Peça (%)"
                            required
                            name="peça"
                            width="156px"
                            value={peca}
                            onChange={(e) =>
                              setPeca(formataCampoPorcentagem(e.target.value))
                            }
                          // onBlur={() => confereCampos('cpf')}
                          />
                          <S.NumberFormat
                            suffix="%"
                            placeholder="PO (%)"
                            required
                            name="po"
                            width="156px"
                            value={po}
                            onChange={(e) =>
                              setPo(formataCampoPorcentagem(e.target.value))
                            }
                          // onBlur={() => confereCampos('cpf')}
                          />
                        </S.DivInput>
                        <S.DivInput display="flex">
                          <S.NumberFormat
                            suffix="%"
                            placeholder="Inox (%)"
                            required
                            name="inox"
                            width="156px"
                            value={inox}
                            onChange={(e) =>
                              setInox(formataCampoPorcentagem(e.target.value))
                            }
                          // onBlur={() => confereCampos('cpf')}
                          />
                          <S.NumberFormat
                            suffix="%"
                            placeholder="Filtro (%)"
                            required
                            name="filtro"
                            width="156px"
                            value={filtro}
                            onChange={(e) =>
                              setFiltro(formataCampoPorcentagem(e.target.value))
                            }
                          // onBlur={() => confereCampos('cpf')}
                          />
                        </S.DivInput>
                        <S.DivInput width="328px">
                          <S.NumberFormat
                            suffix="%"
                            placeholder="Parâmetro (%)"
                            required
                            name="parâmetro"
                            width="328px"
                            value={parametro}
                            onChange={(e) =>
                              setParametro(
                                formataCampoPorcentagem(e.target.value)
                              )
                            }
                          // onBlur={() => confereCampos('cpf')}
                          />
                        </S.DivInput>
                      </S.DivInput>
                      <S.DivInput>
                        <S.Text>Dados Bancários</S.Text>
                        <S.DivInput display="flex">
                          <S.InputUser
                            placeholder="Agência"
                            name="agencia"
                            width="156px"
                            value={agencia}
                            onChange={(e) => setAgencia(e.target.value)}
                          // onBlur={() => confereCampos('cpf')}
                          />
                          <S.InputUser
                            placeholder="Banco"
                            name="banco"
                            width="156px"
                            value={banco}
                            onChange={(e) => setBanco(e.target.value)}
                          // onBlur={() => confereCampos('cpf')}
                          />
                          <S.InputUser
                            placeholder="Conta"
                            name="conta"
                            width="156px"
                            value={conta}
                            onChange={(e) => setConta(e.target.value)}
                          // onBlur={() => confereCampos('cpf')}
                          />
                        </S.DivInput>
                        <S.DivInput display="flex">
                          <S.InputUser
                            placeholder="Titular"
                            name="titular"
                            width="156px"
                            value={titular}
                            onChange={(e) => setTitular(e.target.value)}
                          // onBlur={() => confereCampos('cpf')}
                          />
                          <S.InputMask
                            placeholder="CPF do Titular"
                            name="cpfdotitular"
                            width="156px"
                            mask="999.999.999-99"
                            value={cpfDoTitular}
                            onChange={(e) => setCpfDoTitular(e.target.value)}
                            onBlur={() => confereCampos('cpf')}
                          />
                        </S.DivInput>
                      </S.DivInput>
                    </>
                  )}

                <S.Text fontWeight="700">Status</S.Text>
                {/* <S.DivInput>
                    <SelectPicker
                      data={statusOpts}
                      style={{
                        position: 'relative',
                        width: 156,
                        height: 34,
                        marginTop: 16
                      }}
                      backgroundC="transparent"
                      size="md"
                      menuStyle={{ zIndex: 10000 }}
                      defaultValue={statusOpts[0]}
                      required
                      block
                      preventOverflow={true}
                      onChange={(val) => setStatus(val)}
                    ></SelectPicker>
                  </S.DivInput> */}

                <S.DivStatus className="card-form">
                  <FormGroup>
                    <S.SelectStatus
                      placeholder="Tipo"
                      required
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                    >
                      {optionsStatus()}
                    </S.SelectStatus>
                  </FormGroup>
                </S.DivStatus>

                <S.Text fontWeight="700">Senha</S.Text>

                <S.DivInput>
                  <FormControl isRequired>
                    <S.InputGroupS width="328px">
                      <S.DivInput>
                        <S.InputUser
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Senha"
                          required
                          size="lg"
                          autoComplete="off"
                          width="328px"
                          value={senha}
                          focusBorderColor="gray"
                          onChange={(event) =>
                            setSenha(event.currentTarget.value)
                          }
                        />
                        <InputRightElement width="3rem">
                          <Botao
                            h="1.5rem"
                            size="lg"
                            onClick={handlePasswordVisibility}
                            style={{
                              backgroundColor: 'transparent',
                              color: globalStyle.colors.GRAY400,
                              top: '20px'
                            }}
                          >
                            {showPassword ? (
                              <Icone name="view" />
                            ) : (
                              <Icone name="view-off" />
                            )}
                          </Botao>
                        </InputRightElement>
                      </S.DivInput>
                    </S.InputGroupS>
                  </FormControl>
                </S.DivInput>
                <S.DivInput>
                  <FormControl isRequired>
                    <S.InputGroupS width="328px">
                      <S.DivInput>
                        <S.InputUser
                          type={showPassword2 ? 'text' : 'password'}
                          placeholder="Confirme a senha"
                          required
                          width="328px"
                          size="lg"
                          value={senha2}
                          autoComplete="off"
                          focusBorderColor="gray"
                          onChange={(event) =>
                            setSenha2(event.currentTarget.value)
                          }
                        />
                        <InputRightElement width="3rem">
                          <Botao
                            h="1.5rem"
                            size="lg"
                            onClick={handlePasswordVisibility2}
                            style={{
                              backgroundColor: 'transparent',
                              color: globalStyle.colors.GRAY400,
                              top: '20px'
                            }}
                          >
                            {showPassword2 ? (
                              <Icone name="view" />
                            ) : (
                              <Icone name="view-off" />
                            )}
                          </Botao>
                        </InputRightElement>
                      </S.DivInput>
                    </S.InputGroupS>
                  </FormControl>
                </S.DivInput>

                <Button
                  text="CANCELAR"
                  padding="0"
                  color='white'
                  backGroundColor={globalStyle.colors.RED500}
                  fontSize="16px"
                  marginTop="15px"
                  marginRight="20px"
                  width="132px"
                  height="40px"
                  tooltip="Cancelar"
                  onClick={() => navigate(-1)}
                />

                <Button
                  text="SALVAR"
                  padding="0"
                  fontSize="16px"
                  marginTop="15px"
                  color='white'
                  backGroundColor={globalStyle.colors.GRAY100}
                  width="132px"
                  height="40px"
                  tooltip="Salvar"
                  // type="submit"
                  onClick={() => setShowConfirmaModal(true)}
                // style={{
                //   opacity: 0.5
                // }}
                >
                  {isLoading ? (
                    <CircularProgress
                      isIndeterminate
                      size="24px"
                      color="red"
                    />
                  ) : (
                    'ATUALIZAR'
                  )}
                </Button>
              </form>
            </>
          </Content>
        </Container>
      </Container>
      <ConfirmaModal
        title="Criar Usuário"
        show={showConfirmaModal}
        setShow={setShowConfirmaModal}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto="Criar"
        submitFn={handleSubmit}
      />
    </>
  );
}
