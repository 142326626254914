import React from 'react';
import { ButtonContainer, Container, DivRadio, LabelContainer, Radio, Row, Sub, Text } from './styled';
import Input from '../UI/Input';
import Table from './Table';
import Button from '../UI/Button';
import globalStyle from '../../styles/globalStyle';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadImages from '../UploadImages';
import { useExtrato } from '../../hooks/extrato';
import { useCatalogo } from '../../hooks/catalogo';
import { Switch } from '@chakra-ui/react'
import ConfirmaModal from '../ConfirmaModal';

export default function ProdutoForm({ data, productId, tipo: tipoContainer }) {
    const { EditCatalogo, createCatalogo } = useCatalogo()
    const tipo_lista = {
        1: 'catalisador',
        2: 'pesavel',
        // 3: 'Inox',
        // 4: 'Filtro'
    };
    const navigate = useNavigate()

    const [showConfirmaModal, setShowConfirmaModal] = useState(false)

    const [marca, setMarca] = useState(data?.marca ? data.marca : '');
    const [modelo, setModelo] = useState(data?.modelo ? data.modelo : '');
    const [codigo1, setCodigo1] = useState(data?.codigo1 ? data.codigo1 : '');
    const [codigo2, setCodigo2] = useState(data?.codigo2 ? data.codigo2 : '');
    const [codigo3, setCodigo3] = useState(data?.codigo3 ? data.codigo3 : '');
    const [classificacao, setClassificacao] = useState(data?.targeting ? data.targeting : '');
    const [tipo, setTipo] = useState(data?.tipo ? tipo_lista[data.tipo] : '');
    const [definicao, setDefinicao] = useState(data?.definition ? data.definition : '');
    const [desconto, setDesconto] = useState(data?.desconto ? data?.desconto : '00');

    // const [valor, setValor] = useState(data?.preco?.br ? data.preco.br : '');
    const [observacao, setObservacao] = useState(data?.observacao ? data.observacao : '');
    const [status, setStatus] = useState(data?.status ? data.status : 2);
    const [fotos, setFotos] = useState({});
    const [teor, setTeor] = useState({});

    const handleSalvar = async () => {
        const tipoParaNumero = tipo === 'catalisador' ? 1 : 2;
        const data = {
            marca,
            modelo,
            codigo1,
            codigo2,
            codigo3,
            definition: definicao,
            targeting: classificacao,
            status,
            observacao,
            tipo: tipoParaNumero,
            photo_excluir: fotos.fotosExcluir,
            desconto,
            ...teor
        }

        if (tipoContainer == 'editar') {
            const formData = new FormData();

            formData.append('body', JSON.stringify(data));

            fotos.fotosUpload.forEach(foto => {
                formData.append('photos', foto)
            })

            await EditCatalogo(formData, productId, 1, navigate)
            setShowConfirmaModal(false)
        } else {
            delete data.photo_excluir;
            const formData = new FormData();

            formData.append('body', JSON.stringify(data));

            fotos.fotosUpload.forEach(foto => {
                formData.append('photos', foto)
            })

            await createCatalogo(formData, navigate);
            setShowConfirmaModal(false)
        }
    }

    const handleCancelar = () => {
        navigate(-1)
    }

    const handleDesconto = (value) => {

        if (/[^0-9]/g.test(value) || Number(value) > 100)
            return

        if (value.length >= 2) {
            let valueformat = value;

            if (value[0] == '0') valueformat = value.replace('0', '');

            return setDesconto(valueformat);
        }

        setDesconto('0' + value)
    }

    return (
        <>
            <Container>
                <UploadImages data={data ? data : []} setData={setFotos} />
                <LabelContainer style={{ display: 'flex' }}>
                    <div style={{ width: '705px' }}>
                        <label>Informações Básicas</label>
                    </div>
                    <div>
                        <label>Status</label>
                    </div>
                </LabelContainer>
                <Row>
                    <Input
                        placeholder='Marca'
                        value={marca}
                        onChange={(e) => setMarca(e.target.value)}
                        width={214}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                    <Input
                        placeholder='Modelo'
                        value={modelo}
                        onChange={(e) => setModelo(e.target.value)}
                        width={443}
                        height={34}
                        style={{
                            marginRight: 32,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                    <div className="switch__container">
                        <input id="switch-shadow" className="switch switch--shadow" type="checkbox" checked={status == 1} onChange={(e) => {
                            if (status == 2) {
                                setStatus(1)
                            } else {
                                setStatus(2)
                            }
                        }} />
                        <label htmlFor="switch-shadow"></label>
                    </div>
                </Row>

                <LabelContainer>
                    <label>Códigos</label>
                </LabelContainer>
                <Row>
                    <Input
                        placeholder='Código 1'
                        value={codigo1}
                        onChange={(e) => setCodigo1(e.target.value)}
                        width={214}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                    <Input
                        placeholder='Código 2'
                        value={codigo2}
                        onChange={(e) => setCodigo2(e.target.value)}
                        width={214}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                    <Input
                        placeholder='Código 3'
                        value={codigo3}
                        onChange={(e) => setCodigo3(e.target.value)}
                        width={214}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </Row>

                <div>
                    <LabelContainer>
                        <label>Informações Técnicas</label>
                    </LabelContainer>
                    <div style={{
                        marginBottom: 16
                    }}>
                        <Sub>Classificação</Sub>
                    </div>
                </div>
                <Row>
                    <DivRadio>
                        <Radio
                            name="classificacao"
                            type="radio"
                            checked={classificacao === 'pesavel'}
                            onChange={() => setClassificacao('pesavel')}
                        />
                        {' '}
                        <Text fontSize="18px">Pesáveis</Text>
                        <Radio
                            name="classificacao"
                            type="radio"
                            checked={classificacao === 'catalisador'}
                            onChange={() =>
                                setClassificacao('catalisador')
                            }
                        />{' '}
                        <Text fontSize="18px">Catalisador</Text>
                        <Radio
                            name="classificacao"
                            type="radio"
                            checked={classificacao === 'g51'}
                            onChange={() =>
                                setClassificacao('g51')
                            }
                        />{' '}
                        <Text fontSize="18px">G51</Text>
                        <Radio
                            name="classificacao"
                            type="radio"
                            checked={classificacao === 'corte especial'}
                            onChange={() => setClassificacao('corte especial')}
                        />{' '}
                        <Text fontSize="18px">Corte Especial</Text>
                    </DivRadio>
                </Row>

                <div
                    style={{
                        margin: '16px 0 8px'
                    }}
                >
                    <Sub>
                        Tipo
                    </Sub>
                </div>
                <Row>
                    <DivRadio>
                        <Radio
                            name="produtoTipo"
                            type="radio"
                            checked={tipo === 'pesavel'}
                            onChange={() =>
                                setTipo('pesavel')
                            }
                        />
                        {' '}
                        <Text fontSize="18px">Pesáveis</Text>
                        <Radio
                            name="produtoTipo"
                            type="radio"
                            checked={tipo === 'catalisador'}
                            onChange={() =>
                                setTipo('catalisador')
                            }
                        />{' '}
                        <Text fontSize="18px">Catalisador</Text>
                    </DivRadio>
                </Row>

                <div
                    style={{
                        margin: '16px 0 8px'
                    }}
                >
                    <Sub>
                        Definição
                    </Sub>
                </div>
                <Row>
                    <DivRadio>
                        <Radio
                            name="userType"
                            type="radio"
                            checked={definicao === 'inox'}
                            onChange={() =>
                                setDefinicao('inox')
                            }
                        />
                        {' '}
                        <Text fontSize="18px">Inox</Text>
                        <Radio
                            name="userType"
                            type="radio"
                            checked={definicao === 'flex'}
                            onChange={() =>
                                setDefinicao('flex')
                            }
                        />{' '}
                        <Text fontSize="18px">Flex</Text>
                    </DivRadio>
                </Row>

                <div
                    style={{
                        margin: '16px 0 8px',
                        display: 'flex'
                    }}
                >
                    {/* <div
                        style={{
                            width: 214,
                            marginRight: 16,
                        }}
                    >
                        <Sub>
                            Valor
                        </Sub>
                    </div> */}
                    <Sub>Observação</Sub>
                </div>
                <Row>
                    {/* <Input 
                        placeholder='Valor' 
                        onChange={(e) => setValor(e.target.value)}
                        value={valor}
                        width={214}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    /> */}
                    <Input
                        placeholder='Observação'
                        onChange={(e) => setObservacao(e.target.value)}
                        value={observacao}
                        width={214}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A'
                        }}
                    />
                </Row>

                <LabelContainer>
                    <label>Tabela</label>
                </LabelContainer>
                <Table data={data} setTeor={setTeor} />

                <LabelContainer>
                    <label>Desconto da Peça (%)</label>
                </LabelContainer>
                <Row>
                    <Input
                        placeholder='Desconto'
                        onChange={(e) => handleDesconto(e.target.value)}
                        value={desconto}
                        width={214}
                        height={34}
                        style={{
                            marginRight: 16,
                            background: 'transparent',
                            borderColor: '#71717A',
                            marginBottom: "22px"
                        }}
                    />
                </Row>
                <ButtonContainer>
                    <Button
                        backGroundColor={globalStyle.colors.RED500}
                        onClick={handleCancelar}
                        text="Cancelar"
                        uppercase
                        color='white'
                        width="132px"
                        height="40px"
                    />
                    <Button
                        onClick={() => setShowConfirmaModal(true)}
                        text="Salvar"
                        uppercase
                        color='white'
                        backGroundColor={globalStyle.colors.GRAY100}
                        width="132px"
                        height="40px"
                        style={{
                            marginLeft: 16
                        }}
                    />
                </ButtonContainer>
            </Container>
            <ConfirmaModal
                title={tipoContainer == "novo" ? "Criar Produto" : "Editar Produto"}
                show={showConfirmaModal}
                setShow={setShowConfirmaModal}
                bodyText="Confirmar essa ação ?"
                btnAcaoTexto={tipoContainer == "novo" ? "Criar" : "Editar"}
                submitFn={handleSalvar}
            />
        </>
    )
}   