import React from 'react';
import { Tooltip, Whisper } from 'rsuite';
import styled from 'styled-components';

const Btn = styled.button`
  height: ${(props) => (props.height ? props.height : '3rem')};
  width: ${(props) => (props.width ? props.width : '')};
  ${(props) => (props.uppercase ? `text-transform: uppercase` : '')};
  padding: ${(props) => (props.padding ? props.padding : '0 2rem')};
  font-size: ${(props) => props.fontSize ? props.fontSize : '1rem'};
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : '400'};
  color: ${(props) => (props.color ? props.color : props.theme.colors.GRAY100)};
  border-color: var(--info-color);
  border-radius: 0.25rem;
  border: none;
  margin-bottom: 1.25rem;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  margin-Top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  background-color: ${(props) =>
    props.backGroundColor
      ? props.backGroundColor
      : props.theme.colors.GREEN500};
  transition: filter 0.2s;
  text-align: center;

  &:hover {
    filter: brightness(0.9);
  }
  &:focus {
    outline: none;
  }
`;

export default function Button(props) {
  return (
      <Btn
        type={props.type ? props.type : 'button'}
        onClick={props.onClick}
        height={props.height}
        backGroundColor={props.backGroundColor}
        color={props.color}
        width={props.width}
        style={props.style}
        fontWeight={props.fontWeight}
        fontSize={props.fontSize}
        padding={props.padding}
        uppercase={props.uppercase}
        marginRight={props.marginRight}
        marginTop={props.marginTop}
        marginLeft={props.marginLeft}
      >
        {props.text}
      </Btn>
  );
}
