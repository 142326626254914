import React from "react";
import { Container, Header, Sidebar as Sidenav, Content, Loader } from 'rsuite';
import { useNavigate, useParams, useRoutes, useNavigation, useLocation } from 'react-router-dom';

import { CatalogoProvider, useCatalogo } from "../../hooks/catalogo";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar";
import { usePerfil } from "../../hooks/perfil";
import Product from "./Product";
import globalStyle from "../../styles/globalStyle";

export default function DetalheProduto() {
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();

  const { state } = useLocation();

  return (
    <>
      {loadingPer ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : (
        <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
          <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
            <Sidebar activePage={'3'} />
          </Sidenav>
          <Container style={{ width: '80%' }}>
            <Header style={{ minHeight: '3.6rem' }}>
              <Topbar
                detalhesProduto
                productName={state.data.marca}
                pageTitle={'Catálogo'}
              />
            </Header>
            <Content>
              <Product data={state.data} />
            </Content>
          </Container>
        </Container>
      )}
    </>
  )
}