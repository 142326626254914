import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { ParceiroMapUUID } from '../../components/ParceiroMapUUID/index';
import { ParceiroProvider } from '../../hooks/parceiro';
import { Container, Header, Sidebar as Sidenav, Content, Loader } from 'rsuite';
import { usePerfil } from '../../hooks/perfil';
import { Navigate } from 'react-router-dom';
import globalStyle from '../../styles/globalStyle';

export function ParceiroListaMap() {
  let app = 'parceiro';

  const { permissaoPerfil, tipoPerfil, loadingPer, isLoading } = usePerfil();

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_' + app + '_visualizar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }

  return (
    <ParceiroProvider>
      {loadingPer ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : (
        <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
          <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
            <Sidebar activePage={'4'} />
          </Sidenav>
          <Container>
            <Header style={{ minHeight: '3.6rem', zIndex: 9999 }}>
              <Topbar pageTitle={'Parceiros'} parceiroTitle={true} />
            </Header>
            <Content>
              <ParceiroMapUUID />
            </Content>
          </Container>
        </Container>
      )}
    </ParceiroProvider>
  );
}
