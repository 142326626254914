import { Content as ContentUI } from 'rsuite';
import styled from 'styled-components';

export const Content = styled(ContentUI)`
  width: 100%;

@media only screen and (max-width: 1500px) and (min-width: 1331px) {
    width: 1000px;
} 

@media only screen and (max-width: 1330px) {
    width: 900px;
    button {
      height: 50px;
    }
} 
`;

export const ConteudoContainer = styled.div`
    padding: 39px 0 0 65px;
    margin-bottom: 36px;
`

export const ConteudoValores = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ConteudoTopParceiros = styled.div``