import styled from 'styled-components';
import InputMaskUI from '../UI/InputMask';
import { InputGroup } from '@chakra-ui/core';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 5px;
`;

export const Container = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  margin-left: 50px;

  label {
    color: #fff;
  }

  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }

`;

export const ContainerSenha = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  margin-bottom: 50px;

  label {
    color: #ffffff;
  }

  input {
    color: ${(props) => props.theme.colors.GRAY100};
    border: solid 2px ${(props) => props.theme.colors.DARK400};
    background: ${(props) => props.theme.colors.TRANSPARENT};
    height: 50px;
    width: 300px;
    padding: 10px;
  }
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }
`;

export const ContainerCardForm = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .card-form {
    display: grid;
    flex: 1;
    gap: 2rem;
    margin: 0px 1rem;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
      h3 {
        margin-top: 40px;
      }
    }

    .rs-uploader-picture .rs-uploader-trigger-btn {
      width: 150px;
      height: 150px;
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      border-radius: 50%;
      border: solid 2px #ccc;
    }
    .rs-uploader-trigger-btn {
      img {
        object-fit: cover;
      }
    }
    .rs-icon-size-5x,
    .rs-icon-size-5x.rs-icon {
      font-size: 150px;
      height: 0px;
      margin-left: -2px;
      margin-top: -17px;
    }
    .rs-icon-size-5x,
    .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
      margin-top: 0px !important;
    }
  }
`;

export const ButtonImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 2px #ccc;

  img {
    object-fit: cover;
  }
`;

export const TituloTopo = styled.h1`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  color: ${props => props.theme.colors.GRAY900};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-family: 'Lato';
  margin-top: 20px;
`;

export const Text = styled.h2`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  color: ${props => props.theme.colors.GRAY400};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-family: 'Lato';
  padding-top: 1rem;
`;

export const DivInput = styled.div`
  flex: 1;
  display: ${(props) => (props.display ? props.display : '')};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
`;

export const InputUser = styled.input`
  width: ${(props) => (props.width ? props.width : '335px')};
  height: 34px;
  padding-left: 10px;
  padding-bottom: 2px;
  color: ${props => props.theme.colors.GRAY900};
  border: 1px solid #71717a;
  border-radius: 4px;
  background: transparent;
  margin-right: 15px;
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
`;

export const InputMask = styled(InputMaskUI)`
  width: ${(props) => (props.width ? props.width : '328px')};
  height: 34px;
  padding-left: 10px;
  padding-bottom: 2px;
  color: ${props => props.theme.colors.GRAY900};
  border: 1px solid #71717a;
  border-radius: 4px;
  background: transparent;
  margin-right: 16px;
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
`;

export const InputGroupS = styled(InputGroup)`
  /* width: 190px; */
`;