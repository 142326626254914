import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
  width: 100%;
  table {
    width: 100%;
    thead {
      width: 100%;
      height: 32px;
      i {
        margin-left: 12px;
        font-size: 8px;
        pointer-events: none;
      }
    }

    .inputQty {
      background: transparent;
      border: 1px solid #71717A;
      border-radius: 4px;
      padding: 8px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 90px;
      height: 34px;
    }

    .imgColumn {
      width: 10%;
    }

    .nameColumn {
      width: 30%;
    }
    

    .nomeVisuCompraColumn{
      width: 20%;
    }

    .valorColumn {
      width: 10%;
    }

    .codigoColumn {
      width: 10%;
    }

    .dataHoraColumn{
      width: 20%;
    }

    .columnActive {
      color: var(--black);
    }

    .columnID {
      width: 125px;
    }
    .columnDefault {
      color: white;
    }

    .spaceColumn {
      width: 40%;
    }
    .action {
      width: 20%;
      height: 100%;
      color: ${(props) => props.theme.colors.GRAY900};
    }
    th {
      color: ${(props) => props.theme.colors.white};
      font-weight: bold;
      font-family: 'Lato';
      padding: 6px 1rem;
      height: 32px;
      text-align: left;
      line-height: 1.5em;
      max-width: 300px;
      background-color: ${(props) => props.theme.colors.GRAY100};

      @media screen and (max-width: 600px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }
    tbody tr td button {
      color: ${(props) => props.theme.colors.GRAY100};
    }

    tr {
      cursor: pointer;
      text-align: left;
      &:hover {
        filter: brightness(0.9);
      }
    }

    td {
      padding: 1rem 16px;
      max-width: 300px;
      text-align: left;
      border: none;
      background: ${(props) => props.theme.colors.TRANSPARENT};
      font-weight: 400;
      color: ${(props) => props.theme.colors.GRAY900};
      word-wrap: break-word;

      @media screen and (max-width: 600px) {
        &:nth-child(2) {
          display: none;
        }
      }

      &:first-child(1) {
        color: var(--text-title);
      }
    }
    .acoes {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      @media screen and (max-width: 1000px) {
        justify-content: flex-end;
      }
    }
    .ativo {
      vertical-align: middle;
      div {
        margin: 0 auto;
        content: ' ';
        height: 25px;
        width: 30%;
        color: ${(props) => props.theme.colors.GREEN500};
        border-radius: 2px;
        text-align: center;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }

    .inativo {
      vertical-align: middle;
      div {
        margin: 0 auto;
        content: ' ';
        height: 25px;
        width: 30%;
        color: ${(props) => props.theme.colors.RED500};
        border-radius: 2px;
        text-align: center;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
`;

export const Entrada = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 69px;
  background: var(--gray);
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 3px;
  z-index: 400;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const PlusIconContainer = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.GREEN500};
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;
