import styled from "styled-components";

export const Container = styled.div`
    margin-top: 32px;
    margin-right: 0px;
    width: 150px;

    @media (max-width: 1540px) {
        margin-right: 24px;
    }
`