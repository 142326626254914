import { InputGroup } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { Content as ContentUI } from 'rsuite';
import styled from 'styled-components';
import InputMaskUI from '../../components/UI/InputMask';
import NumberFormatUI from '../../components/UI/NumberFormat';

export const Content = styled(ContentUI)(({ theme }) => ({
  backgroundColor: theme.colors.BACKGROUND_PRIMARY,
  marginLeft: '2.43rem'
}));

export const InputGroupS = styled(InputGroup)`
  /* width: 190px; */
`;

export const Text = styled.h2`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  color: ${(props) => props.theme.colors.GRAY900};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-family: 'Lato';
  margin-right: 15px;
  padding-top: 18px;
`;

export const SubText = styled.h3`
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-size: 14px;
  line-height: 125%;
  color: ${(props) => props.theme.colors.GRAY400};
  width: 100px;
  margin-right: 0;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  position: absolute;
`;

export const DivLabel = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 30px;
`;

export const Label = styled.label`
  /* display: flex; */


  input:checked {
    background-color: #e5e5e5;

    color: #fff;
  }

  font-size: 20px;
  color:  ${props => props.theme.colors.GRAY900};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-family: 'Lato';
  margin-right: 15px;
  padding-top: 18px;
`;

export const Span = styled.span``;

export const Check = styled.input`
  margin-right: 10px;
  margin-top: 35px;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid ${props => props.theme.colors.GRAY900};
  background: #404040;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
`;

const checkIcon = styled.svg`
  fill: none;
  stroke: white;
  strokewidth: 2px;
`;

export const DivRadio = styled.div`
  input:checked {
    background-color: #e5e5e5;
  }

  align-items: center;

  display: flex;
`;

export const Radio = styled.input`
  margin-top: 15px;
  margin-right: 15px;
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.GRAY100};
  width: 24px;
  height: 24px;
  border-radius: 50%;

  ::after {
    content: '';
    background: transparent;
    border: solid 4px ${(props) => props.theme.colors.GRAY100};
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
`;

export const ContainerCheck = styled.div`
  flex: 1;
  margin-top: 20px;
  display: flex;
`;

export const DivCheck = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const DivInput = styled.div`
  flex: 1;
  display: ${(props) => (props.display ? props.display : '')};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};

  .rs-picker-default .rs-btn,
  .rs-picker-input .rs-btn,
  .rs-picker-default .rs-picker-toggle,
  .rs-picker-input .rs-picker-toggle {
    background: transparent !important;
  }

  .rs-picker-toggle-value {
    color: ${(props) => props.theme.colors.GRAY100} !important;
  }

  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
    border-color: ${(props) => props.theme.colors.GRAY100};
  }
`;

export const InputMask = styled(InputMaskUI)`
  width: ${(props) => (props.width ? props.width : '328px')};
  height: 34px;
  padding-left: 10px;
  padding-bottom: 2px;
  color: ${(props) => props.theme.colors.GRAY900};
  border: 1px solid #71717a;
  border-radius: 4px;
  background: transparent;
  margin-right: 16px;
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
`;

export const NumberFormat = styled(NumberFormatUI)`
  width: ${(props) => (props.width ? props.width : '328px')};
  height: 34px;
  padding-left: 10px;
  padding-bottom: 2px;
  color: ${props => props.theme.colors.GRAY900};
  border: 1px solid #71717a;
  border-radius: 4px;
  background: transparent;
  margin-right: 16px;
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
`;

export const InputUser = styled.input`
  width: ${(props) => (props.width ? props.width : '328px')};
  height: 34px;
  padding-left: 10px;
  padding-bottom: 2px;
  color: ${(props) => props.theme.colors.GRAY900};
  border: 1px solid #71717a;
  border-radius: 4px;
  background: transparent;
  margin-right: 16px;
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
`;

export const SelectStatus = styled.select`
  width: 155px;
  padding: 0 1.5rem;
  height: 34px;
  border-radius: 0.25rem;
  border: 1px solid #d7d7d7;
  background: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
  color: ${(props) => props.theme.colors.GRAY100};
  font-weight: 400;
  font-size: 1rem;
  &::placeholder {
    color: var(--text-body);
  }
  //todo input que tiver um input acima vai receber margin top
  & + input {
    margin-top: 0.25rem;
  }
`;

export const DivStatus = styled.div`
  width: calc(100% - 20px);
  display: grid;
  flex: 1;
  gap: 2rem;
  margin-top: 15px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0px;
    h3 {
      margin-top: 40px;
    }
  }

  .rs-uploader-picture .rs-uploader-trigger-btn {
    width: 150px;
    height: 150px;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn {
    border-radius: 50%;
    border: solid 2px #ccc;
  }
  .rs-uploader-trigger-btn {
    img {
      object-fit: cover;
    }
  }
  .rs-icon-size-5x,
  .rs-icon-size-5x.rs-icon {
    font-size: 150px;
    height: 0px;
    margin-left: -2px;
    margin-top: -17px;
  }
  .rs-icon-size-5x,
  .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
    margin-top: 0px !important;
  }
  label {
    color: ${(props) => props.theme.colors.GRAY100};
  }
`;

export const Container = styled.div`
  margin-top: 2rem;
  padding-left: 55px;
  padding-right: 20px;

  section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (max-width: 560px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .card-form {
    width: calc(100% - 20px);
    display: grid;
    flex: 1;
    gap: 2rem;
    margin: 0px 1rem;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
      h3 {
        margin-top: 40px;
      }
    }

    .rs-uploader-picture .rs-uploader-trigger-btn {
      width: 150px;
      height: 150px;
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      border-radius: 50%;
      border: solid 2px #ccc;
    }
    .rs-uploader-trigger-btn {
      img {
        object-fit: cover;
      }
    }
    .rs-icon-size-5x,
    .rs-icon-size-5x.rs-icon {
      font-size: 150px;
      height: 0px;
      margin-left: -2px;
      margin-top: -17px;
    }
    .rs-icon-size-5x,
    .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
      margin-top: 0px !important;
    }
    label {
      color: ${(props) => props.theme.colors.GRAY100};
    }
  }

  table {
    width: 100%;
    border-spacing: 0 0.5rem;
    thead {
      i {
        margin-left: 12px;
        font-size: 8px;
        pointer-events: none;
      }
    }
    .columnActive {
      color: var(--black);
    }

    .columnID {
      width: 125px;
    }
    .columnDefault {
      color: ${(props) => props.theme.colors.GRAY900};
    }
    th {
      color: var(--text-body);
      font-weight: 400;
      padding: 15px 2rem;
      text-align: left;
      line-height: 1.5em;
      background-color: ${(props) => props.theme.colors.GRAY100};
      &.status {
        text-align: center;
      }
      @media screen and (max-width: 600px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }

    tr {
      cursor: pointer;
      border: 2px solid #181818;
      &:hover {
        filter: brightness(0.9);
      }
    }

    td {
      padding: 1rem 2rem;
      border: 0;
      background: ${(props) => props.theme.colors.TRANSPARENT};
      font-weight: 400;
      color: var(--text-body);

      @media screen and (max-width: 600px) {
        &:nth-child(2) {
          display: none;
        }
      }

      &:first-child(1) {
        color: var(--text-title);
      }
    }
    .ativo {
      vertical-align: middle;
      div {
        margin: 0 auto;
        content: ' ';
        height: 25px;
        width: 30%;
        color: ${(props) => props.theme.colors.GREEN500};
        border-radius: 2px;
        text-align: center;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }

    .inativo {
      vertical-align: middle;
      div {
        margin: 0 auto;
        content: ' ';
        height: 25px;
        width: 30%;
        color: ${(props) => props.theme.colors.RED500};
        border-radius: 2px;
        text-align: center;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`;
export const InputSearch = styled.input`
  padding: 0 1.5rem;
  height: 3rem;
  border-radius: 0.25rem 0 0 0;
  border: 1px solid #d7d7d7;
  background: #e7e9ee;
  margin-left: 1rem;
  font-weight: 400;
  font-size: 1rem;
  &::placeholder {
    color: var(--text-body);
  }
  @media screen and (max-width: 560px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-left: 0;
  }
`;

export const FilterContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const PlusIconContainer = styled(Link)`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.GREEN500};
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;
