import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 5px;
`;

export const previewImg = styled.div`
  width: 100px;
  height: 100px;
`

export const Container = styled.form`
  width: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  margin-top: 40px;

  label {
    color: #fff;
  }

  input {
    color: ${(props) => props.theme.colors.GRAY900};
    border: solid 2px ${(props) => props.theme.colors.DARK400};
    background: ${(props) => props.theme.colors.TRANSPARENT};
    height: 50px;
    width: 300px;
    padding: 10px;
  }

  input::placeholder {
    color: ${(props) => props.theme.colors.GRAY100};
  }
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }

  h2 {
    width: 100%;
    color: var(--red);
    font-size: 1.5rem;
    margin-bottom: 5px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-top: 20px;
  }

`;

export const ContainerSenha = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  margin-bottom: 50px;

  h2 {
    width: 100%;
    color: var(--red);
    font-size: 1.5rem;
    margin-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 20px;
    padding-top: 20px;
  }

  label {
    color: #ffffff;
  }

  input {
    color: ${(props) => props.theme.colors.GRAY100};
    border: solid 2px ${(props) => props.theme.colors.DARK400};
    background: ${(props) => props.theme.colors.TRANSPARENT};
    height: 50px;
    width: 300px;
    padding: 10px;
  }
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }

  button[type='submit'] {
    width: 100%;
    max-width: 220px;
    padding: 0 1.5rem;
    height: 3rem;
    background: var(--green);
    color: #ffffff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.8rem;
    font-weight: 600;
    margin-left: 18px;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
`;

export const ContainerCardForm = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .card-form {
    display: grid;
    flex: 1;
    gap: 2rem;
    margin: 0px 1rem;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
      h3 {
        margin-top: 40px;
      }
    }

    .rs-uploader-picture .rs-uploader-trigger-btn {
      width: 150px;
      height: 150px;
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      border-radius: 50%;
      border: solid 2px #ccc;
    }
    .rs-uploader-trigger-btn {
      img {
        object-fit: cover;
      }
    }
    .rs-icon-size-5x,
    .rs-icon-size-5x.rs-icon {
      font-size: 150px;
      height: 0px;
      margin-left: -2px;
      margin-top: -17px;
    }
    .rs-icon-size-5x,
    .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
      margin-top: 0px !important;
    }
  }
`;

export const ButtonImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 2px #ccc;

  img {
    object-fit: cover;
  }
`;

export const TituloTopo = styled.h1`
  width: 100%;
  color: var(--red);
  font-size: 1.8rem;
  margin-bottom: 10px;
  border-bottom: 1px solid #d7d7d7;
  padding-left: 20px;
`;

export const BotaoIA = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const FotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  label {
    margin-top: 10px;
    color: ${props => props.theme.colors.GRAY900};
    margin-bottom: 0px;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0px;
    opacity: 0;
    cursor: pointer;
    z-index: 1000;
  }
`;

