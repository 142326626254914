import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { Alert } from 'rsuite';

const CatalogoContext = createContext({});

export const CatalogoProvider = (props) => {
  const [catalogoLista, setCatalogoLista] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [pagination, setPagination] = useState([] || '');
  const [catalogoPorUUID, setCatalogoPorUUID] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [activePage, setPageActive] = useState('');
  const [order, setOrder] = useState('');
  const [direction, setDirection] = useState('');
  const [quantity, setQuantity] = useState('');
  const [catalogoSearch, setCatalogoSearch] = useState('');

  const handleCatalogo = useCallback(async () => {
    setIsLoading(true);

    const buscaAtiva = localStorage.getItem('salvarBusca');
    if (buscaAtiva && buscaAtiva != '') {
      const search = JSON.parse(buscaAtiva);
      setCatalogoSearch(search.codigo || search.marca || search.modelo);
      const response = await api.post(
        '/catalogo/codigo',
        JSON.parse(buscaAtiva)
      );
      const data = [
        {
          count: response.data.count,
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setCatalogoLista(response.data.items);
      setPagination(data);
      setIsLoading(false);
    } else {
      const response = await api.get('/catalogo');
      const data = [
        {
          count: response.data.count,
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setCatalogoLista(response.data.items);
      setPagination(data);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleCatalogo();

    return () => {
      setCatalogoSearch('');
      setCatalogoLista([]);
      setPagination([]);
      setIsLoading(false);
    };
  }, [handleCatalogo]);

  async function buscaRelatorioTodosCatalogos() {
    setIsLoading(true);

    try {
      const response = await api.get(`/catalogo/relatorio`);

      window.location.assign(response.data.url);

      setIsLoading(false);
    } catch (err) {
      console.log(err);

      setIsLoading(false);
    }
  }

  // Paginação
  async function catalogoPage(page, quantidade) {
    try {
      const buscaAtiva = localStorage.getItem('salvarBusca');
      if (buscaAtiva && buscaAtiva != '') {
        setIsLoading(true);

        const response = await api.post(
          `/catalogo/codigo?page=${page}&quantidade=${quantity}&order=${order}&direction=${direction}`,
          JSON.parse(buscaAtiva)
        );

        setCatalogoLista(response.data.items);

        const data = [
          {
            count: response.data.count,
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setIsLoading(false);
        setPagination(data);
      } else {
        setIsLoading(true);

        const response = await api.get(
          `/catalogo?page=${page}&quantidade=${quantidade}&order=${order}&direction=${direction}`
        );

        setCatalogoLista(response.data.items);

        const data = [
          {
            count: response.data.count,
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setIsLoading(false);
        setPagination(data);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  // quantidade
  async function catalogoQuantidade(page, qtd) {
    setQuantity(qtd);
    try {
      const buscaAtiva = localStorage.getItem('salvarBusca');
      if (buscaAtiva && buscaAtiva != '') {
        setIsLoading(true);

        const response = await api.post(
          `/catalogo/codigo?page=${page}&quantidade=${qtd}&order=${order}&direction=${direction}`,
          JSON.parse(buscaAtiva)
        );

        setCatalogoLista(response.data.items);
        const data = [
          {
            count: response.data.count,
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
        setIsLoading(false);
      } else {
        setIsLoading(true);

        const response = await api.get(
          '/catalogo?page=' +
            page +
            '&quantidade=' +
            qtd +
            '&order=' +
            order +
            '&direction=' +
            direction
        );

        setCatalogoLista(response.data.items);
        const data = [
          {
            count: response.data.count,
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  // CATÁLOGO POR UUID

  async function getCatalogoPorUuid(uuid, page) {
    try {
      setIsLoadingEdit(true);
      const response = await api.get('/catalogo/' + uuid);

      setCatalogoPorUUID([response.data]);
      setPageActive(page);
      setIsLoadingEdit(false);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoading(false);
    }
  }

  // Criar OS PRODUTOS
  async function createCatalogo(formData, navigate) {
    try {
      setIsLoadingCreate(true);

      const response = await api.post('/catalogo', formData);

      handleCatalogo();
      Alert.success(response.data.message);
      setIsLoadingCreate(false);

      setTimeout(() => {
        navigate('/catalogo');
      }, 1000);

      return {
        erro: false
      };
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingCreate(false);
      return {
        erro: true
      };
    }
  }

  // Editar OS PRODUTOS
  async function EditCatalogo(
    formData,
    uuid,
    page,
    navigate,
    mensagemManual = null
  ) {
    try {
      setIsLoading(true);

      const response = await api.put('/catalogo/' + uuid, formData);

      await handleCatalogo();

      if (response.data.message) {
        if (mensagemManual) {
          Alert.success(mensagemManual);
        } else {
          Alert.success(response.data.message);
        }
        navigate('/catalogo');
      }

      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response.data.message);

      setIsLoading(false);

      throw new Error(err.response.data.message);
    }
  }
  //Ordenação catálogo
  async function orderCatalogo(column, direction, page) {
    setIsLoading(true);
    setOrder(column);
    setDirection(direction);
    const buscaAtiva = localStorage.getItem('salvarBusca');
    if (buscaAtiva && buscaAtiva != '') {
      const response = await api.post(
        `/catalogo/codigo?order=${column}&direction=${direction}&page=${page}&quantidade=${quantity}`,
        JSON.parse(buscaAtiva)
      );
      const data = [
        {
          count: response.data.count,
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setCatalogoLista(response.data.items);
      setPagination(data);
      setIsLoading(false);
    } else {
      const response = await api.get(
        `/catalogo?order=${column}&direction=${direction}&page=${page}&quantidade=${quantity}`
      );
      const data = [
        {
          count: response.data.count,
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setCatalogoLista(response.data.items);
      setPagination(data);
      setIsLoading(false);
    }
  }

  async function handleDeletar(uuid) {
    setIsLoading(true);
    try {
      const response = await api.post(`/catalogo/${uuid}/excluir`);

      await handleCatalogo();

      setIsLoading(false);

      Alert.success(response.data.message);
    } catch (err) {
      setIsLoading(false);
      console.log('Excluir produto erro: ', err);
    }
  }

  // Pesquisar Catálogo

  async function searchCatalogo(search, Modal) {
    try {
      setIsLoadingSearch(true);

      if (
        search.marca == '' &&
        search.modelo == '' &&
        search.codigo == '' &&
        search.tipo == '' &&
        search.status == '' &&
        search.targeting == '' &&
        search.definition == ''
      ) {
        let keysToRemove = ['buscaAtiva', 'salvarBusca'];

        keysToRemove.forEach((k) => localStorage.removeItem(k));
        handleCatalogo();
      } else {
        const response = await api.post('/catalogo/codigo', search);
        if (response.data.error == true) {
          Alert.error(response.data.message);
        } else {
          localStorage.setItem('buscaAtiva', 'ativa');
          localStorage.setItem('salvarBusca', JSON.stringify(search));

          setCatalogoLista(response.data.items);
          const data = [
            {
              count: response.data.count,
              current_page: response.data.current_page,
              next_page: response.data.next_page,
              prev_page: response.data.prev_page,
              last_page: response.data.last_page,
              total: response.data.total
            }
          ];
          setPagination(data);
        }
      }

      setIsLoadingSearch(false);
      if (Modal != undefined) Modal();
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }
  async function limpaCatalogo() {
    try {
      setIsLoadingSearch(true);

      handleCatalogo();
      let keysToRemove = ['buscaAtiva', 'salvarBusca'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));
      window.location.reload();

      setIsLoadingSearch(false);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  return (
    <CatalogoContext.Provider
      value={{
        isLoading,
        catalogoLista,
        catalogoSearch,
        setCatalogoSearch,
        pagination,
        catalogoPage,
        getCatalogoPorUuid,
        catalogoPorUUID,
        createCatalogo,
        EditCatalogo,
        searchCatalogo,
        isLoadingSearch,
        isLoadingEdit,
        limpaCatalogo,
        activePage,
        catalogoQuantidade,
        isLoadingCreate,
        orderCatalogo,
        handleDeletar,
        buscaRelatorioTodosCatalogos
      }}
    >
      {props.children}
    </CatalogoContext.Provider>
  );
};

export function useCatalogo() {
  const context = useContext(CatalogoContext);

  return context;
}
