import React from 'react';
import { Container, TableHeader, Title } from './styled';
import formatReal from '../../../utils/formatReal';
import globalStyle from '../../../styles/globalStyle';

const Row = ({ data }) => {
    return (
        <tr>
            <td
                style={{
                    paddingLeft: 8
                }}
            >
                {data?.name}
            </td>
            <td
                style={{
                    color: globalStyle.colors.GRAY50
                }}
            >
                R$ {data?.valor_recebido ? formatReal(data?.valor_recebido) : '0,00'}
            </td>
            <td
                style={{
                    color: globalStyle.colors.RED600
                }}
            >
                R$ {data?.valor_entregue ? formatReal(data?.valor_entregue) : '0,00'}
            </td>
            <td
                style={{
                    color: `${data?.delta}`[0] != '-'
                        ? globalStyle.colors.GRAY50
                        : globalStyle.colors.RED600
                }}
            >
                R$ {data?.delta ? formatReal(data?.delta) : '0,00'}
            </td>
        </tr>
    )
}

export function TopParceiros({ data }) {
    return (
        <Container>
            <Title>TOP 10 Parceiros</Title>
            <table>
                <thead>
                    <tr>
                        {
                            tableHeader.map((Header, index) => <Header key={index} />)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map((usuario, index) => <Row data={usuario} key={usuario.user || index} />)
                    }
                </tbody>
            </table>
        </Container>
    )
}

const tableHeader = [
    () =>
        <TableHeader
            width="500px"
            style={{
                padding: '4px 8px'
            }}
        >
            Parceiro
        </TableHeader>,
    () =>
        <TableHeader width="200px">
            Valor Recebido
        </TableHeader>,
    () =>
        <TableHeader width="200px">
            Valor Entregue
        </TableHeader>,
    () =>
        <TableHeader width="200px">
            Delta
        </TableHeader>,
    () =>
        <TableHeader />
]