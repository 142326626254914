import React from 'react';
import globalStyle from '../../styles/globalStyle';

const CatalogIcon = ({ largura, altura, color = globalStyle.colors.GRAY100 }) => {
  return (
    <svg
      width={largura}
      height={altura}
      viewBox="0 0 24 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline', marginRight: 10, marginLeft: 10 }}
    >
      <g clipPath="url(#clip0_2743_7763)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 6.87109C4.30964 6.87109 3.75 7.43074 3.75 8.12109V16.1211C3.75 16.8115 4.30964 17.3711 5 17.3711H19C19.6904 17.3711 20.25 16.8115 20.25 16.1211V8.12109C20.25 7.43074 19.6904 6.87109 19 6.87109H5ZM2.25 8.12109C2.25 6.60231 3.48122 5.37109 5 5.37109H19C20.5188 5.37109 21.75 6.60231 21.75 8.12109V16.1211C21.75 17.6399 20.5188 18.8711 19 18.8711H5C3.48122 18.8711 2.25 17.6399 2.25 16.1211V8.12109ZM12 9.87109C10.7574 9.87109 9.75 10.8785 9.75 12.1211C9.75 13.3637 10.7574 14.3711 12 14.3711C13.2426 14.3711 14.25 13.3637 14.25 12.1211C14.25 10.8785 13.2426 9.87109 12 9.87109ZM8.25 12.1211C8.25 10.05 9.92893 8.37109 12 8.37109C14.0711 8.37109 15.75 10.05 15.75 12.1211C15.75 14.1922 14.0711 15.8711 12 15.8711C9.92893 15.8711 8.25 14.1922 8.25 12.1211ZM5.25 12.1211C5.25 11.7069 5.58579 11.3711 6 11.3711H6.01C6.42421 11.3711 6.76 11.7069 6.76 12.1211C6.76 12.5353 6.42421 12.8711 6.01 12.8711H6C5.58579 12.8711 5.25 12.5353 5.25 12.1211ZM17.25 12.1211C17.25 11.7069 17.5858 11.3711 18 11.3711H18.01C18.4242 11.3711 18.76 11.7069 18.76 12.1211C18.76 12.5353 18.4242 12.8711 18.01 12.8711H18C17.5858 12.8711 17.25 12.5353 17.25 12.1211Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2743_7763">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0 0.121094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CatalogIcon;
