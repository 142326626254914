import styled from 'styled-components';
import Btn from '../UI/Button';

export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`;

export const Container = styled.div`
  margin-top: 2rem;
  padding-left: 20px;

  button {
    height: 2rem;
    padding: 0 1rem;
    font-size: 1rem;
    width: 100px;
    color: #fff;
    border-color: var(--info-color);
    border-radius: 0.25rem;
    border: none;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    &:focus {
      outline: none;
    }
  }
`;
export const Header = styled.header`
  display: flex;
  justify-content: space-between;

  div > p {
    color: ${props => props.theme.colors.GRAY100};
    margin-right: 20px;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 1rem;
  label {
      font-weight: 400;
      white-space: nowrap;
      font-size: 0.9rem;
      display: block;
      color: ${props => props.theme.colors.GRAY900};
  }

  div {
    width: 17%;
    margin-right: 16px;
  }

  select,
  input {
    height: 32px;
    width: 100%;
    background-color: white;
    padding: 0 1.5rem;
    border-radius: 0.25rem;
  }

`

export const ButtonSalvar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.GRAY100};
  color: #fff;
  padding: 5px 40px;
  border-radius: 3px;
  text-transform: uppercase;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonLimpar = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray);
  color: #fff;
  padding: 5px 40px;
  border-radius: 3px;
  text-transform: uppercase;

  &:hover {
    filter: brightness(0.9);
  }
`;