import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: ${(props) => (props.height ? props.height : '3rem')};
  width: ${(props) => (props.width ? props.width : '')};
  padding: ${(props) => (props.padding ? props.padding : '0 .5rem')};
  border-color: var(--info-color);
  margin-bottom: 1.25rem;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  margin-Top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  border-radius: 0.25rem;
  border: none;
  background-color: ${(props) =>
    props.backGroundColor
      ? props.backGroundColor
      : props.theme.colors.GREEN500};
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
  &:focus {
    outline: none;
  }
`

export const Btn = styled.button`
  ${(props) => (props.uppercase ? `text-transform: uppercase` : '')};
  font-size: ${(props) => props.fontSize ? props.fontSize : '1rem'};
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : '400'};
  color: ${(props) => (props.color ? props.color : props.theme.colors.GRAY100)};
`;