import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  padding-left: 20px;
  padding-right: 20px;

  button {
    height: 3rem;
    padding: 0 2rem;
    font-size: 1rem;
    color: #fff;
    background-color: var(--info-color);
    border-color: var(--info-color);
    border-radius: 0.25rem;
    border: none;
    margin-bottom: 1.25rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    &:focus {
      outline: none;
    }
  }

  table {
    width: 100%;
    border-spacing: 0 0.5rem;
    border-radius: 5px;
    @media screen and (max-width: 1000px) {
      border: 0;
    }
    thead {
      @media screen and (max-width: 1000px) {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      i {
        margin-left: 12px;
        font-size: 8px;
        pointer-events: none;
      }
    }
    .columnActive {
      color: var(--black);
    }

    .columnDefault {
      color: var(--background);
    }
    th {
      color: var(--text-body);
      font-weight: 400;
      padding: 15px 2rem;
      text-align: left;
      line-height: 1.5em;
      background-color: #17191b;
      &.status {
        text-align: center;
      }
      @media screen and (max-width: 1000px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }

    tr {
      cursor: pointer;
      border: 2px solid #333;
      &:hover {
        filter: brightness(0.9);
      }
      @media screen and (max-width: 1000px) {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
      }
    }

    td {
      padding: 1rem 1rem;
      border: 0;
      background: var(--shape);
      font-weight: 400;
      color: var(--text-body);
      vertical-align: middle;
      @media screen and (max-width: 1000px) {
        /* &:nth-child(2) {
                 display: none;
             } */
        height: 50px;
        &:before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
        }
        &:last-child {
          border-bottom: 0;
        }
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
      }

      &:first-child(1) {
        color: var(--text-title);
      }
    }
    .acoes {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .ativo {
      vertical-align: middle;
      div {
        margin: 0 auto;
        content: ' ';
        height: 25px;
        width: 8px;
        background: var(--green);
        border-radius: 2px;
      }
    }

    .inativo {
      vertical-align: middle;
      div {
        margin: 0 auto;
        content: ' ';
        height: 25px;
        width: 8px;
        background: var(--red);
        border-radius: 2px;
      }
    }
  }
`;

export const Saida = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--red);
  color: #fff;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 3px;
  z-index: 400;

  &:hover {
    filter: brightness(0.9);
  }
`;
export const Entrada = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green);
  color: #fff;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 3px;
  z-index: 400;

  &:hover {
    filter: brightness(0.9);
  }
`;
export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`;

export const ContainerCardForm = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 10px;
  }
  .del {
    color: ${(props) => props.theme.colors.RED500};
    font-size: 1em;
    line-height: 1.5em;
    vertical-align: middle;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .card-form {
    width: calc(100% - 20px);
    display: grid;
    flex: 1;
    gap: 2rem;
    margin: 0px 10px;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
      h3 {
        margin-top: 40px;
      }
    }

    p {
      color: #fff;
      font-size: 1.5em;
      line-height: 1.5em;
    }

    .rs-uploader-picture .rs-uploader-trigger-btn {
      width: 150px;
      height: 150px;
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      border-radius: 50%;
      border: solid 2px #ccc;
    }
    .rs-uploader-trigger-btn {
      img {
        object-fit: cover;
      }
    }
    .rs-icon-size-5x,
    .rs-icon-size-5x.rs-icon {
      font-size: 150px;
      height: 0px;
      margin-left: -2px;
      margin-top: -17px;
    }
    .rs-icon-size-5x,
    .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
      margin-top: 0px !important;
    }
  }
`;
