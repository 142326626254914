import styled from "styled-components";

export const Container = styled.div`
    height: 160px;
    width: 100%;  
    display: flex;  
    
    label {
        color: ${props => props.theme.colors.GRAY50};
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-family: 'Lato';
        text-align: center;
        display: block;
        cursor: pointer;
        width: 170px;
        border-radius: 4px;
    }
`

export const CarregarContainer = styled.div`
    height: 160px;
    width: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.DARK100};
    border-radius: 4px;
    cursor: pointer;

    div {
        
        margin-bottom: 11.25px;
    }


    input[type="file"] {
        display: none;
    }
`

export const ImagesContainer = styled.div`
    height: 160px;
    width: 100%;
    display: flex;
`

export const ImageContainer = styled.div`
    position: relative;
    height: 160px;
    width: 160px;
    min-width: 160px;
    border-radius: 4px;
    overflow: hidden;
    margin-left: 16px;

    img {
        width: 160px;
        height: 160px;
    }
`