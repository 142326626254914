import { Modal } from 'rsuite';
import styled from 'styled-components';

export const ContainerModal = styled(Modal)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -75px; /* Metade da altura */
    margin-left: -125px; /* Metade da largura */
    .rs-modal-content {
        position: relative;
        background: ${props => props.theme.colors.GRAY50};
        /* width: 256px;
        height: 154px; */

        .rs-modal-header-close {
            color: ${props => props.theme.colors.GRAY800};
        }

        .rs-modal-body {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
`

export const Container = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .rs-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 200px !important;
  }

  h2 {
    width: 100%;
    color: var(--red);
    font-size: 1.7rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #d7d7d7;
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }

  button[type='submit'],
  button {
    width: auto;
    padding: 0 1.5rem;
    height: 2rem;
    color: #ffffff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.8rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
  button {
    margin-left: 15px;
  }
`;

export const ContainerCardForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .card-form {
    display: grid;
    flex: 1;
    gap: 2rem;
    margin: 0px 1rem;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
      h3 {
        margin-top: 40px;
      }
    }
    .rs-form-group {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow-x: hidden;
      p {
        font-size: 1.2em;
        margin-bottom: 20px;
        text-align: center;
        color: ${(props) => props.theme.colors.GRAY100};
      }
    }
  }
`;

export const StatusFilialContainer = styled.div`
  margin: 1rem 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;


export const Title = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.colors.GRAY800};
`

export const BotaoAcaoContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 38px;
    /* margin-top: 15px; */
`