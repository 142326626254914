import React from "react";
import { Container } from "./styled";

// Componente Global da pagina Config
import { Label, Title } from "../styles";

import Input from "../../../components/UI/Input";
import globalStyle from "../../../styles/globalStyle";
import { useConfig } from "../../../hooks/configuracao";

export default function ClassificacaoB2() {
    const { setClassificacaoB2, classificacaoB2 } = useConfig();

    return (
        <Container>
            <Title>
                Classificação B2
            </Title>
            <Label
                style={{
                    margin: '8px 0'
                }}
            >
                Valor
            </Label>
            <Input
                value={classificacaoB2}
                onChange={(e) => setClassificacaoB2(e.target.value)}
                style={{
                    width: 98,
                    height: 34,
                    background: 'transparent',
                    border: `1px solid ${globalStyle.colors.DARK400}`,
                    color: globalStyle.colors.GRAY400
                }}
            />
        </Container>
    )
}