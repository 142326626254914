import React from 'react';
import globalStyle from '../../styles/globalStyle';

const IAIcon = ({ largura, altura, color = globalStyle.colors.GRAY100 }) => {
    return (
        <svg width={largura} height={altura} version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet" style={{ display: 'inline', marginRight: 10, marginLeft: 10 }}>

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill={color} stroke="none">
                <path d="M1855 4786 c-37 -17 -70 -52 -84 -89 -7 -17 -11 -127 -11 -283 l0
       -254 -188 0 c-209 0 -266 -10 -363 -61 -70 -38 -157 -126 -193 -197 -47 -93
       -56 -150 -56 -354 l0 -187 -269 -3 c-254 -3 -271 -4 -297 -24 -53 -39 -69 -71
       -69 -134 0 -63 16 -95 69 -134 26 -20 43 -21 297 -24 l269 -3 0 -159 0 -159
       -269 -3 c-254 -3 -271 -4 -297 -24 -53 -39 -69 -71 -69 -134 0 -63 16 -95 69
       -134 26 -20 43 -21 297 -24 l269 -3 0 -159 0 -159 -269 -3 c-254 -3 -271 -4
       -297 -24 -53 -39 -69 -71 -69 -134 0 -63 16 -95 69 -134 26 -20 43 -21 297
       -24 l269 -3 0 -187 c0 -204 9 -261 56 -354 36 -71 123 -159 193 -197 97 -51
       154 -61 363 -61 l187 0 3 -269 c3 -254 4 -271 24 -297 39 -53 71 -69 134 -69
       63 0 95 16 134 69 20 26 21 43 24 297 l3 269 159 0 159 0 3 -269 c3 -254 4
       -271 24 -297 39 -53 71 -69 134 -69 63 0 95 16 134 69 20 26 21 43 24 297 l3
       269 159 0 159 0 3 -269 c3 -254 4 -271 24 -297 39 -53 71 -69 134 -69 63 0 95
       16 134 69 20 27 21 39 21 406 0 367 -1 379 -21 406 -11 15 -33 37 -48 48 -27
       21 -38 21 -966 26 -928 5 -939 5 -966 26 -15 11 -37 33 -48 48 -21 27 -21 31
       -21 1206 0 1175 0 1179 21 1206 11 15 33 37 48 48 27 21 31 21 1206 21 1175 0
       1179 0 1206 -21 15 -11 37 -33 48 -48 21 -27 21 -34 24 -1179 1 -634 0 -1168
       -4 -1187 -4 -26 -23 -51 -69 -95 -69 -67 -89 -110 -75 -170 26 -115 148 -162
       251 -97 59 39 132 118 163 181 47 91 56 149 56 353 l0 187 269 3 c254 3 271 4
       297 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -26 20 -43 21 -297 24 l-269 3
       0 159 0 159 269 3 c254 3 271 4 297 24 53 39 69 71 69 134 0 63 -16 95 -69
       134 -26 20 -43 21 -297 24 l-269 3 0 159 0 159 269 3 c254 3 271 4 297 24 53
       39 69 71 69 134 0 63 -16 95 -69 134 -26 20 -43 21 -297 24 l-269 3 0 187 c0
       209 -10 266 -61 363 -38 70 -126 157 -197 193 -93 47 -150 56 -354 56 l-187 0
       -3 269 c-3 254 -4 271 -24 297 -39 53 -71 69 -134 69 -63 0 -95 -16 -134 -69
       -20 -26 -21 -43 -24 -297 l-3 -269 -159 0 -159 0 -3 269 c-3 254 -4 271 -24
       297 -39 53 -71 69 -134 69 -63 0 -95 -16 -134 -69 -20 -26 -21 -43 -24 -297
       l-3 -269 -159 0 -159 0 -3 269 c-3 254 -4 271 -24 297 -11 15 -32 37 -46 47
       -34 25 -113 32 -153 13z"/>
                <path d="M1961 3504 c-169 -45 -301 -180 -346 -351 -22 -85 -22 -1101 0 -1186
       45 -173 178 -307 350 -352 88 -22 1102 -22 1190 0 172 45 305 179 350 352 22
       85 22 1101 0 1186 -45 173 -178 307 -350 352 -85 22 -1112 21 -1194 -1z m1165
       -330 c15 -11 37 -33 48 -48 21 -27 21 -38 21 -566 0 -528 0 -539 -21 -566 -11
       -15 -33 -37 -48 -48 -27 -21 -38 -21 -566 -21 -528 0 -539 0 -566 21 -15 11
       -37 33 -48 48 -20 27 -21 40 -24 539 -2 282 0 526 3 544 7 40 49 91 90 109 24
       11 134 13 557 11 514 -2 527 -2 554 -23z"/>
            </g>
        </svg>
    )
}

export default IAIcon;