import { Container, Header, Sidebar as Sidenav, Content } from 'rsuite';
import { PerfilForm } from '../../components/PerfilForm/index';
import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { PerfilProvider } from '../../hooks/perfil';
import globalStyle from '../../styles/globalStyle';

export function Perfil() {
  return (
    <div>
      <PerfilProvider>
        <Container style={{ background: globalStyle.colors.BACKGROUND_PRIMARY }}>
          <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
            <Sidebar activePage={''} />
          </Sidenav>
          <Container>
            <Header style={{ minHeight: '3.6rem' }}>
              <Topbar pageTitle={'Minha Conta'} />
            </Header>
            <Content>
              <PerfilForm />
            </Content>
          </Container>
        </Container>
      </PerfilProvider>
    </div>
  );
}
