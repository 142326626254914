import * as React from "react"

const EnviarIcon = ({ largura, altura, ...props }) => (
  <svg
    width={largura}
    height={altura}
    fill="none"
    viewBox="1 0 16 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.942 1.058a.625.625 0 0 1 .146.654l-5.417 15a1.083 1.083 0 0 1-1.984.06l-2.82-5.64-5.639-2.82a1.083 1.083 0 0 1 .06-1.983l15-5.417a.625.625 0 0 1 .654.146ZM8.094 10.79l2.547 5.095 4.32-11.961-6.867 6.866Zm5.982-7.75L2.116 7.359 7.21 9.906l6.866-6.866ZM1.76 7.182a.15.15 0 0 1 .006.002l-.006-.002Zm9.058 9.058-.002-.005.002.005Z"
      fill="#fff"
    />
  </svg>
)

export default EnviarIcon
