import React from 'react';
import * as S from './style';
import { FormGroup, Uploader, Icon, InputGroup, Alert, Loader } from 'rsuite';
import Input from '../UI/Input';
import Button from '../UI/Button';
import InputMask from '../UI/InputMask';
import { useEffect, useState } from 'react';
import { usePerfil } from '../../hooks/perfil';
import api from '../../services/api';
import ConfirmaModal from '../ConfirmaModal';
import globalStyle from '../../styles/globalStyle';

export function IAForm() {
  const [showConfirmaModal, setShowConfirmaModal] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [photo, setPhoto] = useState('');

  const [platinum, setplatinum] = useState('');
  const [palladium, setpalladium] = useState('');
  const [rhodium, setrhodium] = useState('');
  const [fileList, setFileList] = useState(false);
  const { isLoading, setIsLoading } = usePerfil();

  const [foto, setFoto] = useState('');
  const [foto_upload, setFotoUpload] = useState('');

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onPhotoChange = async (files) => {
    toggleDisable();
    let file = files[0]?.blobFile;
    let base64 = await convertToBase64(file);

    setAvatar(base64);
    setPhoto(file);
  };

  const toggleDisable = () => {
    if (fileList == true) {
      setFileList(false);
    } else {
      setFileList(true);
    }
  };

  const uploadForm = async () => {
    setIsLoading(true);

    try {
      const data = new FormData();

      let dataEnvio = {
        photo: photo,
        body: {
          rhodium: rhodium,
          palladium: palladium,
          platinum: platinum
        }
      };

      data.append('body', JSON.stringify(dataEnvio.body));

      data.append('photo', dataEnvio.photo);

      const url = '/ia/envio-consulta';

      const response = await api.post(url, data);

      setPhoto('');
      setFoto('');
      setpalladium('');
      setrhodium('');
      setplatinum('');
      setShowConfirmaModal(false)
      Alert.success('Consulta enviada com sucesso.');
    } catch (err) {
      setShowConfirmaModal(false)
      Alert.error('Falha ao enviar consulta.');
    }
    setIsLoading(false);
  };

  const onImageChange = (event) => {
    const { files } = event.target;
    setFoto(URL.createObjectURL(files[0]));
    setFotoUpload(files[0]);
    setPhoto(files[0]);
  };

  const handlClearFoto = async () => {
    setFoto('');
    setPhoto('');
    setFotoUpload('');
  };

  return (
    <div>
      {isLoading ? <Loader backdrop content="Carregando..." vertical /> : ''}
      <S.Container onSubmit={uploadForm}>
        <S.ContainerCardForm>
          <div className="card-form" style={{ marginBottom: '2rem' }}>
            <FormGroup>
              <div className="card-form" style={{ marginBottom: '1rem' }}>
                <FormGroup>
                  <S.FotoContainer>
                    {foto ? (
                      <>
                        <InputGroup
                          className="close_search"
                          label="Excluir foto"
                          style={{ border: 'none', justifyContent: 'end' }}
                        >
                          <InputGroup.Button
                            onClick={() => handlClearFoto()}
                            style={{ background: 'transparent' }}
                          >
                            <Icon
                              icon="close"
                              style={{ fontSize: 25, color: 'white' }}
                            />
                          </InputGroup.Button>
                        </InputGroup>
                        <img
                          src={foto}
                          alt="imagem"
                          style={{ width: 250, height: 250 }}
                          onChange={onImageChange}
                        />
                      </>
                    ) : (
                      <>
                        <Icon icon="file-image-o" size="4x" />
                        <Input
                          type="file"
                          required
                          id="upload-button"
                          onChange={onImageChange}
                        />
                        <label>Clique aqui para inserir uma imagem</label>
                      </>
                    )}
                  </S.FotoContainer>
                </FormGroup>
              </div>
            </FormGroup>
          </div>
          <div className="card-form">
            <FormGroup>
              {/* <label>platinum</label> */}
              <InputMask
                type="text"
                required
                name="platinum"
                placeholder="Platinum"
                mask="9.999"
                value={platinum}
                onChange={(event) => setplatinum(event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              {/* <label>palladium</label> */}
              <InputMask
                type="text"
                required
                name="palladium"
                placeholder="Palladium"
                mask="9.999"
                value={palladium}
                onChange={(event) => setpalladium(event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              {/* <label>rhodium</label> */}
              <InputMask
                type="text"
                required
                name="rhodium"
                placeholder="Rhodium"
                mask="9.999"
                value={rhodium}
                onChange={(event) => setrhodium(event.target.value)}
              />
            </FormGroup>
          </div>
        </S.ContainerCardForm>
        {/* <S.BotaoIA> */}
        <Button
          fontSize="16px"
          marginTop="45px"
          marginLeft="16px"
          width="132px"
          height="40px"
          tooltip="Atualizar"
          color='white'
          backGroundColor={globalStyle.colors.GRAY100}
          // type="submit"
          onClick={() => setShowConfirmaModal(true)}
          text={isLoading ? 'Carregando...' : 'Salvar'}
        />
        {/* </S.BotaoIA> */}
      </S.Container>
      <ConfirmaModal
        title="Enviar Consulta"
        show={showConfirmaModal}
        setShow={setShowConfirmaModal}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto="Enviar"
        submitFn={uploadForm}
      />
    </div>
  );
}
