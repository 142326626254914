import React, { useState } from "react";
import { BotaoAcaoContainer, Container, Title } from "./styled";
import { Modal } from "rsuite";
import globalStyle from "../../styles/globalStyle";
import Button from "../UI/Button";

export default function ObservacaoModal({ show, observacao, btnAcaoTexto, setShow, submitFn, isFinished }) {
    const [observacaoData, setObservacaoData] = useState(observacao || '');
    const fechaModal = () => setShow && setShow(false);

    return (
        <Container
            backdrop={'static'}
            size="xs"
            show={show}
            onHide={fechaModal}

        >
            <Modal.Header>
                <Modal.Title>
                    <Title>
                        Observação
                    </Title>
                </Modal.Title>
                <div style={{
                    width: '100%',
                    height: 1,
                    background: '#E5E7EB',
                    position: 'absolute',
                    left: 0,
                    top: 50
                }} />
            </Modal.Header>
            <Modal.Body>
                <div>
                    <textarea
                        onChange={(e) => setObservacaoData(e.target.value)}
                        value={observacaoData}
                        style={{
                            background: 'transparent',
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        {observacao}
                    </textarea>
                </div>
                {!isFinished ? <BotaoAcaoContainer>
                    <Button
                        text="Cancelar"
                        width="100%"
                        height="100%"
                        padding="0"
                        backGroundColor="transparent"
                        color={globalStyle.colors.GRAY500}
                        onClick={fechaModal}
                    />
                    {btnAcaoTexto == 'Cancelar Atualização' || btnAcaoTexto == 'Deletar' || btnAcaoTexto == 'Inativar' ? (
                        <Button
                            text={btnAcaoTexto}
                            width="100%"
                            height="50px"
                            padding="0"
                            backGroundColor={globalStyle.colors.RED500}
                            color={globalStyle.colors.GRAY50}
                            onClick={submitFn}
                        />
                    ) : <Button
                        text={btnAcaoTexto}
                        width="100%"
                        height="100%"
                        padding="0"
                        backGroundColor={globalStyle.colors.GRAY100}
                        color={globalStyle.colors.white}
                        onClick={() => {
                            submitFn(observacaoData);

                            fechaModal();
                        }}
                    />}

                </BotaoAcaoContainer> : (
                    <BotaoAcaoContainer>
                        <Button
                            text="Cancelar"
                            height="100%"
                            padding="0"
                            width='100px'
                            backGroundColor={globalStyle.colors.GRAY100}
                            color={globalStyle.colors.white}
                            onClick={fechaModal}
                        />
                    </BotaoAcaoContainer>
                )}
            </Modal.Body>
        </Container>
    )
}
