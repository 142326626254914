import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  padding-left: 20px;

  button {
    height: 2rem;
    padding: 0 1rem;
    font-size: 1rem;
    width: 100px;
    color: #fff;
    background-color: var(--info-color);
    border-color: var(--info-color);
    border-radius: 0.25rem;
    border: none;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
    &:focus {
      outline: none;
    }
  }
`;

export const HeaderTable = styled.div`
  text-align: right;
  margin-bottom: 1rem;

  div > p {
    margin-right: 20px;
    color: ${props => props.theme.colors.GRAY100};
  }
`
export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .rs-pagination-btn a {
    color: ${(props) => props.theme.colors.GRAY400};
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #fff;
    background-color: #737373;
  }
  .rs-pagination a:hover {
    color: ${(props) => props.theme.colors.GRAY400};
    outline: 1px solid ${(props) => props.theme.colors.GRAY400};
  }
  .rs-table-pagination-start {
    display: none;
  }
`;
