import { useState } from 'react';
import { VerCompraTable } from '../../components/VerCompraTable/index';
import { SearchCompraModal } from '../../components/SearchCompraModal/index';
import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { CompraProvider, useCompra } from '../../hooks/compra';
import { Container, Header, Sidebar as Sidenav, Content, Loader } from 'rsuite';
import { usePerfil } from '../../hooks/perfil';
import { Navigate } from 'react-router-dom';
import { ExtratoProvider } from '../../hooks/extrato';
import globalStyle from '../../styles/globalStyle';

export function VerCompra() {
  let app = 'compra';
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();
  const [isSearchCompraModalOpen, setIsSearchCompraModalOpen] = useState(false);
  const {
    isLoadingVer,
  } = useCompra();

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_' + app + '_editar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }
  function handleOpenSearchCompraModal() {
    setIsSearchCompraModalOpen(true);
  }

  function handleCloseSearchCompraModal() {
    setIsSearchCompraModalOpen(false);
  }

  return (
    <ExtratoProvider>
      <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
        <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
          <Sidebar activePage={'8'} />
        </Sidenav>
        <Container>
          <Header style={{ minHeight: '3.6rem' }}>
            <Topbar
              pageTitle={'Visualizar Compra'}
              onOpenSearchCompraModal={handleOpenSearchCompraModal}
            />
          </Header>
          <Content>
            <VerCompraTable />
            {isSearchCompraModalOpen ? (
              <SearchCompraModal
                isOpenSearch={isSearchCompraModalOpen}
                onRequestCloseSearch={handleCloseSearchCompraModal}
              />
            ) : null}
          </Content>
        </Container>
      </Container>
    </ExtratoProvider>
  );
}
