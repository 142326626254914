import { Modal, Button, FormGroup } from 'rsuite';
import * as S from './styles';
import { useExtrato } from '../../hooks/extrato';
import globalStyle from '../../styles/globalStyle';

export function ConfirmaExcluirExtratoModal({
  isOpenExcluirConfirm,
  onRequestCloseExcluirConfirm,
  uuid,
  uuidUser
}) {
  const { deletarExtrato, isLoading } = useExtrato();

  async function handleDeleteConfirm(e) {
    e.preventDefault();

    deletarExtrato(uuid, onRequestCloseExcluirConfirm, uuidUser);
  }

  return (
    <Modal
      backdrop={'static'}
      size="xs"
      show={isOpenExcluirConfirm}
      onHide={onRequestCloseExcluirConfirm}
    >
      <Modal.Header>
        <Modal.Title style={{ color: globalStyle.colors.GRAY100 }}>
          Excluir extrato
        </Modal.Title>
      </Modal.Header>
      <S.Container onSubmit={handleDeleteConfirm}>
        <Modal.Body>
          <S.ContainerCardForm>
            <div className="card-form">
              <FormGroup>
                <p>
                  Tem certeza de que deseja excluir esse extrato? Essa operação
                  não poderá ser desfeita.
                </p>
                <input name="uuid" value={uuid} type="hidden"></input>
              </FormGroup>
            </div>
          </S.ContainerCardForm>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" appearance="primary">
            {isLoading ? 'Carregando...' : 'Sim'}
          </Button>
          <Button onClick={onRequestCloseExcluirConfirm} appearance="default">
            Cancelar
          </Button>
        </Modal.Footer>
      </S.Container>
    </Modal>
  );
}
