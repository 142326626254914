import React from 'react';
import globalStyle from '../../styles/globalStyle';

const UserIcon = ({ largura, altura, color = globalStyle.colors.GRAY100 }) => {
  return (
    <svg
      width={largura}
      height={altura}
      viewBox="0 0 24 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline', marginRight: 10, marginLeft: 10 }}
    >
      <g clipPath="url(#clip0_2743_7779)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.87109C10.2051 3.87109 8.75 5.32617 8.75 7.12109C8.75 8.91602 10.2051 10.3711 12 10.3711C13.7949 10.3711 15.25 8.91602 15.25 7.12109C15.25 5.32617 13.7949 3.87109 12 3.87109ZM7.25 7.12109C7.25 4.49774 9.37665 2.37109 12 2.37109C14.6234 2.37109 16.75 4.49774 16.75 7.12109C16.75 9.74445 14.6234 11.8711 12 11.8711C9.37665 11.8711 7.25 9.74445 7.25 7.12109ZM10 15.8711C9.13805 15.8711 8.3114 16.2135 7.7019 16.823C7.09241 17.4325 6.75 18.2591 6.75 19.1211V21.1211C6.75 21.5353 6.41421 21.8711 6 21.8711C5.58579 21.8711 5.25 21.5353 5.25 21.1211V19.1211C5.25 17.8613 5.75044 16.6531 6.64124 15.7623C7.53204 14.8715 8.74022 14.3711 10 14.3711H14C15.2598 14.3711 16.468 14.8715 17.3588 15.7623C18.2496 16.6531 18.75 17.8613 18.75 19.1211V21.1211C18.75 21.5353 18.4142 21.8711 18 21.8711C17.5858 21.8711 17.25 21.5353 17.25 21.1211V19.1211C17.25 18.2591 16.9076 17.4325 16.2981 16.823C15.6886 16.2135 14.862 15.8711 14 15.8711H10Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2743_7779">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0 0.121094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
