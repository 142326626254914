import { useEffect, useState } from 'react';
import { Modal, FormGroup, Icon, InputGroup } from 'rsuite';
import * as S from './styles';
import { useCatalogo } from '../../hooks/catalogo';
import Nav from '@rsuite/responsive-nav';
import Skeleton from 'react-loading-skeleton';
import InnerImageZoom from 'react-inner-image-zoom';
import { useEditable } from '@chakra-ui/react';
import globalStyle from '../../styles/globalStyle';
import Input from '../UI/Input';
import Button from '../UI/Button';

export function EditarCatalogoModal({ isOpenEdit, onRequestCloseEdit }) {
  const { catalogoPorUUID, isLoadingEdit, EditCatalogo, activePage } =
    useCatalogo();
  const [activeKey, setActiveKey] = useState('A');

  const [tipo, setTipo] = useState(catalogoPorUUID[0].tipo);
  const [marca, setMarca] = useState(catalogoPorUUID[0].marca);
  const [modelo, setModelo] = useState(catalogoPorUUID[0].modelo);
  const [codigo1, setCodigo1] = useState(catalogoPorUUID[0].codigo1);
  const [codigo2, setCodigo2] = useState(catalogoPorUUID[0].codigo2);
  const [codigo3, setCodigo3] = useState(catalogoPorUUID[0].codigo3);
  const [definition, setDefinition] = useState(catalogoPorUUID[0].definition);
  const [targeting, setTargeting] = useState(catalogoPorUUID[0].targeting);
  const [foto, setFoto] = useState(catalogoPorUUID[0].foto);
  const [image, setImage] = useState(catalogoPorUUID[0].image);

  const [foto_upload, setFotoUpload] = useState(catalogoPorUUID[0].foto);

  const [apeso, setAPESO] = useState(catalogoPorUUID[0]?.A?.PESO || '');
  const [apt, setAPT] = useState(catalogoPorUUID[0]?.A?.PT || '');
  const [apd, setAPD] = useState(catalogoPorUUID[0]?.A?.PD || '');
  const [arh, setARH] = useState(catalogoPorUUID[0]?.A?.RH || '');

  const [bpeso, setBPESO] = useState(catalogoPorUUID[0]?.B?.PESO || '');
  const [bpt, setBPT] = useState(catalogoPorUUID[0]?.B?.PT || '');
  const [bpd, setBPD] = useState(catalogoPorUUID[0]?.B?.PD || '');
  const [brh, setBRH] = useState(catalogoPorUUID[0]?.B?.RH || '');

  const [cpeso, setCPESO] = useState(catalogoPorUUID[0]?.C?.PESO || '');
  const [cpt, setCPT] = useState(catalogoPorUUID[0]?.C?.PT || '');
  const [cpd, setCPD] = useState(catalogoPorUUID[0]?.C?.PD || '');
  const [crh, setCRH] = useState(catalogoPorUUID[0]?.C?.RH || '');

  const [dpeso, setDPESO] = useState(catalogoPorUUID[0]?.D?.PESO || '');
  const [dpt, setDPT] = useState(catalogoPorUUID[0]?.D?.PT || '');
  const [dpd, setDPD] = useState(catalogoPorUUID[0]?.D?.PD || '');
  const [drh, setDRH] = useState(catalogoPorUUID[0]?.D?.RH || '');
  const [status, setStatus] = useState(catalogoPorUUID[0].status);

  async function handlEditCatalogo(e) {
    e.preventDefault();

    let A = JSON.stringify({
      PESO: apeso || 0,
      PT: apt || 0,
      PD: apd || 0,
      RH: arh || 0
    });

    let B = JSON.stringify({
      PESO: bpeso || 0,
      PT: bpt || 0,
      PD: bpd || 0,
      RH: brh || 0
    });

    let C = JSON.stringify({
      PESO: cpeso || 0,
      PT: cpt || 0,
      PD: cpd || 0,
      RH: crh || 0
    });

    let D = JSON.stringify({
      PESO: dpeso || 0,
      PT: dpt || 0,
      PD: dpd || 0,
      RH: drh || 0
    });

    let data = {
      tipo,
      marca,
      modelo,
      codigo1,
      codigo2,
      codigo3,
      definition,
      targeting,
      A,
      B,
      C,
      D,
      foto: foto_upload,
      image,
      status: status
    };

    await EditCatalogo(
      data,
      onRequestCloseEdit,
      catalogoPorUUID[0].uuid,
      activePage
    );
  }
  const onImageChange = (event) => {
    const { files } = event.target;
    setFoto(URL.createObjectURL(files[0]));
    setFotoUpload(files[0]);
  };

  const handlClearFoto = async () => {
    setFoto('');
    setFotoUpload('');
  };

  const status_lista = {
    1: 'Ativo',
    2: 'Inativo',
    3: 'Pendente para análise'
  };

  const typeDefinition = {
    flex: 'Catalisador Flex',
    inox: 'Inox',
    dpf: 'DPF'
  };

  const typeTargeting = {
    'corte especial': 'Corte Especial',
    g51: 'G51'
  };

  function optionsStatus() {
    var es = Object.entries(status_lista);
    return es.map((item) => (
      <option key={`status${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  function optionsDefinition() {
    var es = Object.entries(typeDefinition);
    return es.map((item) => (
      <option key={`${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  function optionsTargeting() {
    var es = Object.entries(typeTargeting);
    return es.map((item) => (
      <option key={`${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const tipo_lista = {
    1: 'Catalisador',
    2: 'Pó comum',
    3: 'Inox',
    4: 'Filtro'
  };

  function optionsTipoLista() {
    var es = Object.entries(tipo_lista);
    return es.map((item) => (
      <option key={`tipo_lista${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  useEffect(() => {
    const img = new Image();
    img.src = foto;

    img.onerror = () => {
      setFoto(null);
    };
  }, [foto]);
  return (
    <Modal backdrop={'static'} show={isOpenEdit} onHide={onRequestCloseEdit}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ color: globalStyle.colors.GRAY100 }}>
            Editar Produto
          </div>
        </Modal.Title>
      </Modal.Header>
      <S.Container onSubmit={handlEditCatalogo}>
        {isLoadingEdit ? (
          <Skeleton />
        ) : (
          <>
            <Modal.Body>
              <Nav
                activeKey={activeKey}
                onSelect={setActiveKey}
                appearance="tabs"
              >
                <Nav.Item eventKey="A">
                  <div style={{ color: globalStyle.colors.GRAY100 }}>
                    Dados Básicos
                  </div>
                </Nav.Item>
                <Nav.Item eventKey="B">
                  <div style={{ color: globalStyle.colors.GRAY100 }}>
                    Tabela
                  </div>
                </Nav.Item>
              </Nav>
              {activeKey == 'A' ? (
                <>
                  <FormGroup>
                    <S.FotoContainer>
                      {foto ? (
                        <>
                          <InputGroup
                            className="close_search"
                            label="Excluir foto"
                          >
                            <InputGroup.Button
                              onClick={() => handlClearFoto()}
                              style={{
                                backgroundColor: globalStyle.colors.RED500
                              }}
                            >
                              <Icon icon="close" />
                            </InputGroup.Button>
                          </InputGroup>
                          <InnerImageZoom
                            alt="Imagem"
                            className="w-100"
                            src={foto}
                            zoomScale={2}
                            startsActive={true}
                            hideCloseButton={true}
                            hideHint={true}
                            moveType={'drag'}
                          />
                          <S.ContainerUpload>
                            <InputGroup
                              className="alterar"
                              label="Alterar foto"
                            >
                              <Icon icon="upload2" />
                            </InputGroup>
                            <Input
                              type="file"
                              id="upload-button"
                              onChange={onImageChange}
                            />
                          </S.ContainerUpload>
                        </>
                      ) : (
                        <>
                          <Icon icon="file-image-o" size="4x" />
                          <Input
                            type="file"
                            id="upload-button"
                            onChange={onImageChange}
                          />
                          <label>Clique aqui para inserir uma imagem</label>
                        </>
                      )}
                    </S.FotoContainer>
                  </FormGroup>
                  <FormGroup>
                    <label>Tipo:</label>
                    <select
                      placeholder="Tipo"
                      onChange={(e) => {
                        setTipo(e.target.value);
                      }}
                      value={tipo}
                    >
                      <option key="" value="">
                        Selecione o tipo
                      </option>
                      {optionsTipoLista()}
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <label>Marca</label>
                    <Input
                      type="text"
                      placeholder="Marca"
                      value={marca}
                      onChange={(event) => setMarca(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Modelo</label>
                    <Input
                      type="text"
                      placeholder="Modelo"
                      value={modelo}
                      onChange={(event) => setModelo(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Código 1</label>
                    <Input
                      type="text"
                      placeholder="Código 1"
                      value={codigo1}
                      onChange={(event) => setCodigo1(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Código 2</label>
                    <Input
                      type="text"
                      placeholder="Código 2"
                      value={codigo2}
                      onChange={(event) => setCodigo2(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Código 3</label>
                    <Input
                      type="text"
                      placeholder="Código 3"
                      value={codigo3}
                      onChange={(event) => setCodigo3(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Definição</label>
                    <select
                      placeholder="Definição"
                      onChange={(e) => {
                        setDefinition(e.target.value);
                      }}
                      value={definition}
                    >
                      <option key="" value="">
                        Selecione a definição
                      </option>
                      {optionsDefinition()}
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <label>Classificação</label>
                    <select
                      placeholder="Classificação"
                      onChange={(e) => {
                        setTargeting(e.target.value);
                      }}
                      value={targeting}
                    >
                      <option key="" value="">
                        Selecione a classificação
                      </option>
                      {optionsTargeting()}
                    </select>
                  </FormGroup>
                </>
              ) : null}
              {activeKey == 'B' ? (
                <>
                  <FormGroup>
                    <S.TabelaCatalogo>
                      <thead>
                        <tr>
                          <th>Teor</th>
                          <th>Peso</th>
                          <th>PT</th>
                          <th>PD</th>
                          <th>RH</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>A</th>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={apeso}
                              onChange={(event) => setAPESO(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={apt}
                              onChange={(event) => setAPT(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={apd}
                              onChange={(event) => setAPD(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={arh}
                              onChange={(event) => setARH(event.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>B</th>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={bpeso}
                              onChange={(event) => setBPESO(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={bpt}
                              onChange={(event) => setBPT(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={bpd}
                              onChange={(event) => setBPD(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={brh}
                              onChange={(event) => setBRH(event.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>C</th>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={cpeso}
                              onChange={(event) => setCPESO(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={cpt}
                              onChange={(event) => setCPT(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={cpd}
                              onChange={(event) => setCPD(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={crh}
                              onChange={(event) => setCRH(event.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>D</th>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={dpeso}
                              onChange={(event) => setDPESO(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={dpt}
                              onChange={(event) => setDPT(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={dpd}
                              onChange={(event) => setDPD(event.target.value)}
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              placeholder="0"
                              value={drh}
                              onChange={(event) => setDRH(event.target.value)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </S.TabelaCatalogo>
                  </FormGroup>
                </>
              ) : null}
              <FormGroup>
                <label>Status:</label>
                <select
                  placeholder="Tipo"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  value={status}
                >
                  <option key="" value="">
                    Selecione o tipo
                  </option>
                  {optionsStatus()}
                </select>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                style={{ marginTop: '20px' }}
                text={isLoadingEdit ? 'Carregando...' : 'Atualizar'}
              />
            </Modal.Footer>
          </>
        )}
      </S.Container>
    </Modal>
  );
}
