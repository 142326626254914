import React from 'react';

const DocumentIcon = ({ largura, altura, style, color = '#F5F5F5' }) => {
  return (
    <div style={style}>
      <svg
        width={largura}
        height={altura}
        viewBox="-4 -1 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.83329 2.125C2.55703 2.125 2.29207 2.23475 2.09672 2.4301C1.90137 2.62545 1.79163 2.8904 1.79163 3.16667V14.8333C1.79163 15.1096 1.90137 15.3746 2.09672 15.5699C2.29207 15.7653 2.55702 15.875 2.83329 15.875H11.1666C11.4429 15.875 11.7078 15.7653 11.9032 15.5699C12.0985 15.3746 12.2083 15.1096 12.2083 14.8333L12.2083 6.29167H9.49996C9.11318 6.29167 8.74225 6.13802 8.46876 5.86453C8.19527 5.59104 8.04163 5.22011 8.04163 4.83333V2.125H2.83329ZM9.29163 3.00888L11.3244 5.04167H9.49996C9.44471 5.04167 9.39172 5.01972 9.35264 4.98065C9.31358 4.94158 9.29163 4.88859 9.29163 4.83333V3.00888ZM1.21284 1.54621C1.64261 1.11644 2.2255 0.875 2.83329 0.875H8.66663C8.83239 0.875 8.99136 0.940848 9.10857 1.05806L13.2752 5.22473C13.3924 5.34194 13.4583 5.50091 13.4583 5.66667L13.4583 14.8333C13.4583 15.4411 13.2168 16.024 12.7871 16.4538C12.3573 16.8836 11.7744 17.125 11.1666 17.125H2.83329C2.22551 17.125 1.64261 16.8836 1.21284 16.4538C0.783069 16.024 0.541626 15.4411 0.541626 14.8333V3.16667C0.541626 2.55888 0.783069 1.97598 1.21284 1.54621ZM6.99996 7.54167C7.34514 7.54167 7.62496 7.82149 7.62496 8.16667V11.6578L9.05802 10.2247C9.3021 9.98065 9.69782 9.98065 9.9419 10.2247C10.186 10.4688 10.186 10.8645 9.9419 11.1086L7.4419 13.6086C7.32469 13.7258 7.16572 13.7917 6.99996 13.7917C6.8342 13.7917 6.67523 13.7258 6.55802 13.6086L4.05802 11.1086C3.81394 10.8645 3.81394 10.4688 4.05802 10.2247C4.3021 9.98065 4.69782 9.98065 4.9419 10.2247L6.37496 11.6578V8.16667C6.37496 7.82149 6.65478 7.54167 6.99996 7.54167Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default DocumentIcon;
