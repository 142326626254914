import React from "react";
import { BotaoAcaoContainer, Container, Title } from "./styled";
import { FormGroup, Modal } from "rsuite";
import globalStyle from "../../styles/globalStyle";
import Button from "../UI/Button";
import { useCompra } from '../../hooks/compra';
import { useExtrato } from '../../hooks/extrato';

export default function ConfirmaModal({ title, show, bodyText, btnAcaoTexto, setShow, submitFn }) {
    const fechaModal = () => setShow && setShow(false)
    return (
        <Container
            backdrop={'static'}
            size="xs"
            show={show}
            onHide={fechaModal}

        >
            <Modal.Header>
                <Modal.Title>
                    <Title>
                        {title}
                    </Title>
                </Modal.Title>
                <div style={{
                    width: '100%',
                    height: 1,
                    background: '#E5E7EB',
                    position: 'absolute',
                    left: 0,
                    top: 50
                }} />
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>
                        {bodyText}
                    </p>
                </div>
                <BotaoAcaoContainer>
                    <Button
                        text="Cancelar"
                        width="100%"
                        height="100%"
                        padding="0"
                        backGroundColor="transparent"
                        color={globalStyle.colors.GRAY500}
                        onClick={fechaModal}
                    />
                    {btnAcaoTexto == 'Cancelar Atualização' || btnAcaoTexto == 'Deletar' || btnAcaoTexto == 'Inativar' ? (
                        <Button
                            text={btnAcaoTexto}
                            width="100%"
                            height="50px"
                            padding="0"
                            backGroundColor={globalStyle.colors.RED500}
                            color={globalStyle.colors.white}
                            onClick={submitFn}
                        />
                    ) : <Button
                        text={btnAcaoTexto}
                        width="100%"
                        height="100%"
                        padding="0"
                        backGroundColor={globalStyle.colors.GRAY100}
                        color={'white'}
                        onClick={submitFn}
                    />}

                </BotaoAcaoContainer>
            </Modal.Body>
        </Container>
    )
}
