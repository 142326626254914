import { InputGroup, Table, Tooltip, Whisper, Icon } from 'rsuite';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import { useCompra } from '../../hooks/compra';
import { usePerfil } from '../../hooks/perfil';
import { useState } from 'react';
import TableComponent from '../UI/TableComponent';

export function CompraTable() {
  const app = 'compra';
  const [search, setSearch] = useState('');
  const {
    verCompraPorUuid,
    CompraLista,
    pagination,
    isLoading,
    CompraPage,
    orderCompra,
    searchCompraUsuario,
    isLoadingVer,
    buscaRelatorioTodasCompra
  } = useCompra();
  const { permissaoPerfil, tipoPerfil } = usePerfil();

  const handleSelect = async (eventKey) => {
    await CompraPage(eventKey);
  };
  
  const handleClickOpenVer = async (uuid) => {
    // eslint-disable-next-line no-mixed-operators
    if (
      tipoPerfil == 1 ||
      (permissaoPerfil != null &&
        permissaoPerfil.includes('per_' + app + '_visualizar')) ||
      permissaoPerfil.includes('per_' + app + '_editar')
    ) {
      await verCompraPorUuid(uuid, false);
    }
  };
  const handleOrderingColumn = (event, column) => {
    const columns = document.getElementsByTagName('th');
    for (let column of columns) {
      column.classList.add('columnDefault');
    }

    let el = event.target;
    el.classList.remove('columnDefault');
    el.classList.add('columnActive');

    if (el.lastChild?.classList?.contains('rs-icon-arrow-up')) {
      el.lastChild.classList.remove('rs-icon-arrow-up');
      el.lastChild.classList.add('rs-icon-arrow-down');

      orderCompra(column, 'DESC', pagination[0].current_page);
    } else {
      el.lastChild.classList.remove('rs-icon-arrow-down');
      el.lastChild.classList.add('rs-icon-arrow-up');
      
      orderCompra(column, 'ASC', pagination[0].current_page);
    }
  };

  const handlSearchCompra = async () => {
    let data = {
      nome: search,
      cpf: search
    };

    await searchCompraUsuario(data);
  };

  const handleDowloadAllPurchasesUser = (uuid) => {
    buscaRelatorioTodasCompra(uuid);
  }
  const { Pagination } = Table;
  console.log('total', pagination)
  return (
    <div>
      <S.Container>
        <S.HeaderTable>
          <div>
            <p>
              Total: {pagination[0]?.total}
            </p>
          </div>
        </S.HeaderTable>
        <TableComponent
          page={app}
          pagination={pagination}
          handleOrderingColumn={handleOrderingColumn}
          handleClickOpenEdit={handleClickOpenVer}
          lista={CompraLista}
          isLoading={isLoading}
          handleDowloadAllPurchasesUser={handleDowloadAllPurchasesUser}
          columns={[
            { header: 'Nome', body: 'nome' },
            { header: 'CPF', body: 'cpf' },
            { header: 'Saldo', body: 'valorTotal' },
            { header: 'Limite', body: 'limite' },
            { header: '', body: 'action' },
          ]}
          // sempre enviar valores no mesmo index da prop "columns", componente pega pelo index
          orderingByColumnsIndex={['name', 'cpf', 'saldo', 'value_limit', '']}
        />
        <S.ContainerPagination>
          {pagination.length ? (
            <Pagination
              prev
              last
              next
              first
              size="lg"
              pages={pagination[0].last_page}
              activePage={pagination[0].current_page}
              onSelect={handleSelect}
            />
          ) : null}
        </S.ContainerPagination>
      </S.Container>
    </div>
  );
}
