import { useCallback, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import * as S from './styles';
import { useParceiro } from '../../hooks/parceiro';
import { Loader } from 'rsuite';
import Skeleton from 'react-loading-skeleton';
import L from 'leaflet';
import { usePerfil } from '../../hooks/perfil';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

export function ParceiroMapUUID() {
  let app = 'parceiro';
  const { id } = useParams();
  const {
    parceiroPorUUID,
    isLoading,
    VisualizarParceiroPorUUID,
    VisualizarHistoricoDeLocalizacaoPorUUID,
    historicoDeLocalizacaoPorUUID
  } = useParceiro();
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_' + app + '_visualizar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleParceiro = useCallback(async () => {
    await VisualizarParceiroPorUUID(id);
    await VisualizarHistoricoDeLocalizacaoPorUUID(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    handleParceiro();
  }, [handleParceiro]);

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <div>
      {loadingPer ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : (
        <S.Container>
          {isLoading ? (
            <Skeleton />
          ) : (
            <MapContainer
              style={{ height: '100%', width: '100%' }}
              center={[-15.7934036, -47.8823172]}
              zoom={4}
              minZoom={3}
              maxZoom={18}
              maxBounds={[
                [-180, 180],
                [180, -180]
              ]}
              scrollWheelZoom={true}
              className="leaflet-map"
            >
              <TileLayer
                noWrap={true}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <MarkerClusterGroup
                maxClusterRadius={60}
                disableClusteringAtZoom={17}
                removeOutsideVisibleBounds
              >
                {historicoDeLocalizacaoPorUUID
                  .filter(
                    (objeto) =>
                      objeto.latitude != null && objeto.longitude != null
                  )
                  .map((lista) => {
                    return (
                      <Marker
                        key={lista.uuid}
                        position={[lista.latitude, lista.longitude]}
                      >
                        <Popup>
                          {lista.usuario.name}
                          <br />
                          <br />
                          Origem: {lista.origem}
                          <br />
                          <br />
                          Data:{' '}
                          {new Date(
                            `${lista.created_at.split('T')[0]} 00:00:01`
                          ).toLocaleDateString('pt-BR')}
                          <br />
                          <br />
                          Endereço:{' '}
                          {`Rua: ${lista.logradouro}, ${lista.numero} - ${lista.uf}`}
                        </Popup>
                      </Marker>
                    );
                  })}
              </MarkerClusterGroup>
            </MapContainer>
          )}
        </S.Container>
      )}
    </div>
  );
}
