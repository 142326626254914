import api from '../../services/api';
import { useEffect, useState, React } from 'react';
import * as S from './styles';

import Skeleton from 'react-loading-skeleton';
import { useUsuario } from '../../hooks/user';
import { usePerfil } from '../../hooks/perfil';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, SelectPicker, Table, Tooltip, Whisper, Message, useToaster } from 'rsuite';
import {
  Flex,
  Heading,
  FormControl,
  CircularProgress,
  InputGroup,
  InputRightElement,
  Button as Botao,
  Icon as Icone,
  Image
} from '@chakra-ui/core';
import TableComponent from '../UI/TableComponent';
import Button from '../UI/Button';
import Input from '../../components/UI/Input';
import ErrorMessage from '../../components/ErrorMessage';
import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Header, Sidebar as Sidenav, Loader, Icon, Alert } from 'rsuite';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';

import PlusIcon from '../../assets/PlusIcon.png';
import globalStyle from '../../styles/globalStyle';
import formatReal from '../../utils/formatReal';
import formataCampoPorcentagem, {
  formataCampoPorcentagemNegativos
} from '../../utils/formataCampoPorcentagem';
import permissao from '../../utils/permissao';

export function DetalheUsuario({ toggleViewUser, viewUser }) {
  const app = 'usuario';
  let { id } = useParams();
  // const toaster = useToaster();
  // const [placement, setPlacement] = React.useState('topCenter');

  const [search, setSearch] = useState('');
  const [userFilterByMainPartner, setUserFilterByMainPartner] = useState(null);
  const [userFilterByType, setUserFilterByType] = useState(null);
  const [filter, setFilter] = useState(null);
  const [mainPartnerUuid, setMainPartnerUuid] = useState(null);
  const [mainPartnerId, setMainPartnerId] = useState(null);
  const [userDetails, setUserDetails] = useState('');
  const [isChecked, setIsChecked] = useState();
  const [radioSelected, setRadioSelected] = useState(null);

  const [parceiroPrincipalId, setParceiroPrincipalId] = useState('');
  const [userCodigo, setUserCodigo] = useState('');
  const [userLimite, setUserLimite] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [enderecoNumero, setEnderecoNumero] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [bairro, setBairro] = useState('');
  const [peca, setPeca] = useState('');
  const [po, setPo] = useState('');
  const [inox, setInox] = useState('');
  const [filtro, setFiltro] = useState('');
  const [parametro, setParametro] = useState('');
  const [agencia, setAgencia] = useState('');
  const [banco, setBanco] = useState('');
  const [conta, setConta] = useState('');
  const [titular, setTitular] = useState('');
  const [cpfDoTitular, setCpfDoTitular] = useState('');
  const [status, setStatus] = useState('');
  const [userName, setUserName] = useState('');

  const [idDisp, setIdDisp] = useState(null);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCPF] = useState('');
  const [celular, setCelular] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaConfirma, setSenhaConfirma] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [permissaoPerfil, setPermissao] = useState('');
  const [tipoPerfil, setTipo] = useState('');
  const [permissoesUser, setPermissoesUser] = useState([]);
  const [viewPerms, setViewPerms] = useState(false);
  const [idDisabled, setIdDisabled] = useState(false);
  const [aplicarParaDependentes, setAplicarParaDependentes] = useState(false);
  const [error, setError] = useState({
    name: {
      error: false
    },
    email: {
      error: false
    },
    cpf: {
      error: false
    },
    celular: {
      error: false
    },
    nascimento: {
      error: false
    }
  });
  const [isLoading, setIsLoading] = useState(false);

  const [perUsuarioVisualizar, setPermUsuarioVisualizar] = useState(false);
  const [perUsuarioAdicionar, setPermUsuarioAdicionar] = useState(false);
  const [perUsuarioEditar, setPermUsuarioEditar] = useState(false);

  const [perCatalogoVisualizar, setPermCatalogoVisualizar] = useState(false);
  const [perCatalogoAdicionar, setPermCatalogoAdicionar] = useState(false);
  const [perCatalogoEditar, setPermCatalogoEditar] = useState(false);

  const [perExtratoVisualizar, setPermExtratoVisualizar] = useState(false);
  const [perExtratoAdicionar, setPermExtratoAdicionar] = useState(false);
  const [perExtratoAtualizar, setPermExtratoAtualizar] = useState(false);

  const [perLotesVisualizar, setPermLotesVisualizar] = useState(false);
  const [perLotesEditar, setPermLotesEditar] = useState(false);
  const [perLotesAdicionar, setPermLotesAdicionar] = useState(false);

  const [perParceiroVisualizar, setPermParceiroVisualizar] = useState(false);
  const [perParceiroAdicionar, setPermParceiroAdicionar] = useState(false);
  const [perParceiroAtualizar, setPermParceiroAtualizar] = useState(false);

  const [perConfiguracaoVisualizar, setPermConfiguracaoVisualizar] =
    useState(false);
  const [perConfiguracaoAtualizar, setPermConfiguracaoAtualizar] =
    useState(false);

  const [perCompraVisualizar, setPermCompraVisualizar] = useState(false);
  const [perCompraAtualizar, setPermCompraAtualizar] = useState(false);
  const [perCompraAdicionar, setPermCompraAdicionar] = useState(false);

  const [perIaAdicionar, setPermIaAdicionar] = useState(false);

  const [tipoAdmin, setTipoAdmin] = useState(false);
  const [tipoRecebimento, setTipoRecebimento] = useState(false);
  const [tipoAnalisador, setTipoAnalisador] = useState(false);
  const [tipoParceiro, setTipoParceiro] = useState(false);
  const [tipoParceiroPrincipal, setTipoParceiroPrincipal] = useState(false);

  const [userType, setUserType] = useState('');

  const [showPopover, setShowPopover] = useState(false);

  const permissions = {
    per_usuario_visualizar: {
      active: perUsuarioVisualizar,
      id: 13
    },
    per_usuario_adicionar: {
      active: perUsuarioAdicionar,
      id: 26
    },
    per_usuario_editar: {
      active: perUsuarioEditar,
      id: 14
    },
    per_catalogo_visualizar: {
      active: perCatalogoVisualizar,
      id: 10
    },
    per_catalogo_adicionar: {
      active: perCatalogoAdicionar,
      id: 27
    },
    per_catalogo_editar: {
      active: perCatalogoEditar,
      id: 12
    },
    per_extrato_visualizar: {
      active: perExtratoVisualizar,
      id: 28
    },
    per_extrato_editar: {
      active: perExtratoAtualizar,
      id: 15
    },
    per_extrato_adicionar: {
      active: perExtratoAdicionar,
      id: 32
    },
    per_lotes_visualizar: {
      active: perLotesVisualizar,
      id: 29
    },
    per_lotes_editar: {
      active: perLotesEditar,
      id: 30
    },
    per_lotes_adicionar: {
      active: perLotesAdicionar,
      id: 33
    },
    per_parceiro_visualizar: {
      active: perParceiroVisualizar,
      id: 31
    },
    per_parceiro_adicionar: {
      active: perParceiroAdicionar,
      id: 35
    },
    per_parceiro_atualizar: {
      active: perParceiroAtualizar,
      id: 34
    },
    per_configuracao_visualizar: {
      active: perConfiguracaoVisualizar,
      id: 16
    },
    per_configuracao_editar: {
      active: perConfiguracaoAtualizar,
      id: 36
    },
    per_compra_editar: {
      active: perCompraAtualizar,
      id: 37
    },
    per_compra_adicionar: {
      active: perCompraAdicionar,
      id: 38
    },
    per_compra_visualizar: {
      active: perCompraVisualizar,
      id: 39
    },
    per_ia_adicionar: {
      active: perIaAdicionar,
      id: 40
    }
  };

  const {
    getUsuarioPorUuid,
    usuarioLista,
    userPage,
    pagination,
    searchUsuario,
    usuarioPorUUID,
    orderUsuario,
    getUsuariosPorTipo,
    usuarioListaPorTipo,
    getUsuariosByMainPartner,
    cleanFilters,
    editUsuario,
    createUsuario
  } = useUsuario();

  const navigate = useNavigate();

  useEffect(() => {
    getUsuariosPorTipo(5, false);
  }, []);

  const dadosUsuarioFormatado = usuarioListaPorTipo.map((usr) => {
    return { label: usr.id + ' - ' + usr.nome, value: usr.id };
  });

  useEffect(() => {
    async function fetchData() {
      handleGetPermissoes();
    }
    fetchData();
  }, []);

  useEffect(() => {
    handleClickOpenEdit();
  }, [permissaoPerfil]);

  useEffect(() => {
    if (cleanFilters) {
      setUserFilterByMainPartner('');
      setUserFilterByType('');
    }
  }, [cleanFilters]);

  const handleGetPermissoes = async (target) => {
    const response = await api.get('/me');
    const Perms = response.data.permissoes.map((perm) => perm.permissionName);

    setPermissao(Perms);

    setTipo(response.data.tipo);
  };

  const handleSelectedRadio = (type) => {
    setRadioSelected(type);
  };

  const handleSelect = async (eventKey) => {
    await userPage(eventKey, userFilterByType, mainPartnerUuid, mainPartnerId);
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const handlePasswordVisibility2 = () => setShowPassword2(!showPassword2);

  const handleClickOpenEdit = async () => {
    setIsLoading(true);
    if (
      permissaoPerfil != null &&
      permissaoPerfil.includes('per_' + app + '_editar') &&
      !permissaoPerfil.indexOf('per_' + app + '_visualizar') == -1
    ) {
      const res = await getUsuarioPorUuid(id, 'detalhes_usuario');

      setPermissoesUser(res.permissoes);
      setUserDetails(res);
      res.nome && setUserName(res.nome);
      res.idDispositivo && setIdDisp(res.idDispositivo);
      res.status && setStatus(res.status);
      res.permissoes && setPermissoesUser(res.permissoes);
      res.nome && setNome(res.nome);
      res.email && setEmail(res.email);
      res.cpf?.br && setCPF(res.cpf?.br);
      res.celular?.br && setCelular(res.celular?.br);
      res.telefone?.br && setTelefone(res.telefone?.br);
      res.data_nascimento?.br && setNascimento(res.data_nascimento?.br);
      res.codigo && setUserCodigo(res.codigo);
      res.limite?.real && setUserLimite(res.limite?.real);
      res.cidade && setCidade(res.cidade);
      res.titular_cpf && setCpfDoTitular(res.titular_cpf);
      res.endereco && setLogradouro(res.endereco);
      res.numero && setEnderecoNumero(res.numero);
      res.estado && setEstado(res.estado);
      res.bairro && setBairro(res.bairro);
      res.peca && setPeca(res.peca);
      res.inox && setInox(res.inox);
      res.po && setPo(res.po);
      res.filtro && setFiltro(res.filtro);
      res.parametro && setParametro(res.parametro);
      res.banco && setBanco(res.banco);
      res.agencia && setAgencia(res.agencia);
      res.conta && setConta(res.conta);
      res.titular && setTitular(res.titular);
      res.user_principal && setParceiroPrincipalId(res.user_principal);
      if (res.tipo == 1) {
        setTipoAdmin(true);
        setUserType('admin');
        setTipoRecebimento(false);
        setTipoParceiro(false);
        setTipoParceiroPrincipal(false);
        setTipoAnalisador(false);
      } else if (res.tipo == 2) {
        setTipoRecebimento(true);
        setUserType('recebimento');
        setTipoParceiro(false);
        setTipoAdmin(false);
        setTipoAnalisador(false);
        setTipoParceiroPrincipal(false);
      } else if (res.tipo == 3) {
        setTipoAnalisador(true);
        setUserType('analisador');
        setTipoRecebimento(false);
        setTipoParceiro(false);
        setTipoAdmin(false);
        setTipoParceiroPrincipal(false);
      } else if (res.tipo == 4) {
        setTipoParceiro(true);

        setUserType('parceiro');
        setTipoRecebimento(false);
        setTipoAdmin(false);
        setTipoAnalisador(false);
        setTipoParceiroPrincipal(false);
      } else if (res.tipo == 5) {
        setTipoParceiroPrincipal(true);
        setUserType('parceiro principal');
        setTipoParceiro(false);
        setTipoRecebimento(false);
        setTipoAdmin(false);
        setTipoAnalisador(false);
      }

      permissoesUser.forEach((data) => {
        if (data.permissionName === 'per_usuario_visualizar') {
          setPermUsuarioVisualizar(true);
        } else if (data.permissionName === 'per_usuario_adicionar') {
          setPermUsuarioAdicionar(true);
        } else if (data.permissionName === 'per_usuario_editar') {
          setPermUsuarioEditar(true);
        } else if (data.permissionName === 'per_catalogo_visualizar') {
          setPermCatalogoVisualizar(true);
        } else if (data.permissionName === 'per_catalogo_adicionar') {
          setPermCatalogoAdicionar(true);
        } else if (data.permissionName === 'per_catalogo_editar') {
          setPermCatalogoEditar(true);
        } else if (data.permissionName === 'per_extrato_visualizar') {
          setPermExtratoVisualizar(true);
        } else if (data.permissionName === 'per_lotes_visualizar') {
          setPermLotesVisualizar(true);
        } else if (data.permissionName === 'per_lotes_editar') {
          setPermLotesEditar(true);
        } else if (data.permissionName === 'per_parceiro_visualizar') {
          setPermParceiroVisualizar(true);
        } else if (data.permissionName === 'per_extrato_adicionar') {
          setPermExtratoAdicionar(true);
        } else if (data.permissionName === 'per_extrato_editar') {
          setPermExtratoAtualizar(true);
        } else if (data.permissionName === 'per_lote_adicionar') {
          setPermLotesAdicionar(true);
        } else if (data.permissionName === 'per_parceiro_adicionar') {
          setPermParceiroAdicionar(true);
        } else if (data.permissionName === 'per_parceiro_atualizar') {
          setPermParceiroAtualizar(true);
        } else if (data.permissionName === 'per_compra_visualizar') {
          setPermCompraVisualizar(true);
        } else if (data.permissionName === 'per_compra_adicionar') {
          setPermCompraAdicionar(true);
        } else if (data.permissionName === 'per_compra_editar') {
          setPermCompraAtualizar(true);
        } else if (data.permission === 'per_configuracao_visualizar') {
          setPermConfiguracaoVisualizar(true);
        } else if (data.permission === 'per_configuracao_editar') {
          setPermConfiguracaoAtualizar(true);
        } else if (data.permission === 'per_ia_adicionar') {
          setPermIaAdicionar(true);
        }
      });
      setIsLoading(false);
    }

    if (
      tipoPerfil == 1 ||
      (permissaoPerfil != null &&
        permissaoPerfil.includes('per_' + app + '_visualizar'))
    ) {
      const res = await getUsuarioPorUuid(id, 'detalhes_usuario');

      setUserDetails(res);
      res.nome && setUserName(res.nome);
      res.idDispositivo && setIdDisp(res.idDispositivo);
      res.status && setStatus(res.status);
      res.permissoes && setPermissoesUser(res.permissoes);
      res.nome && setNome(res.nome);
      res.email && setEmail(res.email);
      res.cpf?.br && setCPF(res.cpf?.br);
      res.celular?.br && setCelular(res.celular?.br);
      res.telefone?.br && setTelefone(res.telefone?.br);
      res.data_nascimento?.br && setNascimento(res.data_nascimento?.br);
      res.codigo && setUserCodigo(res.codigo);
      res.limite?.real && setUserLimite(res.limite?.real);
      res.cidade && setCidade(res.cidade);
      res.titular_cpf && setCpfDoTitular(res.titular_cpf);
      res.endereco && setLogradouro(res.endereco);
      res.numero && setEnderecoNumero(res.numero);
      res.estado && setEstado(res.estado);
      res.bairro && setBairro(res.bairro);
      res.peca && setPeca(res.peca);
      res.inox && setInox(res.inox);
      res.po && setPo(res.po);
      res.filtro && setFiltro(res.filtro);
      res.parametro && setParametro(res.parametro);
      res.banco && setBanco(res.banco);
      res.agencia && setAgencia(res.agencia);
      res.conta && setConta(res.conta);
      res.titular && setTitular(res.titular);
      res.user_principal && setParceiroPrincipalId(res.user_principal);
      if (res.tipo == 1) {
        setTipoRecebimento(false);
        setUserType('admin');
        setTipoAdmin(true);
        setTipoParceiro(false);
        setTipoParceiroPrincipal(false);
        setTipoAnalisador(false);
        setViewPerms(false);
      } else if (res.tipo == 2) {
        setTipoRecebimento(true);
        setTipoAnalisador(false);
        setUserType('recebimento');
        setTipoAdmin(false);
        setTipoParceiro(false);
        setTipoParceiroPrincipal(false);
        setViewPerms(true);
      } else if (res.tipo == 3) {
        setTipoAnalisador(true);
        setUserType('analisador');
        setTipoRecebimento(false);
        setTipoAdmin(false);
        setTipoParceiro(false);
        setTipoParceiroPrincipal(false);
        setViewPerms(true);
      } else if (res.tipo == 4) {
        setTipoParceiro(true);
        setTipoAnalisador(false);
        setTipoRecebimento(false);
        setUserType('parceiro');
        setTipoParceiroPrincipal(false);
        setTipoAdmin(false);
        setViewPerms(false);
      } else if (res.tipo == 5) {
        setTipoParceiroPrincipal(true);
        setTipoParceiro(false);
        setUserType('parceiro principal');
        setTipoAnalisador(false);
        setTipoRecebimento(false);
        setTipoAdmin(false);
        setViewPerms(false);
      }

      res.permissoes.forEach((data) => {
        if (data.permissionName === 'per_usuario_visualizar') {
          setPermUsuarioVisualizar(true);
        } else if (data.permissionName === 'per_usuario_adicionar') {
          setPermUsuarioAdicionar(true);
        } else if (data.permissionName === 'per_usuario_editar') {
          setPermUsuarioEditar(true);
        } else if (data.permissionName === 'per_catalogo_visualizar') {
          setPermCatalogoVisualizar(true);
        } else if (data.permissionName === 'per_catalogo_adicionar') {
          setPermCatalogoAdicionar(true);
        } else if (data.permissionName === 'per_catalogo_editar') {
          setPermCatalogoEditar(true);
        } else if (data.permissionName === 'per_extrato_visualizar') {
          setPermExtratoVisualizar(true);
        } else if (data.permissionName === 'per_lotes_visualizar') {
          setPermLotesVisualizar(true);
        } else if (data.permissionName === 'per_lotes_editar') {
          setPermLotesEditar(true);
        } else if (data.permissionName === 'per_parceiro_visualizar') {
          setPermParceiroVisualizar(true);
        } else if (data.permissionName === 'per_extrato_adicionar') {
          setPermExtratoAdicionar(true);
        } else if (data.permissionName === 'per_extrato_editar') {
          setPermExtratoAtualizar(true);
        } else if (data.permissionName === 'per_lote_adicionar') {
          setPermLotesAdicionar(true);
        } else if (data.permissionName === 'per_parceiro_adicionar') {
          setPermParceiroAdicionar(true);
        } else if (data.permissionName === 'per_parceiro_atualizar') {
          setPermParceiroAtualizar(true);
        } else if (data.permissionName === 'per_compra_visualizar') {
          setPermCompraVisualizar(true);
        } else if (data.permissionName === 'per_compra_adicionar') {
          setPermCompraAdicionar(true);
        } else if (data.permissionName === 'per_compra_editar') {
          setPermCompraAtualizar(true);
        } else if (data.permissionName === 'per_configuracao_visualizar') {
          setPermConfiguracaoVisualizar(true);
        } else if (data.permissionName === 'per_configuracao_editar') {
          setPermConfiguracaoAtualizar(true);
        } else if (data.permissionName === 'per_ia_adicionar') {
          setPermIaAdicionar(true);
        }
      });
      setIsLoading(false);
    }
  };

  const handlSearchUsuario = async () => {
    let data = {
      tipo: '',
      nome: search,
      cpf: search,
      status: ''
    };

    await searchUsuario(data);
  };

  const { Pagination } = Table;

  const handleOrderingColumn = (event, column) => {
    const columns = document.getElementsByTagName('th');
    for (let column of columns) {
      column.classList.add('columnDefault');
    }

    let el = event.target;
    el.classList.remove('columnDefault');
    el.classList.add('columnActive');

    if (el.lastChild?.classList?.contains('rs-icon-arrow-up')) {
      el.lastChild.classList.remove('rs-icon-arrow-up');
      el.lastChild.classList.add('rs-icon-arrow-down');
      orderUsuario(
        column,
        'DESC',
        pagination[0].current_page,
        filter,
        userFilterByType,
        userFilterByMainPartner,
        mainPartnerUuid
      );
    } else {
      el.lastChild.classList.remove('rs-icon-arrow-down');
      el.lastChild.classList.add('rs-icon-arrow-up');
      orderUsuario(
        column,
        'ASC',
        pagination[0].current_page,
        filter,
        userFilterByType,
        userFilterByMainPartner,
        mainPartnerUuid
      );
    }
  };

  const tipos = [
    { label: 'Administrador', value: 1 },
    { label: 'Recebimento', value: 2 },
    { label: 'Analisador', value: 3 },
    { label: 'Parceiro', value: 4 },
    { label: 'Parceiro Principal', value: 5 }
  ];

  const filtroPorPrincipal = usuarioListaPorTipo.map((usr) => {
    return {
      value: [usr.id, usr.uuid],
      label: usr.id + ' - ' + usr.nome
    };
  });

  const handleFilterByType = (tipo) => {
    setUserFilterByMainPartner('');

    setUserFilterByType(tipo);
    if (tipo) {
      setFilter('type');
    } else {
      setFilter(null);
    }
    setUserFilterByMainPartner('');
    getUsuariosPorTipo(tipo);
  };

  const handleFilterByMainPartner = (value) => {
    setUserFilterByType('');

    if (value) {
      let mainp_id = value[0];
      let mainp_uuid = value[1];

      setFilter('mainPartner');
      setMainPartnerUuid(mainp_uuid);
      setMainPartnerId(mainp_id);
      getUsuariosByMainPartner(mainp_id, mainp_uuid);
    } else {
      setFilter(null);
      setMainPartnerUuid('');
      getUsuariosByMainPartner();
    }

    setUserFilterByMainPartner(value);
  };

  const handleEditUser = async (userData, uuid) => {
    const data = {
      nome: nome,
      cpf: cpf,
      email: email
    };

    // await editUsuario();
  };

  const handleEditRadio = (userType, setUserTypeInputCheck) => {
    setUserType(userType);
    setUserTypeInputCheck();
  };

  const handleClearDispositivo = () => {
    Alert.info('Dispositivo liberado. Salve para confirmar as alterações.');
    setIdDisp('');
    console.log();
  };

  const confereCampos = (type) => {
    if (type == 'name') {
      if (!nome) {
        setError((anterior) => {
          return {
            ...anterior,
            name: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            name: {
              error: false
            }
          };
        });
      }
    }
    if (type == 'email') {
      if (!email) {
        setError((anterior) => {
          return {
            ...anterior,
            email: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            email: {
              error: false
            }
          };
        });
      }
    }
    if (type == 'cpf') {
      if (!cpf) {
        setError((anterior) => {
          return {
            ...anterior,
            cpf: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            cpf: {
              error: false
            }
          };
        });
      }
    }
    if (type == 'celular') {
      if (!celular) {
        setError((anterior) => {
          return {
            ...anterior,
            celular: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            celular: {
              error: false
            }
          };
        });
      }
    }
    if (type == 'nascimento') {
      if (!nascimento) {
        setError((anterior) => {
          return {
            ...anterior,
            nascimento: {
              error: true
            }
          };
        });
      } else {
        setError((anterior) => {
          return {
            ...anterior,
            nascimento: {
              error: false
            }
          };
        });
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (
      !(
        permissaoPerfil != null &&
        permissaoPerfil.includes('per_' + app + '_editar')
      ) &&
      tipoPerfil != 1
    ) {
      setIsLoading(false);
      Alert.error('Sem permissão para executar essa ação!');
      return;
    }
    if (senha != '' && senha != senhaConfirma) {
      Alert.error('As senhas não conferem');
      setIsLoading(false);
      return;
    }

    const userPermissions = [];
    if (userType === 'recebimento' || userType === 'analisador') {
      const permissionsKeys = Object.keys(permissions);
      for (let key of permissionsKeys) {
        if (permissions[key].active) userPermissions.push(permissions[key].id);
      }
    }

    const tipo =
      userType === 'admin'
        ? 1
        : userType === 'recebimento'
          ? 2
          : userType === 'analisador'
            ? 3
            : userType === 'parceiro'
              ? 4
              : userType === 'parceiro principal'
                ? 5
                : null;

    const data = {
      idDispositivo: idDisabled == false ? idDisp : null,
      id: usuarioPorUUID[0].id,
      nome,
      email,
      cpf,
      telefone,
      celular,
      data_nascimento: nascimento,
      status: status == '' ? usuarioPorUUID[0].status : status,
      tipo,
      senha: senha != '' ? senha : null,
      peca,
      po,
      inox,
      filtro,
      banco,
      titular,
      titularCpf: cpfDoTitular,
      agencia,
      conta,
      logradouro,
      numero: enderecoNumero,
      bairro,
      cidade,
      estado,
      codigo: userCodigo,
      limite: userLimite,
      parametro: userType === 'parceiro' ? parametro : null,
      principal: userType === 'parceiro' ? parceiroPrincipalId : null,
      aplicarParaDependentes:
        userType === 'parceiro principal' ? aplicarParaDependentes : null
    };

    if (userType === 'recebimento' || userType === 'analisador') {
      data.permissions = userPermissions;
    }

    await editUsuario(data, usuarioPorUUID[0].uuid, 1);

    setTimeout(() => {
      window.location.pathname = '/usuario';
    }, 1000);

    try {
      handleClickOpenEdit();
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setError(err);
      setIsLoading(false);
    }
  };

  const handleClearPermissionBoxes = () => {
    setPermParceiroVisualizar(false);
    setPermUsuarioVisualizar(false);
    setPermUsuarioAdicionar(false);
    setPermUsuarioEditar(false);
    setPermCatalogoVisualizar(false);
    setPermCatalogoAdicionar(false);
    setPermCatalogoEditar(false);
    setPermExtratoVisualizar(false);
    setPermLotesVisualizar(false);
    setPermLotesEditar(false);
  };
  useEffect(() => {
    if (userType !== 'recebimento' && userType !== 'analisador') {
      handleClearPermissionBoxes();
    }
  }, [userType]);

  const disableFields =
    !(
      permissaoPerfil != null &&
      permissaoPerfil.includes('per_' + app + '_editar')
    ) && tipoPerfil != 1;

  return (
    <div>
      {isLoading && <Loader backdrop content="Carregando..." vertical />}
      {!isLoading && (
        <>
          <Container>
            <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
              <Sidebar activePage={'2'} />
            </Sidenav>
            <Container>
              <Header style={{ minHeight: '3.6rem' }}>
                <Topbar
                  pageTitle={'Usuários'}
                  viewUser={true}
                  username={userName}
                  detalhes={true}
                />
              </Header>
              <S.Content>
                <S.Container onSubmit={handleSubmit}>
                  <>
                    <S.Title fontWeight="700">Tipo de Usuário</S.Title>
                    {(userType == 'admin' ||
                      userType == 'recebimento' ||
                      userType == 'analisador') && (
                        <S.DivRadio>
                          <S.Radio
                            name="userType"
                            type="radio"
                            checked={tipoAdmin}
                            onChange={() =>
                              !disableFields &&
                              handleEditRadio('admin', () => {
                                setTipoAdmin(true);
                                setTipoRecebimento(false);
                                setTipoParceiro(false);
                                setTipoParceiroPrincipal(false);
                                setTipoAnalisador(false);
                                setViewPerms(false);
                              })
                            }
                          />{' '}
                          <S.Text fontSize="18px">Administrador</S.Text>
                          <S.Radio
                            name="userType"
                            type="radio"
                            checked={tipoRecebimento}
                            onChange={() =>
                              !disableFields &&
                              handleEditRadio('recebimento', () => {
                                setTipoRecebimento(true);
                                setTipoParceiro(false);
                                setTipoAdmin(false);
                                setTipoAnalisador(false);
                                setTipoParceiroPrincipal(false);
                                setViewPerms(true);
                              })
                            }
                          />{' '}
                          <S.Text fontSize="18px">Recebimento</S.Text>
                          <S.Radio
                            name="userType"
                            type="radio"
                            checked={tipoAnalisador}
                            onChange={() =>
                              !disableFields &&
                              handleEditRadio('analisador', () => {
                                setTipoAnalisador(true);
                                setTipoRecebimento(false);
                                setTipoParceiro(false);
                                setTipoAdmin(false);
                                setTipoParceiroPrincipal(false);
                                setViewPerms(true);
                              })
                            }
                          />{' '}
                          <S.Text fontSize="18px">Analisador</S.Text>
                        </S.DivRadio>
                      )}

                    {(userType == 'parceiro' ||
                      userType == 'parceiro principal') && (
                        <S.DivRadio>
                          <S.Radio
                            name="userType"
                            type="radio"
                            checked={tipoParceiro}
                            onChange={() =>
                              !disableFields &&
                              handleEditRadio('parceiro', () => {
                                setTipoAdmin(false);
                                setTipoAnalisador(false);
                                setTipoParceiro(true);
                                setTipoParceiroPrincipal(false);
                                setTipoRecebimento(false);
                                setViewPerms(false);
                              })
                            }
                          />{' '}
                          <S.Text fontSize="18px">Parceiro</S.Text>
                          <S.Radio
                            name="userType"
                            type="radio"
                            checked={tipoParceiroPrincipal}
                            onChange={() =>
                              !disableFields &&
                              handleEditRadio('parceiro principal', () => {
                                setTipoAdmin(false);
                                setTipoAnalisador(false);
                                setTipoParceiro(false);
                                setTipoParceiroPrincipal(true);
                                setTipoRecebimento(false);
                                setViewPerms(false);
                              })
                            }
                          />{' '}
                          <S.Text fontSize="18px">Parceiro Principal</S.Text>
                        </S.DivRadio>
                      )}

                    <S.Text fontWeight="700">Dados Básicos</S.Text>

                    <form>
                      <FormControl isRequired>
                        <S.DivInput>
                          <S.InputUser
                            placeholder="Nome"
                            required
                            disabled={disableFields}
                            name="nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            onBlur={() => confereCampos('name')}
                          />
                          {error.name.error && (
                            <h1 style={{ color: '#BE123C' }}>
                              Campo obrigatório
                            </h1>
                          )}
                        </S.DivInput>
                      </FormControl>
                      <FormControl isRequired>
                        <S.DivInput>
                          <S.InputUser
                            placeholder="Email"
                            required
                            disabled={disableFields}
                            name="email"
                            value={email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => confereCampos('email')}
                          />
                          {error.email.error && (
                            <h1 style={{ color: '#BE123C' }}>
                              Campo obrigatório
                            </h1>
                          )}
                        </S.DivInput>
                      </FormControl>
                      <S.DivInput display="flex">
                        <FormControl isRequired>
                          <S.InputMask
                            placeholder="CPF"
                            required
                            disabled={disableFields}
                            name="cpf"
                            mask="999.999.999-99"
                            width="160px"
                            value={cpf}
                            onChange={(e) => setCPF(e.target.value)}
                            onBlur={() => confereCampos('cpf')}
                          />
                          {error.cpf.error && (
                            <h1 style={{ color: '#BE123C' }}>
                              Campo obrigatório
                            </h1>
                          )}
                        </FormControl>
                        <FormControl isRequired>
                          <S.InputMask
                            placeholder="Celular"
                            required
                            disabled={disableFields}
                            mask="(99) 99999-9999"
                            name="celular"
                            width="160px"
                            value={celular}
                            onChange={(e) => setCelular(e.target.value)}
                            onBlur={() => confereCampos('celular')}
                          />
                          {error.celular.error && (
                            <h1 style={{ color: '#BE123C' }}>
                              Campo obrigatório
                            </h1>
                          )}
                        </FormControl>
                        <S.InputMask
                          placeholder="Telefone"
                          mask="(99) 9999-9999"
                          disabled={disableFields}
                          name="telefone"
                          width="160px"
                          value={telefone}
                          onChange={(e) => setTelefone(e.target.value)}
                        />
                        <FormControl isRequired>
                          <S.InputMask
                            placeholder="Data de nascimento"
                            required
                            name="nascimento"
                            mask="99/99/9999"
                            disabled={disableFields}
                            width="160px"
                            value={nascimento}
                            onChange={(e) => setNascimento(e.target.value)}
                            onBlur={() => confereCampos('nascimento')}
                          />
                          {error.nascimento.error && (
                            <h1 style={{ color: '#BE123C' }}>
                              Campo obrigatório
                            </h1>
                          )}
                        </FormControl>
                      </S.DivInput>

                      {viewPerms && (
                        <>
                          <S.Text fontWeight="700">Permissões</S.Text>

                          <S.DivLabel>
                            <S.Label>
                              <S.SubText>Usuários</S.SubText>
                              <S.Check
                                value={perUsuarioVisualizar}
                                type="checkbox"
                                checked={perUsuarioVisualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermUsuarioVisualizar(
                                    !perUsuarioVisualizar
                                  )
                                }
                              />
                              <S.Span />
                              Visualizar
                            </S.Label>

                            <S.Label>
                              <S.Check
                                value={perUsuarioAdicionar}
                                type="checkbox"
                                checked={perUsuarioAdicionar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermUsuarioAdicionar(!perUsuarioAdicionar)
                                }
                              />
                              <S.Span />
                              Adicionar
                            </S.Label>

                            <S.Label>
                              <S.Check
                                value={perUsuarioEditar}
                                type="checkbox"
                                checked={perUsuarioEditar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermUsuarioEditar(!perUsuarioEditar)
                                }
                              />
                              <S.Span />
                              Atualizar
                            </S.Label>

                            <S.Label style={{ marginLeft: 16 }}>
                              <S.SubText>Catálogo</S.SubText>
                              <S.Check
                                value={perCatalogoVisualizar}
                                type="checkbox"
                                checked={perCatalogoVisualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermCatalogoVisualizar(
                                    !perCatalogoVisualizar
                                  )
                                }
                              />
                              <S.Span />
                              Visualizar
                            </S.Label>

                            <S.Label>
                              <S.Check
                                value={perCatalogoAdicionar}
                                type="checkbox"
                                checked={perCatalogoAdicionar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermCatalogoAdicionar(
                                    !perCatalogoAdicionar
                                  )
                                }
                              />
                              <S.Span />
                              Adicionar
                            </S.Label>

                            <S.Label>
                              <S.Check
                                value={perCatalogoEditar}
                                type="checkbox"
                                checked={perCatalogoEditar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermCatalogoEditar(!perCatalogoEditar)
                                }
                              />
                              <S.Span />
                              Atualizar
                            </S.Label>
                          </S.DivLabel>

                          <S.DivLabel>
                            <S.Label>
                              <S.SubText>Extrato</S.SubText>
                              <S.Check
                                value={perExtratoVisualizar}
                                type="checkbox"
                                checked={perExtratoVisualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermExtratoVisualizar(
                                    !perExtratoVisualizar
                                  )
                                }
                              />
                              <S.Span />
                              Visualizar
                            </S.Label>
                            <S.Label>
                              <S.Check
                                value={perExtratoAdicionar}
                                type="checkbox"
                                checked={perExtratoAdicionar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermExtratoAdicionar(!perExtratoAdicionar)
                                }
                              />
                              <S.Span />
                              Adicionar
                            </S.Label>
                            <S.Label>
                              <S.Check
                                value={perExtratoAtualizar}
                                type="checkbox"
                                checked={perExtratoAtualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermExtratoAtualizar(!perExtratoAtualizar)
                                }
                              />
                              <S.Span />
                              Atualizar
                            </S.Label>
                            <S.Label style={{ marginLeft: 16 }}>
                              <S.SubText>Parceiros</S.SubText>
                              <S.Check
                                value={perParceiroVisualizar}
                                type="checkbox"
                                checked={perParceiroVisualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermParceiroVisualizar(
                                    !perParceiroVisualizar
                                  )
                                }
                              />
                              <S.Span />
                              Visualizar
                            </S.Label>
                            <S.Label>
                              <S.Check
                                value={perParceiroAdicionar}
                                type="checkbox"
                                checked={perParceiroAdicionar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermParceiroAdicionar(
                                    !perParceiroAdicionar
                                  )
                                }
                              />
                              <S.Span />
                              Adicionar
                            </S.Label>

                            <S.Label>
                              <S.Check
                                value={perParceiroAtualizar}
                                type="checkbox"
                                checked={perParceiroAtualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermParceiroAtualizar(
                                    !perParceiroAtualizar
                                  )
                                }
                              />
                              <S.Span />
                              Atualizar
                            </S.Label>
                          </S.DivLabel>
                          <S.DivLabel>
                            <S.Label>
                              <S.SubText>Configuração</S.SubText>
                              <S.Check
                                value={perConfiguracaoVisualizar}
                                type="checkbox"
                                checked={perConfiguracaoVisualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermConfiguracaoVisualizar(
                                    !perConfiguracaoVisualizar
                                  )
                                }
                              />
                              <S.Span />
                              Visualizar
                            </S.Label>
                            <S.Label>
                              <S.Check
                                value={perConfiguracaoAtualizar}
                                type="checkbox"
                                checked={perConfiguracaoAtualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermConfiguracaoAtualizar(
                                    !perConfiguracaoAtualizar
                                  )
                                }
                              />
                              <S.Span />
                              Atualizar
                            </S.Label>
                            <S.Label />
                            <S.Label style={{ marginLeft: 16 }}>
                              <S.SubText>Lotes</S.SubText>
                              <S.Check
                                value={perCompraVisualizar}
                                type="checkbox"
                                checked={perCompraVisualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermCompraVisualizar(!perCompraVisualizar)
                                }
                              />
                              <S.Span />
                              Visualizar
                            </S.Label>
                            <S.Label>
                              <S.Check
                                value={perCompraAtualizar}
                                type="checkbox"
                                checked={perCompraAtualizar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermCompraAtualizar(!perCompraAtualizar)
                                }
                              />
                              <S.Span />
                              Atualizar
                            </S.Label>
                          </S.DivLabel>
                          <S.DivLabel>
                            <S.Label>
                              <S.SubText>IA</S.SubText>
                              <S.Check
                                value={perIaAdicionar}
                                type="checkbox"
                                checked={perIaAdicionar}
                                onChange={() =>
                                  !disableFields &&
                                  setPermIaAdicionar(!perIaAdicionar)
                                }
                              />
                              <S.Span />
                              Adicionar
                            </S.Label>
                          </S.DivLabel>
                        </>
                      )}
                      {userType === 'parceiro' && (
                        <>
                          <S.Text>Parceiro Principal</S.Text>
                          <S.DivInput>
                            <SelectPicker
                              data={dadosUsuarioFormatado}
                              value={parceiroPrincipalId}
                              style={{
                                position: 'relative',
                                width: 328,
                                height: 34,
                                marginTop: 16,
                                color: globalStyle.colors.GRAY100
                              }}
                              backgroundc="transparent"
                              size="md"
                              menuStyle={{
                                zIndex: 10000,
                                height: 250,
                                color: globalStyle.colors.GRAY100
                              }}
                              placeholder="Parceiro Principal"
                              required
                              disabled={disableFields}
                              block
                              preventOverflow={true}
                              onChange={(val) => setParceiroPrincipalId(val)}
                            ></SelectPicker>
                          </S.DivInput>
                        </>
                      )}
                      {(userType === 'parceiro' ||
                        userType === 'parceiro principal') && (
                          <>
                            <S.DivInput display="flex">
                              <FormControl>
                                <S.Text>Código</S.Text>
                                <S.InputUser
                                  type="text"
                                  placeholder="Código"
                                  required
                                  size="lg"
                                  autoComplete="off"
                                  disabled={disableFields}
                                  width="328px"
                                  value={userCodigo}
                                  focusBorderColor="gray"
                                  onChange={(event) =>
                                    setUserCodigo(event.currentTarget.value)
                                  }
                                />
                              </FormControl>
                              <FormControl>
                                <S.Text>Limite</S.Text>
                                <S.InputUser
                                  type="text"
                                  placeholder="Limite"
                                  required
                                  size="lg"
                                  autoComplete="off"
                                  width="328px"
                                  disabled={disableFields}
                                  value={userLimite}
                                  focusBorderColor="gray"
                                  onChange={(event) =>
                                    setUserLimite(
                                      formatReal(event.currentTarget.value)
                                    )
                                  }
                                />
                              </FormControl>
                            </S.DivInput>
                            <S.DivInput>
                              <S.Text fontWeight="700">Endereço</S.Text>
                              <S.DivInput>
                                <S.InputUser
                                  placeholder="Logradouro"
                                  required
                                  name="logradouro"
                                  disabled={disableFields}
                                  width="328px"
                                  value={logradouro}
                                  onChange={(e) => setLogradouro(e.target.value)}
                                // onBlur={() => confereCampos('cpf')}
                                />
                              </S.DivInput>
                              <S.DivInput display="flex">
                                <FormControl isRequired>
                                  <S.NumberFormat
                                    placeholder="Numero"
                                    required
                                    disabled={disableFields}
                                    name="numero"
                                    width="156px"
                                    value={enderecoNumero}
                                    onChange={(e) =>
                                      setEnderecoNumero(e.target.value)
                                    }
                                  // onBlur={() => confereCampos('cpf')}
                                  />
                                  <S.InputUser
                                    placeholder="Cidade"
                                    required
                                    name="cidade"
                                    disabled={disableFields}
                                    width="156px"
                                    value={cidade}
                                    onChange={(e) => setCidade(e.target.value)}
                                  // onBlur={() => confereCampos('cpf')}
                                  />
                                  <S.InputUser
                                    placeholder="Estado"
                                    required
                                    disabled={disableFields}
                                    name="estado"
                                    width="156px"
                                    value={estado}
                                    onChange={(e) => setEstado(e.target.value)}
                                  // onBlur={() => confereCampos('cpf')}
                                  />
                                </FormControl>
                              </S.DivInput>
                              <S.DivInput>
                                <S.InputUser
                                  placeholder="Bairro"
                                  required
                                  name="bairro"
                                  disabled={disableFields}
                                  width="328px"
                                  value={bairro}
                                  onChange={(e) => setBairro(e.target.value)}
                                // onBlur={() => confereCampos('cpf')}
                                />
                              </S.DivInput>
                            </S.DivInput>
                            <S.DivInput>
                              <S.Text fontWeight="700">
                                Peça
                                {(userType === 'parceiro' ||
                                  userType === 'parceiro principal') &&
                                  ' (%)'}
                              </S.Text>
                              <S.DivInput display="flex">
                                <S.NumberFormat
                                  onMouseEnter={() => setShowPopover(true)}
                                  onMouseLeave={() => setShowPopover(false)}
                                  suffix="%"
                                  placeholder="Peça (%)"
                                  required
                                  disabled={disableFields}
                                  name="peça"
                                  width="156px"
                                  value={peca}
                                  onValueChange={(e) =>
                                    setPeca(
                                      formataCampoPorcentagemNegativos(
                                        e.formattedValue
                                      )
                                    )
                                  }
                                // onBlur={() => confereCampos('cpf')}
                                />
                                <S.NumberFormat
                                  suffix="%"
                                  placeholder="PO (%)"
                                  required
                                  name="po"
                                  disabled={disableFields}
                                  width="156px"
                                  value={po}
                                  onChange={(e) =>
                                    setPo(formataCampoPorcentagem(e.target.value))
                                  }
                                // onBlur={() => confereCampos('cpf')}
                                />
                              </S.DivInput>
                              <S.Popover isOpen={showPopover}>
                                Valores negativos são válidos.
                              </S.Popover>
                              <S.DivInput display="flex">
                                <S.NumberFormat
                                  suffix="%"
                                  placeholder="Inox (%)"
                                  required
                                  name="inox"
                                  disabled={disableFields}
                                  width="156px"
                                  value={inox}
                                  onChange={(e) =>
                                    setInox(
                                      formataCampoPorcentagem(e.target.value)
                                    )
                                  }
                                // onBlur={() => confereCampos('cpf')}
                                />
                                <S.NumberFormat
                                  suffix="%"
                                  placeholder="Filtro (%)"
                                  required
                                  name="filtro"
                                  disabled={disableFields}
                                  width="156px"
                                  value={filtro}
                                  onChange={(e) =>
                                    setFiltro(
                                      formataCampoPorcentagem(e.target.value)
                                    )
                                  }
                                // onBlur={() => confereCampos('cpf')}
                                />
                              </S.DivInput>
                              {userType === 'parceiro' && (
                                <S.DivInput width="328px">
                                  <S.NumberFormat
                                    suffix="%"
                                    placeholder="Parâmetro (%)"
                                    required
                                    name="parâmetro"
                                    disabled={disableFields}
                                    width="328px"
                                    value={parametro}
                                    onChange={(e) =>
                                      setParametro(
                                        formataCampoPorcentagem(e.target.value)
                                      )
                                    }
                                  // onBlur={() => confereCampos('cpf')}
                                  />
                                </S.DivInput>
                              )}

                              {userType === 'parceiro principal' && (
                                <S.DivInput width="328px">
                                  <S.Label style={{ fontSize: 14 }}>
                                    <S.Check
                                      value={aplicarParaDependentes}
                                      type="checkbox"
                                      checked={aplicarParaDependentes}
                                      onChange={() =>
                                        !disableFields &&
                                        setAplicarParaDependentes(
                                          !aplicarParaDependentes
                                        )
                                      }
                                      style={{ marginTop: 10 }}
                                    />
                                    <S.Span />
                                    Aplicar parceiros
                                  </S.Label>
                                </S.DivInput>
                              )}
                            </S.DivInput>
                            <S.DivInput>
                              <S.Text>Dados Bancários</S.Text>
                              <S.DivInput display="flex">
                                <S.InputUser
                                  placeholder="Agência"
                                  required
                                  disabled={disableFields}
                                  name="agencia"
                                  width="156px"
                                  value={agencia}
                                  onChange={(e) => setAgencia(e.target.value)}
                                // onBlur={() => confereCampos('cpf')}
                                />
                                <S.InputUser
                                  placeholder="Banco"
                                  required
                                  name="banco"
                                  disabled={disableFields}
                                  width="156px"
                                  value={banco}
                                  onChange={(e) => setBanco(e.target.value)}
                                // onBlur={() => confereCampos('cpf')}
                                />
                                <S.InputUser
                                  placeholder="Conta"
                                  required
                                  name="conta"
                                  disabled={disableFields}
                                  width="156px"
                                  value={conta}
                                  onChange={(e) => setConta(e.target.value)}
                                // onBlur={() => confereCampos('cpf')}
                                />
                              </S.DivInput>
                              <S.DivInput display="flex">
                                <S.InputUser
                                  placeholder="Titular"
                                  required
                                  disabled={disableFields}
                                  name="titular"
                                  width="156px"
                                  value={titular}
                                  onChange={(e) => setTitular(e.target.value)}
                                // onBlur={() => confereCampos('cpf')}
                                />
                                <S.InputMask
                                  placeholder="CPF do Titular"
                                  required
                                  disabled={disableFields}
                                  name="cpfdotitular"
                                  width="156px"
                                  mask="999.999.999-99"
                                  value={cpfDoTitular}
                                  onChange={(e) =>
                                    setCpfDoTitular(e.target.value)
                                  }
                                  onBlur={() => confereCampos('cpf')}
                                />
                              </S.DivInput>
                            </S.DivInput>
                          </>
                        )}

                      <S.Text fontWeight="700">Status</S.Text>
                      <S.DivInput>
                        <SelectPicker
                          data={[
                            { label: 'Ativo', value: 1 },
                            { label: 'Inativo', value: 2 }
                          ]}
                          value={status}
                          style={{
                            position: 'relative',
                            width: 156,
                            height: 34,
                            marginTop: 16,
                          }}
                          size="md"
                          menuStyle={{ zIndex: 10000 }}
                          placeholder="Status"
                          required
                          disabled={disableFields}
                          block
                          preventOverflow={true}
                          onChange={(val) => {
                            setStatus(val);
                          }}
                        ></SelectPicker>
                        {/* AQUI */}
                        <S.Text fontWeight="700">ID de dispositivo</S.Text>
                        <div style={{ display: 'flex', width: '490px' }}>
                          <S.DivInput style={{ marginTop: '20px' }}>
                            <S.InputUser
                              placeholder="ID"
                              disabled={true}
                              name="id"
                              value={idDisp}
                              width={'300px'}
                            />
                          </S.DivInput>
                          {idDisp != null && (
                            <Button
                              disabled={idDisabled}
                              text="LIBERAR DISPOSITIVO"
                              fontSize="16px"
                              padding="0"
                              marginTop="26px"
                              marginLeft="20px"
                              width="132px"
                              height="50px"
                              color='white'
                              backGroundColor={globalStyle.colors.GRAY100}
                              tooltip="Limpar ID"
                              type="button"
                              onClick={() => {
                                handleClearDispositivo();
                              }}
                            >
                              LIBERAR DISPOSITIVO
                            </Button>
                          )}
                        </div>
                      </S.DivInput>

                      <S.Text fontWeight="700">Senha</S.Text>

                      <S.DivInput>
                        <FormControl isRequired>
                          <S.InputGroupS width="328px">
                            <S.DivInput>
                              <S.InputUser
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Senha"
                                required={senhaConfirma != ''}
                                value={senha}
                                disabled={disableFields}
                                size="lg"
                                width="328px"
                                autoComplete="off"
                                focusBorderColor="gray"
                                onChange={(event) =>
                                  setSenha(event.currentTarget.value)
                                }
                              />
                              <InputRightElement width="3rem">
                                <Botao
                                  h="1.5rem"
                                  size="lg"
                                  disabled={disableFields}
                                  onClick={handlePasswordVisibility}
                                  style={{
                                    backgroundColor: 'transparent',
                                    color: globalStyle.colors.GRAY400,
                                    top: '20px'
                                  }}
                                >
                                  {showPassword ? (
                                    <Icone name="view" />
                                  ) : (
                                    <Icone name="view-off" />
                                  )}
                                </Botao>
                              </InputRightElement>
                            </S.DivInput>
                          </S.InputGroupS>
                        </FormControl>
                      </S.DivInput>
                      <S.DivInput>
                        <FormControl isRequired>
                          <S.InputGroupS width="328px">
                            <S.DivInput>
                              <S.InputUser
                                type={showPassword2 ? 'text' : 'password'}
                                placeholder="Confirme a senha"
                                required={senha != ''}
                                value={senhaConfirma}
                                disabled={disableFields}
                                width="328px"
                                size="lg"
                                autoComplete="off"
                                focusBorderColor="gray"
                                onChange={(event) =>
                                  setSenhaConfirma(event.currentTarget.value)
                                }
                              />
                              <InputRightElement width="3rem">
                                <Botao
                                  h="1.5rem"
                                  size="lg"
                                  disabled={disableFields}
                                  onClick={handlePasswordVisibility2}
                                  style={{
                                    backgroundColor: 'transparent',
                                    color: globalStyle.colors.GRAY400,
                                    top: '20px'
                                  }}
                                >
                                  {showPassword2 ? (
                                    <Icone name="view" />
                                  ) : (
                                    <Icone name="view-off" />
                                  )}
                                </Botao>
                              </InputRightElement>
                            </S.DivInput>
                          </S.InputGroupS>
                        </FormControl>
                      </S.DivInput>

                      <Button
                        text="CANCELAR"
                        fontSize="16px"
                        padding="0"
                        marginTop="15px"
                        marginRight="20px"
                        width="132px"
                        color='white'
                        backGroundColor={globalStyle.colors.RED500}
                        height="40px"
                        tooltip="Cancelar"
                        onClick={() => {
                          navigate('/usuario/');
                        }}
                      />

                      <Button
                        disabled={disableFields}
                        text="ATUALIZAR"
                        fontSize="16px"
                        padding="0"
                        marginTop="15px"
                        width="132px"
                        color='white'
                        backGroundColor={globalStyle.colors.GRAY100}
                        height="40px"
                        tooltip="Atualizar"
                        type="submit"
                      >
                        {isLoading ? (
                          <CircularProgress
                            isIndeterminate
                            size="24px"
                            color="red"
                          />
                        ) : (
                          'ATUALIZAR'
                        )}
                      </Button>
                    </form>
                  </>
                </S.Container>
              </S.Content>
            </Container>
          </Container>
        </>
      )}
    </div>
  );
}
