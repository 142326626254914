import React from "react";
import { FlexboxGrid } from "rsuite";
import * as S from "./styles";

export function NotFound() {
  return (
    <div className="show-grid"  style={{backgroundColor: "#151729", height: "100vh"}}>
        <FlexboxGrid justify="center" align="middle"  style={{height: "100vh"}}>
            <S.Content>
                <S.TituloErro>404</S.TituloErro>
                <S.TituloErroSubtitulo>Opps! Página não encontrada</S.TituloErroSubtitulo>
                <S.Message>A página que você está procurando não existe. Você pode ter digitado incorretamente o endereço ou a página pode ter sido movida.</S.Message>
                <S.LinkHome href="/">Voltar para o início</S.LinkHome>
            </S.Content>
        </FlexboxGrid>
    </div>
  );
};
