import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 5px;

  button {
    background-color: var(--info-color);
    height: 2rem;
    padding: 0 1rem;
    font-size: 1rem;
    color: #fff;
    border-color: var(--info-color);
    border-radius: 0.25rem;
    border: none;
    text-align: center;

    transition: filter 0.2s;
    width: 100px;

    &:hover {
      filter: brightness(0.9);
    }
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  @media (max-width: 768px) {
    padding: 1rem 0px;
  }

  .rs-modal-body {
    overflow-x: hidden !important;
  }

  .rs-modal-footer {
    width: 100%;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
  }

  input,
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }

  h2 {
    width: 100%;
    color: var(--red);
    font-size: 1.5rem;
    margin-bottom: 5px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-top: 20px;
  }
`;

export const ContainerCardForm = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .rs-form-group {
  }

  label {
    color: ${(props) => props.theme.colors.GRAY100};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 10px;
  }

  .card-form {
    width: calc(100% - 20px);
    display: grid;
    flex: 1;
    gap: 2rem;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
      h3 {
        margin-top: 40px;
      }
    }

    .rs-uploader-picture .rs-uploader-trigger-btn {
      width: 150px;
      height: 150px;
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      border-radius: 50%;
      border: solid 2px #ccc;
    }
    .rs-uploader-trigger-btn {
      img {
        object-fit: cover;
      }
    }
    .rs-icon-size-5x,
    .rs-icon-size-5x.rs-icon {
      font-size: 150px;
      height: 0px;
      margin-left: -2px;
      margin-top: -17px;
    }
    .rs-icon-size-5x,
    .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
      margin-top: 0px !important;
    }
  }
`;

export const ButtonImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 2px #ccc;

  img {
    object-fit: cover;
  }
`;

export const StatusFilialContainer = styled.div`
  margin: 1rem 0px;
`;

export const ContainerPermissao = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

export const TituloPermissao = styled.p`
  font-size: 20px;
  line-height: 1.5em;
  color: #333;
`;
export const ContainerCheck = styled.div`
  margin: 0 0 20px 0;
`;
