import { Table, Container, Loader } from 'rsuite';
import * as S from './styles';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useExtrato } from '../../hooks/extrato';
import Skeleton from 'react-loading-skeleton';
import { VerCompraModal } from '../../components/VerCompraModal/index';
import { ConfirmaExcluirCompraModal } from '../ConfirmaExcluirCompraModal/index';
import { useCompra } from '../../hooks/compra';
import TableComponent from '../UI/TableComponent';
import { usePerfil } from '../../hooks/perfil';
import ConfirmaModal from '../ConfirmaModal';

export function VerCompraTable() {
  const app = 'visualizar_compra';
  const { id } = useParams();
  const { permissaoPerfil, tipoPerfil } = usePerfil();

  const {
    getCompraPorUuid,
    isLoading,
    isLoadingVer,
    CompraPorUUID,
    compraDownload,
    isLoadingDownload,
    verCompraPorUuid,
    VerCompraPorUUID,
    paginationUUID,
    VisualizarCompraPorUUID,
    CompraPageUUID,
    orderCompra,
    deletarCompra
  } = useCompra();
  const { editExtrato } = useExtrato();
  const [isVerCompraModalOpen, setIsVerCompraModalOpen] = useState(false);
  const [uuid, setUuid] = useState('');
  const [listaFinalizar, setListaFinalizar] = useState([]);
  const [isConfirmDeleteExtratoModalOpen, setIsConfirmDeleteExtratoModalOpen] = useState(false);
  const [isConfirmFinalizarExtratoModalOpen, setIsConfirmFinalizarExtratoModalOpen] = useState(false);

  function handleOpenConfirmDelExtratoModal(uuid) {
    setUuid(uuid);

    setIsConfirmDeleteExtratoModalOpen(true);
  }

  function handleCloseConfirmDelExtratoModal() {
    setIsConfirmDeleteExtratoModalOpen(false);
  }

  function handleOpenConfirmFinalizarExtratoModal(uuid, lista) {
    setUuid(uuid);
    setListaFinalizar(lista);

    setIsConfirmFinalizarExtratoModalOpen(true);
  }


  function handleCloseConfirmFinalizarExtratoModal() {
    setIsConfirmFinalizarExtratoModalOpen(false);
  }

  const handleCompra = async () => {
    await VisualizarCompraPorUUID(id);
  };

  useEffect(() => {
    if(id){
      handleCompra();
    }
  }, [id]);

  // useEffect(() => {
  //   if (isConfirmDeleteExtratoModalOpen){
  //     handleCompra();
  //   } 
  // }, [isConfirmDeleteExtratoModalOpen]);


  async function handleDeleteConfirm(e) {
    e.preventDefault();
    
    await deletarCompra(uuid, handleCloseConfirmDelExtratoModal);
    handleCompra();
  }

  const handleFinalizaCompra = async (event) => {
    event.preventDefault();

    function formatarValor(valor) {
      let valorFormatado;

      valorFormatado = `${valor.replace('.', ',')}`;

      return valorFormatado;
    }

    const vendaTipo = '4';

    const formData = {
      usuario: listaFinalizar?.usuario,
      quantidade: listaFinalizar?.Catalogo.length,
      qtd_bruta: listaFinalizar?.Catalogo.length,
      descricao: vendaTipo,
      status: listaFinalizar?.status,
      valor: formatarValor(listaFinalizar?.valor?.real),
      codigo: listaFinalizar?.codigo,
      uuid: listaFinalizar?.uuid
    };

    await editExtrato(formData, handleCloseConfirmFinalizarExtratoModal);
    handleCompra();
  };

  const { Pagination } = Table;

  const handleVerLote = async (uuid, idLote, page) => {
    await getCompraPorUuid(idLote, page);
    window.location.pathname = window.location.pathname + "/" + uuid;
  };

  const handleSelect = async (eventKey) => {
    await CompraPageUUID(id, eventKey);
  };


  const handleOrderingColumn = (event, column) => {
    const columns = document.getElementsByTagName('th');
    for (let column of columns) {
      column.classList.add('columnDefault');
    }

    let el = event.target;
    el.classList.remove('columnDefault');
    el.classList.add('columnActive');

    if (el.lastChild.classList.contains('rs-icon-arrow-up')) {
      el.lastChild.classList.remove('rs-icon-arrow-up');
      el.lastChild.classList.add('rs-icon-arrow-down');
      orderCompra(column, 'DESC', paginationUUID[0].current_page);
    } else {
      el.lastChild.classList.remove('rs-icon-arrow-down');
      el.lastChild.classList.add('rs-icon-arrow-up');
      orderCompra(column, 'ASC', paginationUUID[0].current_page);
    }
  };

  const handleDownload = async (e) => {
    let result = await compraDownload(e);

    if (result.erro == false) {
      window.location.assign(result.link);
    }
  };

  return (
    <div>
      <Container>
        {isLoadingVer || isLoading ? (
          <Loader backdrop content="Carregando..." vertical />
        ) : (
          <>
            <TableComponent
              page={app}
              pagination={paginationUUID}
              handleOrderingColumn={handleOrderingColumn}
              handleClickOpenEdit={handleVerLote}
              handleClickFinalizarLote={handleOpenConfirmFinalizarExtratoModal}
              handleClickDownload={handleDownload}
              handleDeleteExtrato={handleOpenConfirmDelExtratoModal}
              isLoading={isLoadingVer}
              parceiroData={VerCompraPorUUID ? { nome: VerCompraPorUUID[1], cpf: VerCompraPorUUID[2] } : []}
              lista={VerCompraPorUUID[0] ? VerCompraPorUUID[0] : []}
              columns={[
                { header: 'Data / Hora', body: 'dataHora' },
                { header: 'Código', body: 'codigo' },
                { header: 'Nome', body: 'nome' },
                { header: 'CPF', body: 'cpf' },
                { header: 'Valor Total', body: 'valorTotal' },
                { header: '', body: 'action' },
              ]}
              // sempre enviar valores no mesmo index da prop "columns", componente pega pelo index
              orderingByColumnsIndex={['', '', '', '']}
            />
            {isConfirmDeleteExtratoModalOpen ? (
              <ConfirmaModal
                show={isConfirmDeleteExtratoModalOpen}
                setShow={setIsConfirmDeleteExtratoModalOpen}
                bodyText={'Esta ação não pode ser desfeita depois de confirmada. Tem certeza?'}
                title={'Deletar Lote'}
                btnAcaoTexto={'Deletar'}
                submitFn={handleDeleteConfirm}
              />
            ) : null}
            {isConfirmFinalizarExtratoModalOpen ? (
              <ConfirmaModal
                show={isConfirmFinalizarExtratoModalOpen}
                setShow={setIsConfirmFinalizarExtratoModalOpen}
                bodyText={'O lote não poderá ser alterado depois de finalizado. Tem certeza?'}
                title={'Finalizar Lote'}
                btnAcaoTexto={'Finalizar'}
                submitFn={handleFinalizaCompra}
              />
            ) : null}
          </>
        )}
        <S.ContainerPagination>
          {paginationUUID.length ? (
            <Pagination
              prev
              last
              next
              first
              size="lg"
              pages={paginationUUID[0].last_page}
              activePage={paginationUUID[0].current_page}
              onSelect={handleSelect}
            />
          ) : null}
        </S.ContainerPagination>
      </Container>
    </div>
  );
}
