import React from 'react';
import { Container, MaiorExtratoNegativo, MaiorExtratoPositivo, MaioresRegistrosContainer, SaldoValor, Title, UltimaCompra, UltimaCompraData, UltimaCompraParceiro, UltimaCompraTotal } from './styled';
import formatReal from '../../../utils/formatReal';
import globalStyle from '../../../styles/globalStyle';
import Button from '../../../components/UI/Button';
import moment from 'moment';
import { useNavigate } from 'react-router';

export default function Saldo({ data }) {
    const { saldo, lastPurchase } = data;

    const navigate = useNavigate()

    const handleVerExtrato = () => {
        navigate('/extrato')
    }

    const handleVerUltimaCompra = () => {
        if (lastPurchase?.usuario) {
            navigate(`/compra/${lastPurchase.usuario.uuid}`)
        }
    }

    return (
        <Container>
            <Title>Saldo</Title>
            <SaldoValor color={saldo?.saldo[0] == '-' ? globalStyle.colors.RED500 : globalStyle.colors.GREEN500}>
                R$ {formatReal(saldo?.saldo)}
            </SaldoValor>
            <MaioresRegistrosContainer>
                <MaiorExtratoPositivo>
                    R$ {formatReal(saldo?.valorPositivo)}
                </MaiorExtratoPositivo>
                {' - '}
                <MaiorExtratoNegativo>
                    R$ {formatReal(saldo?.valorNegativo)}
                </MaiorExtratoNegativo>
            </MaioresRegistrosContainer>
            <Button
                text="Ver Extrato Completo"
                height="34px"
                uppercase
                backGroundColor={globalStyle.colors.GRAY100}
                color={'white'}
                onClick={handleVerExtrato}
            />
            <Title style={{ marginTop: 32 }}>Última Compra</Title>
            <UltimaCompra>
                <UltimaCompraParceiro>PARCEIRO: <span>{lastPurchase?.usuario?.name}</span></UltimaCompraParceiro>
                <UltimaCompraData>DATA: <span>{moment(lastPurchase?.created_at).format('DD/MM/YYYY - HH:mm')}</span></UltimaCompraData>
                <UltimaCompraTotal>VALOR TOTAL: <span>R$ {formatReal(lastPurchase?.value)}</span></UltimaCompraTotal>
            </UltimaCompra>
            <Button
                text="Detalhe do Lote"
                height="34px"
                uppercase
                style={{
                    marginTop: 16
                }}
                backGroundColor={globalStyle.colors.GRAY100}
                color={'white'}
                onClick={handleVerUltimaCompra}
            />
        </Container>
    )
}