import React from 'react';
import { Container, Label, Valor, Content, Header, HeaderLabel, Value, Title } from './styled';
import globalStyle from '../../../styles/globalStyle';
import formatReal from '../../../utils/formatReal';
import moment from 'moment';

export default function FornecedoresAtivos({ data }) {

    return (
        <Container>
            <Title>Fornecedores Ativos</Title>
            {
                data?.fornecedoresAtivos?.fornecedores ? (
                    <>
                        <Content>
                            <Label>Total</Label>
                            <Valor>{data?.fornecedoresAtivos?.fornecedores ? data?.fornecedoresAtivos?.fornecedores : '0'}</Valor>
                        </Content>
                    </>
                ) : null
            }

        </Container>
    )
}