import React, { useEffect, useState } from 'react';
import { Dropdown, Avatar, Icon, Whisper, Tooltip, InputGroup } from 'rsuite';
import * as S from './styles';
import { Link, useParams } from 'react-router-dom';
import { logout } from '../../services/auth';
import { useCatalogo } from '../../hooks/catalogo';
import { useUsuario } from '../../hooks/user';
import { useExtrato } from '../../hooks/extrato';
import { useCompra } from '../../hooks/compra';
import Input from '../UI/Input';
import globalStyle from '../../styles/globalStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import CatalogIcon from '../../assets/Icons/CatalogIcon';
import UserIcon from '../../assets/Icons/UserIcon';
import ParceirosIcon from '../../assets/Icons/ParceirosIcon';
import DashboardIcon from '../../assets/Icons/DashboardIcon';
import LotesIcon from '../../assets/Icons/LotesIcon';
import ConfigIcon from '../../assets/Icons/ConfigIcon';
import ExtratoIcon from '../../assets/Icons/ExtratoIcon';
import IAIcon from '../../assets/Icons/IAIcon';
import { useParceiro } from '../../hooks/parceiro';
import Button from '../UI/Button';
import DocumentIcon from '../../assets/Icons/DocumentIcon';
import CloseIcon from '../../assets/Icons/CloseIcon';
import ConfirmaModal from '../ConfirmaModal';
import { VscChromeClose } from 'react-icons/vsc';

const Topbar = ({
  pageTitle,
  parceiroTitle,
  parceiroMap,
  viewUser,
  isLocationHistoryPage,
  detalhes,
  username,
  productName,
  novoProduto,
  detalhesProduto,
  detalhesLote,
  onOpenSearchCatalogoModal,
  onOpenSearchUsuarioModal,
  onOpenSearchExtratoModal,
  onOpenSearchCompraModal,
  onOpenSearchCompraUsuarioModal
}) => {
  const [userDD, toggleUserDD] = useState(false);
  const { limpaCatalogo, catalogoSearch, setCatalogoSearch } = useCatalogo();
  const { limpaUser, userSearch, setUserSearch } = useUsuario();
  const { limpaExtrato, extratoSearch, setExtratoSearch } = useExtrato();
  const { limpaCompra, limpaCompraUsuario, compraSearch, setCompraSearch } = useCompra();

  const {
    getCompraPorUuid,
    isLoading,
    CompraPorUUID,
    compraDownload,
    isLoadingDownload,
    verCompraPorUuid,
    VerCompraPorUUID,
    paginationUUID,
    VisualizarCompraPorUUID,
    CompraPageUUID,
    orderCompra,
  } = useCompra();
  const { editExtrato } = useExtrato();
  const { codigoLote } = useParams();
  const { searchCompraUsuario, deletarCompra } = useCompra();
  const { searchExtrato } = useExtrato();
  const { searchCatalogo } = useCatalogo();
  const { searchUsuario } = useUsuario();
  const { getParceiroPorNome, setParceiroNome, parceiroNome } = useParceiro();

  const [search, setSearch] = useState('');
  const [parceiroSearch, setParceiroSearch] = useState('');
  const [isDetalhesLote, setIsDetalhesLote] = useState('');
  const [isConfirmDeleteExtratoModalOpen, setIsConfirmDeleteExtratoModalOpen] = useState(false);
  const [uuid, setUuid] = useState('');

  const handlSearchCompra = async () => {
    let data = {
      nome: compraSearch,
      cpf: compraSearch
    };

    await searchCompraUsuario(data);
  };

  const handlSearchExtrato = async () => {

    const data = {
      nome: extratoSearch,
      cpf: extratoSearch
    };

    await searchExtrato(data);
  };

  const handlSearchCatalog = async () => {

    const data = {
      marca: catalogoSearch,
      modelo: catalogoSearch,
      codigo: catalogoSearch,
      tipo: '',
      status: ''
    };

    await searchCatalogo(data);
  };

  const handlSearchUsuario = async () => {
    const data = {
      tipo: '',
      nome: userSearch,
      cpf: userSearch,
      email: userSearch,
      status: ''
    };

    await searchUsuario(data);
  };

  const handleSearchParceiro = async () =>
    await getParceiroPorNome(parceiroNome);

  const Logout = () => {
    logout();
  };

  async function handlSearchCatalogo(e) {
    e.preventDefault();
    await limpaCatalogo();
  }
  async function handlSearchUser(e) {
    e.preventDefault();
    await limpaUser();
  }
  async function handleSearchExtrato(e) {
    e.preventDefault();
    await limpaExtrato();
  }
  async function handleSearchCompra(e) {
    e.preventDefault();
    await limpaCompra();
  }
  async function handleSearchCompraUsuario(e) {
    e.preventDefault();
    await limpaCompraUsuario();
  }

  const buscaAtivaCompraUsuario = localStorage.getItem(
    'buscaAtivaCompraUsuario'
  );

  const isDetalhesParceiro = () => {
    let url = window.location.href;

    if (url.includes('/parceiro/historico-localizacao/')) {
      return true;
    } else {
      return false
    }
  };

  const handleGetData = async () => {
    await getCompraPorUuid(codigoLote, paginationUUID.current_page);
  }

  useEffect(() => {
    if (isDetalhesLote) {
      handleGetData();
      console.log("DATA", CompraPorUUID);
    }
  }, [isDetalhesLote]);

  useEffect(() => {
    if (codigoLote) {
      setIsDetalhesLote(true);
    }
  }, []);

  const handleUrlLotes = () => {
    let url = window.location.href;

    let newUrl = url.replace(codigoLote, '');
    newUrl = newUrl.slice(0, -1);
    return newUrl;
  }

  function handleOpenConfirmDelExtratoModal() {
    setUuid(codigoLote);

    setIsConfirmDeleteExtratoModalOpen(true);
  }

  function handleCloseConfirmDelExtratoModal() {
    setIsConfirmDeleteExtratoModalOpen(false);
  }

  async function handleDeleteConfirm(e) {
    e.preventDefault();

    await deletarCompra(uuid, handleCloseConfirmDelExtratoModal);
    // window.location.href = handleUrlLotes();
  }

  const handleDownload = async () => {
    let result = await compraDownload(CompraPorUUID[0]?.uuid);

    if (result.erro == false) {
      window.location.assign(result.link);
    }
  };

  const buscaAtivaCompra = localStorage.getItem('buscaAtivaCompra');
  const buscaAtivaUsuarioExtrato = localStorage.getItem('buscaAtivaExtrato');
  const buscaAtivaUsuario = localStorage.getItem('buscaAtivaUsuario');
  const buscaAtiva = localStorage.getItem('buscaAtiva');

  return (
    <>
      <S.TopbarStyle>
        <S.Title>
          {pageTitle == 'Visualizar Compra' ? (
            <S.VoltarPagina href="/compra">
              <Icon icon="long-arrow-left" className="voltar_page" />
            </S.VoltarPagina>
          ) : null}
          {isDetalhesParceiro() ? (
            <S.VoltarPagina href="/parceiros-lista">
              <Icon icon="long-arrow-left" className="voltar_page" />
            </S.VoltarPagina>
          ) : null}
          {detalhesLote ? (
            <S.VoltarPagina href={handleUrlLotes()}>
              <Icon icon="long-arrow-left" className="voltar_page" />
            </S.VoltarPagina>
          ) : null}
          <section>
            <div className='titleLeft' >
              <div>
                <h2>
                  {(pageTitle == 'Usuários' || pageTitle == 'Novo Usuário') && (
                    <UserIcon largura={40} altura={40}></UserIcon>
                  )}

                  {pageTitle == 'Resumo' && (
                    <DashboardIcon largura={40} altura={40} ></DashboardIcon>
                  )}

                  {pageTitle == 'Catálogo' && (
                    <CatalogIcon largura={40} altura={40}></CatalogIcon>
                  )}

                  {pageTitle == 'Lotes' && (
                    <LotesIcon largura={40} altura={40}></LotesIcon>
                  )}

                  {pageTitle == 'Configurações' && (
                    <ConfigIcon largura={40} altura={40}></ConfigIcon>
                  )}

                  {pageTitle == 'Extrato' && (
                    <ExtratoIcon largura={40} altura={40}></ExtratoIcon>
                  )}

                  {pageTitle == 'Parceiros' && (
                    <ParceirosIcon largura={40} altura={40}></ParceirosIcon>
                  )}

                  {pageTitle == 'Minha Conta' && (
                    <UserIcon largura={40} altura={40}></UserIcon>
                  )}

                  {pageTitle == 'IA' && (
                    <IAIcon largura={40} altura={40}></IAIcon>
                  )}

                  {pageTitle != `Novo Usuário` ? pageTitle : 'Usuários'}
                </h2>
              </div>
              {(detalhes || pageTitle == 'Novo Usuário' || detalhesProduto || novoProduto || detalhesLote) && (
                <div>
                  <h2 style={{ marginLeft: 20 }}>{'>'}</h2>
                </div>
              )}

              {detalhesLote && (
                <div className="userName">
                  <h2>{CompraPorUUID[0]?.codigo ? CompraPorUUID[0]?.codigo : null}</h2>
                </div>
              )}

              {detalhes && (
                <div className="userName">
                  <h2>{username}</h2>
                </div>
              )}

              {detalhesProduto && (
                <div className="producName">
                  <h2>{productName}</h2>
                </div>
              )}

              {novoProduto && (
                <div className="novoProduto">
                  <h2>{productName}</h2>
                </div>
              )}

              {pageTitle == 'Novo Usuário' && (
                <div className="userName">
                  <h2>Novo Usuário</h2>
                </div>
              )}
            </div>


          </section>

          {parceiroTitle == true ? (
            <S.ButtonFornecedor href="/parceiros-lista">
              {window.innerWidth > 850 ? (
                'Lista de parceiros'
              ) : (
                <Icon style={{ fontSize: '18px' }} icon="th-list" />
              )}
            </S.ButtonFornecedor>
          ) : null}
          {parceiroMap == true ? (
            <S.ButtonFornecedor href="/parceiros">
              {window.innerWidth > 850 ? (
                'Mapa de parceiros'
              ) : (
                <Icon style={{ fontSize: '18px' }} icon="map-marker" />
              )}
            </S.ButtonFornecedor>
          ) : null}
        </S.Title>
        <S.End>
          {detalhesLote && (
            <div>
              <h3>Valor Total:</h3><h2 className='valor_total'>R$ {CompraPorUUID[0]?.valor?.real ? CompraPorUUID[0]?.valor?.real : null}</h2>

              <Button
                style={{ marginTop: 7 }}
                onClick={() => { handleDownload() }}
                placement="bottom"
                trigger="hover"
                tooltip={'Baixar planilha'}
                text={<DocumentIcon largura={25} />}
                backGroundColor="#CA8A04"
                width="28px"
                height="28px"
                marginLeft="20px"
                marginRight="20px"
                padding="0 0"
              ></Button>

              <Button
                onClick={() => { handleOpenConfirmDelExtratoModal() }}
                placement="bottom"
                trigger="hover"
                tooltip={'Excluir usuário'}
                text={<VscChromeClose
                  color={globalStyle.colors.white}
                  style={{ cursor: 'pointer', marginLeft: 1 }}
                  size={25}
                />}
                backGroundColor={globalStyle.colors.RED500}
                width="28px"
                height="28px"
                padding="0 0"
              ></Button>

            </div>
          )}
          {pageTitle == 'Catálogo' && !detalhes && !detalhesProduto && !novoProduto && !detalhesLote ? (
            <>
              <InputGroup
                style={{ border: `solid 2px ${globalStyle.colors.DARK400}` }}
              >
                <Input
                  value={catalogoSearch}
                  onChange={(e) => setCatalogoSearch(e.target.value)}
                  placeholder="Pesquisar"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handlSearchCatalog()
                    }
                  }}
                  width={418}
                  style={{
                    backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY,
                  }}
                />
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  speaker={<Tooltip>Pesquisar</Tooltip>}
                >
                  <InputGroup.Button
                    onClick={handlSearchCatalog}
                    style={{
                      height: '2.4rem',
                      backgroundColor: globalStyle.colors.TRANSPARENT,
                      color: globalStyle.colors.GRAY100
                    }}
                  >
                    <Icon icon="search" />
                  </InputGroup.Button>
                </Whisper>
              </InputGroup>
            </>
          ) : null}
          {pageTitle == 'Usuários' && !viewUser ? (
            <>
              <InputGroup
                style={{ border: `solid 2px ${globalStyle.colors.DARK400}` }}
              >
                <Input
                  value={userSearch}
                  onChange={(e) => setUserSearch(e.target.value)}
                  placeholder="Pesquisar"
                  width={418}
                  style={{
                    backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY,
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handlSearchUsuario()
                    }
                  }}
                />
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  speaker={<Tooltip>Pesquisar</Tooltip>}
                >
                  <InputGroup.Button
                    onClick={handlSearchUsuario}
                    style={{
                      height: '2.4rem',
                      backgroundColor: globalStyle.colors.TRANSPARENT,
                      color: globalStyle.colors.GRAY100
                    }}
                  >
                    <Icon icon="search" />
                  </InputGroup.Button>
                </Whisper>
              </InputGroup>

              {/* <InputGroup >
            <Whisper
                  placement="bottom"
                  trigger="hover"
                  speaker={<Tooltip>Pesquisar</Tooltip>}
                  >
                  <InputGroup.Button onClick={() => onOpenSearchUsuarioModal()}>
                      <Icon icon="search" />
                  </InputGroup.Button>
                </Whisper>
            </InputGroup>  */}
            </>
          ) : null}
          {pageTitle == 'Extrato' && !detalhes ? (
            <>
              <InputGroup
                style={{ border: `solid 2px ${globalStyle.colors.DARK400}` }}
              >
                <Input
                  value={extratoSearch}
                  onChange={(e) => setExtratoSearch(e.target.value)}
                  placeholder="Pesquisar"
                  width={418}
                  style={{
                    backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY,
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handlSearchExtrato()
                    }
                  }}
                />
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  speaker={<Tooltip>Pesquisar</Tooltip>}
                >
                  <InputGroup.Button
                    onClick={handlSearchExtrato}
                    style={{
                      height: '2.4rem',
                      backgroundColor: globalStyle.colors.TRANSPARENT,
                      color: globalStyle.colors.GRAY100
                    }}
                  >
                    <Icon icon="search" />
                  </InputGroup.Button>
                </Whisper>
              </InputGroup>
            </>
          ) : null}
          {pageTitle === 'Parceiros' && !isLocationHistoryPage ? (
            <>
              <InputGroup
                style={{ border: `solid 2px ${globalStyle.colors.DARK400}` }}
              >
                <Input
                  value={parceiroNome}
                  onChange={(e) => setParceiroNome(e.target.value)}
                  placeholder="Pesquisar"
                  width={418}
                  style={{
                    backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY,
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handleSearchParceiro()
                    }
                  }}
                />
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  speaker={<Tooltip>Pesquisar</Tooltip>}
                >
                  <InputGroup.Button
                    onClick={handleSearchParceiro}
                    style={{
                      height: '2.4rem',
                      backgroundColor: globalStyle.colors.TRANSPARENT,
                      color: globalStyle.colors.GRAY100
                    }}
                  >
                    <Icon icon="search" />
                  </InputGroup.Button>
                </Whisper>
              </InputGroup>
            </>
          ) : null}

          {pageTitle == 'Lotes' && !detalhesLote ? (
            <>
              <InputGroup
                style={{ border: `solid 2px ${globalStyle.colors.DARK400}` }}
              >
                <Input
                  value={compraSearch}
                  onChange={(e) => setCompraSearch(e.target.value)}
                  placeholder="Pesquisar"
                  width={418}
                  style={{
                    backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY,
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handlSearchCompra()
                    }
                  }}
                />
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  speaker={<Tooltip>Pesquisar</Tooltip>}
                >
                  <InputGroup.Button
                    onClick={handlSearchCompra}
                    style={{
                      height: '2.4rem',
                      backgroundColor: globalStyle.colors.TRANSPARENT,
                      color: globalStyle.colors.GRAY100
                    }}
                  >
                    <Icon icon="search" />
                  </InputGroup.Button>
                </Whisper>
              </InputGroup>
            </>
          ) : null}
        </S.End>
      </S.TopbarStyle>
      {isConfirmDeleteExtratoModalOpen ? (
        <ConfirmaModal
          show={isConfirmDeleteExtratoModalOpen}
          setShow={setIsConfirmDeleteExtratoModalOpen}
          bodyText={'Esta ação não pode ser desfeita depois de confirmada. Tem certeza?'}
          title={'Deletar Lote'}
          btnAcaoTexto={'Deletar'}
          submitFn={handleDeleteConfirm}
        />
      ) : null}
    </>
  );
};

export default Topbar;
