// Chave do Token, colocar um
import api from './api';

// Verifica se esta autenticado
export const isAuthenticated = () =>
  localStorage.getItem('access_token') != null;

// Retorna o Token
export const getToken = () => localStorage.getItem('access_token');

// Login
export const login = (token) => {
  localStorage.setItem('access_token', token);
};

// Logout
export const logout = () => {
  api
    .get('/logout')
    .then((response) => {
      if (response.data.message == 'Deslogado com sucesso!') {
        localStorage.clear();
        window.location.pathname = '/login';
      }
    })
    .catch((err) => {
      console.log(err.response);
    });
};
