import React, { useState } from 'react';
import {
  Flex,
  Heading,
  FormControl,
  CircularProgress,
  InputGroup,
  InputRightElement,
  Icon,
  Button,
  Image
} from '@chakra-ui/core';

import ErrorMessage from '../../components/ErrorMessage';
import logo from '../../assets/logo.png';
import styled from 'styled-components';
import FormLabel from '../../components/UI/FormLabel';
import Input from '../../components/UI/Input';
import { Botao, Box, InputSenha, Logo } from './styles';
import globalStyle from '../../styles/globalStyle';
import api from '../../services/api';

export default function ResetSenha() {
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [error, setError] = useState('');
  const [step2, setStep2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [senhasIguais, setSenhasIguais] = useState(false);

  const handleSubmit = async (event) => {
    // event.preventDefault();

    setIsLoading(true);
    const codigo = window.location.search.replace('?key=', '');

    try {
      await api.patch(`/usuario/resetarSenha/${codigo}`, { new_password: newPassword })
      setIsLoading(false);
      setStep2(true);
      voltarLogin()
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const handlePasswordVisibility2 = () => setShowPassword2(!showPassword2);

  const confereSenha = () => {
    if (
      newPassword == newPassword2 &&
      newPassword != '' &&
      newPassword2 != ''
    ) {
      setSenhasIguais(true);
    } else {
      setSenhasIguais(false);
    }
  };

  const salvarSenha = () => {
    // Função salvar senha
    handleSubmit()
    // voltarLogin();
  };

  const voltarLogin = () => {
    window.location.pathname = '/login';
  };

  return (
    <>
      <Flex width="full" align="center" justifyContent="center" height="100%">
        <Box
          p={8}
          maxWidth="380px"
          borderWidth={1}
          borderRadius={8}
          boxShadow="lg"
          style={{ marginTop: '12%' }}
        >
          <>
            <Box textAlign="center">
              <Heading>
                <Logo src={logo} height="120px" alt="Precious Group" />
              </Heading>
            </Box>
            <Box my={4} textAlign="left">
              <FormControl isRequired mt={6}>
                <InputGroup>
                  <InputSenha
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Nova senha"
                    size="lg"
                    autoComplete="off"
                    focusBorderColor="gray"
                    onChange={(event) =>
                      setNewPassword(event.currentTarget.value)
                    }
                    onKeyUp={confereSenha}
                  />
                  <InputRightElement width="3rem">
                    <Button
                      h="1.5rem"
                      size="sm"
                      onClick={handlePasswordVisibility}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      {showPassword ? (
                        <Icon name="view" />
                      ) : (
                        <Icon name="view-off" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <InputGroup>
                  <InputSenha
                    type={showPassword2 ? 'text' : 'password'}
                    placeholder="Confirme a nova senha"
                    size="lg"
                    autoComplete="off"
                    focusBorderColor="gray"
                    onChange={(event) =>
                      setNewPassword2(event.currentTarget.value)
                    }
                    onKeyUp={confereSenha}
                  />
                  <InputRightElement width="3rem">
                    <Button
                      h="1.5rem"
                      size="sm"
                      onClick={handlePasswordVisibility2}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      {showPassword2 ? (
                        <Icon name="view" />
                      ) : (
                        <Icon name="view-off" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Botao
                isDisabled={!senhasIguais}
                onClick={salvarSenha}
                backgroundColor={globalStyle.colors.GRAY100}
                color={'white'}
                variant="outline"
                width="full"
                mt={4}
              >
                {isLoading ? (
                  <CircularProgress isIndeterminate size="24px" color="red" />
                ) : (
                  'SALVAR MINHA NOVA SENHA'
                )}
              </Botao>
            </Box>
          </>
        </Box>
      </Flex>
    </>
  );
}
