import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { Alert } from 'rsuite';
import formatReal, { formatCotacao } from '../utils/formatReal';
import revertFormaPercent from '../utils/revertFormaPercent';
import formatPercent from '../utils/formatPercent';
import moment from 'moment';

const ConfigContext = createContext({});

export const ConfigProvider = (props) => {
  const [dados, setDados] = useState();
  const [configuracao, setConfiguracao] = useState([]);
  
  const [param, setParam] = useState([]);
  const [paramClassificacao, setParamClassificacao] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [isLoadingTeor, setIsLoadingTeor] = useState(false);

  const [classificacaoB2, setClassificacaoB2] = useState('');
  const [geral, setGeral] = useState({});
  const [refino, setRefino] = useState({});
  const [leasing, setLeasing] = useState({});
  const [drop, setDrop] = useState({});
  const [outrasTaxas, setOutrasTaxas] = useState({});
  const [custoOperacional, setCustoOperacional] = useState({});
  const [cotacoes, setCotacoes] = useState({})

  const [atualizaCotacao, setAtualizaCotacao] = useState('')
  const [parceiros, setParceiros] = useState({})
  

  const [isLoadingEditCotacoes, setIsLoadingEditCotacoes] = useState(false);

  const handlePegaUsuarioTeor = useCallback(async () => {
    const response = await api.get('/me');
    const quotation = await api.get('/quotation/last');

    setIsLoading(true);

    setParceiros({
      peca: response.data.peca || '',
      po: response.data.po || '',
      inox: response.data.inox || '',
      filtro: response.data.filtro || ''
    });

    const cotacoes = {
      ptCotacao: formatReal(quotation.data.pt_quotation?.toFixed(2)),
      pdCotacao: formatReal(quotation.data.pd_quotation?.toFixed(2)),
      rhCotacao: formatReal(quotation.data.rh_quotation?.toFixed(2)),
      dolarCotacao: formatReal(quotation.data.dolar_quotation?.toFixed(2)),
      validadeInicio: quotation.data.start_date || moment().format("YYYY-MM-DD"),
      validadeFim: quotation.data.end_date
    }

    setCotacoes(cotacoes);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    handlePegaUsuarioTeor();
  }, [handlePegaUsuarioTeor]);

  const handlePegaParam = useCallback(async () => {
    const response = await api.get('/cron');
    setIsLoading(true);
    setAtualizaCotacao(response.data.update_frequency);

    setIsLoading(false);
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    handlePegaParam();
  }, [handlePegaParam]);

  const handleCalculo = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get('/calculo');

    const data = response.data;

    setDados(data);

    setGeral({
      cal_determinaCustoFinan: revertFormaPercent(data?.cal_determinaCustoFinan) || '',
      cal_determinaPeso: revertFormaPercent(data?.cal_determinaPeso) || '',
      cal_divisorCustoTotal: data?.cal_divisorCustoTotal || '',
      cal_logistica: formatReal(data?.cal_logistica.toFixed(2)) || ''
    });

    setRefino({
      cal_ptPercentual: revertFormaPercent(data?.cal_ptPercentual) || '',
      cal_pdPercentual: revertFormaPercent(data?.cal_pdPercentual) || '',
      cal_rhPercentual: revertFormaPercent(data?.cal_rhPercentual) || '',
      cal_ptCusto: data?.cal_ptCusto || '',
      cal_pdCusto: data?.cal_pdCusto || '',
      cal_rhCusto: data?.cal_rhCusto || ''
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleCalculo();
  }, [handleCalculo]);

  const handleParamClassificacao = useCallback(async () => {
    setIsLoadingConfig(true);

    try {
      const response = await api.get('/params');

      setClassificacaoB2(response.data.b2.replace('.', ','));

      setIsLoadingConfig(false);
    } catch(err) {
      console.log(err);
      
      setIsLoadingConfig(false);
    }
  }, [])

  useEffect(() => {
    handleParamClassificacao();
  }, [handleParamClassificacao])

  const handleConfiguracao = useCallback(async () => {
    setIsLoadingConfig(true);
    const response = await api.get('/configuracao');

    setParam(response.data);
    setIsLoadingConfig(false);
  }, []);

  useEffect(() => {
    handleConfiguracao();

    return () => {
      setParam([]);
      setIsLoadingConfig(false);
    };
  }, [handleConfiguracao]);

  const handleParam = useCallback(async () => {
    setIsLoadingConfig(true);
    const response = await api.get('/configuracao');

    setConfiguracao(response.data);
    setIsLoadingConfig(false);
  }, []);

  useEffect(() => {
    handleConfiguracao();
  }, [handleConfiguracao]);

  // EDITANDO OS USUÁRIOS
  async function editCalculo(ConfigInputEdit) {
    try {
      setIsLoadingEdit(true);

      let data = {
        // general
        peso_embarque: ConfigInputEdit.peso_embarque,
        pt: ConfigInputEdit.pt,
        pd: ConfigInputEdit.pd,
        rh: ConfigInputEdit.rh,
        pt_quotation: ConfigInputEdit.pt_quotation,
        pd_quotation: ConfigInputEdit.pd_quotation,
        rh_quotation: ConfigInputEdit.rh_quotation,
        pt_refining_charges: ConfigInputEdit.pt_refining_charges,
        pd_refining_charges: ConfigInputEdit.pd_refining_charges,
        rh_refining_charges: ConfigInputEdit.rh_refining_charges,
        pd_pgm_drop: ConfigInputEdit.pd_pgm_drop,
        pt_pgm_drop: ConfigInputEdit.pt_pgm_drop,
        rh_pgm_drop: ConfigInputEdit.rh_pgm_drop,
        pt_days: ConfigInputEdit.pt_days,
        pd_days: ConfigInputEdit.pd_days,
        rh_days: ConfigInputEdit.rh_days,
        //Leasing Charge
        pt_leasing_charge: ConfigInputEdit.pt_leasing_charge,
        pd_leasing_charge: ConfigInputEdit.pd_leasing_charge,
        rh_leasing_charge: ConfigInputEdit.rh_leasing_charge,
        //Other Charge
        assay_custom_per_shipment: ConfigInputEdit.assay_custom_per_shipment,
        treatment_per_shipment: ConfigInputEdit.treatment_per_shipment,
        refining_per_shipment: ConfigInputEdit.refining_per_shipment,
        assay_custom_per: ConfigInputEdit.assay_custom_per,
        treatment_per: ConfigInputEdit.treatment_per,
        refining_per: ConfigInputEdit.refining_per,
        //Aditional Residual
        cambio: ConfigInputEdit.cambio,
        residual_per_shipment: ConfigInputEdit.residual_per_shipment,
        //operational costs
        custos_fixos_per_shipment: ConfigInputEdit.custos_fixos_per_shipment,
        imposto_per_shipment: ConfigInputEdit.imposto_per_shipment,
        frete_per_shipment: ConfigInputEdit.frete_per_shipment,
        imposto_percent: ConfigInputEdit.imposto_percent,
        custos_fixos_percent: ConfigInputEdit.custos_fixos_percent,
        frete_percent: ConfigInputEdit.frete_percent,
        //antecipacao
        antecipacao: ConfigInputEdit.antecipacao
      };

      const response = await api.post('/calculo', data);

      setIsLoadingEdit(false);
      handleCalculo();

      Alert.success(response.data.message);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingEdit(false);
    }
  }
  // EDITANDO OS USUÁRIOS
  async function uploadPlanilha(planilha, modalPlanilha, modalUpload) {
    try {
      setIsLoadingEdit(true);

      const data = new FormData();
      data.append('planilha', planilha);

      const response = await api.post('/planilha', data);

      setIsLoadingEdit(false);
      handleCalculo();
      modalPlanilha();
      modalUpload();

      Alert.success(response.data.message);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingEdit(false);
    }
  }

  async function atualizarConfigs() {
    setIsLoading(true);
    const data = {
      calcs: {
        cal_pdPercentual: formatPercent(refino.cal_pdPercentual),
        cal_ptPercentual: formatPercent(refino.cal_ptPercentual),
        cal_rhPercentual: formatPercent(refino.cal_rhPercentual),
        cal_pdCusto: refino.cal_pdCusto,
        cal_ptCusto: refino.cal_ptCusto,
        cal_rhCusto: refino.cal_rhCusto,
        cal_divisorCustoTotal: geral.cal_divisorCustoTotal,
        cal_logistica: typeof geral.cal_logistica === 'number' ? geral.cal_logistica.toFixed(2) : formatCotacao(geral.cal_logistica),
        cal_determinaCustoFinan: formatPercent(geral.cal_determinaCustoFinan),
        cal_determinaPeso: formatPercent(geral.cal_determinaPeso),
    },
    quotation: {
        pt_quotation: typeof cotacoes.ptCotacao === 'number' ? cotacoes.ptCotacao : formatCotacao(cotacoes.ptCotacao),
        pd_quotation: typeof cotacoes.pdCotacao === 'number' ? cotacoes.pdCotacao : formatCotacao(cotacoes.pdCotacao),
        rh_quotation: typeof cotacoes.rhCotacao === 'number' ? cotacoes.rhCotacao : formatCotacao(cotacoes.rhCotacao),
        dolar_quotation: typeof cotacoes.dolarCotacao === 'number' ? cotacoes.dolarCotacao : formatCotacao(cotacoes.dolarCotacao),
        start_date: cotacoes.validadeInicio,
        end_date: cotacoes.validadeFim,
        origin: "usuario"
    },
    cronFrequency: {
        new_frequency: atualizaCotacao
    },
    params: {
        b2: classificacaoB2.replace(',', '.')
    }
  }

    try {
      const response = await api.put('/configuracao/atualizar', data);

      console.log(response)
      Alert.success(response.data.message);
      setIsLoading(false);
    } catch(err) {
      console.log(err);        
      setIsLoading(true);
    }
  }

  async function atualizaTeor() {
    const data = {
      teor: {
          piece: parceiros.peca,
          dust: parceiros.po,
          inox: parceiros.inox,
          filter: parceiros.filtro
      },
    }

    try {
      const response = await api.put('/configuracao/atualizar', data);

      Alert.success(response.data.message);
    } catch(err) {
      console.log(err);
    }
  }

  // EDITANDO OS USUÁRIOS
  async function editTeor(modalExcluir, ConfigInputEdit) {
    try {
      setIsLoadingTeor(true);

      let data = {
        piece: ConfigInputEdit.peca,
        dust: ConfigInputEdit.po,
        inox: ConfigInputEdit.inox,
        filter: ConfigInputEdit.filtro
      };

      const response = await api.put('/usuario/teor', data);

      setIsLoadingTeor(false);
      modalExcluir();

      Alert.success(response.data.message);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingTeor(false);
    }
  }

  // EDITANDO OS PARAMETRO DE ATUALIZACAO COTACAO
  async function editParam(modalExcluir, ConfigInputEdit) {
    try {
      setIsLoadingTeor(true);

      let data = {
        new_frequency: ConfigInputEdit.param
      };

      const response = await api.post('/cron', data);

      setIsLoadingTeor(false);
      modalExcluir();

      Alert.success(
        response.status == 200
          ? 'Parâmetro de atualizacões da cotação atualizado com sucesso!'
          : response.data.message
      );
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingTeor(false);
    }
  }

  // EDITANDO OS PARAMETRO DE ATUALIZACAO COTACAO
  async function editB2(modalExcluir, ConfigInputEdit) {
    try {
      setIsLoadingTeor(true);

      let data = {
        b2: ConfigInputEdit.b2
      };

      const response = await api.post('/params', data);

      setIsLoadingTeor(false);
      modalExcluir();

      Alert.success(
        response.status == 200
          ? 'Parâmetro de classificação B2 atualizado com sucesso!'
          : response.data.message
      );
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingTeor(false);
    }
  }

  async function zerarDescontos() {
    try {
      const response = await api.post('/catalogo/reset-descontos');

      Alert.success(response.data.message);
    } catch(err) {
      Alert.error('Não foi possivel zerar os descontos.');
    }
  }
  async function handleQuotations(
    handleCloseEditCotacoesModal,
    platinum,
    palladium,
    rhodium,
    dolar
  ) {
    setIsLoadingEditCotacoes(true);
    try {
      const data = {
        pt_quotation: formatCotacao(platinum),
        pd_quotation: formatCotacao(palladium),
        rh_quotation: formatCotacao(rhodium),
        dolar_quotation: formatCotacao(dolar),
        origin: 'usuario'
      };

      await api
        .post('/quotation/create', data)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log('ErrorUpdateQuotation: ', err);
        });
      handleCloseEditCotacoesModal();
      Alert.success('Informações atualizadas.');
    } catch (err) {
      Alert.error('Não foi possivel atualizar as informações.');
    }
    setIsLoadingEditCotacoes(false);
  }

  return (
    <ConfigContext.Provider
      value={{
        isLoading,
        isLoadingEditCotacoes,
        handleQuotations,
        dados,
        editCalculo,
        configuracao,
        isLoadingConfig,
        isLoadingEdit,
        uploadPlanilha,
        editTeor,
        editParam,
        editB2,
        isLoadingTeor,
        classificacaoB2,
        setClassificacaoB2,
        setGeral,
        geral,
        refino,
        setRefino,
        leasing,
        setLeasing,
        drop,
        setDrop,
        outrasTaxas,
        setOutrasTaxas,
        setCustoOperacional,
        custoOperacional,
        atualizaCotacao,
        setAtualizaCotacao,
        setParceiros,
        parceiros,
        atualizarConfigs,
        cotacoes,
        setCotacoes,
        atualizaTeor,
        zerarDescontos
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export function useConfig() {
  const context = useContext(ConfigContext);

  return context;
}
