import React, { useEffect, useState } from 'react';

// Componente Global da pagina Config
import { Label, Title } from '../styles';

import { Container, InputsContainer } from './styled';
import globalStyle from '../../../styles/globalStyle';
import Input from '../../../components/UI/Input';
import { useConfig } from '../../../hooks/configuracao';
import formataCampoPorcentagem from '../../../utils/formataCampoPorcentagem';
import formatReal from '../../../utils/formatReal';
import NumberFormat from 'react-number-format';

export default function Geral() {
  const { geral, setGeral } = useConfig();

  return (
    <Container>
      <Title>Geral</Title>
      <div style={{ display: 'flex', width: 700 }}>
        <InputsContainer
          style={{
            display: 'flex',
            width: 700,
            justifyContent: 'flex-start'
          }}
        >
          <div
            style={{
              margin: '0 20px 0 0'
            }}
          >
            <Label
              style={{
                marginBottom: 8
              }}
            >
              {'Determina custo financeiro'}
            </Label>
            <NumberFormat
              suffix="%"
              type="%"
              value={geral.cal_determinaCustoFinan || ''}
              onChange={(e) =>
                setGeral({
                  ...geral,
                  cal_determinaCustoFinan: formataCampoPorcentagem(
                    e.target.value
                  )
                })
              }
              width={99}
              height={34}
              style={{
                width: 99,
                height: 34,
                borderRadius: 4,
                padding: '0 16px',
                marginRight: 0,
                background: 'transparent',
                border: `solid 1px ${globalStyle.colors.DARK400}`,
                color: globalStyle.colors.GRAY400
              }}
            />
          </div>
          <div
            style={{
              margin: '0 25px'
            }}
          >
            <Label
              style={{
                marginBottom: 8
              }}
            >
              {'Determina peso'}
            </Label>
            <NumberFormat
              suffix="%"
              type="%"
              value={geral.cal_determinaPeso || ''}
              onChange={(e) =>
                setGeral({
                  ...geral,
                  cal_determinaPeso: formataCampoPorcentagem(e.target.value)
                })
              }
              width={99}
              height={34}
              style={{
                width: 99,
                height: 34,
                borderRadius: 4,
                padding: '0 16px',
                marginRight: 0,
                background: 'transparent',
                border: `solid 1px ${globalStyle.colors.DARK400}`,
                color: globalStyle.colors.GRAY400
              }}
            />
          </div>
          <div
            style={{
              margin: '0 25px'
            }}
          >
            <Label
              style={{
                marginBottom: 8
              }}
            >
              {'Divisor custo total'}
            </Label>
            <Input
              value={geral.cal_divisorCustoTotal || ''}
              onChange={(e) =>
                setGeral({ ...geral, cal_divisorCustoTotal: e.target.value })
              }
              style={{
                width: 99,
                height: 34,
                background: 'transparent',
                border: `1px solid ${globalStyle.colors.DARK400}`,
                color: globalStyle.colors.GRAY400
              }}
            />
          </div>
          <div
            style={{
              margin: '0 25px'
            }}
          >
            <Label
              style={{
                marginBottom: 8
              }}
            >
              {'Logística'}
            </Label>
            <NumberFormat
              type="text"
              prefix="$ "
              decimalSeparator=","
              thousandSeparator="."
              value={geral.cal_logistica || '   '}
              onChange={(e) =>
                setGeral({
                  ...geral,
                  cal_logistica: formatReal(e.target.value)
                })
              }
              style={{
                width: 99,
                height: 34,
                borderRadius: 4,
                padding: '0 16px',
                marginRight: 0,
                background: 'transparent',
                border: `solid 1px ${globalStyle.colors.DARK400}`,
                color: globalStyle.colors.GRAY400
              }}
            />
          </div>
        </InputsContainer>
      </div>
    </Container>
  );
}
