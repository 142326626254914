import { useState } from 'react';
import { Modal, Button, FormGroup, Alert } from 'rsuite';
import * as S from './styles';
import { useExtrato } from '../../hooks/extrato';
import formatReal from '../../utils/formatReal';
import { Checkbox, CheckboxGroup } from 'rsuite';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';

export function EditExtratoModal({ isOpenEdit, onRequestCloseEdit }) {
  const { tipoEdit, editExtrato, uuidEdit, nomeEdit } = useExtrato();

  let descricaoNeutro = tipoEdit == 6 ? '6' : '';
  const [valor, setValor] = useState('');
  const [descricao, setDescricao] = useState(descricaoNeutro);
  const [status, setStatus] = useState('');
  const [codigoLote, setCodigoLote] = useState('');
  const [dataCriacao, setDataCriacao] = useState('');

  const [qntLiquida, setQntLiquida] = useState('');
  const [qntBruta, setQntBruta] = useState('');
  const [observacao, setObservacao] = useState('');
  const [saldo, setSaldo] = useState(1);

  const handleSaveExtrato = async (event) => {
    event.preventDefault();

    if (!descricao) {
      return Alert.error('Selecione o tipo');
    }
    if (!dataCriacao) {
      return Alert.error('Selecione a data');
    }
    if (!status) {
      return Alert.error('Selecione o status');
    }
    if (!qntBruta) {
      return Alert.error('Insira a quantidade bruta');
    }
    if (!valor) {
      return Alert.error('Insira um valor');
    }
    let data = {
      usuario: uuidEdit,
      valor,
      descricao,
      quantidade: qntLiquida,
      status,
      codigo: codigoLote,
      observacao,
      saldo: saldo,
      qtdBruta: qntBruta,
      created_at: dataCriacao
    };

    await editExtrato(data, onRequestCloseEdit);
  };

  const descricoes = {
    1: 'Transferência',
    2: 'Pesáveis',
    3: 'Catalisadores',
    4: 'Venda',
    5: 'Crédito',
    6: 'Outros',
    7: 'Retorno Pesáveis',
    8: 'Retorno Catalisadores',
    9: 'Pagamento',
    10: 'Adiantamento'
  };

  function optionsDescricaoPositivo() {
    var es = Object.entries(descricoes);
    return es.map((item) => (
      <option key={`descricao${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const vendaTipo = {
    1: 'SELECIONADO',
    2: 'MANTA',
    3: 'FILTRO',
    4: 'CAÇAMBA',
    5: 'CAMINHÃO',
    6: 'TIPO AMAROK',
    7: 'TIPO DUCATO',
    8: 'ANEL DE INOX',
    9: 'SONDA LAMBDA',
    10: 'B2'
  };

  function optionsVendaTipo() {
    var es = Object.entries(vendaTipo);
    return es.map((item) => (
      <option key={`venda_tipo${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const ostatus = {
    1: 'Feito',
    2: 'Análise',
    3: 'Estorno'
  };

  function optionsStatus() {
    var es = Object.entries(ostatus);
    return es.map((item) => (
      <option key={`status${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  return (
    <S.Container
      style={{
        display: `${isOpenEdit ? 'flex' : 'none'}`
      }}
      onSubmit={handleSaveExtrato}
    >
      <div className="extrato-modal-body">
        <div className="card-form">
            <h2>Saldo</h2>
            <p>{nomeEdit}</p>
            <FormGroup className="descricao">
              <div>Tipo</div>
              <select
                placeholder="Transação"
                required
                onChange={(event) => {
                  setDescricao(event.target.value);
                }}
                value={descricao}
              >
                <option key="" value="">
                  Tipo
                </option>
                {optionsDescricaoPositivo()}
              </select>
              {descricao == 6 && (
                <>
                  {/*<CheckboxGroup style={{marginTop: 15}}>
                                        <Checkbox>
                                            <S.SaldoPositivo onClick={(e) => setSaldo(1)}>+ Saldo</S.SaldoPositivo>
                                        </Checkbox>
                                        <Checkbox>
                                            <S.ButtonCancelar onClick={(e) => setSaldo(0)}>- Saldo</S.ButtonCancelar>
                                        </Checkbox>        
                            </CheckboxGroup>*/}

                  <div style={{ marginTop: 15 }}>Saldo</div>
                  <select onChange={(e) => setSaldo(e.target.value)}>
                    <option value={1}>+ Saldo</option>
                    <option value={0}>- Saldo</option>
                  </select>
                  <input
                    onChange={(e) => setObservacao(e.target.value)}
                    type="text"
                    placeholder="Descricao"
                  />
                </>
              )}
            </FormGroup>
            <div
              style={{
                fontWeight: '600',
                marginTop: '1.25rem',
                fontSize: '1.5rem'
              }}
            >
              Informações
            </div>
            <S.Informacoes>
              <FormGroup className="cod-lote">
                <label>Data de criação</label>
                <InputMask
                  type="datetime-local"
                  placeholder="Data de criação"
                  required
                  onChange={(event) => setDataCriacao(event.target.value)}
                  value={dataCriacao}
                />
              </FormGroup>
              <FormGroup className="cod-lote">
                <label>Código do Lote</label>
                <input
                  type="text"
                  placeholder="Código do Lote"
                  required
                  value={codigoLote}
                  onChange={(event) => setCodigoLote(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <label>Quantidade Bruta</label>

                <input
                  type="text"
                  placeholder="Quantidade Bruta"
                  required
                  value={qntBruta}
                  onChange={(event) =>
                    setQntBruta(formatReal(event.target.value))
                  }
                // isNumericString={true}
                />
              </FormGroup>
              <FormGroup>
                <label>Quantidade Líquida</label>
                <input
                  type="text"
                  placeholder="Quantidade Líquida"
                  required
                  value={qntLiquida}
                  onChange={(event) =>
                    setQntLiquida(formatReal(event.target.value))
                  }
                // isNumericString={true}
                />
              </FormGroup>
              <FormGroup className="valor">
                <label>Valor (R$)</label>
                <input
                  type="text"
                  placeholder="Valor"
                  required
                  value={valor}
                  onChange={(event) => setValor(formatReal(event.target.value))}
                  disabled={descricao == '7' || descricao == '8'}
                />
              </FormGroup>
              <FormGroup className="status">
                <label>Status</label>
                <select
                  placeholder="Status"
                  required
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  value={status}
                >
                  <option key="" value="">
                    Status
                  </option>
                  {optionsStatus()}
                </select>
              </FormGroup>
            </S.Informacoes>

            <div style={{ display: 'flex', marginTop: '2rem' }}>
              <S.ButtonCancelar onClick={onRequestCloseEdit}>
                Cancelar
              </S.ButtonCancelar>
              <S.ButtonSalvar type='submit'>Salvar</S.ButtonSalvar>
            </div>
        </div>
      </div>
    </S.Container>
  );
}
