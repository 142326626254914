import styled from "styled-components";

export const Container = styled.div`
    margin-top: 32px;
    margin-right: 71px;
`

export const Label = styled.div`
    width: 150px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.theme.colors.GRAY100};
`