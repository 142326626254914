import React from 'react';
import { Container, Header, Sidebar as Sidenav } from 'rsuite';

import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar/index';
import { PerfilProvider, usePerfil } from '../../hooks/perfil';
import globalStyle from '../../styles/globalStyle';
import { Content, ConteudoContainer, ConteudoTopParceiros, ConteudoValores } from './styled';
import Saldo from './Saldo';
import Cotacao from './Cotacao';
import Pecas from './Pecas';
import { TopParceiros } from './TopParceiros';
import { Navigate } from 'react-router-dom';
import FornecedoresAtivos from './FornecedoresAtivos';
import PrepostosAtivos from './PrepostosAtivos';

const Home = () => {
  const { dadosDashboard, tipoPerfil, loadingPer } = usePerfil()

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1
  ) {
    return <Navigate replace to="/catalogo" />;
  }
  return (
    <>
      <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
        <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
          <Sidebar activePage={'1'} />
        </Sidenav>
        <Container>
          <Header style={{ minHeight: '3.6rem' }}>
            <Topbar pageTitle={'Resumo'} />
          </Header>
          <Content>
            <ConteudoContainer>
              <ConteudoValores>
                <Saldo data={dadosDashboard} />
                <div style={{ width: '60%' }}>
                  <Cotacao data={dadosDashboard?.lastQuotation} />
                  <Pecas data={dadosDashboard} />
                  <div style={{ display: 'flex', marginTop: '30px', marginLeft: '7px' }}>
                    <FornecedoresAtivos data={dadosDashboard} />
                    <PrepostosAtivos data={dadosDashboard} />
                  </div>
                </div>
              </ConteudoValores>
              <ConteudoTopParceiros>
                <TopParceiros data={dadosDashboard?.top10} />
              </ConteudoTopParceiros>
            </ConteudoContainer>
          </Content>
        </Container>
      </Container>
    </>
  );
};

export default Home;
