import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  @media (max-width: 768px) {
    padding: 1rem 0px;
  }

  .rs-modal-body {
    overflow-x: hidden !important;
  }

  .rs-modal-footer {
    width: 100%;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .rs-form-group {
    width: 100%;
    margin-top: 1.25rem;
    .close_search {
      width: 40px;
      margin-right: 5px;
      margin-bottom: 5px;
      z-index: 2000;
      a {
        background-color: #181818;
        color: rgb(255, 255, 255);
      }
      &:hover,
      &:focus {
        border-color: #181818 !important;
      }
    }
  }
  label {
    color: ${(props) => props.theme.colors.GRAY100};
  }
  input {
    color: ${(props) => props.theme.colors.GRAY100};
    border: 2px solid ${(props) => props.theme.colors.GRAY100};
    width: calc(100% - 20px);
  }

  select {
    width: calc(100% - 20px);
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
    color: ${(props) => props.theme.colors.GRAY100};
    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }

    & + input {
      margin-top: 0.25rem;
    }
  }

  h2 {
    width: 100%;
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 5px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-top: 20px;
  }
`;

export const TabelaCatalogo = styled.table`
  width: calc(100% - 10px);
  border-collapse: collapse;
  border-spacing: 0;
  margin: 10;
  padding: 10;
  background: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};

  tr {
    padding: 15px;
    margin-bottom: 10px;
    display: block;
    display: table-row;
    border-bottom-width: 1px;
    margin-bottom: 0;
    background: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
  }

  td,
  th {
    padding: 5px;
    background: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
  }

  thead tr th {
    color: ${(props) => props.theme.colors.GRAY100};
    background: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
  }

  tbody tr th {
    color: ${(props) => props.theme.colors.GRAY100};
    background: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
  }

  thead tr:first-child th:first-child {
    background-color: ${(props) => props.theme.colors.GRAY100};
    color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
  }

  tbody tr td {
    text-align: center;

    input {
      color: ${(props) => props.theme.colors.GRAY100};
    }
    select {
      width: 100%;
      padding: 0px;
      height: 2rem;
      border-radius: 0.25rem;
      border: 1px solid #eee;
      background: #eee;

      font-weight: 400;
      font-size: 1rem;
      &::placeholder {
        color: var(--text-body);
      }

      & + input {
        margin-top: 0.25rem;
      }
    }
  }
`;
export const StatusContainer = styled.div`
  margin: 10px 0px;
`;
export const FotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 20px);
  cursor: pointer;
  label {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0px;
    opacity: 0;
    cursor: pointer;
    z-index: 1000;
  }
`;
export const ContainerUpload = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  padding: 0px 20px;
  .alterar {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
    a {
      background-color: var(--warning-color);
      color: rgb(255, 255, 255);
    }
    &:hover,
    &:focus {
      border-color: var(--warning-color) !important;
    }
  }
`;
