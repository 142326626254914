import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Container, Header, Sidebar as Sidenav, Content, Loader } from 'rsuite';
import { usePerfil } from '../../hooks/perfil';
import { ExtratoProvider, useExtrato } from '../../hooks/extrato';
import DetalheTable from './DetalheTable';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import globalStyle from '../../styles/globalStyle';

export function ExtratoDetalhe() {
  const { isLoadingVerEdit, VerExtratoPorUUID, ExtratoLista, verExtratoPorUuid, isLoadingEdit } = useExtrato();

  const navigate = useNavigate()

  const { state } = useLocation()

  const handleDados = async () => {

    await verExtratoPorUuid(state.uuid, state.nome);
  }

  useEffect(() => {
    handleDados();
  }, [])

  return (
    <>
      {isLoadingVerEdit || isLoadingEdit ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : (
        <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
          <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
            <Sidebar activePage={'6'} />
          </Sidenav>
          <Container>
            <Header style={{ minHeight: '3.6rem' }}>
              <Topbar
                username={state.nome}
                detalhes
                pageTitle={'Extrato'}
              />
            </Header>
            <Content>
              <DetalheTable nome={state.nome} uuid={state.uuid} />
            </Content>
          </Container>
        </Container>
      )}
    </>
  )
}