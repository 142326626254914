function formatReal(i) {
  if (i) {
    var v = i.replace(/\D/g, '');
    v = (v / 100).toFixed(2) + '';
    v = v.replace('.', ',');
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
    return (i = v);
  }
  return '';
}

export function formatCotacao(i) {
  if (i) {
    var v = i.replace('.', '');
    v = v.replace(',', '.');
    return (i = v);
  }
  return '';
}
export default formatReal;
