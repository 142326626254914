import { propNames } from '@chakra-ui/react';
import React from 'react';
import {
  Modal,
  Button,
  FormGroup,
  Placeholder,
  Tooltip,
  Whisper,
  Table
} from 'rsuite';
import globalStyle from '../../styles/globalStyle';
import * as S from './styles';

export default function PiecesHistory(props) {
  const { Pagination } = Table;

  const handleSelect = async (eventKey) => {
    await props.handlePages(eventKey, props.userUuid);
  };

  return (
    <Modal show={props.show} onHide={props.close} size="lg">
      <Modal.Header>
        <div style={{ color: globalStyle.colors.GRAY100 }}>
          Visualizar Historico de Peças
        </div>
      </Modal.Header>
      <Modal.Body>
        <S.ContainerCardForm>
          <table>
            <thead>
              <tr>
                <th style={{ width: 50 }}>Peça</th>
                <th style={{ width: 50 }}>Pó</th>
                <th style={{ width: 50 }}>INOX</th>
                <th style={{ width: 50 }}>Filtro</th>
                <th style={{ width: 50 }}>Data Criação</th>
                <th style={{ width: 50 }}>Alterado por</th>
              </tr>
            </thead>
            <tbody>
              {props.pieces
                ? props.pieces.map((piece, index) => (
                    <tr key={index}>
                      <td style={{ width: 50 }} data-label="Peça">
                        {piece?.piece}
                      </td>
                      <td style={{ width: 50 }} data-label="Pó">
                        {piece?.dust}
                      </td>
                      <td style={{ width: 50 }} data-label="INOX">
                        {piece?.inox}
                      </td>
                      <td data-label="Filtro">{piece?.filter}</td>
                      <td style={{ width: 50 }} data-label="Data de Criação">
                        {new Date(
                          `${piece.created_at.split('T')[0]} ${
                            piece.created_at.split('T')[1]
                          }`
                        ).toLocaleDateString('pt-BR')}{' '}
                        {new Date(
                          `${piece.created_at.split('T')[0]} ${
                            piece.created_at.split('T')[1]
                          }`
                        ).toLocaleTimeString('pt-BR')}
                      </td>
                      <td style={{ width: 50 }} data-label="Usuário">
                        {piece?.criado_por.name}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </S.ContainerCardForm>
        <S.ContainerPagination>
          {true ? (
            <Pagination
              prev
              last
              next
              first
              size="lg"
              pages={props.pagination?.last_page}
              activePage={props.pagination?.current_page}
              onSelect={handleSelect}
            />
          ) : null}
        </S.ContainerPagination>
      </Modal.Body>
    </Modal>
  );
}
