import React, { useEffect, useState } from "react";
import { ExtratoProvider, useExtrato } from "../../../hooks/extrato";
import { Container, ContainerPaginacao, LinhaDeMargem, Select, TH, ValorTotal } from "./styled";
import { FaRegEdit } from 'react-icons/fa';
import { usePerfil } from "../../../hooks/perfil";
import formatReal from "../../../utils/formatReal";
import Button from "../../../components/UI/Button";
import { AiOutlineClose, AiOutlineEye } from 'react-icons/ai';
import globalStyle from "../../../styles/globalStyle";
import { Pagination, Tooltip, Whisper } from "rsuite";
import { VscChromeClose } from 'react-icons/vsc';
import EyeIcon from "../../../assets/Icons/EyeIcon";
import EnviarIcon from "../../../assets/Icons/EnviarIcon";
import { useNavigate } from "react-router-dom";
import ConfirmaModal from "../../../components/ConfirmaModal";


const Linha = () => (
  <LinhaDeMargem>
    <td />
    <td />
    <td />
    <td />
    <td />
    <td />
    <td />
    <td />
  </LinhaDeMargem>
)

export default function DetalheTable({ nome, uuid }) {
  const { vereditExtrato, VerExtratoPorUUID, paginationExtrato: paginationData, extratoModalPage, deletarExtrato } = useExtrato();

  const navigate = useNavigate()

  const [showConfirmaModal, setShowConfirmaModal] = useState(false)

  const [data, setData] = useState(VerExtratoPorUUID[0] || []);
  const [pagination, setPagination] = useState(paginationData || null)
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null
  });
  const [dataCriacao, setDataCriacao] = useState('');
  const [descricao, setDescricao] = useState('');
  const [qtdBruta, setQtdBruta] = useState('');
  const [codigo, setCodigo] = useState('');
  const [status, setStatus] = useState('');
  const [qtdLiquida, setQtdLiquida] = useState('');
  const [valor, setValor] = useState('');
  const [usuarioId, setUsuarioId] = useState('');
  const [usuarioUUID, setUsuarioUUID] = useState('');
  const [naoEditavel, setNaoEditavel] = useState({});
  const [userFilterByType, setUserFilterByType] = useState(null);
  const [mainPartnerUuid, setMainPartnerUuid] = useState('');
  const [mainPartnerId, setMainPartnerId] = useState(null);

  const [saldo, setSaldo] = useState('');
  const [observacao, setObservacao] = useState('');
  const [extUUID, setExtUUID] = useState('');
  const [isConfirmDeleteExtratoModalOpen, setIsConfirmDeleteExtratoModalOpen] =
    useState(false);


  const handleEditExtratoDescricaoModal = () => {

  }

  const handleOpenConfirmDelExtratoModal = (uuid, user) => {
    setExtUUID(uuid);
    setUsuarioUUID(user);

    setIsConfirmDeleteExtratoModalOpen(true);
  }

  const onRequestCloseExcluirConfirm = () => {
    setIsConfirmDeleteExtratoModalOpen(false);
  }

  async function handleDeleteConfirm() {
    deletarExtrato(extUUID, onRequestCloseExcluirConfirm, usuarioUUID);
  }


  const handleSelect = async (eventKey) => {
    await extratoModalPage(
      uuid,
      eventKey
    );
  };

  const onEditTable = ({
    id,
    data,
    descricao,
    qtdBruta,
    qtdLiquida,
    codigo,
    status,
    valor,
    uuid,
    ...rest
  }) => {
    setInEditMode({
      status: true,
      rowKey: uuid
    });
    setUsuarioId(id);
    // setDataCriacao(data);
    setDescricao(descricao);
    setQtdBruta(qtdBruta);
    setQtdLiquida(qtdLiquida);
    setCodigo(codigo);
    setStatus(status);
    setValor(valor.real);
    setNaoEditavel(rest);
    setUsuarioUUID(uuid);
    if (rest.tipo == 2) {
      setSaldo(0);
    } else {
      setSaldo(1);
    }
  };

  const handleUpdate = async () => {
    const dados = {
      ...naoEditavel,
      usuario: usuarioId,
      valor: valor,
      descricao: Number(descricao),
      quantidade: qtdBruta,
      status: status,
      saldo: saldo,
      observacao: observacao,
      codigo: codigo,
      created_at: dataCriacao,
      qtd2: qtdLiquida
    };

    try {
      await vereditExtrato(dados, onCancel, usuarioUUID, dados.usuario);
      setShowConfirmaModal(false);
    } catch (err) {
      console.log(err);
      setShowConfirmaModal(false);
    }
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null
    });
    setDescricao('');
    setQtdBruta('');
    setQtdLiquida('');
    setCodigo('');
    setStatus('');
    setValor('');
    setDataCriacao('');
  };

  const handleVerExtratoDescricaoModal = () => {

  }

  useEffect(() => {
    if (VerExtratoPorUUID) {
      if (VerExtratoPorUUID[0]?.items) {
        setData(VerExtratoPorUUID[0]?.items)
      } else {
        setData([]);
      }
    }
  }, [VerExtratoPorUUID]);

  useEffect(() => {
    if (paginationData && paginationData[0]) {
      setPagination(paginationData)
    }
  }, [paginationData]);

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <TH
              width="175px"
              style={{
                paddingLeft: 16,
              }}
            >
              Data / Hora
            </TH>
            <TH
              width="175px"
            >
              Tipo
            </TH>
            <TH
              width="200px"
            >
              Código do Lote
            </TH>
            <TH>
              Quantidade Bruta
            </TH>
            <TH
              width="200px"
            >
              Quantidade Liquida
            </TH>
            <TH
            >
              Status
            </TH>
            <TH
            >
              Valor Total
            </TH>
            <TH
              style={{ width: 'auto' }}
            />
          </tr>
        </thead>
        <tbody>
          <Linha />
          {
            data && data.length > 0 ? data?.map((item) =>
              inEditMode.status &&
                inEditMode.rowKey == item.uuid ? (
                <tr key={item.uuid}>
                  <td
                    data-label="Data de criação"
                    style={{
                      width: 150
                    }}
                  >
                    <input
                      style={{ height: 25 }}
                      type="datetime-local"
                      placeholder="Data de criação"
                      value={dataCriacao}
                      onChange={(event) =>
                        setDataCriacao(event.target.value)
                      }
                    />
                  </td>
                  <td
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      width: 175
                    }}
                  >
                    <Select
                      style={{ background: globalStyle.colors.BACKGROUND_PRIMARY }}
                      defaultValue={descricao}
                      onChange={(e) =>
                        setDescricao(e.target.value)
                      }
                    >
                      <option value={1}>Transferência</option>
                      <option value={2}>Pesáveis</option>
                      <option value={3}>Catalisadores</option>
                      <option value={4}>Venda</option>
                      <option value={5}>Crédito</option>
                      <option value={6}>Outros</option>
                      <option value={7}>Retorno Pesáveis</option>
                      <option value={8}>
                        Retorno Catalisadores
                      </option>
                      <option value={9}>
                        Pagamento
                      </option>
                      <option value={10}>
                        Adiantamento
                      </option>
                    </Select>
                    {descricao == 6 && (
                      <FaRegEdit
                        size={25}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleEditExtratoDescricaoModal(
                            item.description1,
                            item,
                            item.tipo
                          )
                        }
                      />
                    )}
                  </td>

                  <td width={175}>
                    <input
                      style={{
                        padding: 0
                      }}
                      value={codigo}
                      onChange={(event) =>
                        setCodigo(event.target.value)
                      }
                    />
                  </td>

                  <td
                    style={{
                      width: 200
                    }}
                    data-label="Quantidade Bruta"
                  >
                    {item?.qtd1 || 'Sem quantidade'}
                  </td>

                  <td
                    style={{
                      width: 200
                    }}
                  >
                    <input
                      style={{ padding: 0 }}
                      value={qtdLiquida}
                      onChange={(event) =>
                        setQtdLiquida(
                          formatReal(event.target.value)
                        )
                      }
                    />
                  </td>

                  <td>
                    <select
                      style={{
                        height: 25,
                        width: 100,
                        background: globalStyle.colors.BACKGROUND_PRIMARY
                      }}
                      defaultValue={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={1}>Feito</option>
                      <option value={2}>Analise</option>
                      <option value={3}>Estorno</option>
                    </select>
                  </td>

                  <td style={{ width: 150 }}>
                    <input
                      style={{
                        padding: 0,
                        width: 125
                      }}
                      type="text"
                      value={valor}
                      onChange={(event) =>
                        setValor(event.target.value)
                      }
                    />
                  </td>
                  <td>
                    {inEditMode.status && (
                      <div style={{ display: 'flex', }}>
                        <Whisper
                          placement="bottom"
                          trigger="hover"
                          speaker={<Tooltip>Salvar</Tooltip>}
                          onClick={() => setShowConfirmaModal(true)}
                        >
                          <div
                            style={{
                              background:
                                globalStyle.colors.GREEN500,
                              padding: 10,
                              borderRadius: 4,
                              marginRight: 16
                            }}
                          >
                            <EnviarIcon
                              color={globalStyle.colors.GRAY100}
                              largura={12}
                              altura={12}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                        </Whisper>
                        <Whisper
                          placement="bottom"
                          trigger="hover"
                          speaker={<Tooltip>Cancelar</Tooltip>}
                          onClick={() => onCancel()}
                        >
                          <div
                            style={{
                              background:
                                globalStyle.colors.RED600,
                              padding: 10,
                              borderRadius: 4,
                              marginRight: 16
                            }}
                          >
                            <VscChromeClose
                              color={globalStyle.colors.white}
                              style={{ cursor: 'pointer' }}
                              size={12}
                            />
                          </div>
                        </Whisper>
                      </div>
                    )}
                  </td>
                </tr>
              ) : (
                <tr key={item.uuid}>
                  <td
                    data-label="Data de criação"
                    className="primeira_td"
                    style={{
                      width: 175
                    }}
                  >
                    {item?.data_criacao.br || 'Data de criação'}
                  </td>
                  <td
                    data-label="Descrição"
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      {item.descricao == 1
                        ? 'Transferência' // if
                        : item.descricao == 2
                          ? 'Pesáveis' // else if
                          : item.descricao == 3
                            ? 'Catalisadores'
                            : item.descricao == 4
                              ? 'Venda'
                              : item.descricao == 5
                                ? 'Crédito'
                                : item.descricao == 6
                                  ? item.description1
                                  : item.descricao == 7
                                    ? 'Retorno Pesáveis'
                                    : item.descricao == 8
                                      ? 'Retorno Catalisadores'
                                      : item.descricao == 9
                                        ? 'Pagamento'
                                        : item.descricao == 10
                                          ? 'Adiantamento'
                                          : 'Sem descrição'}
                    </div>
                    {item.descricao == 6 && (
                      <AiOutlineEye
                        size={25}
                        style={{
                          cursor: 'pointer',
                          marginLeft: 5
                        }}
                        onClick={() =>
                          handleVerExtratoDescricaoModal(
                            item.description1,
                            item.saldo == 1
                              ? '+ Saldo'
                              : '- Saldo'
                          )
                        }
                      />
                    )}
                  </td>

                  <td
                    data-label="Código"
                    style={{
                      width: 150
                    }}
                  >
                    {item?.codigo ? item?.codigo : 'Sem código'}
                  </td>

                  <td
                    data-label="Quantidade Bruta"
                    style={{
                      width: 200
                    }}
                  >
                    {item?.qtd1 || 'Sem quantidade'}
                  </td>

                  <td
                    data-label="Quantidade Liquida"
                    style={{
                      width: 200
                    }}
                  >
                    {item?.qtd2}
                  </td>

                  <td
                    data-label="Status"
                    className={
                      item.status == 1
                        ? 'feito' // if
                        : item.status == 2
                          ? 'analise' // else if
                          : item.status == 3
                            ? 'estorno'
                            : 'Sem status'
                    }
                    style={{
                      width: 100
                    }}
                  >
                    {item.status == 1
                      ? 'Feito' // if
                      : item.status == 2
                        ? 'Análise' // else if
                        : item.status == 3
                          ? 'Estorno'
                          : 'Sem status'}
                  </td>
                  <td>
                    <ValorTotal
                      tipo={item.descricao}
                      data-label="Valor"
                    >
                      {item?.valor?.real}
                    </ValorTotal>
                  </td>
                  {inEditMode.status &&
                    inEditMode.rowKey == item.id ? (
                    <td>
                      <button className={'btn-success'}>
                        Salvar
                      </button>

                      <button
                        className={'btn-secondary'}
                        style={{ marginLeft: 8 }}
                        onClick={() => onCancel()}
                      >
                        Cancelar
                      </button>
                    </td>
                  ) : (
                    <td
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: 300
                      }}
                    >
                      <Whisper
                        placement="bottom"
                        trigger="hover"
                        speaker={<Tooltip>Cancelar</Tooltip>}
                        onClick={() => {
                          onEditTable({
                            id: item.usuario,
                            data: item.data_criacao.br,
                            descricao: item.description1,
                            qtdBruta: item.quantidade,
                            qtdLiquida: item.qtd2,
                            codigo: item.codigo,
                            status: item.status,
                            valor: item.valor?.real,
                            uuid: item.usuario,
                            ...item
                          });
                        }}
                      >
                        <div
                          style={{
                            background: globalStyle.colors.GRAY400,
                            padding: 10,
                            borderRadius: 4,
                            marginRight: 16
                          }}
                        >
                          <FaRegEdit
                            color={globalStyle.colors.GRAY100}
                            size={12}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Whisper>
                      <Whisper
                        placement="bottom"
                        trigger="hover"
                        speaker={<Tooltip>Excluir</Tooltip>}
                        onClick={() => {
                          handleOpenConfirmDelExtratoModal(
                            item.uuid,
                            item.usuario
                          );
                        }}
                      >
                        <div
                          style={{
                            background: globalStyle.colors.RED500,
                            padding: 10,
                            borderRadius: 4
                          }}
                        >
                          <VscChromeClose
                            color={globalStyle.colors.white}
                            style={{ cursor: 'pointer' }}
                            size={12}
                          />
                        </div>
                      </Whisper>
                    </td>
                  )}
                </tr>
              )
            ) : null}
        </tbody>
      </table>
      <ContainerPaginacao>
        {pagination.length ? (
          <Pagination
            prev
            last
            next
            first
            size="lg"
            pages={pagination[0].last_page}
            activePage={pagination[0].current_page}
            onSelect={handleSelect}
          />
        ) : null}
      </ContainerPaginacao>
      <ConfirmaModal
        title={"Editar Compra"}
        show={showConfirmaModal}
        setShow={setShowConfirmaModal}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto={"Editar"}
        submitFn={handleUpdate}
      />
      <ConfirmaModal
        title={"Deletar Compra"}
        show={isConfirmDeleteExtratoModalOpen}
        setShow={onRequestCloseExcluirConfirm}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto={"Deletar"}
        submitFn={handleDeleteConfirm}
      />
    </Container>
  )
}