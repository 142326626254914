import styled from "styled-components";

export const Container = styled.div`
    > span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: ${props => props.theme.colors.GRAY300}
    }
`

export const LabelContainer = styled.div`
       margin: 16px 0 8px;

    label {
        font-family: 'Lato',
        font-style: normal;
        font-size: 20px;
        line-height: 25px;
        color: ${props => props.theme.colors.GRAY900};
    }
`

export const Row = styled.div`
    display: flex;
    
    .switch__container {
      /* margin: 30px auto; */
      width: 55px;
      border: none;
    }

    .switch {
      visibility: hidden;
      position: absolute;
      margin-left: -9999px;
    }

    .switch + label {
      display: block;
      position: relative;
      cursor: pointer;
      outline: none;
      user-select: none;
      border: none;
    }

    .switch--shadow + label {
      padding: 2px;
      width: 50px;
      height: 17.5px;
      border: none;
      background-color: #dddddd;
      border-radius: 60px;
    }
    .switch--shadow + label:before,
    .switch--shadow + label:after {
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      content: "";
    }
    .switch--shadow + label:before {
      right: 0px;
      background-color: #3F3F46;
      border-radius: 60px;
      transition: background 0.4s;
    }
    .switch--shadow + label:after {
      width: 25px;
      height: 25px;
      top: -3.5px;
      background-color: #fff;
      border: solid 1px ${props => props.theme.colors.GRAY900};
      border-radius: 100%;
      /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
      transition: all 0.4s;
    }
    .switch--shadow:checked + label:before {
      background-color: ${props => props.theme.colors.GRAY50};
    }
    .switch--shadow:checked + label:after {
      transform: translateX(25px);
      background-color: ${props => props.theme.colors.GRAY50};
      border: solid 1px ${props => props.theme.colors.GRAY100};
    }
`

export const Sub = styled.span`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.theme.colors.DARK100};
`

export const DivRadio = styled.div`
  input:checked {
    background-color: #e5e5e5;
    color: #fff;
  }
  align-items: center;
  
  display: flex;
`;

export const Radio = styled.input`
  margin-right: 8px;
  -webkit-appearance: none;
  appearance: none;

  background: ${props => props.theme.colors.GRAY100};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ::after {
    content: '';
    background: transparent;
    border: solid 4px ${props => props.theme.colors.GRAY100};
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
`;

export const Text = styled.h2`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  color: ${props => props.theme.colors.GRAY900};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-family: 'Lato';
  margin-right: 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 48px;
  padding: 0;
  margin-bottom: 44px;
`