import { theme } from '@chakra-ui/core';
const globalStyle = {
  ...theme,
  colors: {
    ...theme.colors,
    // BACKGROUND_PRIMARY: '#1C1917',
    BACKGROUND_PRIMARY: '#eeeeef',
    BLACK900: '#171717',
    DARK400: '#71717A',
    DARK50: '#eeeeef',
    DARK100: '#cbcbcb',
    GREEN500: '#10B981',
    GREEN600: '#059669',
    // GRAY50: '#FAFAF9',
    GRAY50: '#88b99a',
    // GRAY100: '#F5F5F5',
    GRAY100: '#2b6866',
    GRAY300: '#D4D4D4',
    GRAY400: '#A3A3A3',
    GRAY500: '#737373',
    GRAY600: '#525252',
    GRAY700: '#404040',
    GRAY800: '#262626',
    GRAY900: '#171717',
    RED500: '#EF4444',
    RED600: '#DC2626',
    TRANSPARENT: 'transparent',
    WHITESINGLETON: '#FFFFFF',
    YELLOW500: '#EAB308',
    YELLOW600: '#CA8A04'
  },
  fontFamily: 'Lato',
  weight: {
    W700: 700,
    W500: 500,
    W400: 400
  }
};

export default globalStyle;
