import React from "react";
import { FlexboxGrid } from "rsuite";
import * as S from "./styles";

export function SemPermissao() {
  return (
    <div className="show-grid"  style={{backgroundColor: "#151729", height: "100vh"}}>
        <FlexboxGrid justify="center" align="middle"  style={{height: "100vh"}}>
            <S.Content>
                <S.TituloErro>403</S.TituloErro>
                <S.TituloErroSubtitulo>Opps! Sem permissão</S.TituloErroSubtitulo>
                <S.Message>Você não tem acesso para acessar essa página. Em caso de dúvidas, procure pelo administrador do sistema.</S.Message>
                <S.LinkHome href="/">Voltar para o início</S.LinkHome>
            </S.Content>
        </FlexboxGrid>
    </div>
  );
};
