import {
  Icon,
  InputGroup,
  SelectPicker,
  Table,
  Tooltip,
  Whisper
} from 'rsuite';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import { useExtrato } from '../../hooks/extrato';
import { usePerfil } from '../../hooks/perfil';
import { useEffect, useState } from 'react';
import TableComponent from '../UI/TableComponent';
import { useNavigate } from 'react-router-dom';

export function ExtratoTable({
  onOpenEditExtratoModal,
  onOpenVerExtratoModal
}) {
  const app = 'extrato';
  const {
    getExtratoPorUuid,
    verExtratoPorUuid,
    ExtratoLista,
    pagination,
    isLoading,
    extratoPage,
    orderExtrato,
    searchExtrato,
    getExtratoPorTipo,
    usuarioListaPorTipo,
    getExtratoByMainPartner,
    getParceirosTipoPrincipal
  } = useExtrato();
  const { permissaoPerfil, tipoPerfil } = usePerfil();

  const [userFilterByMainPartner, setUserFilterByMainPartner] = useState(null);
  const [filter, setFilter] = useState(null);
  const [userFilterByType, setUserFilterByType] = useState(null);
  const [mainPartnerUuid, setMainPartnerUuid] = useState('');
  const [mainPartnerId, setMainPartnerId] = useState(null);

  const navigate = useNavigate()

  useEffect(() => {
    getParceirosTipoPrincipal();
  }, []);

  const handleSelect = async (eventKey) => {
    await extratoPage(
      eventKey,
      userFilterByType,
      mainPartnerUuid,
      mainPartnerId
    );
  };

  const handleClickOpenEdit = async (uuid, tipo, nome) => {
    if (
      tipoPerfil == 1 ||
      (permissaoPerfil != null &&
        permissaoPerfil.includes('per_' + app + '_adicionar'))
    ) {
      let modal = onOpenEditExtratoModal;
      await getExtratoPorUuid(uuid, tipo, modal, nome);
      setMainPartnerUuid('');
      setMainPartnerId(null);
      setUserFilterByType(null);
      setUserFilterByMainPartner(null);
    }
  };

  const handleClickOpenVer = async (uuid, nome) => {
    if (
      tipoPerfil == 1 ||
      (permissaoPerfil != null &&
        permissaoPerfil.includes('per_' + app + '_editar'))
    ) {
      let modal = onOpenVerExtratoModal;

      navigate('/extrato/detalhe', { state: { uuid, nome }})
      // await verExtratoPorUuid(uuid, nome, navigate);
    }
  };
  const handleOrderingColumn = (event, column) => {
    const columns = document.getElementsByTagName('th');
    for (let column of columns) {
      column.classList.add('columnDefault');
    }

    let el = event.target;
    el.classList.remove('columnDefault');
    el.classList.add('columnActive');

    if (el.lastChild.classList.contains('rs-icon-arrow-up')) {
      el.lastChild.classList.remove('rs-icon-arrow-up');
      el.lastChild.classList.add('rs-icon-arrow-down');
      orderExtrato(
        column,
        'DESC',
        pagination[0].current_page,
        filter,
        userFilterByType,
        userFilterByMainPartner,
        mainPartnerUuid
      );
    } else {
      el.lastChild.classList.remove('rs-icon-arrow-down');
      el.lastChild.classList.add('rs-icon-arrow-up');
      orderExtrato(
        column,
        'ASC',
        pagination[0].current_page,
        filter,
        userFilterByType,
        userFilterByMainPartner,
        mainPartnerUuid
      );
    }
  };

  const { Pagination } = Table;

  const tipos = [
    //  {label: 'Administrador', value: 1},
    //  {label: 'Recebimento', value: 2},
    //  {label: 'Analisador', value: 3},
    { label: 'Parceiro', value: 4 },
    { label: 'Parceiro Principal', value: 5 }
  ];

  const filtroPorPrincipal = usuarioListaPorTipo.map((usr) => {
    return {
      value: [usr.id, usr.uuid],
      label: usr.id + ' - ' + usr.nome
    };
  });
  const handleFilterByMainPartner = (value) => {
    setUserFilterByType('');

    if (value) {
      let mainp_id = value[0];
      let mainp_uuid = value[1];

      setFilter('mainPartner');
      setMainPartnerUuid(mainp_uuid);
      setMainPartnerId(mainp_id);
      getExtratoByMainPartner(mainp_id, mainp_uuid);
    } else {
      setFilter(null);
      setMainPartnerUuid('');
      getExtratoByMainPartner();
    }
    setUserFilterByMainPartner(value);
  };

  const handleFilterByType = (tipo) => {
    setUserFilterByMainPartner('');
    setUserFilterByType(tipo);
    if (tipo) {
      setFilter('type');
    } else {
      setFilter(null);
    }
    setUserFilterByMainPartner('');
    getExtratoPorTipo(tipo);
  };
  console.log('extrato pagination', pagination)
  return (
    <div>
      <S.Container>
        <S.HeaderTable>
          <div>
            <p>
              Total: {pagination[0]?.total}
            </p>
          </div>
        </S.HeaderTable>
        <S.FilterContainer>
          <SelectPicker
            data={tipos}
            value={userFilterByType}
            onChange={(value) => handleFilterByType(value)}
            searchable={false}
            placeholder="Filtro por tipo de usuário."
            size="lg"
            style={{ marginRight: 10 }}
          />
          <SelectPicker
            data={filtroPorPrincipal}
            value={userFilterByMainPartner}
            onChange={(usr_id) => handleFilterByMainPartner(usr_id)}
            placeholder="Filtro por parceiro principal."
            size="lg"
          />
        </S.FilterContainer>
        <TableComponent
          page={app}
          pagination={pagination}
          handleOrderingColumn={handleOrderingColumn}
          handleClickOpenEdit={handleClickOpenVer}
          handleAction={handleClickOpenEdit}
          lista={ExtratoLista}
          isLoading={isLoading}
          columns={[
            { header: 'Nome', body: 'nome' },
            { header: 'CPF', body: 'cpf' },
            { header: 'Saldo', body: 'saldo' },
            { header: 'Limite', body: 'limite' },
            { header: ' ', body: 'action' }
          ]}
          // sempre enviar valores no mesmo index da prop "columns", componente pega pelo index
          orderingByColumnsIndex={['name', 'cpf', 'saldo', 'value_limit', '']}
        />
        <S.ContainerPagination>
          {pagination.length ? (
            <Pagination
              prev
              last
              next
              first
              size="lg"
              pages={pagination[0].last_page}
              activePage={pagination[0].current_page}
              onSelect={handleSelect}
            />
          ) : null}
        </S.ContainerPagination>
      </S.Container>
    </div>
  );
}
