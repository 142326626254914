import React from 'react';
import { Container, Content, Header, HeaderLabel, Value, Title } from './styled';
import globalStyle from '../../../styles/globalStyle';
import formatReal from '../../../utils/formatReal';
import moment from 'moment';

export default function Cotacao({ data }) {

    const calculaDiasrestante = (fim) => {
        const hoje = new Date();

        console.log("hoje", hoje)
        console.log("fim", new Date(fim))

        const diff = Math.abs(hoje - new Date(fim));
        const diffDias = Math.ceil(diff / (1000 * 60 * 60 * 24));

        return diffDias;
    }
    const diasRestantes = calculaDiasrestante(data?.end_date);

    return (
        <Container>
            <Title>Cotação</Title>
            <Header>
                <HeaderLabel>USD</HeaderLabel>
                <HeaderLabel>Pt</HeaderLabel>
                <HeaderLabel>Pd</HeaderLabel>
                <HeaderLabel width="100%">Rh</HeaderLabel>
            </Header>
            <Content>
                <Value>R${formatReal(data?.dolar_quotation?.toFixed(2))}</Value>
                <Value>U$ {data?.pt_quotation?.toFixed(2)}</Value>
                <Value>U$ {data?.pd_quotation?.toFixed(2)}</Value>
                <Value width="100%">U$ {data?.rh_quotation?.toFixed(2)}</Value>
            </Content>
            {
                data?.start_date && data?.end_date ? (
                    <>
                        <Content>
                            <p>Validade da cotação: <strong>de {moment(data.start_date).format("DD/MM/YYYY")} até {moment(data.end_date).format("DD/MM/YYYY")}</strong>.</p>
                        </Content>
                        <Content>
                            <p>Dias restantes: <b>{diasRestantes}</b>.</p>
                        </Content>
                    </>
                ) : null
            }

        </Container>
    )
}