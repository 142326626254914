import React, { useState } from 'react';
import * as S from './styles.js';
import Skeleton from 'react-loading-skeleton';
import { FormGroup, Modal } from 'rsuite';
import { useExtrato } from '../../hooks/extrato';

export function VerExtratoDescricaoModal({
  isOpenVer,
  onRequestClose,
  observacao,
  handleEditDados,
  saldo,
  isEdit
}) {
  const { isLoadingVer } = useExtrato();

  const [observacaoEdit, setObservacaoEdit] = useState(observacao);
  const [saldoEdit, setSaldoEdit] = useState(saldo);

  return (
    <Modal
      show={isOpenVer}
      onHide={() => {
        onRequestClose();
      }}
    >
      {isLoadingVer ? (
        <Skeleton count={8} />
      ) : (
        <Modal.Body>
          <FormGroup>
            <S.ContainerInput>
              <input
                type="text"
                value={observacaoEdit}
                onChange={(e) => setObservacaoEdit(e.target.value)}
                disabled={!isEdit}
              />
            </S.ContainerInput>
            {!isEdit && saldo && (
              <S.ContainerInput>
                <div>
                  {saldo == 1 ? '+ Saldo' : saldo == 0 ? '- Saldo' : null}
                </div>
              </S.ContainerInput>
            )}
            {isEdit && (
              <>
                <S.ContainerInput>
                  <select
                    value={saldoEdit}
                    onChange={(e) => setSaldoEdit(e.target.value)}
                  >
                    <option value={1}>+ Saldo</option>
                    <option value={0}>- Saldo</option>
                  </select>
                </S.ContainerInput>
                <S.ButtonContainer>
                  <S.Button
                    onClick={() => handleEditDados(observacaoEdit, saldoEdit)}
                  >
                    Guardar
                  </S.Button>
                </S.ButtonContainer>
              </>
            )}
          </FormGroup>
        </Modal.Body>
      )}
    </Modal>
  );
}
