import React from "react";
import { Container, Header, Sidebar as Sidenav, Content, Loader } from 'rsuite';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Topbar";
import { usePerfil } from "../../hooks/perfil";
import { CatalogoProvider, useCatalogo } from "../../hooks/catalogo";
import { Container as FormContainer } from "./styled";
import UploadImages from "../../components/UploadImages";
import ProdutoForm from "../../components/ProdutoForm";
import globalStyle from "../../styles/globalStyle";

export default function EditarProduto() {
  const { isLoading } = useCatalogo();
  const { id } = useParams()
  const navigate = useNavigate();

  const { state } = useLocation();

  const data = state.data;

  return (
    <>
      {isLoading ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : (
        <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
          <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
            <Sidebar activePage={'3'} />
          </Sidenav>
          <Container style={{ width: '80%' }}>
            <Header style={{ minHeight: '3.6rem' }}>
              <Topbar
                detalhesProduto
                productName={data.marca}
                pageTitle={'Catálogo'}
              />
            </Header>
            <Content>
              <FormContainer>
                <ProdutoForm data={data} productId={id} tipo="editar" />
              </FormContainer>
            </Content>
          </Container>
        </Container>
      )}
    </>
  )
}