import React from 'react';

const PaperPlaneIcon = ({ largura, altura }) => {
    return (
        <svg 
        width={largura} 
        height={altura} 
        viewBox="-2.5 -2 22 22"  
        fill="none"  
        xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.942 1.05808C17.1133 1.22942 17.1702 1.48438 17.0879 1.7123L11.6712 16.7123C11.6654 16.7286 11.6588 16.7446 11.6516 16.7604C11.5652 16.949 11.4264 17.1088 11.2518 17.2208C11.0772 17.3328 10.8742 17.3924 10.6667 17.3924C10.4593 17.3924 10.2562 17.3328 10.0816 17.2208C9.91065 17.1111 9.77405 16.9556 9.68731 16.7721L6.86755 11.1325L1.22803 8.31277C1.04452 8.22603 0.888983 8.08943 0.779261 7.91845C0.667223 7.74387 0.607666 7.54079 0.607666 7.33335C0.607666 7.12591 0.667223 6.92283 0.779261 6.74825C0.891297 6.57367 1.0511 6.43492 1.23968 6.3485C1.25544 6.34128 1.27148 6.33472 1.28778 6.32884L16.2878 0.912171C16.5157 0.829868 16.7707 0.886727 16.942 1.05808ZM8.09387 10.7901L10.6411 15.8846L14.9604 3.92358L8.09387 10.7901ZM14.0765 3.03969L2.11547 7.35895L7.20998 9.90621L14.0765 3.03969ZM1.76043 7.18184C1.76215 7.18262 1.76385 7.18344 1.76553 7.18428L1.76043 7.18184ZM10.8182 16.2396L10.8158 16.2345C10.8166 16.2362 10.8175 16.2379 10.8182 16.2396Z" fill="#F5F5F5" />
        </svg>

    );
};

export default PaperPlaneIcon;
