import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 5px;

  .select-state {
    background-color: ${(props) => props.theme.colors.BACKGROUND_PRIMARY};
    color: ${(props) => props.theme.colors.GRAY100};
    width: 140px;
    height: 2.45rem;
  }
`;

export const Container = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  @media (max-width: 768px) {
    padding: 1rem 0px;
  }

  .rs-modal-body {
    overflow-x: hidden !important;
  }

  .rs-modal-footer {
    width: 100%;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
  }
  input,
  label {
    width: calc(100% - 20px);
    color: ${(props) => props.theme.colors.GRAY100};
  }

  input {
    border: 2px solid ${(props) => props.theme.colors.DARK400};
    padding: 0.5rem 1rem;
  }

  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }
    //todo input que tiver um input acima vai receber margin top
    & + input {
      margin-top: 0.25rem;
    }
  }

  h2 {
    width: 100%;
    color: var(--red);
    font-size: 1.5rem;
    margin-bottom: 5px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-top: 20px;
  }
`;

export const ContainerCardForm = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 10px;
  }

  .card-form {
    width: calc(100% - 20px);
    display: grid;
    flex: 1;
    gap: 2rem;
    margin: 0px 10px;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0px;
      h3 {
        margin-top: 40px;
      }
    }

    .rs-uploader-picture .rs-uploader-trigger-btn {
      width: 150px;
      height: 150px;
    }
    .rs-uploader-picture .rs-uploader-trigger-btn {
      border-radius: 50%;
      border: solid 2px #ccc;
    }
    .rs-uploader-trigger-btn {
      img {
        object-fit: cover;
      }
    }
    .rs-icon-size-5x,
    .rs-icon-size-5x.rs-icon {
      font-size: 150px;
      height: 0px;
      margin-left: -2px;
      margin-top: -17px;
    }
    .rs-icon-size-5x,
    .cYHYoN .card-form .rs-icon-size-5x.rs-icon {
      margin-top: 0px !important;
    }
  }
`;

export const ButtonImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: solid 2px #ccc;

  img {
    object-fit: cover;
  }
`;

export const StatusFilialContainer = styled.div`
  margin: 1rem 0px;
`;

export const ContainerPermissao = styled.div`
  padding-left: 5px;
  padding-right: 20px;
`;

export const TituloPermissao = styled.p`
  font-size: 20px;
  line-height: 1.5em;
  color: #333;
`;
export const ContainerCheck = styled.div`
  margin: 0 0 20px 0;
`;

export const FotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  label {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0px;
    opacity: 0;
    cursor: pointer;
    z-index: 1000;
  }
  .rs-input-group {
    width: 40px;
    margin-bottom: 5px;
  }
`;
export const ContainerUpload = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  padding: 0px 20px;
  .alterar {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
    a {
      background-color: var(--warning-color);
      color: rgb(255, 255, 255);
    }
    &:hover,
    &:focus {
      border-color: var(--warning-color) !important;
    }
  }
`;
