const config = {
  local: {
    URL_API: 'http://localhost:3004'
  },
  development: {
    URL_API: 'http://docker.techlise.com.br:3025'
  },
  stage: {
    URL_API: 'http://docker.techlise.com.br:3030'
  },
  production: {
    URL_API: 'https://api.preciousgroup.com.br'
  }
};

const ambiente = process.env.REACT_APP_AMBIENTE;

export default config[ambiente || 'production'];
