import api from '../../services/api';
import { useEffect, useState } from 'react';
import * as S from './styles';

import Skeleton from 'react-loading-skeleton';
import { useUsuario } from '../../hooks/user';
import { usePerfil } from '../../hooks/perfil';
import { Loader, SelectPicker, Table, Tooltip, Whisper } from 'rsuite';
import {
  Flex,
  Heading,
  FormControl,
  CircularProgress,
  InputGroup,
  InputRightElement,
  Button as Botao,
  Icon as Icone,
  Image
} from '@chakra-ui/core';
import TableComponent from '../UI/TableComponent';
import Button from '../UI/Button';
import ErrorMessage from '../../components/ErrorMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';

import PlusIcon from '../../assets/PlusIcon.png';
import globalStyle from '../../styles/globalStyle';
import formatReal from '../../utils/formatReal';
import formataCampoPorcentagem from '../../utils/formataCampoPorcentagem';
import { ConfirmaInativarUsuario } from '../ConfirmaInativarUsuario';
import { useNavigate } from 'react-router-dom';
import ConfirmaModal from '../ConfirmaModal';
import DocumentIcon from '../../assets/Icons/DocumentIcon';
import SpreadsheetFilterModal from '../SpreadsheetFilterModal';

export function UsuarioTable({
  onOpenNewUserModal,
  onOpenEditUserModal,
  onOpenViewUserModal,
  toggleViewUser,
  viewUser,
  setUserName
}) {
  const app = 'usuario';
  const [search, setSearch] = useState('');
  const [userFilterByMainPartner, setUserFilterByMainPartner] = useState(null);
  const [userFilterByType, setUserFilterByType] = useState(null);
  const [userFilterByPiece, setUserFilterByPiece] = useState('');
  const [filter, setFilter] = useState(null);
  const [mainPartnerUuid, setMainPartnerUuid] = useState(null);
  const [mainPartnerId, setMainPartnerId] = useState(null);
  const [userDetails, setUserDetails] = useState('');
  const [isChecked, setIsChecked] = useState();
  const [radioSelected, setRadioSelected] = useState(null);
  const [isOpenInativarConfirm, setIsOpenInativarConfirm] = useState(false);
  const [isOpenSpreadsheetFilters, setIsOpenSpreadsheetFilters] =
    useState(false);
  const [uuid, setUuid] = useState('');

  const [parceiroPrincipalId, setParceiroPrincipalId] = useState('');
  const [userCodigo, setUserCodigo] = useState('');
  const [userLimite, setUserLimite] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [enderecoNumero, setEnderecoNumero] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [bairro, setBairro] = useState('');
  const [peca, setPeca] = useState('');
  const [po, setPo] = useState('');
  const [inox, setInox] = useState('');
  const [filtro, setFiltro] = useState('');
  const [parametro, setParametro] = useState('');
  const [agencia, setAgencia] = useState('');
  const [banco, setBanco] = useState('');
  const [conta, setConta] = useState('');
  const [titular, setTitular] = useState('');
  const [cpfDoTitular, setCpfDoTitular] = useState('');
  const [status, setStatus] = useState('');

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCPF] = useState('');
  const [celular, setCelular] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [senha, setSenha] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [permissaoPerfil, setPermissao] = useState('');
  const [tipoPerfil, setTipo] = useState('');
  const [permissoesUser, setPermissoesUser] = useState([]);
  const [viewPerms, setViewPerms] = useState(false);
  const [error, setError] = useState({
    name: {
      error: false
    },
    email: {
      error: false
    },
    cpf: {
      error: false
    },
    celular: {
      error: false
    },
    nascimento: {
      error: false
    }
  });
  const [isLoading, setIsLoading] = useState(false);

  const [perUsuarioVisualizar, setPermUsuarioVisualizar] = useState(false);
  const [perUsuarioAdicionar, setPermUsuarioAdicionar] = useState(false);
  const [perUsuarioEditar, setPermUsuarioEditar] = useState(false);

  const [perCatalogoVisualizar, setPermCatalogoVisualizar] = useState(false);
  const [perCatalogoAdicionar, setPermCatalogoAdicionar] = useState(false);
  const [perCatalogoEditar, setPermCatalogoEditar] = useState(false);

  const [perExtratoVisualizar, setPermExtratoVisualizar] = useState(false);
  const [perExtratoAdicionar, setPermExtratoAdicionar] = useState(false);
  const [perExtratoAtualizar, setPermExtratoAtualizar] = useState(false);

  const [perLotesVisualizar, setPermLotesVisualizar] = useState(false);
  const [perLotesEditar, setPermLotesEditar] = useState(false);
  const [perLotesAdicionar, setPermLotesAdicionar] = useState(false);

  const [perParceiroVisualizar, setPermParceiroVisualizar] = useState(false);
  const [perParceiroAdicionar, setPermParceiroAdicionar] = useState(false);
  const [perParceiroAtualizar, setPermParceiroAtualizar] = useState(false);

  const [tipoAdmin, setTipoAdmin] = useState(false);
  const [tipoRecebimento, setTipoRecebimento] = useState(false);
  const [tipoAnalisador, setTipoAnalisador] = useState(false);
  const [tipoParceiro, setTipoParceiro] = useState(false);
  const [tipoParceiroPrincipal, setTipoParceiroPrincipal] = useState(false);

  const [userType, setUserType] = useState('');

  const permissions = {
    per_usuario_visualizar: {
      active: perUsuarioVisualizar,
      id: 13
    },
    per_usuario_adicionar: {
      active: perUsuarioAdicionar,
      id: 26
    },
    per_usuario_editar: {
      active: perUsuarioEditar,
      id: 14
    },
    per_catalogo_visualizar: {
      active: perCatalogoVisualizar,
      id: 10
    },
    per_catalogo_adicionar: {
      active: perCatalogoAdicionar,
      id: 27
    },
    per_catalogo_editar: {
      active: perCatalogoEditar,
      id: 12
    },
    per_extrato_visualizar: {
      active: perExtratoVisualizar,
      id: 28
    },
    per_extrato_editar: {
      active: perExtratoAtualizar,
      id: 15
    },
    per_extrato_adicionar: {
      active: perExtratoAdicionar,
      id: 32
    },
    per_lotes_visualizar: {
      active: perLotesVisualizar,
      id: 29
    },
    per_lotes_editar: {
      active: perLotesEditar,
      id: 30
    },
    per_lotes_adicionar: {
      active: perLotesAdicionar,
      id: 33
    },
    per_parceiro_visualizar: {
      active: perParceiroVisualizar,
      id: 31
    },
    per_parceiro_adicionar: {
      active: perParceiroAdicionar,
      id: 35
    },
    per_parceiro_atualizar: {
      active: perParceiroAtualizar,
      id: 34
    }
  };

  const {
    getUsuarioPorUuid,
    usuarioLista,
    userPage,
    pagination,
    searchUsuario,
    usuarioPorUUID,
    orderUsuario,
    isLoadingSearch,
    getUsuariosPorTipo,
    usuarioListaPorTipo,
    getUsuariosByMainPartner,
    cleanFilters,
    getUserByPiece,
    buscaRelatorioUsuarios,
    buscaRelatorioConsultas
  } = useUsuario();

  const navigate = useNavigate();

  // const dadosUsuarioFormatado = usuarioListaPorTipo && usuarioListaPorTipo?.length > 0 ? usuarioListaPorTipo.map((usr) => {
  //   return { label: usr.id + ' - ' + usr.nome, value: usr.id };
  // }) : [];

  function handleCloseConfirmInativaUserModal() {
    setIsOpenInativarConfirm(false);
  }

  async function handleInativarConfirm() {
    invalidateUser(uuid);
  }

  const invalidateUser = async (uuid) => {
    const url = `/usuario/inativar/${uuid}`;

    const response = await api.put(url);

    getUsuariosPorTipo(5, false);

    userPage(pagination[0].current_page);

    setIsOpenInativarConfirm(false);
  };

  useEffect(() => {
    async function fetchData() {
      handleGetPermissoes();
    }
    fetchData();
  }, []);

  useEffect(() => {
    getUsuariosPorTipo(5, false);
  }, []);
  useEffect(() => {
    if (cleanFilters) {
      setUserFilterByMainPartner('');
      setUserFilterByType('');
      setUserFilterByPiece('');
    }
  }, [cleanFilters]);

  const handleGetPermissoes = async (target) => {
    const response = await api.get('/me');

    const Perms = response.data.permissoes.map((perm) => perm.permissionName);

    setPermissao(Perms);
    setTipo(response.data.tipo);
  };

  const handleSelectedRadio = (type) => {
    setRadioSelected(type);
  };

  const handleSelect = async (eventKey) => {
    await userPage(
      eventKey,
      userFilterByType,
      mainPartnerUuid,
      mainPartnerId,
      userFilterByPiece
    );
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const handlePasswordVisibility2 = () => setShowPassword2(!showPassword2);

  const handleClickOpenEdit = async (uuid, page) => {
    if (
      permissaoPerfil != null &&
      permissaoPerfil.includes('per_' + app + '_editar') &&
      !permissaoPerfil.indexOf('per_' + app + '_visualizar') == -1
    ) {
      const res = await getUsuarioPorUuid(uuid, page);

      setPermissoesUser(res.permissoes);
      setUserDetails(res);
      res.nome && setUserName(res.nome);

      res.permissoes && setPermissoesUser(res.permissoes);
      res.nome && setNome(res.nome);
      res.email && setEmail(res.email);
      res.cpf?.br && setCPF(res.cpf?.br);
      res.celular?.br && setCelular(res.celular?.br);
      res.telefone?.br && setTelefone(res.telefone?.br);
      res.data_nascimento?.br && setNascimento(res.data_nascimento?.br);
      res.codig && setUserCodigo(res.codigo);
      res.limite?.real && setUserLimite(res.limite?.real);
      res.cidade && setCidade(res.cidade);
      res.titular_cpf && setCpfDoTitular(res.titular_cpf);
      res.estado && setEstado(res.estado);
      res.bairro && setBairro(res.bairro);
      res.peca && setPeca(res.peca);
      res.inox && setInox(res.inox);
      res.po && setPo(res.po);
      res.filtro && setFiltro(res.filtro);
      res.parametro && setParametro(res.parametro);
      res.banco && setBanco(res.banco);
      res.agencia && setAgencia(res.agencia);
      res.conta && setConta(res.conta);
      res.titular && setTitular(res.titular);
      res.user_principal && setParceiroPrincipalId(res.user_principal);
      if (res.tipo == 1) {
        setTipoAdmin(true);
        setUserType('admin');
        setTipoRecebimento(false);
        setTipoParceiro(false);
        setTipoParceiroPrincipal(false);
        setTipoAnalisador(false);
      } else if (res.tipo == 2) {
        setTipoRecebimento(true);
        setUserType('recebimento');
        setTipoParceiro(false);
        setTipoAdmin(false);
        setTipoAnalisador(false);
        setTipoParceiroPrincipal(false);
      } else if (res.tipo == 3) {
        setTipoAnalisador(true);
        setUserType('analisador');
        setTipoRecebimento(false);
        setTipoParceiro(false);
        setTipoAdmin(false);
        setTipoParceiroPrincipal(false);
      } else if (res.tipo == 4) {
        setTipoParceiro(true);

        setUserType('parceiro');
        setTipoRecebimento(false);
        setTipoAdmin(false);
        setTipoAnalisador(false);
        setTipoParceiroPrincipal(false);
      } else if (res.tipo == 5) {
        setTipoParceiroPrincipal(true);
        setUserType('parceiro principal');
        setTipoParceiro(false);
        setTipoRecebimento(false);
        setTipoAdmin(false);
        setTipoAnalisador(false);
      }

      permissoesUser.forEach((data) => {
        if (data.permissionName == 'per_usuario_visualizar') {
          setPermUsuarioVisualizar(true);
        } else if (data.permissionName == 'per_usuario_adicionar') {
          setPermUsuarioAdicionar(true);
        } else if (data.permissionName == 'per_usuario_editar') {
          setPermUsuarioEditar(true);
        } else if (data.permissionName == 'per_catalogo_visualizar') {
          setPermCatalogoVisualizar(true);
        } else if (data.permissionName == 'per_catalogo_adicionar') {
          setPermCatalogoAdicionar(true);
        } else if (data.permissionName == 'per_catalogo_editar') {
          setPermCatalogoEditar(true);
        } else if (data.permissionName == 'per_extrato_visualizar') {
          setPermExtratoVisualizar(true);
        } else if (data.permissionName == 'per_lotes_visualizar') {
          setPermLotesVisualizar(true);
        } else if (data.permissionName == 'per_lotes_editar') {
          setPermLotesEditar(true);
        } else if (data.permissionName == 'per_parceiro_visualizar') {
          setPermParceiroVisualizar(true);
        }
      });
    }

    if (
      tipoPerfil == 1 ||
      (permissaoPerfil != null &&
        permissaoPerfil.includes('per_' + app + '_visualizar'))
    ) {
      const res = await getUsuarioPorUuid(uuid, page);

      setUserDetails(res);
      res.nome && setUserName(res.nome);

      res.permissoes && setPermissoesUser(res.permissoes);
      res.nome && setNome(res.nome);
      res.email && setEmail(res.email);
      res.cpf?.br && setCPF(res.cpf?.br);
      res.celular?.br && setCelular(res.celular?.br);
      res.telefone?.br && setTelefone(res.telefone?.br);
      res.data_nascimento?.br && setNascimento(res.data_nascimento?.br);
      res.codig && setUserCodigo(res.codigo);
      res.limite?.real && setUserLimite(res.limite?.real);
      res.cidade && setCidade(res.cidade);
      res.titular_cpf && setCpfDoTitular(res.titular_cpf);
      res.estado && setEstado(res.estado);
      res.bairro && setBairro(res.bairro);
      res.peca && setPeca(res.peca);
      res.inox && setInox(res.inox);
      res.po && setPo(res.po);
      res.filtro && setFiltro(res.filtro);
      res.parametro && setParametro(res.parametro);
      res.banco && setBanco(res.banco);
      res.agencia && setAgencia(res.agencia);
      res.conta && setConta(res.conta);
      res.titular && setTitular(res.titular);
      res.user_principal && setParceiroPrincipalId(res.user_principal);
      if (res.tipo == 1) {
        setTipoRecebimento(false);
        setUserType('admin');
        setTipoAdmin(true);
        setTipoParceiro(false);
        setTipoParceiroPrincipal(false);
        setTipoAnalisador(false);
        setViewPerms(false);
      } else if (res.tipo == 2) {
        setTipoRecebimento(true);
        setTipoAnalisador(false);
        setUserType('recebimento');
        setTipoAdmin(false);
        setTipoParceiro(false);
        setTipoParceiroPrincipal(false);
        setViewPerms(true);
      } else if (res.tipo == 3) {
        setTipoAnalisador(true);
        setUserType('analisador');
        setTipoRecebimento(false);
        setTipoAdmin(false);
        setTipoParceiro(false);
        setTipoParceiroPrincipal(false);
        setViewPerms(true);
      } else if (res.tipo == 4) {
        setTipoParceiro(true);
        setTipoAnalisador(false);
        setTipoRecebimento(false);
        setUserType('parceiro');
        setTipoParceiroPrincipal(false);
        setTipoAdmin(false);
        setViewPerms(false);
      } else if (res.tipo == 5) {
        setTipoParceiroPrincipal(true);
        setTipoParceiro(false);
        setUserType('parceiro principal');
        setTipoAnalisador(false);
        setTipoRecebimento(false);
        setTipoAdmin(false);
        setViewPerms(false);
      }

      res.permissoes.forEach((data) => {
        if (data.permissionName == 'per_usuario_visualizar') {
          setPermUsuarioVisualizar(true);
        } else if (data.permissionName == 'per_usuario_adicionar') {
          setPermUsuarioAdicionar(true);
        } else if (data.permissionName == 'per_usuario_editar') {
          setPermUsuarioEditar(true);
        } else if (data.permissionName == 'per_catalogo_visualizar') {
          setPermCatalogoVisualizar(true);
        } else if (data.permissionName == 'per_catalogo_adicionar') {
          setPermCatalogoAdicionar(true);
        } else if (data.permissionName == 'per_catalogo_editar') {
          setPermCatalogoEditar(true);
        } else if (data.permissionName == 'per_extrato_visualizar') {
          setPermExtratoVisualizar(true);
        } else if (data.permissionName == 'per_lotes_visualizar') {
          setPermLotesVisualizar(true);
        } else if (data.permissionName == 'per_lotes_editar') {
          setPermLotesEditar(true);
        } else if (data.permissionName == 'per_parceiro_visualizar') {
          setPermParceiroVisualizar(true);
        }
      });
    }
  };

  const handlSearchUsuario = async () => {
    let data = {
      tipo: '',
      nome: search,
      cpf: search,
      status: ''
    };

    await searchUsuario(data);
  };

  const { Pagination } = Table;

  const handleOrderingColumn = (event, column) => {
    const columns = document.getElementsByTagName('th');
    for (let column of columns) {
      column.classList.add('columnDefault');
    }

    let el = event.target;
    el.classList.remove('columnDefault');
    el.classList.add('columnActive');

    if (el.lastChild?.classList?.contains('rs-icon-arrow-up')) {
      el.lastChild.classList.remove('rs-icon-arrow-up');
      el.lastChild.classList.add('rs-icon-arrow-down');
      orderUsuario(
        column,
        'DESC',
        pagination[0].current_page,
        filter,
        userFilterByType,
        userFilterByMainPartner,
        mainPartnerUuid
      );
    } else {
      el.lastChild.classList.remove('rs-icon-arrow-down');
      el.lastChild.classList.add('rs-icon-arrow-up');
      orderUsuario(
        column,
        'ASC',
        pagination[0].current_page,
        filter,
        userFilterByType,
        userFilterByMainPartner,
        mainPartnerUuid
      );
    }
  };

  const tipos = [
    { label: 'Administrador', value: 1 },
    { label: 'Recebimento', value: 2 },
    { label: 'Analisador', value: 3 },
    { label: 'Parceiro', value: 4 },
    { label: 'Parceiro Principal', value: 5 }
  ];

  const filtroPorPrincipal = usuarioListaPorTipo
    ? usuarioListaPorTipo.map((usr) => {
      return {
        value: { userId: usr.id, userUuid: usr.uuid },
        label: usr.id + ' - ' + usr.nome
      };
    })
    : [];

  const handleFilterByType = async (tipo) => {
    setIsLoading(true);
    setUserFilterByMainPartner('');

    setUserFilterByType(tipo);
    if (tipo) {
      setFilter('type');
    } else {
      setFilter(null);
    }
    setUserFilterByMainPartner('');
    setUserFilterByPiece('');
    await getUsuariosPorTipo(tipo);
    setIsLoading(false);
  };

  const handleFilterByMainPartner = async (value) => {
    setIsLoading(true);
    setUserFilterByType('');
    setUserFilterByPiece('');

    if (value) {
      const { userId, userUuid } = value;

      setFilter('mainPartner');
      setMainPartnerUuid(userUuid);
      setMainPartnerId(userId);

      await getUsuariosByMainPartner(userId, userUuid);
    } else {
      setFilter(null);
      setMainPartnerUuid('');

      await getUsuariosByMainPartner();
    }

    setUserFilterByMainPartner(value);
    setIsLoading(false);
  };

  const handleFilterByPiece = async (e) => {
    setIsLoading(true);
    const value = e.target.value;

    setFilter('piece');
    setMainPartnerUuid('');
    setUserFilterByType('');
    setUserFilterByMainPartner('');
    setUserFilterByPiece(value);

    await getUserByPiece(value, 1);
    setIsLoading(false);
  };

  const handleDownloadRelatorioUser = async () => {
    setIsLoading(true);
    await buscaRelatorioUsuarios();
    setIsLoading(false);
  };

  const handleOpenSpreadsheetModal = async () =>
    setIsOpenSpreadsheetFilters(true);

  const handleGenerateSpreadsheet = async (filtros) => {
    setIsLoading(true);
    const response = await buscaRelatorioConsultas(filtros);
    setIsLoading(false);
    return response;
  };

  return (
    <div>
      {isLoading || isLoadingSearch ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : null}
      <S.Container>
        <S.FilterContainer>
          <div>
            <S.Select
              placeholder="Tipo de Usuário"
              onChange={handleFilterByType}
              value={userFilterByType}
              data={tipos}
              size="sm"
              searchable={false}
            />
            <S.Select
              placeholder="Parceiro Principal"
              data={filtroPorPrincipal}
              value={userFilterByMainPartner}
              onChange={handleFilterByMainPartner}
              size="sm"
              style={{
                marginLeft: 8
              }}
            />
            <S.PecaInputContainer>
              <S.Input
                type="number"
                placeholder="Peça"
                onChange={(e) => setUserFilterByPiece(e.target.value)}
                onBlur={handleFilterByPiece}
                value={userFilterByPiece}
              />
            </S.PecaInputContainer>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'flex-end', color: globalStyle.colors.GRAY900 }}
          >
            <span>Lista de Usuários:</span>
            <div
              onClick={() => handleDownloadRelatorioUser()}
              style={{
                width: 28,
                backgroundColor: '#CA8A04',
                height: 28,
                padding: 0,
                margin: '0 10px',
                borderRadius: 4,
                cursor: 'pointer'
              }}
            >
              <DocumentIcon
                largura={25}
                style={{ padding: '1px 1px 0', marginLeft: 0 }}
              />
            </div>
            <span>Lista de consultas:</span>
            <div
              onClick={() => handleOpenSpreadsheetModal()}
              style={{
                width: 28,
                backgroundColor: 'white',
                height: 28,
                padding: 0,
                margin: '0 10px',
                borderRadius: 4,
                outline: 'solid 1px #CA8A04',
                cursor: 'pointer'
              }}
            >
              <DocumentIcon
                largura={25}
                color="black"
                style={{ padding: '1px 1px 0', marginLeft: 0 }}
              />
            </div>
            <div>
              <p>Total: {pagination[0]?.total}</p>
            </div>
          </div>
        </S.FilterContainer>
        {permissaoPerfil != null &&
          permissaoPerfil.includes('per_' + app + '_adicionar') && (
            <S.PlusIconContainer to="novo-usuario">
              <img src={PlusIcon} alt="" />
            </S.PlusIconContainer>
          )}

        <TableComponent
          setIsOpenInativarConfirm={setIsOpenInativarConfirm}
          setUuid={setUuid}
          page={app}
          handleClickNew={() => {
            navigate('novo-usuario');
          }}
          pagination={pagination}
          handleOrderingColumn={handleOrderingColumn}
          handleClickOpenEdit={handleClickOpenEdit}
          lista={usuarioLista ? usuarioLista : []}
          listaPorTipo={usuarioListaPorTipo}
          isLoading={isLoading}
          toggleViewUser={toggleViewUser}
          columns={[
            { header: 'Nome', body: 'nome' },
            { header: 'CPF', body: 'cpf' },
            { header: 'Status', body: 'status' },
            { header: 'Peça', body: 'peca' },
            { header: '', body: 'action' }
          ]}
          // sempre enviar valores no mesmo index da prop "columns", componente pega pelo index
          orderingByColumnsIndex={['name', 'cpf', 'status', 'piece', '']}
        />
        <S.ContainerPagination>
          {pagination?.length ? (
            <Pagination
              prev
              last
              next
              first
              size="lg"
              pages={pagination[0].last_page}
              activePage={pagination[0].current_page}
              onSelect={handleSelect}
            />
          ) : null}
        </S.ContainerPagination>
      </S.Container>

      {isOpenInativarConfirm ? (
        <ConfirmaModal
          show={isOpenInativarConfirm}
          setShow={setIsOpenInativarConfirm}
          title={'Inativar usuário'}
          bodyText={
            'Tem certeza de que deseja inativar este usuário? Essa operação não poderá ser desfeita.'
          }
          btnAcaoTexto={'Inativar'}
          submitFn={handleInativarConfirm}
        ></ConfirmaModal>
      ) : null}
      {isOpenSpreadsheetFilters ? (
        <SpreadsheetFilterModal
          show={isOpenSpreadsheetFilters}
          setShow={setIsOpenSpreadsheetFilters}
          submitFn={handleGenerateSpreadsheet}
        ></SpreadsheetFilterModal>
      ) : null}
    </div>
  );
}
