import React, { useState } from "react";
import { Container, Col, ProductPrice, Price, Info, ActionsContainer, InfoContainer, Row, Label, Text, Observation, RowColumn } from "./styled";
import ProductCarousel from "../ProductCarousel";
import Button from "../Button";
import globalStyle from "../../../styles/globalStyle";
import PriceCalcTable from "../../../components/CatalogoTable/PriceCalcTable";
import { useNavigate } from "react-router-dom";
import { useCatalogo } from "../../../hooks/catalogo";
import ConfirmaModal from "../../../components/ConfirmaModal";

export default function Product({ data }) {
    const { EditCatalogo } = useCatalogo();
    const [showConfirmaModal, setShowConfirmaModal] = useState(false)
    const navigate = useNavigate();

    const handleEditar = () => {
        navigate(
            `/catalogo/editar/${data.uuid}`,
            { state: { data } }
        );
    }

    const handleDeletar = async () => {
        const formData = new FormData();

        const dataFormatada = {
            marca: data.marca,
            modelo: data.modelo,
            codigo1: data.codigo1,
            codigo2: data.codigo2,
            codigo3: data.codigo3,
            definition: data.definition,
            targeting: data.targeting,
            observacao: data.observacao,
            tipo: data.tipo,
            photo_excluir: [],
            A: JSON.stringify(data.A),
            B: JSON.stringify(data.B),
            C: JSON.stringify(data.C),
            D: JSON.stringify(data.D),
            status: 2
        }

        formData.append('body', JSON.stringify(dataFormatada))

        EditCatalogo(formData, data.uuid, 1, navigate)
    }

    return (
        <Container>
            <Col>
                <ProductCarousel fotoArr={data.photos} />
                <ProductPrice>
                    <Price>R$ {data.preco?.br ? data.preco?.br : '0,00'}</Price>
                    <Info>O valor referente à peça em 100% de sua condição.</Info>
                </ProductPrice>
                <ActionsContainer>
                    <Button
                        btnType="delete"
                        iconSize={32}
                        width="113px"
                        height="40px"
                        text="Deletar"
                        uppercase
                        color={'white'}
                        backGroundColor={globalStyle.colors.RED600}
                        onClick={() => setShowConfirmaModal(true)}
                    />
                    <Button
                        btnType="edit"
                        iconSize={22}
                        width="104px"
                        backGroundColor={globalStyle.colors.GRAY100}
                        height="40px"
                        color={'white'}
                        text="Editar"
                        uppercase
                        onClick={handleEditar}
                    />
                </ActionsContainer>
            </Col>
            <Col style={{ width: '50%' }}>
                <InfoContainer>
                    <Row>
                        <Label>Marca: </Label>
                        <Text>{data.marca}</Text>
                    </Row>
                    <Row>
                        <Label>Modelo: </Label>
                        <Text>{data.modelo}</Text>
                    </Row>
                    <Row>
                        <Label>Tipo: </Label>
                        <Text>Catalisador</Text>
                    </Row>
                    <Row>
                        <Label>Definição: </Label>
                        <Text>{data.definition}</Text>
                    </Row>
                    <Row>
                        <Label>Classificação: </Label>
                        <Text>{data.targeting}</Text>
                    </Row>
                    <Row>
                        <Label>CÓD-1: </Label>
                        <Text>{data.codigo1}</Text>
                    </Row>
                    <Row>
                        <Label>CÓD-2: </Label>
                        <Text>{data.codigo2}</Text>
                    </Row>
                    <Row>
                        <Label>CÓD-3: </Label>
                        <Text>{data.codigo3}</Text>
                    </Row>
                    <Row
                        style={{
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            marginBottom: 0
                        }}
                    >
                        <RowColumn>
                            <Row>
                                <Label>Peso 1: </Label>
                                <Text>{data.A.PESO}</Text>
                                <Text style={{ textTransform: "none", marginLeft: 0 }}>Kg</Text>
                            </Row>
                            <Row>
                                <Label>Peso 3: </Label>
                                <Text>{data.C.PESO}</Text>
                                <Text style={{ textTransform: "none", marginLeft: 0 }}>Kg</Text>
                            </Row>
                        </RowColumn>
                        <RowColumn>
                            <Row>
                                <Label>Peso 2: </Label>
                                <Text>{data.B.PESO}</Text>
                                <Text style={{ textTransform: "none", marginLeft: 0 }}>Kg</Text>
                            </Row>
                            <Row>
                                <Label>Peso 4: </Label>
                                <Text>{data.D.PESO}</Text>
                                <Text style={{ textTransform: "none", marginLeft: 0 }}>Kg</Text>
                            </Row>
                        </RowColumn>
                    </Row>
                    <Row>
                        <Label>Quantidade: </Label>
                        <Text>9999</Text>
                    </Row>
                    <Row style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <Label style={{ marginBottom: 8 }}>Observação: </Label>
                        <Observation>
                            {data.observacao}
                        </Observation>
                    </Row>
                    <table>
                        <tbody>
                            <tr>
                                <PriceCalcTable data={data} style={{ position: 'relative', right: 0 }} />
                            </tr>
                        </tbody>
                    </table>
                </InfoContainer>
            </Col>
            <ConfirmaModal
                title={"Deletar Produto"}
                show={showConfirmaModal}
                setShow={setShowConfirmaModal}
                bodyText="Confirmar essa ação ?"
                btnAcaoTexto={'Confirmar'}
                submitFn={handleDeletar}
            />
        </Container>
    )
}