import { useState } from 'react';
import { Modal, Button, FormGroup, InputGroup, Icon } from 'rsuite';
import * as S from './styles';
import { useCatalogo } from '../../hooks/catalogo';

export function SearchCatalogoModal({ isOpenSearch, onRequestCloseSearch }) {
  const { searchCatalogo, isLoadingSearch } = useCatalogo();

  const [tipo, setTipo] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [codigo, setCodigo] = useState('');
  const [status, setStatus] = useState('');

  async function handlSearchCatalogo(e) {
    e.preventDefault();

    let data = {
      tipo,
      marca,
      modelo,
      codigo,
      status
    };

    await searchCatalogo(data, onRequestCloseSearch);
  }

  const tipo_lista = {
    1: 'Catalisador',
    2: 'Pó comum',
    3: 'Inox',
    4: 'Filtro'
  };

  function optionsTipoLista() {
    var es = Object.entries(tipo_lista);
    return es.map((item) => (
      <option key={`tipo_lista${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  const status_lista = {
    1: 'Ativo',
    2: 'Inativo',
    3: 'Pendente para análise'
  };

  function optionsStatus() {
    var es = Object.entries(status_lista);
    return es.map((item) => (
      <option key={`status${item[0]}`} value={item[0]}>
        {item[1]}
      </option>
    ));
  }

  Array.prototype.forEach.call(
    document.querySelectorAll('.clearable-input'),
    function (el) {
      var input = el.querySelector('input');

      conditionallyHideClearIcon();

      input.addEventListener('input', conditionallyHideClearIcon);
      el.querySelector('[data-clear-input]').addEventListener(
        'click',
        function (e) {
          input.value = '';
          conditionallyHideClearIcon();
        }
      );

      function conditionallyHideClearIcon(e) {
        var target = (e && e.target) || input;
        target.nextElementSibling.style.display = target.value
          ? 'flex'
          : 'none';
      }
    }
  );

  Array.prototype.forEach.call(
    document.querySelectorAll('.clearable-select'),
    function (el) {
      var select = el.querySelector('select');

      conditionallyHideClearIcon();

      select.addEventListener('select', conditionallyHideClearIcon);
      el.querySelector('[data-clear-select]').addEventListener(
        'click',
        function (e) {
          select.value = '';
          conditionallyHideClearIcon();
        }
      );

      function conditionallyHideClearIcon(e) {
        var target = (e && e.target) || select;
        select.style.width = target.value ? '92%' : '100%';
        target.nextElementSibling.style.display = target.value
          ? 'flex'
          : 'none';
      }
    }
  );

  return (
    <Modal
      backdrop={'static'}
      show={isOpenSearch}
      onHide={onRequestCloseSearch}
    >
      <Modal.Header>
        <Modal.Title>Pesquisar</Modal.Title>
      </Modal.Header>
      <S.Container onSubmit={handlSearchCatalogo}>
        <Modal.Body>
          <S.ContainerCardForm>
            <div className="card-form">
              <FormGroup>
                <label>Tipo:</label>
                <div className="clearable-select">
                  <select
                    placeholder="Tipo"
                    onChange={(e) => {
                      setTipo(e.target.value);
                    }}
                    value={tipo}
                  >
                    <option key="" value="">
                      Selecione o tipo
                    </option>
                    {optionsTipoLista()}
                  </select>
                  <InputGroup.Button
                    data-clear-select
                    onClick={() => setTipo('')}
                  >
                    <Icon icon="close" />
                  </InputGroup.Button>
                </div>
              </FormGroup>
              <FormGroup className="form-group">
                <label>Marca</label>
                <div className="clearable-input">
                  <input
                    type="text"
                    placeholder="Marca"
                    value={marca}
                    name="marca"
                    onChange={(event) => setMarca(event.target.value)}
                  />
                  <InputGroup.Button
                    data-clear-input
                    onClick={() => setMarca('')}
                  >
                    <Icon icon="close" />
                  </InputGroup.Button>
                </div>
              </FormGroup>
              <FormGroup className="form-group">
                <label>Modelo</label>
                <div className="clearable-input">
                  <input
                    type="text"
                    placeholder="Modelo"
                    value={modelo}
                    name="modelo"
                    onChange={(event) => setModelo(event.target.value)}
                  />
                  <InputGroup.Button
                    data-clear-input
                    onClick={() => setModelo('')}
                  >
                    <Icon icon="close" />
                  </InputGroup.Button>
                </div>
              </FormGroup>
              <FormGroup className="form-group">
                <label>Código</label>
                <div className="clearable-input">
                  <input
                    type="text"
                    placeholder="Código"
                    value={codigo}
                    name="codigo"
                    onChange={(event) => setCodigo(event.target.value)}
                  />
                  <InputGroup.Button
                    data-clear-input
                    onClick={() => setCodigo('')}
                  >
                    <Icon icon="close" />
                  </InputGroup.Button>
                </div>
              </FormGroup>
              <FormGroup>
                <label>Status:</label>
                <div className="clearable-select">
                  <select
                    placeholder="Tipo"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    value={status}
                  >
                    <option key="" value="">
                      Selecione o tipo
                    </option>
                    {optionsStatus()}
                  </select>
                  <InputGroup.Button
                    data-clear-select
                    onClick={() => setStatus('')}
                  >
                    <Icon icon="close" />
                  </InputGroup.Button>
                </div>
              </FormGroup>
            </div>
          </S.ContainerCardForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            appearance="primary"
            style={{ marginTop: '20px' }}
          >
            {isLoadingSearch ? 'Carregando...' : 'Pesquisar'}
          </Button>
        </Modal.Footer>
      </S.Container>
    </Modal>
  );
}
