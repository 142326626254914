const revertFormaPercent = (elemento) => {
    if (elemento) {
      let valor = elemento;

      valor = valor * 100;

      return valor.toFixed(2) * 1;
    }
    return 0;
};

export default revertFormaPercent;