import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { Alert } from 'rsuite';
import { useParams } from 'react-router-dom';

const CompraContext = createContext({});

export const CompraProvider = (props) => {
  const [CompraLista, setCompraLista] = useState([]);
  const [VerCompraPorUUID, setVerCompraPorUUID] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [paginationCompra, setPaginationCompra] = useState([]);
  const [paginationUUID, setPaginationUUID] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVer, setIsLoadingVer] = useState(false);
  const [CompraPorUUID, setCompraPorUUID] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [activePage, setPageActive] = useState('');
  const [order, setOrder] = useState('');
  const [direction, setDirection] = useState('');
  const [compraSearch, setCompraSearch] = useState('');
  const { codigoLote } = useParams();

  const handleCompra = useCallback(async () => {
    const buscaAtiva = localStorage.getItem('salvarBuscaCompraUsuario');

    if (buscaAtiva && buscaAtiva != '') {
      setIsLoading(true);
      let search = JSON.parse(buscaAtiva);
      setCompraSearch(search.nome || search.cpf);
      const response = await api.get(
        '/usuario/parceiros/all?nome=' +
          search.nome +
          '&cpf=' +
          search.cpf.replace(/\D/g, '')
      );
      // TEM QUE FAZER ENDPOINT PARA A BUSCA POR NOME NO /compra

      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);

      setCompraLista(response.data.items);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const response = await api.get('/usuario/parceiros/all');


      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);

      setCompraLista(response.data.items);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleCompra();
  }, [handleCompra]);

  const handleUrlLotes = async () => {
    let url = window.location.href;

    let newUrl = url.replace(codigoLote, '');
    newUrl = newUrl.slice(0, -1);
    window.location.href = newUrl;
  }

  // Deletar uuid
  async function deletarCompra(uuid, modalExcluir) {
    try {
      setIsLoading(true);
      let response = await api.delete('/compra/' + uuid);

      if(codigoLote){
        await handleUrlLotes(); 
      }
      
      modalExcluir();
      setIsLoading(false);

      Alert.success(
        response.status == 200
          ? 'Compra excluida com sucesso!'
          : response.data.message
      );
    } catch (err) {
      Alert.success(err.response.data.message);

      handleCompra();

      window.location.reload();
      modalExcluir();
      setIsLoading(false);
    }
  }

  // Paginação
  async function CompraModalPage(uuid, page) {
    try {
      setIsLoadingVer(true);

      const response = await api.get(
        '/compra/' + uuid + '?page=' + page + '&quantidade=15'
      );

      setVerCompraPorUUID([response.data, uuid]);
      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total_page
        }
      ];

      setPaginationCompra(data);
      setIsLoadingVer(false);
    } catch (err) {
      setIsLoadingVer(false);
    }
  }

  // Paginação
  async function CompraPage(page) {
    try {
      const buscaAtiva = localStorage.getItem('salvarBuscaCompraUsuario');
      if (buscaAtiva && buscaAtiva != '') {
        setIsLoading(true);
        let search = JSON.parse(buscaAtiva);
        const response = await api.get(
          '/usuario/parceiros/all?nome=' +
            search.nome +
            '&cpf=' +
            search.cpf.replace(/\D/g, '') +
            '&order=' +
            order +
            '&direction=' +
            direction +
            '&page=' +
            page
        );

        setCompraLista(response.data.items);
        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
        setIsLoading(false);
      } else {
        setIsLoading(true);

        const response = await api.get(
          '/usuario/parceiros/all?page=' +
            page +
            '&order=' +
            order +
            '&direction=' +
            direction
        );

        setCompraLista(response.data.items);
        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  // Paginação
  async function CompraPageUUID(id, page) {
    try {
      const buscaAtiva = localStorage.getItem('salvarBuscaCompra');
      if (buscaAtiva && buscaAtiva != '') {
        setIsLoading(true);

        let search = JSON.parse(buscaAtiva);
        const responsePage = await api.post('/compra/buscar', search);
        const response = await api.get('/compra/' + id + '?page=' + page + '&origem=web');
        const responseUsuario = await api.get('/usuario/' + id);

        let nome = responseUsuario.data.nome;

        console.log("RESPONSE", response.data.items);
        setVerCompraPorUUID(response.data.items);
        const data = [
          { 
            current_page: responsePage.data.current_page,
            next_page: responsePage.data.next_page,
            prev_page: responsePage.data.prev_page,
            last_page: responsePage.data.last_page,
            total: responsePage.data.total
          }
        ];
        setIsLoading(false);
        setPagination(data);
      } else {
        setIsLoading(true);

        const response = await api.get('/compra/' + id + '?page=' + page + '&origem=web');
        const responseUsuario = await api.get('/usuario/' + id);

        let nome = responseUsuario.data.nome;
        let cpf =  responseUsuario.data.cpf.br;

        setVerCompraPorUUID([response.data.items, nome, cpf]);
        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total_page
          }
        ];
        setPaginationUUID(data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  // USUÁRIOS POR UUID
  async function VisualizarCompraPorUUID(uuid) {
    try {
      const buscaAtiva = localStorage.getItem('salvarBuscaCompra');
      if (buscaAtiva && buscaAtiva != '') {
        setIsLoading(true)
        let search = JSON.parse(buscaAtiva);
        const response = await api.post('/compra/buscar', search);
        const responseUsuario = await api.get('/usuario/' + uuid);

        let nome = responseUsuario.data.nome;

        setVerCompraPorUUID([response.data.items, nome]);

        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total_page
          }
        ];

        setPaginationUUID(data);

        setIsLoading(false);
      } else {
        setIsLoading(true);
        const responseedit = await api.get('/compra/' + uuid + '?origem=web');

        const responseUsuario = await api.get('/usuario/' + uuid);

        let nome = responseUsuario.data.nome;
        let cpf =  responseUsuario.data.cpf.br;

        setVerCompraPorUUID([responseedit.data.items, nome, cpf]);

        const data = [
          {
            current_page: responseedit.data.current_page,
            next_page: responseedit.data.next_page,
            prev_page: responseedit.data.prev_page,
            last_page: responseedit.data.last_page,
            total: responseedit.data.total_page
          }
        ];

        setPaginationUUID(data);

        setIsLoading(false);
      }
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoading(false);
    }
  }

  // USUÁRIOS POR UUID
  async function getCompraPorUuid(idLote, page) {
    try {
      setIsLoadingVer(true);
      const responseedit = await api.get('/compra/compra/' + idLote);
      setCompraPorUUID([responseedit.data.items[0], idLote]);
      setPageActive(page);
      setIsLoadingVer(false);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingVer(false);
    }
  }

  async function verCompraPorUuid(uuid, closeModal = true) {
    try {
      setIsLoadingVer(true);
      const responseedit = await api.get('/compra/' + uuid + '?origem=web');
      const dataPage = [
        {
          current_page: responseedit.data.current_page,
          next_page: responseedit.data.next_page,
          prev_page: responseedit.data.prev_page,
          last_page: responseedit.data.last_page,
          total: responseedit.data.total_page
        }
      ];
      setPaginationCompra(dataPage);
      
      setTimeout(() => {
        window.location.pathname = '/compra/' + uuid;

        if(closeModal) {
          setIsLoadingVer(false);
        }
      }, 1000);
      
    } catch (err) {
      Alert.error(err.response?.data?.message);
      setIsLoadingVer(false);
    }
  }

  // Criar OS PRODUTOS
  async function atualizaCompra(uuid, data, id, modal, nome) {
    try {
      setIsLoadingVer(true);

      function formatarValor(valor) {
        let valorFormatado;
        valorFormatado = `${valor.replace('.', '').replace(',', '.')}`;
        return valorFormatado;
      }

      console.log("data")
      console.log(data)
      console.log("data")

      const body = {
        catalogos: JSON.stringify(data.catalogos),
        usuario: data.usuario
      };

      const formData = new FormData();
      formData.append('body', JSON.stringify(body));

      const response = await api.put('/compra/' + uuid, formData);

      let keysToRemove = ['buscaAtivaCompra', 'salvarBuscaCompra'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));

      const responseedit = await api.get('/compra/compra/' + uuid);

      const dataPage = [
        {
          current_page: responseedit.data.current_page,
          next_page: responseedit.data.next_page,
          prev_page: responseedit.data.prev_page,
          last_page: responseedit.data.last_page,
          total: responseedit.data.total_page
        }
      ];
      
      // console.log("RESPONDEDATAEDIT", responseedit);
      setVerCompraPorUUID([responseedit.data.items, nome]);
      setCompraPorUUID([responseedit.data.items[0], uuid]);
      setPaginationUUID(dataPage);

      Alert.success(
        response.status == 200
          ? 'Compra atualizada com sucesso!'
          : response.data.message
      );
      modal();

      setIsLoadingVer(false);
    } catch (err) {
      setIsLoadingVer(false);
      Alert.error(err.response.data.message);
    }
  }

  // Download compra
  async function compraDownload(idLote) {
    try {
      setIsLoadingDownload(true);
      const responseedit = await api.get('/compra/relatorio/' + idLote);

      setIsLoadingDownload(false);

      return {
        erro: false,
        link: responseedit.data.url
      };
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingDownload(false);
      return {
        erro: true,
        link: ''
      };
    }
  }

  async function orderCompra(column, direction, page) {
    setOrder(column);
    setDirection(direction);
    const buscaAtiva = localStorage.getItem('salvarBuscaCompraUsuario');

    if (buscaAtiva && buscaAtiva != '') {
      let search = JSON.parse(buscaAtiva);
      const response = await api.get(
        `/usuario/parceiros/all?nome=${search.nome}&cpf=${search.cpf.replace(
          /\D/g,
          ''
        )}&tipo=${search.tipo}&status=${
          search.status
        }&order=${column}&direction=${direction}&quantidade=15&page=${page}`
      );
      setIsLoading(true);

      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);
      setCompraLista(response.data.items);
      setIsLoading(false);
    } else {
      // setIsLoading(true);
      const response = await api.get(
        `/usuario/parceiros/all?order=${column}&direction=${direction}&page=${page}&quantidade=15`
      );

      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);
      setCompraLista(response.data.items);
      setIsLoading(false);
    }
  }

  // Pesquisar Extrato
  async function searchCompra(search, Modal) {
    try {
      setIsLoadingSearch(true);

      if (search.codigo == '' || search.usuario == '') {
        handleCompra();
      } else {
        let dataPost = {
          codigo: search.codigo,
          usuario: search.usuario
        };

        const response = await api.post('/compra/buscar', dataPost);

        const responseUsuario = await api.get('/usuario/' + search.usuario);

        let nome = responseUsuario.data.nome;

        setVerCompraPorUUID([response.data.items, nome]);
        if (response.data.error == true) {
          Alert.error(response.data.message);
        } else {
          localStorage.setItem('buscaAtivaCompra', 'ativa');
          localStorage.setItem('salvarBuscaCompra', JSON.stringify(search));

          setCompraLista(response.data.items);
          const data = [
            {
              count: response.data.count,
              current_page: response.data.current_page,
              next_page: response.data.next_page,
              prev_page: response.data.prev_page,
              last_page: response.data.last_page,
              total: response.data.total
            }
          ];
          setPagination(data);
        }
      }

      setIsLoadingSearch(false);
      Modal();
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  async function limpaCompra() {
    try {
      setIsLoadingSearch(true);

      handleCompra();
      let keysToRemove = ['buscaAtivaCompra', 'salvarBuscaCompra'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));
      window.location.reload();

      setIsLoadingSearch(false);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  // Pesquisar Extrato
  async function searchCompraUsuario(search, Modal) {
    try {
      setIsLoadingSearch(true);

      if (search.nome == '' && search.cpf == '') {
        let keysToRemove = [
          'buscaAtivaCompraUsuario',
          'salvarBuscaCompraUsuario'
        ];

        keysToRemove.forEach((k) => localStorage.removeItem(k));
        handleCompra();
      } else {
        const response = await api.get(
          '/usuario/parceiros/all?nome=' +
            search.nome +
            '&cpf=' +
            search.cpf.replace(/\D/g, '')
        );

        if (response.data.error == true) {
          Alert.error(response.data.message);
        } else {
          localStorage.setItem('buscaAtivaCompraUsuario', 'ativa');
          localStorage.setItem(
            'salvarBuscaCompraUsuario',
            JSON.stringify(search)
          );

          setCompraLista(response.data.items);
          const data = [
            {
              count: response.data.count,
              current_page: response.data.current_page,
              next_page: response.data.next_page,
              prev_page: response.data.prev_page,
              last_page: response.data.last_page,
              total: response.data.total
            }
          ];
          setPagination(data);
        }
      }

      setIsLoadingSearch(false);
      if (Modal != undefined) Modal();
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  async function buscaRelatorioTodasCompra(uuid) {
    setIsLoading(true);

    try {
      const response = await api.get(`/compra/relatorio/user/${uuid}`);

      window.location.assign(response.data.url);
      
      setIsLoading(false);
    } catch(err) {
      console.log(err);      

      setIsLoading(false);
    }
  }

  async function limpaCompraUsuario() {
    try {
      setIsLoadingSearch(true);
      handleCompra();
      let keysToRemove = [
        'buscaAtivaCompraUsuario',
        'salvarBuscaCompraUsuario'
      ];

      keysToRemove.forEach((k) => localStorage.removeItem(k));
      window.location.reload();

      setIsLoadingSearch(false);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  return (
    <CompraContext.Provider
      value={{
        isLoading,
        CompraLista,
        getCompraPorUuid,
        pagination,
        CompraPage,
        verCompraPorUuid,
        VerCompraPorUUID,
        isLoadingVer,
        deletarCompra,
        paginationCompra,
        CompraModalPage,
        CompraPorUUID,
        atualizaCompra,
        limpaCompra,
        setCompraSearch,
        compraSearch,
        searchCompra,
        isLoadingSearch,
        VisualizarCompraPorUUID,
        paginationUUID,
        CompraPageUUID,
        isLoadingDownload,
        compraDownload,
        limpaCompraUsuario,
        searchCompraUsuario,
        activePage,
        orderCompra,
        buscaRelatorioTodasCompra
      }}
    >
      {props.children}
    </CompraContext.Provider>
  );
};

export function useCompra() {
  const context = useContext(CompraContext);

  return context;
}
