import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Header, Sidebar as Sidenav } from 'rsuite';
import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Topbar';
import { useLocation } from 'react-router-dom';
import { Content } from './styled';
import HistoricoLocalizacaoTable from '../../components/HistoricoLocalizacaoTable';
import globalStyle from '../../styles/globalStyle';

export default function HistoricoLocalizacaoParceiro(props) {
  const { id } = useParams();
  const { state } = useLocation();
  const app = 'HistoricoLocalizacaoParceiro';

  return (
    <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
      <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
        <Sidebar activePage={'6'} />
      </Sidenav>
      <Container>
        <Header style={{ minHeight: '3.6rem' }}>
          <Topbar
            pageTitle={'Parceiros'}
            username={state.usr_name}
            detalhes
            isLocationHistoryPage
          />
        </Header>
        <Content style={{ marginTop: 25 }}>
          <HistoricoLocalizacaoTable app={app} uuid={id} />
        </Content>
      </Container>
    </Container>
  );
}
