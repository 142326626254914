import React from 'react';
import globalStyle from '../../styles/globalStyle';

const DashboardIcon = ({ largura, altura, color = globalStyle.colors.GRAY100 }) => {
  return (
    <svg
      width={largura}
      height={altura}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline', marginRight: 10, marginLeft: 10 }}
    >
      <g clipPath="url(#clip0_2743_7735)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4697 2.59076C11.7626 2.29787 12.2374 2.29787 12.5303 2.59076L21.5303 11.5908C21.7448 11.8053 21.809 12.1279 21.6929 12.4081C21.5768 12.6884 21.3033 12.8711 21 12.8711H19C18.5858 12.8711 18.25 12.5353 18.25 12.1211C18.25 11.7069 18.5858 11.3711 19 11.3711H19.1893L12 4.18175L4.81066 11.3711H5C5.41421 11.3711 5.75 11.7069 5.75 12.1211C5.75 12.5353 5.41421 12.8711 5 12.8711H3C2.69665 12.8711 2.42318 12.6884 2.30709 12.4081C2.191 12.1279 2.25517 11.8053 2.46967 11.5908L11.4697 2.59076Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 11.3711C5.41421 11.3711 5.75 11.7069 5.75 12.1211V19.1211C5.75 19.4526 5.8817 19.7706 6.11612 20.005C6.35054 20.2394 6.66848 20.3711 7 20.3711H17C17.3315 20.3711 17.6495 20.2394 17.8839 20.005C18.1183 19.7706 18.25 19.4526 18.25 19.1211V12.1211C18.25 11.7069 18.5858 11.3711 19 11.3711C19.4142 11.3711 19.75 11.7069 19.75 12.1211V19.1211C19.75 19.8504 19.4603 20.5499 18.9445 21.0656C18.4288 21.5814 17.7293 21.8711 17 21.8711H7C6.27065 21.8711 5.57118 21.5814 5.05546 21.0656C4.53973 20.5499 4.25 19.8504 4.25 19.1211V12.1211C4.25 11.7069 4.58579 11.3711 5 11.3711Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 13.8711C10.6685 13.8711 10.3505 14.0028 10.1161 14.2372C9.8817 14.4716 9.75 14.7896 9.75 15.1211V21.1211C9.75 21.5353 9.41421 21.8711 9 21.8711C8.58579 21.8711 8.25 21.5353 8.25 21.1211V15.1211C8.25 14.3917 8.53973 13.6923 9.05546 13.1766C9.57118 12.6608 10.2707 12.3711 11 12.3711H13C13.7293 12.3711 14.4288 12.6608 14.9445 13.1766C15.4603 13.6923 15.75 14.3917 15.75 15.1211V21.1211C15.75 21.5353 15.4142 21.8711 15 21.8711C14.5858 21.8711 14.25 21.5353 14.25 21.1211V15.1211C14.25 14.7896 14.1183 14.4716 13.8839 14.2372C13.6495 14.0028 13.3315 13.8711 13 13.8711H11Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2743_7735">
          <rect
            width="24"
            height="24"
            fill={color}
            transform="translate(0 0.121094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DashboardIcon;
