import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { Alert } from 'rsuite';
import { useParams } from 'react-router-dom';

const ExtratoContext = createContext({});

export const ExtratoProvider = (props) => {
  const [ExtratoLista, setExtratoLista] = useState([]);
  const [ExtratoPorUUID, setExtratoPorUUID] = useState([]);
  const [VerExtratoPorUUID, setVerExtratoPorUUID] = useState([]);
  const [VerEditExtratoPorUUID, setVerEditExtratoPorUUID] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [paginationExtrato, setPaginationExtrato] = useState([]);
  const [tipoEdit, setTipoEdit] = useState(0);
  const [uuidEdit, setUUIDEdit] = useState('');
  const [nomeEdit, setNomeEdit] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [isLoadingVer, setIsLoadingVer] = useState(false);
  const [isLoadingVerEdit, setIsLoadingVerEdit] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [order, setOrder] = useState('');
  const [direction, setDirection] = useState('');
  const [semExtrato, setSemExtrato] = useState(undefined);
  const [usuarioListaPorTipo, setUsuarioListaPorTipo] = useState([]);
  const [extratoSearch, setExtratoSearch] = useState('');
  const { codigo } = useParams();

  const handleUrlLotes = () => {
    let url = window.location.href;

    let newUrl = url.replace(codigo, '');
    newUrl = newUrl.slice(0, -1);
    window.location.href = newUrl;
  }

  const handleExtrato = useCallback(async () => {
    const buscaAtiva = localStorage.getItem('salvarBuscaExtrato');

    if (buscaAtiva && buscaAtiva != '') {
      setIsLoading(true);
      let search = JSON.parse(buscaAtiva);
      setExtratoSearch(search.nome || search.cpf);
      const response = await api.get(
        '/usuario/parceiros/all?nome=' +
          search.nome +
          '&cpf=' +
          search.cpf.replace(/\D/g, '')
      );
      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);

      setExtratoLista(response.data.items);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const response = await api.get('/usuario/parceiros/all');

      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);

      setExtratoLista(response.data.items);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleExtrato();
  }, [handleExtrato]);

  // Deletar uuid
  async function deletarExtrato(uuid, modalExcluir, uuidUser) {
    try {
      setIsLoadingEdit(true);

      let response = await api.delete('/extrato/' + uuid);

      const responseedit = await api.get('/extrato/lista/' + uuidUser);

      setVerExtratoPorUUID([responseedit.data, uuidUser]);
      const dataPage = [
        {
          current_page: responseedit.data.current_page,
          next_page: responseedit.data.next_page,
          prev_page: responseedit.data.prev_page,
          last_page: responseedit.data.last_page,
          total: responseedit.data.total
        }
      ];
      handleExtrato();

      Alert.success(response.data.message);
      setPaginationExtrato(dataPage);

      modalExcluir();
      setIsLoadingEdit(false);
    } catch (err) {
      Alert.success(err.response.data.message);

      // const responseedit = await api.get('/extrato/lista/' + uuidUser);

      // setVerExtratoPorUUID([responseedit.data, uuidUser]);
      // const dataPage = [
      //   {
      //     current_page: responseedit.data.current_page,
      //     next_page: responseedit.data.next_page,
      //     prev_page: responseedit.data.prev_page,
      //     last_page: responseedit.data.last_page,
      //     total: responseedit.data.total
      //   }
      // ];
      // handleExtrato();
      // setPaginationExtrato(dataPage);
      setVerExtratoPorUUID([[], uuidUser]);
      handleExtrato();
      modalExcluir();
      setIsLoadingEdit(false);
    }
  }

  // Paginação
  async function extratoModalPage(uuid, page) {
    try {
      setIsLoadingVer(true);
      setIsLoadingVerEdit(true);
      const response = await api.get(
        '/extrato/lista/' + uuid + '?page=' + page
      );

      setVerExtratoPorUUID([response.data, uuid]);
      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];

      setPaginationExtrato(data);
      setIsLoadingVer(false);
      setIsLoadingVerEdit(false);
    } catch (err) {
      setIsLoadingVer(false);
      setIsLoadingVerEdit(false);
    }
  }

  // Paginação
  async function extratoPage(page, type, mainp_uuid, mainp_id) {
    setIsLoading(true);
    try {
      const buscaAtiva = localStorage.getItem('salvarBuscaExtrato');

      if (buscaAtiva && buscaAtiva != '') {
        let search = JSON.parse(buscaAtiva);
        const response = await api.get(
          `/usuario/parceiros/all?nome=${search.nome}&cpf=${search.cpf.replace(
            /\D/g,
            ''
          )}&tipo=${search.tipo}&status=${
            search.status
          }&order=${order}&direction=${direction}&page=${page}`
        );
        setExtratoLista(response.data.items);
        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
        setIsLoading(false);
      } else if (type) {
        getExtratoPorTipo(type, page);
      } else if (mainp_uuid) {
        getExtratoByMainPartner(mainp_id, mainp_uuid, page);
      } else {
        const response = await api.get(
          `/usuario/parceiros/all?order=${order}&direction=${direction}&page=${page}&tipo=${type}`
        );

        setExtratoLista(response.data.items);
        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  // USUÁRIOS POR UUID

  async function getExtratoPorUuid(uuid, tipo, modal, nome) {
    try {
      setIsLoadingEdit(true);
      const responseedit = await api.get('/extrato/' + uuid);

      setExtratoPorUUID([responseedit.data.items]);

      setTipoEdit(tipo);
      setUUIDEdit(uuid);
      setNomeEdit(nome);
      modal();
      setIsLoadingEdit(false);
    } catch (err) {
      setTipoEdit(tipo);
      setUUIDEdit(uuid);
      setNomeEdit(nome);
      modal();
      // Alert.error(err.response.data.message);
      setIsLoadingEdit(false);
    }
  }

  // USUÁRIOS POR TIPO
  async function getExtratoPorTipo(tipo, page) {
    setIsLoading(true);
    await limpaExtrato(false);
    try {
      if (tipo) {
        setExtratoSearch('');
        const response = await api.get(
          `/usuario?tipo=${tipo}&order=${order}&direction=${direction}${
            page ? `&page=${page}` : ''
          }&quantidade=15`
        );

        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);

        setExtratoLista(response.data.items);
        setIsLoading(false);
      } else {
        setExtratoSearch('');
        const response = await api.get(
          `/usuario/parceiros/all${page ? `?page=${page}` : ''}`
        );

        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);

        setExtratoLista(response.data.items);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  async function getParceirosTipoPrincipal(page) {
    try {
      const res = await api.get(
        `/usuario?tipo=${5}${page ? `&page=${page}` : ''}`
      );
      // const data = [
      //   {
      //     current_page: res.data.current_page,
      //     next_page: res.data.next_page,
      //     prev_page: res.data.prev_page,
      //     last_page: res.data.last_page,
      //     total: res.data.total
      //   }
      // ];
      // setPagination(data);
      setUsuarioListaPorTipo(res.data.items);
    } catch (err) {
      Alert.error(err.response.data.message);
    }
  }

  async function getExtratoByMainPartner(mainp_id, mainp_uuid, page) {
    await limpaExtrato(false);
    try {
      if (mainp_id) {
        setIsLoading(true);
        let userList = [];
        const partnersByMainPartner = await api.get(
          `/usuario/parrceiro-principal/${mainp_id}?order=${order}&direction=${direction}${
            page ? `&page=${page}` : ''
          }`
        );
        const mainPartner = await api.get(`/usuario/${mainp_uuid}`);

        userList.push(mainPartner.data);

        userList.push(...partnersByMainPartner.data.items);

        const data = [
          {
            current_page: partnersByMainPartner.data.current_page,
            next_page: partnersByMainPartner.data.next_page,
            prev_page: partnersByMainPartner.data.prev_page,
            last_page: partnersByMainPartner.data.last_page,
            total: partnersByMainPartner.data.total
          }
        ];
        setPagination(data);
        setExtratoLista(userList);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        const response = await api.get('/usuario/parceiros/all');

        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);

        setExtratoLista(response.data.items);
        setIsLoading(false);
      }
    } catch (err) {}
  }
  async function verExtratoPorUuid(uuid, nome, navigate) {
    setSemExtrato(false);
    try {
      setIsLoadingVerEdit(true);
      const responseedit = await api.get('/extrato/lista/' + uuid);
      const data = [responseedit.data, uuid, nome]
      setVerExtratoPorUUID(data);
      
      const dataPage = [
        {
          current_page: responseedit.data.current_page,
          next_page: responseedit.data.next_page,
          prev_page: responseedit.data.prev_page,
          last_page: responseedit.data.last_page,
          total: responseedit.data.total
        }
      ];
      setPaginationExtrato(dataPage);
      setIsLoadingVerEdit(false);
      setNomeEdit(nome);
      if(navigate) {
        navigate('/extrato/detalhe', { state: data });
      }
      
      return data
    } catch (err) {
      console.log(err)
      Alert.error(err.response?.data?.message);
      setSemExtrato(true);
      setIsLoadingVerEdit(false);
      setNomeEdit('');
    }
  }
  // Download compra
  async function extratoDownload(uuid) {
    try {
      setIsLoadingDownload(true);
      const responseedit = await api.get('/extrato/relatorio/' + uuid);

      setIsLoadingDownload(false);

      return {
        erro: false,
        link: responseedit.data.url
      };
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingDownload(false);
      return {
        erro: true,
        link: ''
      };
    }
  }

  async function verEditExtratoPorUuid(uuid, modal, page) {
    try {
      setIsLoadingVerEdit(true);

      const responseedit = await api.get('/extrato/' + uuid + '?page=' + page);

      setVerEditExtratoPorUUID([responseedit.data, uuid]);

      setIsLoadingVerEdit(false);
      modal();
    } catch (err) {
      Alert.error(err.response?.data?.message);
      setIsLoadingVerEdit(false);
    }
  }

  async function atualizaCodigo(page, codigo, uuid) {
    try {
      setIsLoadingVer(true);
      const dataInput = {
        codigo: codigo
      };
      const responseedit = await api.put('/extrato/' + uuid, dataInput);

      setVerExtratoPorUUID([responseedit.data, uuid]);
      const dataPage = [
        {
          current_page: responseedit.data.current_page,
          next_page: responseedit.data.next_page,
          prev_page: responseedit.data.prev_page,
          last_page: responseedit.data.last_page,
          total: responseedit.data.total
        }
      ];
      setPaginationExtrato(dataPage);
      setIsLoadingVer(false);
    } catch (err) {
      Alert.error(err.response?.data?.message);
      setIsLoadingVer(false);
    }
  }

  // EDITANDO OS USUÁRIOS
  async function editExtrato(ExtratoInputEdit, Modal) {
    try {
      setIsLoadingEdit(true);
      setIsLoading(true);
      const body = {
        uuidCompra: ExtratoInputEdit.uuid ? ExtratoInputEdit.uuid : null,
        usuario: ExtratoInputEdit.usuario ? ExtratoInputEdit.usuario : null,
        valor: ExtratoInputEdit.valor ? ExtratoInputEdit.valor : null,
        descricao: ExtratoInputEdit.descricao
          ? ExtratoInputEdit.descricao
          : null,
        qtd_liquida: ExtratoInputEdit.quantidade
          ? ExtratoInputEdit.quantidade
          : null,
        qtd_bruta: ExtratoInputEdit.qtdBruta ? ExtratoInputEdit.qtdBruta : null,
        status: ExtratoInputEdit.status ? ExtratoInputEdit.status : null,
        cod_lote: ExtratoInputEdit.codigo ? ExtratoInputEdit.codigo : null,
        observacao: ExtratoInputEdit.observacao
          ? ExtratoInputEdit.observacao
          : null,
        saldo: ExtratoInputEdit.saldo ? ExtratoInputEdit.saldo : null,
        created_at: ExtratoInputEdit.created_at
          ? ExtratoInputEdit.created_at
          : null,
        observacao: ExtratoInputEdit.observacao
          ? ExtratoInputEdit.observacao
          : null,
      };

      const data = new FormData();
      data.append('body', JSON.stringify(body));

      const response = await api.post(`/extrato`, data);
  
      // if(codigo){
      //   await handleUrlLotes(); 
      // }
      
      handleExtrato();
      Modal();
      setIsLoadingEdit(false);
      Alert.success(response.data.message);
      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingEdit(false);
      setIsLoading(false);
    }
  }

  // EDITANDO OS USUÁRIOS.
  async function vereditExtrato(ExtratoInputEdit, Modal, uuid, uuidLista) {
    try {
      setIsLoadingVerEdit(true);

      const data = {
        usuario: ExtratoInputEdit.usuario,
        valor: ExtratoInputEdit.valor,
        ns1: ExtratoInputEdit.ns1,
        ns2: ExtratoInputEdit.ns2,
        ns3: ExtratoInputEdit.ns3,
        descricao: ExtratoInputEdit.descricao,
        cod_lote: ExtratoInputEdit.codigo,
        quantidade: ExtratoInputEdit.quantidade,
        tinox: ExtratoInputEdit.tnox,
        tfiltro: ExtratoInputEdit.tfiltro,
        tflex: ExtratoInputEdit.tflex,
        status: ExtratoInputEdit.status,
        venda_tipo: ExtratoInputEdit.venda_tipo,
        v1: ExtratoInputEdit.v1,
        v2: ExtratoInputEdit.v2,
        v3: ExtratoInputEdit.v3,
        qtd1: ExtratoInputEdit.qtd1,
        qtd2: ExtratoInputEdit.qtd2,
        qtd3: ExtratoInputEdit.qtd3,
        descricao1: ExtratoInputEdit.descricao1,
        descricao2: ExtratoInputEdit.descricao2,
        descricao3: ExtratoInputEdit.descricao3,
        saldo: ExtratoInputEdit.saldo,
        observacao: ExtratoInputEdit.observacao,
        created_at: ExtratoInputEdit.created_at
      };

      const response = await api.put('/extrato/' + uuid, data);

      const responseedit = await api.get('/extrato/lista/' + uuidLista);

      setVerExtratoPorUUID([responseedit.data, uuidLista]);
      const dataPage = [
        {
          current_page: responseedit.data.current_page,
          next_page: responseedit.data.next_page,
          prev_page: responseedit.data.prev_page,
          last_page: responseedit.data.last_page,
          total: responseedit.data.total
        }
      ];
      handleExtrato();
      setPaginationExtrato(dataPage);

      if (response.status === 200) {
        Alert.success('Extrato atualizado com sucesso.');
      }

      // Modal();
      setIsLoadingVerEdit(false);
    } catch (err) {
      Alert.error(
        err.response?.data?.message
          ? err.response?.data?.message
          : 'Erro ao atualizar extrato.'
      );
      setIsLoadingVerEdit(false);
    }
  }

  // Pesquisar Catálogo
  async function orderExtrato(
    column,
    direction,
    page,
    filter,
    type,
    mainp,
    mainp_uuid
  ) {
    setOrder(column);
    setDirection(direction);
    const buscaAtiva = localStorage.getItem('salvarBuscaExtrato');

    if (buscaAtiva && buscaAtiva != '' && !filter) {
      let search = JSON.parse(buscaAtiva);
      const response = await api.get(
        `/usuario/parceiros/all?nome=${search.nome}&cpf=${search.cpf.replace(
          /\D/g,
          ''
        )}&tipo=${search.tipo}&status=${
          search.status
        }&order=${column}&direction=${direction}`
      );
      setIsLoading(true);

      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);
      setExtratoLista(response.data.items);
      setIsLoading(false);
    } else {
      if (!filter) {
        // setIsLoading(true);
        const response = await api.get(
          `/usuario/parceiros/all?order=${column}&direction=${direction}&page=${page}&tipo=${type}`
        );

        const data = [
          {
            current_page: response.data.current_page,
            next_page: response.data.next_page,
            prev_page: response.data.prev_page,
            last_page: response.data.last_page,
            total: response.data.total
          }
        ];
        setPagination(data);
        setExtratoLista(response.data.items);
        setIsLoading(false);
      } else {
        if (filter == 'type') {
          setIsLoading(true);
          const response = await api.get(
            `/usuario?tipo=${type}&order=${column}&direction=${direction}&page=${page}&quantidade=15`
          );

          const data = [
            {
              current_page: response.data.current_page,
              next_page: response.data.next_page,
              prev_page: response.data.prev_page,
              last_page: response.data.last_page,
              total: response.data.total
            }
          ];
          setPagination(data);
          setExtratoLista(response.data.items);
          setIsLoading(false);
        } else {
          setIsLoading(true);
          let userList = [];

          const partnersByMainPartner = await api.get(
            `/usuario/parrceiro-principal/${mainp[0]}?order=${column}&direction=${direction}&page=${page}&quantidade=15`
          );
          const mainPartner = await api.get(`/usuario/${mainp_uuid}`);

          userList.push(mainPartner.data);
          userList.push(...partnersByMainPartner.data.items);

          const data = [
            {
              current_page: partnersByMainPartner.data.current_page,
              next_page: partnersByMainPartner.data.next_page,
              prev_page: partnersByMainPartner.data.prev_page,
              last_page: partnersByMainPartner.data.last_page,
              total: partnersByMainPartner.data.total
            }
          ];
          setPagination(data);
          setExtratoLista(userList);
          setIsLoading(false);
        }
      }
    }
  }

  // Pesquisar Extrato
  async function searchExtrato(search, Modal) {
    try {
      setIsLoadingSearch(true);
      setIsLoading(true);
      if (search.nome == '' && search.cpf == '') {
        let keysToRemove = ['buscaAtivaExtrato', 'salvarBuscaExtrato'];

        keysToRemove.forEach((k) => localStorage.removeItem(k));
        handleExtrato();
      } else {
        const response = await api.get(
          '/usuario/parceiros/all?nome=' +
            search.nome +
            '&cpf=' +
            search.cpf.replace(/\D/g, '')
        );

        if (response.data.error == true) {
          Alert.error(response.data.message);
        } else {
          localStorage.setItem('buscaAtivaExtrato', 'ativa');
          localStorage.setItem('salvarBuscaExtrato', JSON.stringify(search));

          setExtratoLista(response.data.items);
          const data = [
            {
              count: response.data.count,
              current_page: response.data.current_page,
              next_page: response.data.next_page,
              prev_page: response.data.prev_page,
              last_page: response.data.last_page,
              total: response.data.total
            }
          ];
          setPagination(data);
        }
      }

      setIsLoadingSearch(false);
      if (Modal != undefined) Modal();
      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response?.data?.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
      setIsLoading(false);
    }
  }
  async function limpaExtrato(reload = true) {
    try {
      setIsLoadingSearch(true);

      if (reload) handleExtrato();
      let keysToRemove = ['buscaAtivaExtrato', 'salvarBuscaExtrato'];

      keysToRemove.forEach((k) => localStorage.removeItem(k));

      if (reload) window.location.reload();

      if (reload) setIsLoadingSearch(false);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoadingSearch(false); //pegando o response da requisição da api
    }
  }

  return (
    <ExtratoContext.Provider
      value={{
        VerExtratoPorUUID,
        isLoading,
        setSemExtrato,
        setExtratoSearch,
        extratoSearch,
        getParceirosTipoPrincipal,
        getExtratoByMainPartner,
        usuarioListaPorTipo,
        getExtratoPorTipo,
        semExtrato,
        ExtratoLista,
        getExtratoPorUuid,
        ExtratoPorUUID,
        editExtrato,
        tipoEdit,
        pagination,
        uuidEdit,
        isLoadingEdit,
        extratoPage,
        verExtratoPorUuid,
        isLoadingVer,
        deletarExtrato,
        paginationExtrato,
        extratoModalPage,
        atualizaCodigo,
        searchExtrato,
        limpaExtrato,
        isLoadingSearch,
        nomeEdit,
        VerEditExtratoPorUUID,
        verEditExtratoPorUuid,
        isLoadingVerEdit,
        vereditExtrato,
        extratoDownload,
        isLoadingDownload,
        orderExtrato
      }}
    >
      {props.children}
    </ExtratoContext.Provider>
  );
};

export function useExtrato() {
  const context = useContext(ExtratoContext);

  return context;
}
