import { CompraTable } from '../../components/CompraTable/index';
import { SearchCompraUsuarioModal } from '../../components/SearchCompraUsuarioModal/index';
import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { CompraProvider, useCompra } from '../../hooks/compra';
import { Container, Header, Sidebar as Sidenav, Content, Loader } from 'rsuite';
import { usePerfil } from '../../hooks/perfil';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { ExtratoProvider } from '../../hooks/extrato';
import globalStyle from '../../styles/globalStyle';

export function Compra() {
  let app = 'compra';
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();
  const {
    isLoadingVer,
    isLoading
  } = useCompra();
  const [isSearchCompraUsuarioModalOpen, setIsSearchCompraUsuarioModalOpen] =
    useState(false);

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_' + app + '_visualizar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }
  function handleOpenSearchCompraUsuarioModal() {
    setIsSearchCompraUsuarioModalOpen(true);
  }

  function handleCloseSearchCompraUsuarioModal() {
    setIsSearchCompraUsuarioModalOpen(false);
  }

  return (
    <>
      {loadingPer || isLoadingVer || isLoading ? (
        <Loader backdrop content="Carregando..." vertical />
      ) : (
        <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
          <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
            <Sidebar activePage={'7'} />
          </Sidenav>
          <Container>
            <Header style={{ minHeight: '3.6rem' }}>
              <Topbar
                pageTitle={'Lotes'}
                onOpenSearchCompraUsuarioModal={
                  handleOpenSearchCompraUsuarioModal
                }
              />
            </Header>
            <Content>
              <CompraTable />
              {isSearchCompraUsuarioModalOpen ? (
                <SearchCompraUsuarioModal
                  isOpenSearch={isSearchCompraUsuarioModalOpen}
                  onRequestCloseSearch={handleCloseSearchCompraUsuarioModal}
                />
              ) : null}
            </Content>
          </Container>
        </Container>
      )}
    </>
  );
}
