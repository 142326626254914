import styled from "styled-components";

export const Container = styled.div`
    margin-top: 51px;

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 8px;
        margin-top: -8px;
        thead {
            background: ${props => props.theme.colors.GRAY300};
        }

        tbody {
            tr {
                height: 25px;
                td {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                    color: ${props => props.theme.colors.GRAY900};
                }
            }
        }
    }
`

export const TableHeader = styled.th`
    width: ${props => props.width ? props.width : 'auto'};
    text-align: left;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 4px 0;
    color: ${props => props.theme.colors.GRAY900};
`

export const Title = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${props => props.theme.colors.GRAY900};
`