import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { Alert } from 'rsuite';
const ParceiroContext = createContext({});

export const ParceiroProvider = (props) => {
  const [parceiroLista, setParceiroLista] = useState([]);
  const [parceiroMapa, setParceiroMapa] = useState([]);
  const [parceiroNome, setParceiroNome] = useState('');
  const [parceiroPorUUID, setParceiroPorUUID] = useState([]);
  const [historicoDeLocalizacaoPorUUID, setHistoricoDeLocalizacaoPorUUID] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState([] || '');
  const [order, setOrder] = useState('');
  const [direction, setDirection] = useState('');
  const [historicoPagination, setHistoricoPagination] = useState([] || '');

  const handleParceiro = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get('/usuario/parceiros/all');

    const data = [
      {
        count: response.data.count,
        current_page: response.data.current_page,
        next_page: response.data.next_page,
        prev_page: response.data.prev_page,
        last_page: response.data.last_page,
        total: response.data.total
      }
    ];
    setPagination(data);
    setParceiroLista(response.data.items);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleParceiro();

    return () => {
      setPagination([]);
      setParceiroLista([]);
      setIsLoading(false);
    };
  }, [handleParceiro]);

  const handleParceiroMapa = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get(
      '/usuario/parceiros/all?quantidade=1000&mapa=true'
    );

    setParceiroMapa(response.data.items);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleParceiroMapa();
  }, [handleParceiroMapa]);

  // FORNECEDOR POR UUID
  async function getParceiroPorUuid(uuid) {
    try {
      setIsLoading(false);

      const responseedit = await api.get('/usuario/' + uuid);

      setParceiroPorUUID(responseedit.data.items);

      if (
        responseedit.data.latitude == null &&
        responseedit.data.longitude == null
      ) {
        Alert.error('Usuário sem geolocalização disponível');
      } else {
        setTimeout(() => {
          window.location.pathname = '/parceiro/' + uuid;
        }, 1000);
      }
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoading(false);
    }
  }

  async function getParceiroPorNome(text) {
    setIsLoading(true);
    try {
      const response = await api.get(`/usuario/parceiros/all?nome=${text}`);

      const data = [
        {
          count: response.data.count,
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setParceiroNome(text);
      setPagination(data);
      setParceiroLista(response.data.items);
      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoading(false);
      setParceiroNome('');
    }
  }

  async function VisualizarParceiroPorUUID(uuid) {
    try {
      setIsLoading(false);

      const responseedit = await api.get('/usuario/' + uuid);

      setParceiroPorUUID([responseedit.data]);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoading(false);
    }
  }

  async function VisualizarHistoricoDeLocalizacaoPorUUID(uuid) {
    setIsLoading(true);
    try {

      const response = await api.post(
        `/location-history?page=1&quantidade=10&order=id&direction=desc&user=${uuid}`
      );

      const data = [
        {
          count: response.data.count,
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setHistoricoPagination(data);
      setHistoricoDeLocalizacaoPorUUID(response.data.items.rows);

      setIsLoading(false);
      return true;
    } catch (err) {
      Alert.error('Usuário sem histórico de geolocalização disponível');
      setIsLoading(false);

      return false;
    }
  }

  // Ordenação
  async function orderParceiro(column, direction, page) {
    setOrder(column);
    setDirection(direction);
    const buscaAtiva = localStorage.getItem('salvarBuscaUsuario');

    setIsLoading(true);
    const response = await api.get(
      `/usuario/parceiros/all?order=${column}&direction=${direction}&page=${page}`
    );

    const data = [
      {
        current_page: response.data.current_page,
        next_page: response.data.next_page,
        prev_page: response.data.prev_page,
        last_page: response.data.last_page,
        total: response.data.total
      }
    ];
    setPagination(data);
    setParceiroLista(response.data.items);
    setIsLoading(false);
  }
  // Paginação
  async function parceiroPage(page) {
    try {
      // const buscaAtiva = localStorage.getItem('salvarBuscaExtrato');

      setIsLoading(true);

      const response = await api.get(
        `/usuario/parceiros/all?order=${order}&direction=${direction}&page=${page}&nome=${parceiroNome}`
      );
      setParceiroLista(response.data.items);
      const data = [
        {
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setPagination(data);
      setIsLoading(false);

      // if(buscaAtiva && buscaAtiva != ''){
      //     setIsLoading(true);
      //     let search = JSON.parse(buscaAtiva);
      //     const response = await api.get(`/usuario/parceiros/all?quantidade=15&mapa=true&nome=${search.nome}&cpf=${search.cpf.replace(/\D/g, '')}&tipo=${search.tipo}&status=${search.status}&order=${order}&direction=${direction}`);
      //     setParceiroLista(response.data.items);

      //     const data = [{
      //             'current_page' : response.data.current_page,
      //             'next_page' : response.data.next_page,
      //             'prev_page' : response.data.prev_page,
      //             'last_page' : response.data.last_page,
      //             'total' : response.data.total
      //         }
      //     ];
      //     setPagination(data);
      //     setIsLoading(false);
      // }else{
      //     setIsLoading(false);

      //     const response = await api.get(`/usuario/parceiros/all?order=${order}&direction=${direction}&page=${page}`);
      //     setParceiroLista(response.data.items);
      //     const data = [{
      //             'current_page' : response.data.current_page,
      //             'next_page' : response.data.next_page,
      //             'prev_page' : response.data.prev_page,
      //             'last_page' : response.data.last_page,
      //             'total' : response.data.total
      //         }
      //     ];
      //     setPagination(data);
      //     setIsLoading(false);
      // }
    } catch (err) {
      setIsLoading(false);
    }
  }

  async function VisualizarHistoricoDeLocalizacaoPorUUIDFiltrado(uuid, filtros) {
    setIsLoading(true);
    try {

      if(filtros.origem == "Origem") filtros.origem = '';

      const response = await api.post(
        `/location-history?page=1&quantidade=10&order=id&direction=desc&user=${uuid}`,
        filtros
      );

      const data = [
        {
          count: response.data.count,
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setHistoricoPagination(data);
      setHistoricoDeLocalizacaoPorUUID(response.data.items.rows);

      setIsLoading(false);
      return true;
    } catch (err) {
      Alert.error('Nenhum registro encontrado!');
      setHistoricoPagination([]);
      setHistoricoDeLocalizacaoPorUUID([]);
      setIsLoading(false);

      return false;
    }
  }

  //Paginação de Localizações
  async function localizacoesPage(page, uuid, filtros) {
    try {
      setIsLoading(true);

      if(filtros.origem == "Origem") filtros.origem = '';

      const response = await api.post(
        `/location-history?page=${page}&quantidade=10&order=id&direction=desc&user=${uuid}`,
        filtros
      );

      const data = [
        {
          count: response.data.count,
          current_page: response.data.current_page,
          next_page: response.data.next_page,
          prev_page: response.data.prev_page,
          last_page: response.data.last_page,
          total: response.data.total
        }
      ];
      setHistoricoPagination(data);
      setHistoricoDeLocalizacaoPorUUID(response.data.items.rows);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  async function downloadRelatorioHistoricoLocalizacao(uuid) {
    setIsLoading(true);

    try {
      const response = await api.get(`/location-history/relatorio/${uuid}`);

      window.location.assign(response.data.url);
      
      setIsLoading(false);
    } catch(err) {
      console.log(err);      

      setIsLoading(false);
    }
  }
  return (
    <ParceiroContext.Provider
      value={{
        isLoading,
        historicoDeLocalizacaoPorUUID,
        downloadRelatorioHistoricoLocalizacao,
        parceiroMapa,
        parceiroLista,
        parceiroPorUUID,
        getParceiroPorUuid,
        localizacoesPage,
        pagination,
        getParceiroPorNome,
        VisualizarParceiroPorUUID,
        orderParceiro,
        parceiroPage,
        VisualizarHistoricoDeLocalizacaoPorUUID,
        historicoPagination,
        setParceiroNome,
        parceiroNome,
        VisualizarHistoricoDeLocalizacaoPorUUIDFiltrado
      }}
    >
      {props.children}
    </ParceiroContext.Provider>
  );
};

export function useParceiro() {
  const context = useContext(ParceiroContext);

  return context;
}
