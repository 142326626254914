import React, { useEffect, useState } from "react";
import { Container, Label } from "./styled";

// Componente Global da pagina Config
import { Flex, Title } from "../styles";
import Input from "../../../components/UI/Input";
import globalStyle from "../../../styles/globalStyle";
import { useConfig } from "../../../hooks/configuracao";
import NumberFormat from "react-number-format";
import formataCampoPorcentagem from "../../../utils/formataCampoPorcentagem";

export default function Refino() {
    const { refino, setRefino } = useConfig();

    return (
        <Container>
            <Title>Refino</Title>
            <Flex style={{ marginBottom: 8 }}>
                <div style={{ width: 150 }} />
                <Label>
                    Pt
                </Label>
                <Label>
                    Pd
                </Label>
                <Label>
                    Rh
                </Label>
            </Flex>
            <Flex style={{ alignItems: 'center' }}>
                <Label>
                    {'Quotation (%)'}
                </Label>
                <NumberFormat
                    suffix="%"
                    type="%"
                    value={refino.cal_ptPercentual || ''}
                    onChange={(e) => setRefino({ ...refino, cal_ptPercentual: formataCampoPorcentagem(e.target.value) })}
                    width={99}
                    height={34}
                    style={{
                        width: 99,
                        height: 34,
                        borderRadius: 4,
                        padding: '0 16px',
                        marginRight: 50,
                        background: 'transparent',
                        border: `solid 1px ${globalStyle.colors.DARK400}`,
                        color: globalStyle.colors.GRAY400
                    }}
                />
                <NumberFormat
                    suffix="%"
                    type="%"
                    value={refino.cal_pdPercentual || ''}
                    onChange={(e) => setRefino({ ...refino, cal_pdPercentual: formataCampoPorcentagem(e.target.value) })}
                    width={99}
                    height={34}
                    style={{
                        width: 99,
                        height: 34,
                        borderRadius: 4,
                        padding: '0 16px',
                        marginRight: 50,
                        background: 'transparent',
                        border: `solid 1px ${globalStyle.colors.DARK400}`,
                        color: globalStyle.colors.GRAY400
                    }}
                />
                <NumberFormat
                    suffix="%"
                    type="%"
                    value={refino.cal_rhPercentual || ''}
                    onChange={(e) => setRefino({ ...refino, cal_rhPercentual: formataCampoPorcentagem(e.target.value) })}
                    width={99}
                    height={34}
                    style={{
                        width: 99,
                        height: 34,
                        borderRadius: 4,
                        padding: '0 16px',
                        marginRight: 50,
                        background: 'transparent',
                        border: `solid 1px ${globalStyle.colors.DARK400}`,
                        color: globalStyle.colors.GRAY400
                    }}
                />
            </Flex>
            <Flex style={{ alignItems: 'center', marginTop: 8 }}>
                <Label>
                    {'Charges ($)'}
                </Label>
                <Input
                    value={refino.cal_ptCusto || ''}
                    onChange={(e) => setRefino({ ...refino, cal_ptCusto: e.target.value })}
                    width={99}
                    height={34}
                    style={{ marginRight: 50, background: 'transparent', border: `solid 1px ${globalStyle.colors.DARK400}`, color: globalStyle.colors.GRAY400 }}
                />
                <Input
                    value={refino.cal_pdCusto || ''}
                    onChange={(e) => setRefino({ ...refino, cal_pdCusto: e.target.value })}
                    width={99}
                    height={34}
                    style={{ marginRight: 50, background: 'transparent', border: `solid 1px ${globalStyle.colors.DARK400}`, color: globalStyle.colors.GRAY400 }}
                />
                <Input
                    value={refino.cal_rhCusto || ''}
                    onChange={(e) => setRefino({ ...refino, cal_rhCusto: e.target.value })}
                    width={99}
                    height={34}
                    style={{ marginRight: 50, background: 'transparent', border: `solid 1px ${globalStyle.colors.DARK400}`, color: globalStyle.colors.GRAY400 }}
                />
            </Flex>
        </Container>
    )
}