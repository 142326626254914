import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 1rem;
  @media (max-width: 768px) {
    padding: 1rem 0px;
  }

  .rs-modal-body {
    overflow-x: hidden !important;
  }

  .rs-modal-footer {
    width: 100%;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .rs-form-group {
    width: 100%;
    margin-top: 1.25rem;
    img {
      border: 1px solid ${(props) => props.theme.colors.DARK400};
      padding: 5px;
    }

    label {
      color: ${(props) => props.theme.colors.GRAY100};
    }
  }

  input,
  select {
    width: calc(100% - 20px);
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;
    &::placeholder {
      color: var(--text-body);
    }

    & + input {
      margin-top: 0.25rem;
    }
  }

  h2 {
    width: 100%;
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 5px;
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-top: 20px;
  }
`;

export const TabelaCatalogo = styled.table`
  width: calc(100% - 10px);
  border-collapse: collapse;
  border-spacing: 0;
  margin: 10;
  padding: 10;
  border: 1px #ccc solid;

  tr {
    padding: 15px;
    margin-bottom: 10px;
    display: block;
    display: table-row;
    border-bottom-width: 1px;
    margin-bottom: 0;
  }

  td,
  th {
    border: 1px #ccc solid;
    padding: 5px;
  }

  thead {
    background: #fafafa;
  }

  thead tr:first-child th:first-child {
    background-color: #333;
    color: #fff;
  }

  tbody tr td {
    text-align: center;
    input,
    select {
      width: 100%;
      padding: 0 1.5rem;
      height: 2rem;
      border-radius: 0.25rem;
      border: 1px solid #eee;
      background: #eee;

      font-weight: 400;
      font-size: 1rem;
      &::placeholder {
        color: var(--text-body);
      }

      & + input {
        margin-top: 0.25rem;
      }
    }
  }
`;
export const StatusContainer = styled.div`
  margin: 10px 0px;
`;
