import api from '../services/api';
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { Alert } from 'rsuite';

const PerfilContext = createContext({});

export const PerfilProvider = (props) => {
  const [tipoPerfil, setTipoPerfil] = useState('');
  const [permissaoPerfil, setPermissao] = useState([]);
  const [permissaoPerfilDetalhes, setPermissaoDetalhes] = useState([]);
  const [dados, setDados] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPer, setIsLoadingPer] = useState(false);
  const [dadosDashboard, setDadosDashboard] = useState({})

  const handleUsuario = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get('/me');

    setDados(response.data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleUsuario();
  }, [handleUsuario]);

  const handleDadosDashboard = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get('/dashboard');

    setDadosDashboard(response.data);
  }, [])

  useEffect(() => {
    handleDadosDashboard();
  }, [handleDadosDashboard]);

  const handleUsuarioTipo = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get('/me');

    setTipoPerfil(response.data.tipo);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleUsuarioTipo();
  }, [handleUsuarioTipo]);

  const handleUsuarioPermissao = useCallback(async () => {
    setIsLoadingPer(true);
    const response = await api.get('/me');

    setPermissaoDetalhes(response.data.permissoes);

    const permissionName = response.data.permissoes.map(
      (perm) => perm.permissionName
    );

    setPermissao(permissionName);

    setIsLoadingPer(false);
  }, []);

  useEffect(() => {
    handleUsuarioPermissao();
  }, [handleUsuarioPermissao]);

  // EDITANDO OS USUÁRIOS
  async function editPerfil(PerfilInputEdit, uuid) {
    try {
      setIsLoading(false);

      const body = {};

      const valid = (text) => {
        return text !== null && text !== undefined && text !== '';
      };
      const validAvatar = (avatar) => {
        if (avatar === null || avatar === undefined || avatar === '')
          return false;

        const avatarRegex = /\/null/g;
        const resutl = avatarRegex.test(avatar);

        return !resutl;
      };
      if (valid(PerfilInputEdit.nome)) body.name = PerfilInputEdit.nome;
      if (valid(PerfilInputEdit.cpf)) body.cpf = PerfilInputEdit.cpf;
      if (valid(PerfilInputEdit.email)) body.email = PerfilInputEdit.email;
      if (valid(PerfilInputEdit.telefone))
        body.phone = PerfilInputEdit.telefone;
      if (valid(PerfilInputEdit.celular))
        body.cellphone = PerfilInputEdit.celular;
      if (valid(PerfilInputEdit.data_nascimento))
        body.birthday = PerfilInputEdit.data_nascimento;
      if (valid(PerfilInputEdit.peca)) body.piece = PerfilInputEdit.peca;
      if (valid(PerfilInputEdit.po)) body.dust = PerfilInputEdit.po;
      if (valid(PerfilInputEdit.inox)) body.inox = PerfilInputEdit.inox;
      if (valid(PerfilInputEdit.filtro)) body.filter = PerfilInputEdit.filtro;
      if (valid(PerfilInputEdit.banco)) body.bank = PerfilInputEdit.banco;
      if (valid(PerfilInputEdit.titular)) body.holder = PerfilInputEdit.titular;
      if (valid(PerfilInputEdit.titularCpf))
        body.holder_cpf = `${PerfilInputEdit.titularCpf}`;
      if (valid(PerfilInputEdit.agencia)) body.agency = PerfilInputEdit.agencia;
      if (valid(PerfilInputEdit.conta)) body.account = PerfilInputEdit.conta;
      if (valid(PerfilInputEdit.logradouro))
        body.address = PerfilInputEdit.logradouro;
      if (valid(PerfilInputEdit.numero)) body.number = PerfilInputEdit.numero;
      if (valid(PerfilInputEdit.bairro)) body.district = PerfilInputEdit.bairro;
      if (valid(PerfilInputEdit.cidade)) body.city = PerfilInputEdit.cidade;
      if (valid(PerfilInputEdit.estado)) body.state = PerfilInputEdit.estado;
      if (valid(PerfilInputEdit.tipo)) body.type = PerfilInputEdit.tipo;
      if(valid(PerfilInputEdit.password)) body.password = PerfilInputEdit.password;
      if (valid(PerfilInputEdit.status))
        body.status = `${PerfilInputEdit.status}`;

      const data = new FormData();
      data.append('body', JSON.stringify(body));
      if (validAvatar(PerfilInputEdit.avatar))
        data.append('avatar', PerfilInputEdit.avatar);

      const response = await api.post('/usuario/atualizar/' + uuid, data);

      Alert.success(response.data.message);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoading(false);
    }
  }

  async function updateSenha(PerfilSenha) {
    try {
      setIsLoading(false);

      const response = await api.put('/usuario/senha', PerfilSenha);

      Alert.success(response.data.message);
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoading(false);
    }
  }

  return (
    <PerfilContext.Provider
      value={{
        isLoading,
        setIsLoading,
        editPerfil,
        dadosDashboard,
        updateSenha,
        dados,
        tipoPerfil,
        permissaoPerfil,
        loadingPer
      }}
    >
      {props.children}
    </PerfilContext.Provider>
  );
};

export function usePerfil() {
  const context = useContext(PerfilContext);

  return context;
}
