import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.GRAY800};
`;

export const BodyText = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.theme.colors.GRAY600};
`;

export const BotaoAcaoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 38px;
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 1.5rem 0 0;
  label {
    font-weight: 400;
    white-space: nowrap;
    font-size: 0.9rem;
    display: block;
    color: ${(props) => props.theme.colors.GRAY900};
  }

  div {
    width: 30%;
    margin-right: 16px;

    button {
      margin-bottom: 0;
    }

    span {
      color: red;
    }
  }

  select,
  input {
    width: 100%;
    background-color: white;
    padding: 0 1.5rem;
    border-radius: 0.25rem;
    border: solid 1px ${(props) => props.theme.colors.GRAY050};
  }
`;
