import { useState } from 'react';
import { Modal, Button, FormGroup } from 'rsuite';
import * as S from './styles';
import { useCompra } from '../../hooks/compra';
import { useParams } from 'react-router-dom';

export function SearchCompraModal({ isOpenSearch, onRequestCloseSearch }) {
  const { searchCompra, isLoadingSearch } = useCompra();
  const { id } = useParams();

  const [codigo, setCodigo] = useState('');

  async function handlSearchExtrato(e) {
    e.preventDefault();

    let data = {
      codigo,
      usuario: id
    };

    await searchCompra(data, onRequestCloseSearch);
  }

  return (
    <Modal
      backdrop={'static'}
      show={isOpenSearch}
      onHide={onRequestCloseSearch}
    >
      <Modal.Header>
        <Modal.Title>Pesquisar</Modal.Title>
      </Modal.Header>
      <S.Container onSubmit={handlSearchExtrato}>
        <Modal.Body>
          <S.ContainerCardForm>
            <div className="card-form">
              <FormGroup>
                <label>Código</label>
                <input
                  type="text"
                  placeholder="Código"
                  value={codigo}
                  onChange={(event) => setCodigo(event.target.value)}
                />
              </FormGroup>
            </div>
          </S.ContainerCardForm>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            appearance="primary"
            style={{ marginTop: '20px' }}
          >
            {isLoadingSearch ? 'Carregando...' : 'Pesquisar'}
          </Button>
        </Modal.Footer>
      </S.Container>
    </Modal>
  );
}
