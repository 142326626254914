import styled from 'styled-components';

export const ContainerInput = styled.div`
    width: 100%;
    padding: 2px 5px;
	border: 1px solid #CCC;
    margin-top: 20px;
    text-align: left;
    border-radius: 3px;

    label {
        font-size: 0.7em;
        line-height: 1.2em;
        color: #666;
        text-align: left;
    }
    p {
        width: 100%;
        font-size: 1em;
        line-height: 1.5em;
        padding-left: 5px;
    }
    input,
    select {
        width: 100%;
        font-size: 1em;
        line-height: 1.5em;
        padding-left: 5px;
    }

    input[disabled] {
        background-color: #FFF;
        color: #999;
    }
    
`;

export const ButtonContainer = styled.div`
    width: 100%;
    text-align: center;
`;

export const Button = styled.button`
    width: 100px;
    background: brown;
    color: white;
    margin: 15px auto;
    padding: 5px 0;
    border-radius: 5px;
`;