import styled from "styled-components";

export const Container = styled.td`
    position: absolute;
    right: 36px;
    height: 140px;
    width: 332px;
`

export const Table = styled.table`
    thead {
        background: ${props => props.theme.colors.GRAY100};
        height: 21px;
        width: 100%;
        margin-bottom: 8px;
    }

    tbody {
        tr {
            text-align: center;

            td {
                color: ${props => props.theme.colors.GRAY900};
                height: 21px;
            }
        }
    }
`

export const TableHeader = styled.th`
    height: 21px;
    width: ${props => props.width ? props.width : 100}px;
    line-height: 21px;

`