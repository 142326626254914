const lista = [
    {
        usuario: {
            titulo: 'Usuário',
            rota: 'usuario',
            permissao: 'per_usuario',
            lista: {
                per_usuario_visualizar: 'Visualizar lista',
                per_usuario_add: 'Adicionar usuário',
                per_usuario_editar: 'Editar usuário',
            },
        },
        catalogo: {
            titulo: 'Catálogo',
            rota: 'catalogo',
            permissao: 'per_catalogo',
            lista: {
                per_catalogo_visualizar: 'Visualizar lista',
                per_catalogo_add: 'Adicionar catálogo',
                per_catalogo_editar: 'Editar catálogo',
            },
        },
        configuracao: {
            titulo: 'Configuração',
            rota: 'configuracao',
            permissao: 'per_configuracao',
            lista: {
                per_configuracao_visualizar: 'Visualizar lista'
            },
        },
        extrato: {
            titulo: 'Extrato',
            rota: 'extrato',
            permissao: 'per_extrato',
            lista: {
                per_extrato_editar: 'Editar extrato',
            },
        },
        compra: {
            titulo: 'Compras',
            rota: 'compra',
            permissao: 'per_compra',
            lista: {
                per_compra_visualizar: 'Visualizar lista',
                per_compra_editar: 'Editar compras',
            },
        },
        parceiro: {
            titulo: 'Parceiros',
            rota: 'parceiro',
            permissao: 'per_parceiro',
            lista: {
                per_parceiro_visualizar: 'Visualizar lista',
            },
        },
    }
];

let listaPermissao = lista.reduce((acc, obj) => [...acc, Object.values(obj).map(y => y)], []);

export default listaPermissao[0];