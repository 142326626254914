import styled from "styled-components";

export const Container = styled.div`
    width: 33%;
`

export const Title = styled.h2`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${props => props.theme.colors.GRAY900};
`

export const SaldoValor = styled.div`
    height: 38px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${props => props.color};
`

export const MaioresRegistrosContainer = styled.div`
    display: flex;
    align-items: center;
`

export const MaiorExtratoPositivo = styled.div`
    height: 18px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${props => props.theme.colors.GREEN600};
    margin-right: 6px;
`

export const MaiorExtratoNegativo = styled.div`
    height: 18px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${props => props.theme.colors.RED600};
    margin-left: 6px;
`

export const UltimaCompra = styled.div`
    div {
        display: flex;
        font-family: 'Lato';
        align-items: flex-end;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: ${props => props.theme.colors.GRAY500};
        span {
            font-size: 20px;
            line-height: 25px;
            color: ${props => props.theme.colors.GRAY900};
            margin-left: 6px;
        }
    }
`

export const UltimaCompraParceiro = styled.div`
    padding: 0 0 0 8px;
`

export const UltimaCompraData = styled.div`
    padding: 0 0 0 8px;

`

export const UltimaCompraTotal = styled.div`
    background: ${props => props.theme.colors.GRAY50};
    width: max-content;
    padding: 0 8px;
`