import { useEffect, useState } from 'react';
import { Modal, FormGroup, Placeholder } from 'rsuite';
import * as S from './styles';
import { useCatalogo } from '../../hooks/catalogo';
import Nav from '@rsuite/responsive-nav';
import { usePerfil } from '../../hooks/perfil';
import InnerImageZoom from 'react-inner-image-zoom';
import globalStyle from '../../styles/globalStyle';
import Button from '../UI/Button';

export function VisualizarCatalogoModal({
  isOpenView,
  onRequestCloseView,
  onOpenEditCatalogoModal
}) {
  const { catalogoPorUUID, getCatalogoPorUuid, isLoadingEdit, activePage } =
    useCatalogo();
  const [activeKey, setActiveKey] = useState('A');
  const { permissaoPerfil, tipoPerfil } = usePerfil();
  const [foto, setFoto] = useState(catalogoPorUUID[0]?.foto);

  const handleClickOpenEdit = async (uuid) => {
    await getCatalogoPorUuid(uuid, activePage);
    onOpenEditCatalogoModal();
    onRequestCloseView();
  };
  const { Paragraph } = Placeholder;

  useEffect(() => {
    const img = new Image();
    img.src = foto;

    img.onerror = () => {
      setFoto(null);
    };
  }, [foto]);
  return (
    <Modal backdrop={'static'} show={isOpenView} onHide={onRequestCloseView}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ color: globalStyle.colors.GRAY100 }}>
            Visualizar Produto
          </div>
        </Modal.Title>
      </Modal.Header>
      {isLoadingEdit ? (
        <Paragraph style={{ marginTop: 30 }} />
      ) : (
        <S.Container>
          <Modal.Body className={'modal_body'}>
            <Nav
              activeKey={activeKey}
              onSelect={setActiveKey}
              appearance="tabs"
            >
              <Nav.Item eventKey="A" className={'nav_item_modifier'}>
                <div style={{ color: globalStyle.colors.GRAY100 }}>
                  Dados Básicos
                </div>
              </Nav.Item>
              <Nav.Item eventKey="B" className={'nav_item_modifier'}>
                <div style={{ color: globalStyle.colors.GRAY100 }}>Tabela</div>
              </Nav.Item>
            </Nav>
            {activeKey == 'A' ? (
              <>
                <FormGroup>
                  <div>
                    {foto ? (
                      <InnerImageZoom
                        alt="Imagem"
                        className="w-100"
                        src={catalogoPorUUID[0].foto}
                        zoomScale={2}
                        startsActive={true}
                        hideCloseButton={true}
                        hideHint={true}
                        moveType={'drag'}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  {catalogoPorUUID[0]?.tipo ? (
                    <label>
                      Tipo:{' '}
                      {catalogoPorUUID[0]?.tipo == 1
                        ? 'Catalisador' // if
                        : catalogoPorUUID[0]?.tipo == 2
                        ? 'Pó comum' // else if
                        : catalogoPorUUID[0]?.tipo == 3
                        ? 'Inox' // else if
                        : catalogoPorUUID[0]?.tipo == 4
                        ? 'Filtro' // else if
                        : null}
                    </label>
                  ) : (
                    ''
                  )}
                </FormGroup>
                <FormGroup>
                  <label>
                    Marca: {catalogoPorUUID[0]?.marca || <Paragraph />}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label>
                    Modelo: {catalogoPorUUID[0]?.modelo || <Paragraph />}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label>
                    Código 1: {catalogoPorUUID[0]?.codigo1 || <Paragraph />}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label>
                    Código 2: {catalogoPorUUID[0]?.codigo2 || <Paragraph />}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label>
                    Código 3: {catalogoPorUUID[0]?.codigo3 || <Paragraph />}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label>
                    Preço: R$ {catalogoPorUUID[0]?.preco?.br || <Paragraph />}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label>
                    Definição:{' '}
                    {catalogoPorUUID[0]?.definition
                      ? catalogoPorUUID[0]?.definition.toUpperCase()
                      : catalogoPorUUID[0]?.definition || <Paragraph />}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label>
                    Classificação:{' '}
                    {catalogoPorUUID[0]?.targeting == 'analise'
                      ? 'ANÁLISE'
                      : catalogoPorUUID[0]?.targeting == 'corte especial'
                      ? 'CORTE ESPECIAL'
                      : catalogoPorUUID[0]?.targeting.toUpperCase() || (
                          <Paragraph />
                        )}
                  </label>
                </FormGroup>
                <FormGroup>
                  {catalogoPorUUID[0]?.status ? (
                    <label>
                      Status:{' '}
                      {catalogoPorUUID[0]?.status == 1
                        ? 'Ativo' // if
                        : catalogoPorUUID[0]?.status == 2
                        ? 'Inativo' // else if
                        : catalogoPorUUID[0]?.status == 3
                        ? 'Pendente para análise' // else if
                        : null}
                    </label>
                  ) : (
                    ''
                  )}
                </FormGroup>
              </>
            ) : null}
            {activeKey == 'B' ? (
              <>
                <FormGroup>
                  <S.TabelaCatalogo>
                    <thead>
                      <tr>
                        <th>Teor</th>
                        <th>Peso</th>
                        <th>PT</th>
                        <th>PD</th>
                        <th>RH</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>A</th>
                        <td>{catalogoPorUUID[0]?.A?.PESO || ''}</td>
                        <td>{catalogoPorUUID[0]?.A?.PT || ''}</td>
                        <td>{catalogoPorUUID[0]?.A?.PD || ''}</td>
                        <td>{catalogoPorUUID[0]?.A?.RH || ''}</td>
                      </tr>
                      <tr>
                        <th>B</th>
                        <td>{catalogoPorUUID[0]?.B?.PESO || ''}</td>
                        <td>{catalogoPorUUID[0]?.B?.PT || ''}</td>
                        <td>{catalogoPorUUID[0]?.B?.PD || ''}</td>
                        <td>{catalogoPorUUID[0]?.B?.RH || ''}</td>
                      </tr>
                      <tr>
                        <th>C</th>
                        <td>{catalogoPorUUID[0]?.C?.PESO || ''}</td>
                        <td>{catalogoPorUUID[0]?.C?.PT || ''}</td>
                        <td>{catalogoPorUUID[0]?.C?.PD || ''}</td>
                        <td>{catalogoPorUUID[0]?.C?.RH || ''}</td>
                      </tr>
                      <tr>
                        <th>D</th>
                        <td>{catalogoPorUUID[0]?.D?.PESO || ''}</td>
                        <td>{catalogoPorUUID[0]?.D?.PT || ''}</td>
                        <td>{catalogoPorUUID[0]?.D?.PD || ''}</td>
                        <td>{catalogoPorUUID[0]?.D?.RH || ''}</td>
                      </tr>
                    </tbody>
                  </S.TabelaCatalogo>
                </FormGroup>
              </>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            {tipoPerfil == 1 ||
            permissaoPerfil.includes('per_catalogo_editar') ? (
              <Button
                onClick={() =>
                  handleClickOpenEdit(catalogoPorUUID[0].uuid || '')
                }
                backGroundColor={globalStyle.colors.GREEN500}
                color={globalStyle.colors.GRAY100}
                style={{ marginTop: '20px' }}
                text={isLoadingEdit ? 'Carregando...' : 'Editar'}
              />
            ) : (
              ''
            )}
          </Modal.Footer>
        </S.Container>
      )}
    </Modal>
  );
}
