import styled from "styled-components";

export const Container = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: green;
    position: fixed;
    bottom: 25px;
    right: 25px;  
    background: ${props => props.theme.colors.GREEN500};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`