import {
  Modal,
  Button,
  FormGroup,
  Placeholder,
  Tooltip,
  Whisper
} from 'rsuite';
import * as S from './styles';
import { useUsuario } from '../../hooks/user';
import { usePerfil } from '../../hooks/perfil';
import { useEffect, useState } from 'react';
import PiecesHistory from '../VisualizarHistoricoDePecas';
import globalStyle from '../../styles/globalStyle';

export function VisualizarUserModal({
  isOpenView,
  onRequestCloseView,
  onOpenEditUserModal
}) {
  const {
    usuarioPorUUID,
    isLoading,
    getUsuarioPorUuid,
    activePage,
    isLoadingEdit,
    getPiecesHistory,
    piecesHistory,
    piecesHistoryPagination,
    piecesPage,
    openPiecesHistory,
    setOpenPiecesHistory
  } = useUsuario();
  const { permissaoPerfil, tipoPerfil } = usePerfil();

  const handleClickOpenEdit = async (uuid) => {
    await getUsuarioPorUuid(uuid, activePage);
    onOpenEditUserModal();
    onRequestCloseView();
  };

  const { Paragraph } = Placeholder;

  const tipos = {
    1: 'Administrador',
    2: 'Recebimento',
    3: 'Analisador',
    4: 'Parceiro',
    5: 'Parceiro Principal'
  };

  const handleOpenPiecesHistory = async (user) => {
    getPiecesHistory(user);
  };

  const handleClosePiecesHistory = async (open) => {
    setOpenPiecesHistory(false);
  };

  return (
    <>
      <Modal backdrop={'static'} show={isOpenView} onHide={onRequestCloseView}>
        <Modal.Header>
          <Modal.Title>
            <div style={{ color: globalStyle.colors.GRAY100 }}>
              Visualizar Usuário
            </div>
          </Modal.Title>
        </Modal.Header>
        {isLoadingEdit ? (
          <Paragraph style={{ marginTop: 30 }} />
        ) : (
          <S.Container>
            <Modal.Body>
              <S.ContainerCardForm>
                <FormGroup>
                  <label>
                    Tipo do usuário: {tipos[usuarioPorUUID[0]?.tipo] || ''}
                  </label>
                </FormGroup>
                {usuarioPorUUID[0]?.tipo != undefined &&
                usuarioPorUUID[0]?.tipo != '' ? (
                  <>
                    {usuarioPorUUID[0]?.tipo != undefined &&
                    (usuarioPorUUID[0]?.tipo == 4 ||
                      usuarioPorUUID[0]?.tipo == 5) ? (
                      <div
                        className="card-form"
                        style={{ marginBottom: '1rem', marginTop: '2rem' }}
                      >
                        <FormGroup>
                          <div>
                            {usuarioPorUUID[0]?.avatar ? (
                              <img
                                alt="Imagem"
                                src={usuarioPorUUID[0]?.avatar}
                                height="80px"
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </FormGroup>
                      </div>
                    ) : null}

                    {usuarioPorUUID[0].tipo == 4 ? (
                      <>
                        <div className="card-form">
                          <h2>Código do Parceiro Principal</h2>
                          <FormGroup>
                            <label>
                              Código:{' '}
                              {usuarioPorUUID[0]?.user_principal
                                ? `${usuarioPorUUID[0]?.user_principal}${
                                    usuarioPorUUID[0]?.user_principal_nome &&
                                    `- ${usuarioPorUUID[0]?.user_principal_nome}`
                                  }`
                                : ''}
                            </label>
                          </FormGroup>
                        </div>
                        <div className="card-form">
                          <h2>Código</h2>
                          <FormGroup>
                            <label>
                              Código: {usuarioPorUUID[0]?.codigo || ''}
                            </label>
                          </FormGroup>
                        </div>
                        <div className="card-form">
                          <h2>Limite</h2>
                          <FormGroup>
                            <label>
                              Limite: {usuarioPorUUID[0]?.limite?.real || ''}
                            </label>
                          </FormGroup>
                        </div>
                      </>
                    ) : null}
                    <div className="card-form">
                      <h2>Dados básicos</h2>
                      <FormGroup>
                        <label>Nome: {usuarioPorUUID[0]?.nome || ''}</label>
                      </FormGroup>
                      <FormGroup>
                        <label>CPF: {usuarioPorUUID[0]?.cpf.br || ''}</label>
                      </FormGroup>
                      <FormGroup>
                        <label>E-mail: {usuarioPorUUID[0]?.email || ''}</label>
                      </FormGroup>
                      <FormGroup>
                        <label>
                          Telefone: {usuarioPorUUID[0]?.telefone.br || ''}
                        </label>
                      </FormGroup>
                      <FormGroup>
                        <label>
                          Celular: {usuarioPorUUID[0]?.celular.br || ''}
                        </label>
                      </FormGroup>
                      <FormGroup>
                        <label>
                          Data de nascimento:{' '}
                          {usuarioPorUUID[0]?.data_nascimento.br || ''}
                        </label>
                      </FormGroup>
                    </div>
                    {usuarioPorUUID[0]?.tipo == 4 ||
                    usuarioPorUUID[0]?.tipo == 5 ? (
                      <>
                        <div className="card-form">
                          <h2>Endereço</h2>
                          <S.Row>
                            <S.Column>
                              <label>
                                Logradouro: {usuarioPorUUID[0]?.endereco || ''}
                              </label>
                            </S.Column>
                          </S.Row>
                          <S.Row>
                            <S.Column>
                              <label>
                                Número: {usuarioPorUUID[0]?.numero || ''}
                              </label>
                            </S.Column>

                            <S.Column>
                              <label>
                                Cidade: {usuarioPorUUID[0]?.cidade || ''}
                              </label>
                            </S.Column>

                            <S.Column>
                              <label>
                                Estado: {usuarioPorUUID[0]?.estado || ''}
                              </label>
                            </S.Column>
                          </S.Row>
                          <S.Column>
                            <label>
                              Bairro: {usuarioPorUUID[0]?.bairro || ''}
                            </label>
                          </S.Column>
                          <h2>Peça</h2>
                          <S.Row>
                            <S.Column>
                              <label>
                                Peça: {usuarioPorUUID[0]?.peca || ''}
                              </label>
                            </S.Column>

                            <S.Column>
                              <label>PO: {usuarioPorUUID[0]?.po || ''}</label>
                            </S.Column>
                          </S.Row>
                          <S.Column>
                            <label>Inox: {usuarioPorUUID[0]?.inox || ''}</label>
                          </S.Column>
                          <S.Column>
                            <label>
                              Filtro: {usuarioPorUUID[0]?.filtro || ''}
                            </label>
                          </S.Column>
                          {usuarioPorUUID[0]?.user_principal &&
                          usuarioPorUUID[0]?.parametro ? (
                            <S.Column>
                              <label>
                                Parâmetro: {usuarioPorUUID[0]?.parametro || ''}
                              </label>
                            </S.Column>
                          ) : null}
                          <S.Column>
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={<Tooltip>Historico das Peças</Tooltip>}
                              onClick={() => {
                                handleOpenPiecesHistory(
                                  usuarioPorUUID[0]?.uuid || ''
                                );
                              }}
                            >
                              <button>Historico</button>
                            </Whisper>
                          </S.Column>
                          <h2>Dados Bancários</h2>
                          <S.Row>
                            <S.Column>
                              <label>
                                Agência: {usuarioPorUUID[0]?.agencia || ''}
                              </label>
                            </S.Column>
                            <S.Column>
                              <label>
                                Banco: {usuarioPorUUID[0]?.banco || ''}
                              </label>
                            </S.Column>
                            <S.Column>
                              <label>
                                Conta: {usuarioPorUUID[0]?.conta || ''}
                              </label>
                            </S.Column>
                          </S.Row>
                          <S.Row>
                            <S.Column>
                              <label>
                                Titular: {usuarioPorUUID[0]?.titular || ''}
                              </label>
                            </S.Column>

                            <S.Column>
                              <label>
                                CPF do Titular:{' '}
                                {usuarioPorUUID[0]?.titular_cpf || ''}
                              </label>
                            </S.Column>
                          </S.Row>
                        </div>
                      </>
                    ) : null}
                    <div className="card-form">
                      <h2>Permissões</h2>
                      <FormGroup>
                        <label>
                          Status:{' '}
                          {usuarioPorUUID[0]?.status == 1 ? 'Ativo' : 'Inativo'}
                        </label>
                      </FormGroup>
                    </div>
                  </>
                ) : null}
              </S.ContainerCardForm>
            </Modal.Body>
            <Modal.Footer>
              {tipoPerfil == 1 ||
              permissaoPerfil.includes('per_usuario_editar') ? (
                <Button
                  onClick={() =>
                    handleClickOpenEdit(usuarioPorUUID[0]?.uuid || '')
                  }
                  style={{
                    marginTop: '20px',
                    backgroundColor: globalStyle.colors.GREEN500,
                    color: globalStyle.colors.GRAY100
                  }}
                >
                  {isLoading ? 'Carregando...' : 'Editar'}
                </Button>
              ) : (
                ''
              )}
            </Modal.Footer>
          </S.Container>
        )}
      </Modal>
      <PiecesHistory
        pieces={piecesHistory}
        close={handleClosePiecesHistory}
        show={openPiecesHistory}
        pagination={piecesHistoryPagination}
        handlePages={piecesPage}
        userUuid={usuarioPorUUID[0]?.uuid || ''}
      />
    </>
  );
}
