import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import * as S from './styles';
import { useParceiro } from '../../hooks/parceiro';
import { Loader } from 'rsuite';
import Skeleton from 'react-loading-skeleton';
import L from 'leaflet';
import { usePerfil } from '../../hooks/perfil';
import { Navigate } from 'react-router-dom';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Loading from '../Loading';
export function ParceiroMap() {
  let app = 'parceiro';
  const { parceiroMapa, isLoading } = useParceiro();

  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_' + app + '_visualizar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  function customIconCreateFunction(cluster) {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'marker-cluster-custom',
      iconSize: L.point(40, 40, true)
    });
  }

  return (
    <>
      <div>
        {isLoading ? (
          <Loader backdrop content="Carregando..." vertical />
        ) : (
          <S.Container>
            {loadingPer ? (
              <Skeleton />
            ) : (
              <MapContainer
                style={{ height: '100%', width: '100%' }}
                center={[-15.7934036, -47.8823172]}
                zoom={4}
                minZoom={3}
                maxZoom={18}
                maxBounds={[
                  [-180, 180],
                  [180, -180]
                ]}
                scrollWheelZoom={true}
                className="leaflet-map"
                tap={false}
              >
                <TileLayer
                  noWrap={true}
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <MarkerClusterGroup
                  maxClusterRadius={60}
                  disableClusteringAtZoom={17}
                  removeOutsideVisibleBounds={true}
                  options={customIconCreateFunction}
                >
                  {parceiroMapa
                    .filter(
                      (objeto) =>
                        objeto.latitude != null && objeto.longitude != null
                    )
                    .map((lista) => (
                      <Marker
                        key={lista.uuid}
                        position={[lista.latitude, lista.longitude]}
                      >
                        <Popup>
                          {lista.nome}
                          <br />
                          <br />
                          Data: {lista.data_local?.br}
                          <br />
                          <br />
                        </Popup>
                      </Marker>
                    ))}
                </MarkerClusterGroup>
              </MapContainer>
            )}
          </S.Container>
        )}
      </div>
      {parceiroMapa.length === 0 && !isLoading ? <Loading size="lg" /> : null}
    </>
  );
}
