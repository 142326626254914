import { useState } from 'react';
import { ExtratoTable } from '../../components/ExtratoTable/index';
import { EditExtratoModal } from '../../components/EditExtratoModal/index';
import { VerExtratoModal } from '../../components/VerExtratoModal/index';
import { SearchExtratoModal } from '../../components/SearchExtratoModal/index';
import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { ExtratoProvider, useExtrato } from '../../hooks/extrato';
import { Container, Header, Sidebar as Sidenav, Content, Loader } from 'rsuite';
import { usePerfil } from '../../hooks/perfil';
import { Navigate } from 'react-router-dom';
import globalStyle from '../../styles/globalStyle';

export function Extrato() {
  let app = 'extrato';
  const [isEditExtratoModalOpen, setIsEditExtratoModalOpen] = useState(false);
  const [isVerExtratoModalOpen, setIsVerExtratoModalOpen] = useState(false);
  const [isSearchExtratoModalOpen, setIsSearchExtratoModalOpen] =
    useState(false);
  const [isConfirmEditExtratoModalOpen, setIsConfirmDeleteExtratoModalOpen] =
    useState(false);

  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();
  const {
    isLoading
  } = useExtrato();

  if (
    loadingPer == false &&
    tipoPerfil != '' &&
    tipoPerfil != 1 &&
    permissaoPerfil.includes('per_' + app + '_visualizar') == false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }

  function handleOpenEditExtratoModal() {
    setIsEditExtratoModalOpen(true);
  }

  function handleCloseEditExtratoModal() {
    setIsEditExtratoModalOpen(false);
  }

  function handleOpenVerExtratoModal() {
    setIsVerExtratoModalOpen(true);
  }

  function handleCloseVerExtratoModal() {
    setIsVerExtratoModalOpen(false);
  }

  function handleOpenSearchExtratoModal() {
    setIsSearchExtratoModalOpen(true);
  }

  function handleCloseSearchExtratoModal() {
    setIsSearchExtratoModalOpen(false);
  }

  return (
    <>

      {isLoading || loadingPer ? <Loader backdrop content="Carregando..." vertical /> : null}

      <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
        <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
          <Sidebar activePage={'6'} />
        </Sidenav>
        <Container>
          <Header style={{ minHeight: '3.6rem' }}>
            <Topbar
              pageTitle={'Extrato'}
              onOpenSearchExtratoModal={handleOpenSearchExtratoModal}
            />
          </Header>
          <Content>
            <ExtratoTable
              onOpenEditExtratoModal={handleOpenEditExtratoModal}
              onOpenVerExtratoModal={handleOpenVerExtratoModal}
            />

            {isVerExtratoModalOpen ? (
              <VerExtratoModal
                isOpenVer={isVerExtratoModalOpen}
                onRequestCloseVer={handleCloseVerExtratoModal}
              />
            ) : null}

            {isEditExtratoModalOpen ? (
              <EditExtratoModal
                isOpenEdit={isEditExtratoModalOpen}
                onRequestCloseEdit={handleCloseEditExtratoModal}
              />
            ) : null}
            {isConfirmEditExtratoModalOpen ? <div></div> : null}
            {isSearchExtratoModalOpen ? (
              <SearchExtratoModal
                isOpenSearch={isSearchExtratoModalOpen}
                onRequestCloseSearch={handleCloseSearchExtratoModal}
              />
            ) : null}
          </Content>
        </Container>
      </Container>
    </>
  );
}
