import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from '../../../components/UI/Input';
import NumberFormat from '../../../components/UI/NumberFormat';
import Button from '../../../components/UI/Button';

import { useConfig } from '../../../hooks/configuracao';
import formataCampoPorcentagem from '../../../utils/formataCampoPorcentagem';

import { Container } from './styled';
import { Flex, Label, Title } from '../styles';
import globalStyle from '../../../styles/globalStyle';
import ConfirmaModal from '../../../components/ConfirmaModal';

export default function Parceiros({ setIsLoading }) {
    const [showConfirmaModal, setShowConfirmaModal] = useState(false);
    const { parceiros, setParceiros, atualizaTeor } = useConfig();

    const navigate = useNavigate();

    const handleCancelar = () => {
        navigate(-1)
    }

    const handleAtualiza = async () => {
        setIsLoading(true);
        await atualizaTeor();
        setIsLoading(false);
        setShowConfirmaModal(false);
    }

    return (
        <Container>
            <Title>Parceiros</Title>
            <Flex>
                <Label style={{ width: 150 }}>{'Peça (%)'}</Label>
                <Label style={{ width: 150 }}>{'Pó (%)'}</Label>
                <Label style={{ width: 150 }}>{'Inox (%)'}</Label>
                <Label style={{ width: 150 }}>{'Filtro (%)'}</Label>
            </Flex>
            <Flex style={{ marginTop: 8 }}>
                <NumberFormat
                    suffix="%"
                    type="text"
                    value={parceiros.peca || ''}
                    onChange={(e) => setParceiros({ ...parceiros, peca: formataCampoPorcentagem(e.target.value) })}
                    style={{
                        width: 99,
                        height: 34,
                        borderRadius: 4,
                        padding: '0 8px',
                        background: 'transparent',
                        border: `solid 1px ${globalStyle.colors.DARK400}`,
                        color: globalStyle.colors.GRAY400,
                        marginRight: 50
                    }}
                />
                <NumberFormat
                    value={parceiros.po || ''}
                    onChange={(e) => setParceiros({ ...parceiros, po: formataCampoPorcentagem(e.target.value) })}
                    suffix="%"
                    type="text"
                    style={{
                        width: 99,
                        height: 34,
                        borderRadius: 4,
                        padding: '0 8px',
                        background: 'transparent',
                        border: `solid 1px ${globalStyle.colors.DARK400}`,
                        color: globalStyle.colors.GRAY400,
                        marginRight: 50
                    }}
                />
                <NumberFormat
                    value={parceiros.inox || ''}
                    suffix="%"
                    type="text"
                    onChange={(e) => setParceiros({ ...parceiros, inox: formataCampoPorcentagem(e.target.value) })}
                    style={{
                        width: 99,
                        height: 34,
                        borderRadius: 4,
                        padding: '0 8px',
                        background: 'transparent',
                        border: `solid 1px ${globalStyle.colors.DARK400}`,
                        color: globalStyle.colors.GRAY400,
                        marginRight: 50
                    }}
                />
                <NumberFormat
                    value={parceiros.filtro || ''}
                    suffix="%"
                    type="text"
                    onChange={(e) => setParceiros({ ...parceiros, filtro: formataCampoPorcentagem(e.target.value) })}
                    style={{
                        width: 99,
                        height: 34,
                        borderRadius: 4,
                        padding: '0 8px',
                        background: 'transparent',
                        border: `solid 1px ${globalStyle.colors.DARK400}`,
                        color: globalStyle.colors.GRAY400,
                        marginRight: 50
                    }}
                />
            </Flex>
            <Flex
                style={{
                    marginTop: 65
                }}
            >
                <Button
                    width="132px"
                    height="40px"
                    type="file"
                    text="Cancelar"
                    uppercase
                    padding="0 0"
                    onClick={handleCancelar}
                    backGroundColor={globalStyle.colors.RED600}
                    color={'white'}
                    style={{
                        marginRight: 16
                    }}
                />
                <Button
                    width="112px"
                    height="40px"
                    type="file"
                    text="Atualizar"
                    uppercase
                    padding="0 0"
                    color={'white'}
                    backGroundColor={globalStyle.colors.GRAY100}
                    onClick={() => setShowConfirmaModal(true)}
                />
            </Flex>
            <ConfirmaModal
                title="Atualizar Configurações"
                show={showConfirmaModal}
                setShow={setShowConfirmaModal}
                bodyText="Confirmar essa ação ?"
                btnAcaoTexto="Atualizar"
                submitFn={handleAtualiza}
            />
        </Container>
    )
}