import { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Header,
  Sidebar as Sidenav,
  Content,
  Loader,
  Alert
} from 'rsuite';
import Topbar from '../../components/Topbar/index';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Navigate, useNavigate } from 'react-router-dom';
import { usePerfil } from '../../hooks/perfil';
import formatReal, { formatCotacao } from '../../utils/formatReal';
import api from '../../services/api';
import Button from '../../components/UI/Button';
import { ContentContainer, Flex, Label, ResetButton, Title } from './styles'
import ClassificacaoB2 from './ClassificacaoB2';
import Geral from './Geral';
import Refino from './Refino';
import Leasing from './Leasing';
import Drop from './Drop';
import OutrasTaxas from './OutrasTaxas';
import CustoOperacional from './CustoOperacional';
import Cotacoes from './Cotacoes';
import Parceiros from './Parceiros';
import globalStyle from '../../styles/globalStyle';
import { useConfig } from '../../hooks/configuracao';
import ConfirmaModal from '../../components/ConfirmaModal';

export function Configuracao() {
  let app = 'configuracao';
  const { permissaoPerfil, tipoPerfil, loadingPer } = usePerfil();
  const { atualizarConfigs, zerarDescontos, isLoading: loading } = useConfig();

  const navigate = useNavigate();

  const [showConfirmaModal, setShowConfirmaModal] = useState(false);
  const [showConfirmaZerarModal, setShowConfirmaZerarModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  if (
    loadingPer === false &&
    tipoPerfil !== '' &&
    tipoPerfil !== 1 &&
    permissaoPerfil.includes('per_' + app + '_visualizar') === false
  ) {
    return <Navigate replace to="/sem-permissao" />;
  }

  const handleCancelar = () => {
    navigate(-1)
  }

  const handleAtualiza = async () => {
    setIsLoading(true);
    await atualizarConfigs()
    setIsLoading(false)
    setShowConfirmaModal(false)
  }


  const handleZerar = async () => {
    setIsLoading(true);
    await zerarDescontos()
    setIsLoading(false)
    setShowConfirmaZerarModal(false)
  }
  return (
    <div>
      {
        isLoading || loading ? (
          <Loader backdrop center content="Carregando..." vertical size="lg" />
        ) : (
          <Container style={{ backgroundColor: globalStyle.colors.BACKGROUND_PRIMARY }}>
            <Sidenav style={{ minHeight: '100vh', maxWidth: '14.375rem' }}>
              <Sidebar activePage={'5'} />
            </Sidenav>
            <Container>
              <Header style={{ minHeight: '3.6rem' }}>
                <Topbar pageTitle={'Configurações'} />
              </Header>
              <Content>
                <ContentContainer>
                  <Flex>
                    <Geral />
                  </Flex>
                  <Flex>
                    <ClassificacaoB2 />
                    <Refino />
                  </Flex>
                  <Flex>
                    <Cotacoes />
                    <Parceiros setIsLoading={setIsLoading} />
                  </Flex>
                  <Flex
                    style={{ marginBottom: 16 }}
                  >
                    <Button
                      width="132px"
                      height="40px"
                      type="file"
                      text="Cancelar"
                      uppercase
                      padding="0 0"
                      onClick={handleCancelar}
                      backGroundColor={globalStyle.colors.RED600}
                      color='white'
                      style={{
                        marginRight: 16
                      }}
                    />
                    <Button
                      width="112px"
                      height="40px"
                      type="file"
                      text="Atualizar"
                      uppercase
                      padding="0 0"
                      color={'white'}
                      backGroundColor={globalStyle.colors.GRAY100}
                      onClick={() => setShowConfirmaModal(true)}
                    />
                  </Flex>
                  <Flex>
                    <div>
                      <Title>Peças</Title>
                      <Label>Zerar descontos</Label>
                      <ResetButton
                        width="132px"
                        height="40px"
                        type="file"
                        text="Zerar"
                        uppercase
                        padding="0 0"
                        onClick={() => setShowConfirmaZerarModal(true)}
                        color={'white'}
                        backGroundColor={globalStyle.colors.GRAY100}
                      />
                    </div>
                  </Flex>
                </ContentContainer>
              </Content>
            </Container>
          </Container>
        )
      }
      <ConfirmaModal
        title="Atualizar Configurações"
        show={showConfirmaModal}
        setShow={setShowConfirmaModal}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto="Atualizar"
        submitFn={handleAtualiza}
      />
      <ConfirmaModal
        title="Zerar Descontos"
        show={showConfirmaZerarModal}
        setShow={setShowConfirmaZerarModal}
        bodyText="Confirmar essa ação ?"
        btnAcaoTexto="Zerar"
        submitFn={handleZerar}
      />
    </div>
  );
}
