import styled from 'styled-components';
import { Box as BoxUI } from '@chakra-ui/core';
import { Image, Button } from '@chakra-ui/core';
import Input from '../../components/UI/Input';

export const Box = styled(BoxUI)(({ theme }) => ({
    backgroundColor: theme.colors.TRANSPARENT
}));

export const Logo = styled(Image)`
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
`;

export const InputSenha = styled(Input)`
    /* width: 213px; */
    height: 34px;
    margin-top: 3px;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 400;
    color: ${props => props.theme.colors.GRAY900};
`;

export const Botao = styled(Button)`
    &:hover{
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#525252')};
    }

    /* width: 213px; */
    font-size: 12px;
    font-family: 'Lato';
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#525252')};
`;